import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SolicitudAntiguoComponent } from './solicitud-antiguo/solicitud-antiguo.component';
import { SolicitudNuevoComponent } from './solicitud-nuevo/solicitud-nuevo.component';
import { BcPageHeaderModule } from '@bancolombia/design-system-web/bc-page-header';
import { BcCardModule } from '@bancolombia/design-system-web/bc-card';
import { BcButtonModule } from '@bancolombia/design-system-web/bc-button';
import { SolicitudAntiguoRechazarComponent } from './solicitud-antiguo-rechazar/solicitud-antiguo-rechazar.component';
import { SolicitudNuevoRechazarComponent } from './solicitud-nuevo-rechazar/solicitud-nuevo-rechazar.component';
import { AppRoutingModule } from 'src/app/app-routing.module';



@NgModule({
  declarations: [
    SolicitudAntiguoComponent,
    SolicitudNuevoComponent,
    SolicitudAntiguoRechazarComponent,
    SolicitudNuevoRechazarComponent
  ],
  exports:[
    SolicitudAntiguoComponent,
    SolicitudNuevoComponent
  ],
  imports: [
    CommonModule,
    BcPageHeaderModule,
    BcCardModule,
    BcButtonModule,
    AppRoutingModule
  
  ]
})
export class ServicioMensajeModule { }
