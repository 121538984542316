<!DOCTYPE html>
<html>
<head>
  <meta name="viewport" content="width=device-width, initial-scale=0.8">
</head>
<body>

  <div class="bc-content bc-container-solid bc-bg-light">
    <ng-container *ngIf="isLoadingData">
      <load-aba-component></load-aba-component>
    </ng-container>
  </div>
  
  
  <ng-container *ngIf="contractDetail !== undefined; else NoEncontrar">
    <div class="container">
      <app-detalles-contrato-user [contractDetail]="contractDetail" [contractChild]="contractChild" [contractMaster]="contractMaster" [userData]="userData.data"></app-detalles-contrato-user>
    </div>
  </ng-container>

  <ng-template #NoEncontrar>
    <div class="no-novedades-message">
      <img src="../../../assets/images/pic-lock-error.svg" alt="No Novedades">
      <p>¡Oh no! Parece que no eres administrador/negociador de este contrato. Sigue atento para futuras actualizaciones.</p>
    </div>
  </ng-template>
</body>
</html>
