<div class="bc-p-3 bc-bg-white">
    <div class="bc-container">
        <div class="bc-row">
            <div class="bc-col-6">
                <div class="bc-container">
                    <div class="bc-col-2">
                        <bc-icon  size="2xl"  name="user-form" [ariaHidden]="false" ariaLabel="name"></bc-icon>
                    </div>
                    <div class="Diseño-items">
                        <div class="bc-col-8">
                            <b>
                                <span>Id del Contrato</span>
                            </b>
                            
                        </div>
                        <div class="bc-col-4">
                            <div >
                                <p>{{novedad.contractId.idContract}}</p>
                            </div>
                        </div>
                    </div>

                </div>
                
                <br>
                <br>
                <!-- FECHA DE RADICACIÓN EXACTA DEL DIA -->
                <!-- <h3>Fecha de radicación</h3>
                <div class="bc-margin-top-2">
                    <h5> {{novedad.contractId.tariffExpiry}}</h5>
                </div>
                <br> -->
                <!-- ADMINSITRADOR -->
                <div class="bc-container">

                    <div class="bc-col-2">
                        <bc-icon  size="2xl"  name="user-check" [ariaHidden]="false" ariaLabel="name"></bc-icon>
                    </div>
                    <div class="Diseño-items">
                        <div class="bc-col-8">
                            <b>
                                <span>Iniciador</span>
                            </b>
                            
                        </div>
                        <div class="bc-col-7">
                            <div >
                                <p>{{novedad.contractId.idUserAdmin}}</p>
                                <span>Rol: Administrador de contrato</span>
                            </div>
                        </div>
                    </div>
                    

                </div>

                <br>
                <br>
                <br>
                
                <div class="bc-container">
                    <div class="bc-col-2">
                        <bc-icon  size="2xl"  name="approving-user" [ariaHidden]="false" ariaLabel="name"></bc-icon>
                    </div>
                    <div class="Diseño-items">
                        <div class="bc-col-8">
                            <b>
                                <span>Negociador asignado al contrato</span>
                            </b>
                        </div>
                        <div class="bc-col-4">
                                <p>{{novedad.contractId.idUserNegotiator}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- TYPE REQUIEREMENT -->
            <div class="bc-col-6">
                <div class="bc-container">

                    <div class="bc-col-2">
                        <bc-icon  size="2xl"  name="value" [ariaHidden]="false" ariaLabel="name"></bc-icon>
                    </div>

                    <div class="Diseño-items">
                        <div class="bc-col-8">
                            <b>
                                <span>Tipo de requerimiento</span>
                            </b>
                        </div>
                        <div class="bc-col-10">
                                <p>{{novedad.typeRequirement}}</p>
                        </div>
                    </div>
                </div>
                <br>
                <br>
                <!-- <div class="bc-container">


                    <div class="bc-row">
                        <div class="bc-col-3">
                            <p>Antiguo</p>
                        </div>

                        <div class="bc-col-3">
                            <p>-></p>
                        </div>
                        <div class="bc-col-3">
                            Nuevo
                        </div>
                    </div>
                </div> -->

                <!-- Observaciones -->

                <div class="bc-container">
                    <div class="bc-col-2">
                        <bc-icon  size="2xl"  name="chat" [ariaHidden]="false" ariaLabel="name"></bc-icon>
                    </div>

                    <div class="Diseño-items">
                        <div class="bc-col-8">
                            <b>
                                <span>Observaciones</span>
                            </b>
                        </div>
                        <div class="bc-col-7">
                                <p>{{novedad.description}}</p>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <!-- <br>
        <div class="bc-w-100">
            <app-offcanvas-novedades-negociador-solicitud [idContrato]="requierement.contractId.idContract" [tipoRequerimiento]="requierement.typeRequirement" [textButton]="'Gestionar Flujo Trabajo'"></app-offcanvas-novedades-negociador-solicitud>

        </div>
        <br> -->
        <br>

        <div *ngIf="novedad.status !== 'COMPLETADO' && novedad.status !== 'CERRADO' && novedad.status !== 'ACEPTADO' && novedad.status !== 'RECHAZADO'" >
            <app-novedad-negociador-accordion-cerrar-ariba
        [novedad]="novedad"
        [idContrato]="novedad.contractId.idContract"
        [tipoRequerimiento]="novedad.typeRequirement"
        [textButton]="'Cerrada solicitud ariba'"
        [requirementId]="novedad.requirementId">

        </app-novedad-negociador-accordion-cerrar-ariba>
        </div>
        

        <!-- <div *ngIf="novedad.status !== 'COMPLETADO' && novedad.status !== 'CERRADO'" >
            <button bc-button class="bc-margin-top-2 bc-button-primary bc-button-primary"
        (click)="onNoveltySend(requirementTypeEnum.REPORTE_ACEPTADO_NEGOCIADOR, requirementStatusEnum.Cerrado)">
            Cerrada solicitud en Ariba
        </button>
        </div> -->
        
    </div>


</div>