<bc-form-field>
    <input bc-input type="text" id="01" class="bc-input-icon-right" placeholder="" value="AYAZO COGOLLO JOSE MANUEL"/>
    <label for="">Nombre Delegado Actual</label>
</bc-form-field>
<br>
<bc-form-field>
    <textarea id="02" bc-input aria-label="text-area" type="text" placeholder="" value=""
        required></textarea>
    <label for="02">Nombre del nuevo delegado*</label>
</bc-form-field>
<br>
<bc-form-field>
    <textarea id="02" bc-input aria-label="text-area" type="text" placeholder="" value=""
        required></textarea>
    <label for="02">Correo del nuevo delegado*</label>
</bc-form-field>
<br>
<!--Parrafo-->
<bc-form-field>
    <textarea id="02" bc-input aria-label="text-area" type="text" placeholder="" value=""
        required></textarea>
    <label for="02">Observaciones</label>
</bc-form-field>