import { Injectable, EventEmitter } from '@angular/core';


@Injectable({providedIn: 'root'})
export class InforamtionServices {

  private _showInformationAler = new EventEmitter<any>();
  get showInformationAler(): EventEmitter<any> {
    return this._showInformationAler;
  };

  private _showInformationLoad = new EventEmitter<any>();
  get showInformationLoad(): EventEmitter<any> {
    return this._showInformationLoad;
  };

}
