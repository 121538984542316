import { Component, Input, OnInit } from '@angular/core';
import { BcDropdownBehavior, BcListBehavior } from '@bancolombia/design-system-behaviors'
import { formatearFechaCorta } from 'src/app/components/utils/formato-fecha.componente';;
import { ContratoLite } from '../../../models/contratos.interface';
import { Router } from '@angular/router';
import { BcAlertComponent } from '@bancolombia/design-system-web/bc-alert';
import { BcDialogService } from '@bancolombia/design-system-web/bc-services';
import { IdContract } from 'src/app/models/contract-favorite.model';
import { ContratosService } from 'src/app/services/api-services/contratos-services/contratos.service';

@Component({
  selector: 'app-list-item-contrato',
  templateUrl: './list-item-contrato.component.html',
  styleUrls: ['./list-item-contrato.component.css']
})
export class ListItemContratoComponent implements OnInit {
  //Inputs
  @Input() contract!: any;
  @Input() class?: string;

  ngOnInit(): void {
    console.log(this.contract);
    
    BcListBehavior.setUpAll();
    BcDropdownBehavior.setUpAll({
      isPopUp: true
    })
  }
  dateformat(date: any) {
    return date != null ? formatearFechaCorta(new Date(date)) : ""
  }

  constructor(private dialogService: BcDialogService, private contratosServices: ContratosService, private router: Router) {}


  alertAba(type: String, menssage: String, tittle: string): void {
    const dialogRef = this.dialogService.open(BcAlertComponent, {
      id: tittle,
      type: type,
      text: menssage,
      timeout: 7000,
    });

    dialogRef.onResult().subscribe(
      (closed) => {},
      (dismissed) => {},
      () => {}
    );
  }

 

  verificarYRedireccionar(idContract: string): void {
    // Realiza la petición al backend antes de redirigir
    this.contratosServices.getContractByIdContract(idContract).subscribe(
        (success) => {
            // Si la petición tiene éxito, realiza la redirección
            const url = `/contratos/detallesContrato/${idContract}`;
            this.router.navigateByUrl(url);
            
        },
        (error) => {
            // Si hay un error, muestra la alerta
            console.error('Error al obtener el contrato:', error);
            this.alertAba('error', `No se puede ingresar al detalle porque no eres el administrador del contrato`, 'Error');
        }
    );
}

compareDates(a: any, b: any): number {
  const dateA = new Date(a.initialDate);
  const dateB = new Date(b.initialDate);
  return dateB.getTime() - dateA.getTime();
}

}
