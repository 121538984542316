import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PopoutComponent } from '../../detalles-contrato/popout/popout.component';
import { BcModalComponent } from '@bancolombia/design-system-web/bc-modal';
import { BcOffCanvasComponent } from '@bancolombia/design-system-web/bc-off-canvas/bc-off-canvas.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ver-detalles',
  templateUrl: './ver-detalles.component.html',
  styleUrls: ['./ver-detalles.component.css']
})
export class VerDetallesComponent {

  @Output() closeOffCanvasEvent = new EventEmitter<void>();

  @Input() selectedContract: any;

  @ViewChild('offCanvasComponent',
    {
      static: true,
    })

  //Variables Internas Grupo Bancolombia
  bcOffCanvasComponent: BcOffCanvasComponent | any;
  bcModalComponent: BcModalComponent | any;
  PopOutComponent: PopoutComponent | any;
  tipoOffcanvas = '';
  backdrop = '';
  alturaHorizontal = '';
  backdrops = [
    {
      label: 'Activo',
      value: '',
    },
    {
      label: 'Apagado',
      value: 'off',
    },
  ];
  alturasHorizontal = [
    {
      label: '90vh',
      value: '90vh',
    },
    {
      label: '50vh',
      value: '50vh',
    },
    {
      label: '40vh',
      value: '40vh',
    },
  ];
  /*Abre el offcanvas.*/
  openOffCanvas() {
    this.bcOffCanvasComponent.openOffCanvas();
  }

  constructor(private router: Router) {

  }
  showDetails(contract: any) {
    this.selectedContract = contract;
    this.openOffCanvas();
  }
  closeOffCanvas() {
    this.closeOffCanvasEvent.emit();
  }

  onContractClick(contractId: number): void {
    this.router.navigate(['/contratos/detallesContrato', contractId]);
  }



}
