import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { BcDialogService } from '@bancolombia/design-system-web/bc-services';
import { Contrato } from 'src/app/models/contratos.interface';
import { EstadoServicesService } from 'src/app/services/estado-services/estado-service';
import { BcAlertComponent } from '@bancolombia/design-system-web/bc-alert';
import { formatearFechaCorta } from 'src/app/components/utils/formato-fecha.componente';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-detalles-contrato-user',
  templateUrl: './detalles-contrato-user.component.html',
  styleUrls: ['./detalles-contrato-user.component.css']
})
export class DetallesContratoUserComponent {

  @Input('contractDetail') _contract!: any;
  @Input('contractChild') contractsChilds!: any;
  @Input('contractMaster') contractMaster!: any;
  @Input ('userData') userData!: any;
  
    /**
 * Objeto que representa el contrat.
 * @typedef {Object} Contrato
 * @property {string} idContrato - Identificador del contrato.
 * @property {Object} administrador - Información del administrador del contrato.
 * @property {string} administrador.usuarioRed - Usuario de red del administrador.
 * @property {string} administrador.nombre - Nombre del administrador.
 * @property {string} administrador.correo - Correo electrónico del administrador.
 * @property {Object} negociador - Información del negociador del contrato.
 * @property {string} negociador.usuarioRed - Usuario de red del negociador.
 * @property {string} negociador.nombre - Nombre del negociador.
 * @property {string} negociador.correo - Correo electrónico del negociador.
 * @property {Object} proveedor - Información del proveedor del contrato.
 * @property {string} proveedor.id - Identificador del proveedor.
 * @property {string} proveedor.nombre - Nombre del proveedor.
 * @property {string} proveedor.nit - NIT del proveedor.
 * @property {string} proveedor.nombreComercial - Nombre comercial del proveedor.
 * @property {string} proveedor.correoComercial - Correo electrónico del contacto comercial del proveedor.
 * @property {string} proveedor.contactoComercial - Número de contacto del contacto comercial del proveedor.
 * @property {Object} estado - Información del estado del contrato.
 * @property {number} estado.idEstado - Identificador del estado.
 * @property {string} estado.estado - Descripción del estado.
 * @property {Array<Object>} contratosHijos - Lista de contratos hijos del contrato.
 * @property {string} contratosHijos.idContrato - Identificador del contrato hijo.
 * @property {Object} contratosHijos.proveedor - Información del proveedor del contrato hijo.
 * @property {string} contratosHijos.proveedor.id - Identificador del proveedor.
 * @property {string} contratosHijos.proveedor.nombre - Nombre del proveedor.
 * @property {string} contratosHijos.proveedor.nit - NIT del proveedor.
 * @property {Object} contratosHijos.estado - Información del estado del contrato hijo.
 * @property {number} contratosHijos.estado.idEstado - Identificador del estado.
 * @property {string} contratosHijos.estado.estado - Descripción del estado.
 * @property {string} contratosHijos.nombre - Nombre del contrato hijo.
 * @property {Object} contratoPadre - Información del contrato padre.
 * @property {string} contratoPadre.idContrato - Identificador del contrato padre.
 * @property {Object} contratoPadre.proveedor - Información del proveedor del contrato padre.
 * @property {string} contratoPadre.proveedor.id - Identificador del proveedor.
 * @property {string} contratoPadre.proveedor.nombre - Nombre del proveedor.
 * @property {string} contratoPadre.proveedor.nit - NIT del proveedor.
 * @property {Object} contratoPadre.estado - Información del estado del contrato padre.
 * @property {number} contratoPadre.estado.idEstado - Identificador del estado.
 * @property {string} contratoPadre.estado.estado - Descripción del estado.
 */

  contrato: Contrato = {
    idContrato: "CW102",
    administrador: {
      usuarioRed: "rafa",
      nombre: "Rafael Muñoz",
      correo: "rafa@bancolombia.com.co",
    },
    negociador: {
      usuarioRed: "pepe",
      nombre: "Jose Perez",
      correo: "pepe@bancolombia.com.co",
    },
    proveedor: {
      id: "ACM120",
      nombre: "IBM",
      nit: "9999999-9",
      nombreComercial: "Pedro Nel Osorio",
      correoComercial: "posorio@gmv.com.co",
      contactoComercial: "3003458757",
    },
    estado: {
      idEstado: 1,
      estado: "Borrador",
    },
    contratosHijos: [
      {
        idContrato: "CW567",
        proveedor: {
          id: "ACM120",
          nombre: "IBM",
          nit: "9999999-9",
        },
        estado: {
          idEstado: 1,
          estado: "Modificación del borrador",
        },
        nombre: "Licencias de software",
      },
      {
        idContrato: "CW567",
        proveedor: {
          id: "ACM120",
          nombre: "IBM",
          nit: "9999999-9",
        },
        estado: {
          idEstado: 1,
          estado: "Congelado",
        },
        nombre: "Licencias de software",
      },
      {
        idContrato: "CW567",
        proveedor: {
          id: "ACM120",
          nombre: "IBM",
          nit: "9999999-9",
        },
        estado: {
          idEstado: 1,
          estado: "Pendiente",
        },
        nombre: "Licencias de software",
      }
    ],
    contratoPadre: {
      idContrato: "CW100",
      proveedor: {
        id: "ACM120",
        nombre: "IBM",
        nit: "9999999-9",
      },
      estado: {
        idEstado: 1,
        estado: "Publicado",
      },
      nombre: "Licencias de software",
    },
    nombre: "Licencias IBM DataStage",
    descripcion: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae ab temporibus enim pariatur id iusto ullam officia dolores, ratione quaerat, veniam dolor deserunt ex? Sed animi consequatur debitis eaque distinctio.  Autem minus ad nam incidunt libero vitae eaque unde deleniti delectus assumenda dicta, omnis soluta ullam, corrupti laudantium quae officiis corporis harum. Amet quia illo, possimus id tempore quam temporibus?",
    fechaInicio: new Date("2020-01-15"),
    fechaFinal: new Date("2024-01-15"),
    vencimientoTarifa: new Date("2022-10-17"),
    tipoAcuerdo: "Contrato marco",
    areaResponsable: "Abastecimiento Bancolombia",
    familia: "Canales digitales",
    proximoVencer: false,
    esFavorito: true,
    tipoVigencia: "Indefinido",
  };



  dateformat(date: any) {
    return date != null ? formatearFechaCorta(new Date(date)) : ""
  }

  //Copiar Administrador
  @ViewChild('correoAdministrador', { static: false }) correoElement?: ElementRef;


  copiarCorreoAdministrador() {
    const correo = this.correoElement?.nativeElement.textContent;
    navigator.clipboard.writeText(correo);
  }


  @ViewChild('correoDelegado', { static: false }) correoElementDelegado?: ElementRef;


  /**
 * Copia el correo electrónico del delegado al portapapeles.
 * 
 * @returns {void}
 */
  copiarCorreoDelegado() {
    const correo = this.correoElementDelegado?.nativeElement.textContent;
    navigator.clipboard.writeText(correo);
  }


  @ViewChild('correoNegociador', { static: false }) correoElementNegociador?: ElementRef;


  copiarCorreoNegociador() {
    const correo = this.correoElementNegociador?.nativeElement.textContent;
    navigator.clipboard.writeText(correo);
  }


  @ViewChild('correoProveedorComercial', { static: false }) correoElementProveedorComercial?: ElementRef;

  
  /**
 * Copia el correo electrónico del proveedor al portapapeles.
 * 
 * @returns {void}
 */
  copiarCorreoProveedorComercial() {
    const correo = this.correoElementProveedorComercial?.nativeElement.textContent;
    navigator.clipboard.writeText(correo);

  }

  estadoSeleccionado: string | null = null;

  constructor(
    private estadoService: EstadoServicesService,
    private dialogService: BcDialogService,
    private router: Router,
    private http: HttpClient
    ) {
    this.estadoSeleccionado = this.estadoService.getEstadoSeleccionado();
    console.log('Estado seleccionado recibido:', this.estadoSeleccionado);
  }

  compareDates(a: any, b: any): number {
    const dateA = new Date(a.fechaInicio);
    const dateB = new Date(b.fechaInicio);
  
    return dateA.getTime() - dateB.getTime();
  }
  
  
  

  // ...

  getFormattedDate(date: string | null): string {
    if (!date) {
      return 'Fecha no disponible';
    }
    
    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) {
      return 'Fecha inválida';
    }

    return parsedDate.toLocaleDateString();
  }

  alertAba(type: String, menssage: String, tittle: string): void {
    const dialogRef = this.dialogService.open(BcAlertComponent, {
      id: tittle,
      type: type,
      text: menssage,
      timeout: 7000,
    });

    dialogRef.onResult().subscribe(
      (closed) => {},
      (dismissed) => {},
      () => {}
    );
  }

  mostrarReportarCambioEstado: boolean = false;

  toggleReportarCambioEstado() {
  this.mostrarReportarCambioEstado = !this.mostrarReportarCambioEstado;
}


//Ruta
goBack() {
  this.router.navigate(['./contratos']);
  }


  ordenarMayorMenor(): void {
    this.contractsChilds.sort((a: any, b: any) => {
      const dateA = a.finalDate ? new Date(a.finalDate) : null;
      const dateB = b.finalDate ? new Date(b.finalDate) : null;
  
      if (dateA && dateB) {
        return dateB.getTime() - dateA.getTime();
      } else if (dateA) {
        return -1;
      } else if (dateB) {
        return 1;
      } else {
        return 0;
      }
    });
    
  }
  
  ordenarMenorMayor(): void {
    this.contractsChilds.sort((a: any, b: any) => {
      const dateA = a.finalDate ? new Date(a.finalDate) : null;
      const dateB = b.finalDate ? new Date(b.finalDate) : null;
  
      if (dateA && dateB) {
        return dateA.getTime() - dateB.getTime();
      } else if (dateA) {
        return 1;
      } else if (dateB) {
        return -1;
      } else {
        return 0;
      }
    });
  }


  ascendente: boolean = true; 

  ordenarPorFecha(orden: string): void {
    this.contractsChilds.sort((a: any, b: any) => {
      const dateA = a[orden] ? new Date(a[orden]) : null;
      const dateB = b[orden] ? new Date(b[orden]) : null;
      
      if (dateA && dateB) {
        return this.ascendente ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
      } else if (dateA) {
        return -1;
      } else if (dateB) {
        return 1;
      } else {
        return 0;
      }
    });
    this.ascendente = !this.ascendente; // Cambiar el estado de la variable
  }

  /*descargarPDF() {
    const url = 'https://neoris0-my.sharepoint.com/personal/juan_sserna_neoris_com/Documents/Document%20(1)%201.pdf';
    this.http.get(url, { responseType: 'blob' }).subscribe(response => {
      const blob = new Blob([response], { type: 'application/pdf' });
      const urlArchivo = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = urlArchivo;
      a.download = 'Algebra-de-A-Baldor.pdf';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(urlArchivo);
      document.body.removeChild(a);
    });
  }
  */
  construirURL(): string {
    const baseUrl = 'https://apps.powerapps.com/play/e/030e1206-2356-eb46-b90a-be6ab6655b0d/a/47c50454-b1bb-42aa-a177-2a8deb5c3e84?tenantId=b5e244bd-c492-495b-8b10-61bfd453e423&hint=90f36883-f672-4a21-b591-770114f970e8&sourcetime=1716905748448&source=portal';
    const tenantId = 'b5e244bd-c492-495b-8b10-61bfd453e423';
    const hint = 'cfce1c92-2a51-4251-a1cf-6904ce8b1696';
    const sourcetime = '128736766';
    const source = 'portal';
    const screenname = 'Cierre_contratos';   
    return  `${baseUrl}?id_contrato=${this._contract.idContract}`;
  }

  abrirEnNuevaPestana() {
    const url = this.construirURL();
    window.open(url, '_blank');
  }
  
}
