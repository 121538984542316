<div class="bc-p-3 bc-bg-white">

    <div class="bc-container">
        <div class="bc-row">
            <div class="bc-col-6">
                <h5>ID Contrato</h5>
                <p>{{novedad.contrato.idContrato}}</p>
                <br>
                <h5>Fecha De Radicación</h5>
                <p>{{novedad.fechaRadicacion | date: 'MMMM d, y, h:mm:ss a'}}</p>
                <br>
                <h5>Iniciador </h5>
                <p>Elizabeth Orozco Vargas</p>
                <br>
                <h5>Negociador asignado al contrato</h5>
                <p>Jose Manuel Ayazo</p>
                <br>
            </div>

            <div class="bc-col-6">
                <h5>Tipo Requerimiento</h5>
                <P>{{novedad.tipoNovedad.nombre}}</P>
                
                <div class="bc-row">
                    <div class="bc-col-xs-6 bc-col-sm-5 bc-col-md-4 bc-col-lg-1 bc-col-xl-1 bc-col-xxl-1">
                        <p>Elizabeth Orozco</p>
                        <span style="color:gray;"> Administrador Anterior</span>
                    </div>
                    <div class="bc-col-xs-3 bc-margin-left-5 bc-col-sm-4 bc-col-md-4 bc-col-lg-1 bc-col-xl-1 bc-col-xxl-1"> →</div>
                    <div class="bc-col-xs-4 bc-col-sm-5 bc-col-md-4 bc-col-lg-1 bc-col-xl-9 bc-col-xxl-1">
                        <p>
                            Cesar Agudelo
                            <span style="color:gray;"> Nuevo Administrador</span>
                        </p>
                    </div>               
                 </div>
                <br>
                <h5>Observaciones</h5>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et.</p>
                <br>
            </div>
        </div>
        
        <div class="bc-w-100 bc-margin-top-3">
            <app-off-canvas-operador-porta-novedad [textButton]="'Gestionar Flujo Trabajo'"></app-off-canvas-operador-porta-novedad>
        </div>
    </div>
    <div class="bc-margin-top-3">
        <hr>
    </div>
    <!--Tabla-->
    <div>
        <bc-table-container class="bc-w-100" [dataTable]="dataTable">
            <bc-table-content>
                <div class="bc-align-left bc-p-3">
                </div>
                <table caption="tabla" bc-table class="bc-p-2">
                    <thead>
                        <tr class="bc-bg-brand-primary-01">
                            <th scope="row" bc-cell type="total">Fecha</th>
                            <th scope="row" bc-cell type="total">Estado novedades</th>
                            <th scope="row" bc-cell type="total">Participantes</th>
                            <th scope="row" bc-cell type="total">Notas</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of novedad.historialEstados">
                            <td>{{item.fecha | date: 'MMMM d, y, h:mm:ss a'}}</td>
                            <td>
                                <!--app-badge-estado [clase]="'novedad'" [estadoNovedad]="item.estado.nombre"
                                    [tipo]="'simple'"></app-badge-estado-->
                            </td>
                            <td>
                                <p>Jose Manuel Ayazo</p>
                            </td>
                            <td>Se realiza el cambio de estado en la herramienta y se actualizan los
                                correspondientes aplicativos.</td>
                        </tr>
                    </tbody>
                </table>
            </bc-table-content>
        </bc-table-container>
    </div>
</div>