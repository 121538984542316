export enum TableNameItem{
    IDFORM      = "ID Forms",
    STATE       = "Estadode  asignación",
    NAME        = "Nombre EVC/CdeE/Funcional",
    REQUEST     = "Solicitante",
    REQUESTRUN  = "¿Solicitud o en curso?",
    DESCRIPTION = "Descripcion de la necesidad",
    BADGET      = "Cuenta con presupuesto",
    MONEY       = "Valor presupuestado",
    PROVIDER    = "Proveedor(es) propuestos"
}