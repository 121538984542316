<section (click)="verificarYRedireccionar(contract.idContract)" class="bc-list bc-flex bc-direction-column bc-direction-lg-row bc-align-items-start {{class}}">
    <section class="bc-py-2 bc-col">
        <p class="bc-list-font bc-text-left">Contrato</p><b>{{contract.idContract}}</b>
    </section>
    <section class="bc-py-1 bc-flex bc-direction-column bc-col">
        <small>Proveedor</small>
        <ng-container *ngif="contract.providerEntity.providerName && contract.providerEntity.providerName.length > 0; else noProvider">
            <p><b>{{contract.providerEntity.providerName}}</b></p>
        </ng-container>
        
        <ng-template #noProvider>
            <p>
                <b>
                    No se registra proveedor
                </b>
            </p>
        </ng-template>
    </section>
    <section class="bc-py-1 bc-flex bc-direction-column bc-col">
        <small>Proyecto</small>
        <p><b>{{contract.contractName}}</b></p>
    </section>
    <section class="bc-py-1 bc-flex bc-direction-column bc-col">
        <small>Estado</small>
        <app-badge-estado [clase]="'contrato'" [tipo]="'simple'"
            [estadoContrato]="
            contract.status.charAt(0).toUpperCase() +
            contract.status.slice(1).toLowerCase()
            "></app-badge-estado>
    </section>
    <section class="bc-py-1 bc-flex bc-direction-column bc-col">
        <small>Fecha de entrada en vigencia</small>
        <p>
            <b>
                {{ contract.initialDate ? dateformat(contract.initialDate) : 'Fecha de vencimiento no disponible' }}
            </b>
        </p>
    </section>
    <section class="bc-py-1 bc-flex bc-direction-column bc-col">
        <small>Fecha de vencimiento</small>
        <p>
            <b>
                {{ contract.finalDate ? dateformat(contract.finalDate) : 'Fecha de vencimiento no disponible' }}
            </b>
        </p>
    </section>
    <div class="bc-list-action-icon bc-padding-left-2">
        <em class="bc-icon bc-st bc-dropdown-toggle" aria-controls="myDropdown4" tabindex="0">arrow2-right</em>
    </div>
</section>


