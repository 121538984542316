<div class="bc-content bc-container-solid bc-bg-light bc-p-3">
	<bc-page-header [date]='fecha' [title]="tittle" [bgWhite]="false" [className]="'bc-bg-light'">
	</bc-page-header>
</div>

<!--SearchBar-->
<app-operador-novedades-searchbar></app-operador-novedades-searchbar>

<!--Accordion-->
<bc-accordions-group [className]="'bc-margin-top-2'">
	<bc-accordion type="column" *ngFor="let novedad of getNovedades()" class="bc-mb-4">
		<bc-accordion-header title="ID {{novedad.idNovedad}}" [columns]="getAccordionColumns(novedad)"></bc-accordion-header>
		<bc-accordion-content>	
			<app-operador-novedad-accordion [novedad]="novedad"></app-operador-novedad-accordion>
		</bc-accordion-content>
	</bc-accordion>
</bc-accordions-group>
