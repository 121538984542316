import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuFilterPipe } from './MenuFilter/menu-filter.pipe';



@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule
  ]
})
export class PipesModule { }
