import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BcStatusModule } from '@bancolombia/design-system-web/bc-status';
import { BcTableModule } from '@bancolombia/design-system-web/bc-table';
import { BcCardContainerModule } from '@bancolombia/design-system-web/bc-card-container';
import { BcIconButtonModule } from '@bancolombia/design-system-web/bc-icon-button';
import { BcPageHeaderModule } from '@bancolombia/design-system-web/bc-page-header';
import { ComponentsModule } from "../../components/components.module";
import { DashboardAdminComponent } from './dashboard-admin/dashboard-admin.component';
import { DashboardUserComponent } from './dashboard-user/dashboard-user.component';
import { DashboardComponent } from './dashboard.component';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BcIconModule } from '@bancolombia/design-system-web/bc-icon';
import {BcAlertModule} from '@bancolombia/design-system-web/bc-alert' 
import {ScrollingModule} from '@angular/cdk/scrolling';
import {BcStrokeModule} from '@bancolombia/design-system-web/bc-stroke';
import { RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { LoadABAComponent } from 'src/app/components/load-aba/load-aba.component';





@NgModule({
    declarations: [
        DashboardAdminComponent,
        DashboardUserComponent,
        DashboardComponent,
        
    ],
    exports: [
        DashboardAdminComponent,
        DashboardUserComponent,
        
    ],
    imports: [
        CommonModule,
        ComponentsModule,
        // SDBC Modules
        BcStatusModule,
        BcTableModule,
        BcCardContainerModule,
        BcIconButtonModule,
        BcPageHeaderModule,
        // Custom modules
        NgxChartsModule,
        BrowserModule,
        HttpClientModule,
        ScrollingModule,
        BcIconModule,
        BcAlertModule,
        BcStrokeModule,
        RouterModule,
        BcStatusModule
    ]
})
export class DashboardModule { }
