import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/api-services/user-services/user.service';

/**
 * Componente para mostrar el dashboard de la aplicación.
 * Implementa el ciclo de vida OnInit y se utiliza para obtener los datos del usuario desde el UserService.
 */
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  // Variables Usuario
  userData: any;
  userDataAvailable = false;

  /**
   * Constructor del componente DashboardComponent.
   * @param {UserService} userService - Servicio para obtener los datos del usuario autenticado.
   */
  constructor(private userService: UserService) {}

  /**
   * Método del ciclo de vida de Angular OnInit.
   * Se ejecuta una vez que el componente ha sido inicializado.
   * Aquí se obtienen los datos del usuario autenticado desde el UserService y se almacenan en la variable userData.
   */
  ngOnInit(): void {
    this.userService.getUserDataSubject().subscribe((userData) => {
      this.userData = userData.data;
      this.userDataAvailable = true;
    });
  }
}
