<div class="Encabezado">
  <img src="../../../../assets/images/Header-Solicitudes.png">
</div>
<div class="bc-content">
  <bc-page-header [date]="fecha"  [bgWhite]="true">
  </bc-page-header>

  <div *ngIf="dataLoaded == false">
    <load-aba-component></load-aba-component>
  </div>
</div>

<div class="bc-text-center">
  <div>
    <p>
      En esta página encuentras las solicitudes de los nuevos contratos que pueden estar bajo tu responsabilidad.
      <br>
      Acéptalas si están a tu cargo o recházalas si no lo están.
    </p>
  </div>
</div>
<br>

<div class="bc-padding-3">
  <ng-container *ngIf="requirement.length > 0; else noSolicitudes">
    <bc-accordions-group>
      <bc-accordion type="column" *ngFor="let requirement of requirement">
        <bc-accordion-header title="ID: {{requirement.contractId.idContract}}"
          [columns]="columnsForRequirement(requirement)"></bc-accordion-header>
        <bc-accordion-content>
          <div class="bc-margin-top-1 bc-p-3">
            <p>Haz recibido una solicitud para ser asignado como nuevo Administrador del siguiente Contrato:</p>
          </div>
          <!-- <div class="bc-margin-top-1 bc-p-3" *ngIf="this.userData.rol == 'DELEGADO'">
            <p>Haz recibido una solicitud para ser asignado como Delegado del siguiente Contrato:</p>
          </div> -->
          <div class="bc-container bc-margin-top-2">
            <div class="bc-row">
              <div class="bc-col-6">
                <div class="bc-container">
  
                  <div class="bc-col-2">
                    <bc-icon size="2xl" name="user-form" [ariaHidden]="false" ariaLabel="name"></bc-icon>
                  </div>
                  <div class="bc-col-8">
                    <p>ID contrato</p>
                  </div>
                  <div class="bc-col-4">
                    <div>
                      <p>{{requirement.contractId.idContract}}</p>
                    </div>
                  </div>
  
                </div>
  
              </div>
  
              <div class="bc-col-6">
  
                <div class="bc-container">
                  <div class="bc-col-2">
                      <bc-icon  size="2xl"  name="calendar-check" [ariaHidden]="false" ariaLabel="name"></bc-icon>
                  </div>
                  <div class="bc-col-8">
                    <p>Fecha radicación</p>
                  </div>
                  <div class="bc-col-4">
                      <div>
                        <p>{{ requirement.initialDate | date: 'yyyy-MM-dd' }}</p>
                      </div>
                  </div>
  
              </div>
          
              </div>
            </div>
          </div>
  
          <div class="bc-container bc-margin-top-2">
            <div class="bc-row">
              <div class="bc-col-6">
  
                <div class="bc-container">
                  <div class="bc-col-2">
                      <bc-icon  size="2xl"  name="user-check" [ariaHidden]="false" ariaLabel="name"></bc-icon>
                  </div>
                  <div class="bc-col-8">
                    <p>Proveedor</p>
                  </div>
                  <div class="bc-col-5">
                      <div >
                        <p>{{requirement.contractId.providerEntity.providerName}}</p>
                      </div>
                  </div>
  
              </div>
  
              </div>
              <div class="bc-col-6">
  
                <div class="bc-container">
                  <div class="bc-col-2">
                      <bc-icon  size="2xl"  name="approving-user" [ariaHidden]="false" ariaLabel="name"></bc-icon>
                  </div>
                  <div class="bc-col-8">
                    <p>Solicitud asignada por: </p>
                  </div>
                  <div class="bc-col-6">
                      <div >
                        <p>{{requirement.requestId.name}}</p>
                      </div>
                  </div>
              </div>
              </div>
            </div>
          </div>
  
          <div class="bc-margin-top-2 bc-p-3">
            
            <b>
              <p>Descripción del proyecto</p>
            </b>
            <div class="bc-row">
              <div class="bc-col-6">
                <p>
                  {{requirement.contractId.description}}
                </p>
              </div>
            </div>
          </div>
  
          <div class="bc-margin-top-2 bc-p-3">
            <p>Si necesitas mayor información sobre tu rol como administrador y responsabilidades del mismo, puedes
              consultarlo a través de nuestro sitio "Administradores de Contratos/Servicios con Proveedores" haciendo clic
              <a href="https://bancolombia.sharepoint.com/sites/co-dca/SitePages/rol-administrador.aspx?" target="_blank" class="bc-button">Aquí</a>
            </p>
          </div>
  
          <!-- ... (otras secciones del HTML) ... -->
  
          <ng-container *ngIf="!requirement.hasActionTaken && requirement.status === 'PENDIENTE'">
            <div class="bc-container bc-margin-top-2">
              <div class="bc-row">
                <div class="bc-col-xs-3 bc-col-sm-2 bc-col-md-2 bc-col-lg-3 bc-col-xl-2 bc-col-xxl-2">
                  <button bc-button (click)="aceptarSolicitud(requirement)">Aceptar</button>
                </div>
                <div class="bc-col-xs-1 bc-col-sm-2 bc-col-md-2 bc-col-lg-1 bc-col-xl-2 bc-col-xxl-2">
                  <button bc-button (click)="rechazarSolicitud(requirement)">Rechazar</button>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- ... (otras secciones del HTML) ... -->
          <ng-container *ngIf="requirement.hasActionTaken">
            <div class="bc-margin-top-2">
              <b>{{ requirement.actionMessage }}</b>
            </div>
          </ng-container>
  
        </bc-accordion-content>
      </bc-accordion>
    </bc-accordions-group>
  </ng-container>
</div>



<ng-template #noSolicitudes>
  <div class="no-solicitudes-message">
    <img src="../../../assets/images/il-sv-process.svg" alt="No Solicitudes">
    <p>¡Oh no! Parece que actualmente no tienes solicitudes pendientes. Mantente atento para futuras actualizaciones.
    </p>
  </div>
</ng-template>