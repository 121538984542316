import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardFechaComponent } from './card-fecha/card-fecha.component';
import { BcIconModule } from '@bancolombia/design-system-web/bc-icon';
import { BcCardContainerModule } from '@bancolombia/design-system-web/bc-card-container';
import { BcIconButtonModule } from '@bancolombia/design-system-web/bc-icon-button';
import { BcBreadcrumbModule } from '@bancolombia/design-system-web/bc-breadcrumb';
import { BcButtonModule } from '@bancolombia/design-system-web/bc-button';
import { ComponentsModule } from "../../components/components.module";
import { BcModalModule } from '@bancolombia/design-system-web/bc-modal';
import { BcOffCanvasModule } from '@bancolombia/design-system-web/bc-off-canvas';
import { BcInputModule } from '@bancolombia/design-system-web/bc-input';
import {BcIllustrationModule} from '@bancolombia/design-system-web/bc-illustration';
import { RouterModule } from '@angular/router'
import { FormsModule } from '@angular/forms';
import { BcTooltipModule } from '@bancolombia/design-system-web/bc-tooltip';
import { FavIconButtonComponent } from 'src/app/components/fav-icon-button/fav-icon-button.component';
import { BcTabsModule } from '@bancolombia/design-system-web/bc-tabs-group';
import {BcInputSelectModule} from '@bancolombia/design-system-web/bc-input-select'
import { ReactiveFormsModule } from '@angular/forms';
import { MainPageComponent } from './main-page/main-page.component';
import { DetallesContratoNegociadorComponent } from './detalles-contrato-negociador/detalles-contrato-negociador.component';
import { DetallesContratoNegociadorHeaderComponent } from './detalles-contrato-negociador-header/detalles-contrato-negociador-header.component';
import { ListItemContratoNegociadorComponent } from './list-item-contrato-negociador/list-item-contrato-negociador.component';


@NgModule({
  declarations: [
    MainPageComponent,
    CardFechaComponent,
    DetallesContratoNegociadorComponent,
    DetallesContratoNegociadorHeaderComponent,
    ListItemContratoNegociadorComponent,
  ],
  imports: [
    CommonModule,
        BcIconModule.forRoot({
            path: "https://library-sdb.apps.bancolombia.com/bds/6.12.10"
        }),
        BcCardContainerModule,
        ReactiveFormsModule,
        BcIconButtonModule,
        BcBreadcrumbModule,
        BcButtonModule,
        ComponentsModule,
        BcModalModule,
        BcOffCanvasModule,
        BcInputModule,
        BcIllustrationModule,
        RouterModule,
        FormsModule,
        BcTooltipModule,
        BcTabsModule,
        BcInputSelectModule,
        ComponentsModule
  ]
})
export class DetallesContratoNegociadorModule { }
