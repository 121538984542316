import { Component } from '@angular/core';

@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.css']
})
export class LogInComponent {

  username: string = "";
  password: string = "";

  constructor() {}

  submitLoginForm() {
    // Agrega tu lógica personalizada para el manejo del formulario de inicio de sesión
    // Puedes acceder a los valores de usuario y contraseña utilizando this.username y this.password
  }
}
