<div class="bc-page-header bc-bg-light" [class]="randomBackgroundColorClass">
    <div class="bc-container bc-page-header-content">
        <div class="bc-container">
            <div class="bc-row">
                <!-- Parte 1 -->
                <br>
                <div class="bc-col-1">
                    <img src="../../../../assets/icons/ilustrations/BB_24px_RGB_v01_documento_entrega.png"
                        style="width: 100px;">
                </div>
                <div class="bc-col-7">
                    <div class="bc-margin-top-3">
                        <b>
                            <span class="info-label">Proveedor: </span>
                            <span class="info-value">{{ contrato.providerEntity?.providerName || 'Proveedor no disponible' }}</span>
                        </b>
                    </div>
                    <div>
                        <b>
                            <span class="info-label_con">Contrato: </span>
                            <span class="info-value_con">{{ contrato.idContract || 'Contrato no disponible' }}</span>
                        </b>
                    </div>
                </div>
                
                <div class="bc-col-4">

                    <div class="bc-page-header-content-right">
                        <div class="bc-page-header-content-right">
                            <p>{{fecha}}</p>
                        </div>
                        <app-fav-icon-button [contractData]="contrato" (favoriteToggled)="onFavoriteToggled($event)" class="large-star-icon"></app-fav-icon-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>