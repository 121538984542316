import { Injectable } from '@angular/core';
import { Observable, Subject, catchError, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { apiPaths } from '../api-services-paths';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public userData: any;
  public userDataSubject = new Subject<any>();

  constructor(private http: HttpClient) {
    // Cargar los datos del usuario al iniciar el servicio
    this.loadUserData();
    //console.log("Datos del usuario almacenados en el servicio:", this.userData);
  }

  /**
   * Carga los datos del usuario desde el backend y los almacena localmente.
   */
  private loadUserData(): void {
    this.getUserDataFromBackend().pipe(
      catchError((error) => {
        console.error('Error al obtener los datos del usuario:', error);
        return [];
      })
    ).subscribe((userData) => {
      this.userData = userData;
      this.userDataSubject.next(userData);
    });
  }

  /**
   * Obtener los datos del usuario autenticado desde el backend.
   * @returns {Observable<any>} Observable que contiene los datos del usuario autenticado.
   */
  private getUserDataFromBackend(): Observable<any> {
    return this.http.get<any>(apiPaths.getUser).pipe(
      catchError((error) => {
        console.error('Error en la petición getUserDataFromBackend:', error);
        return []
      })
    )
  }


  /**
   * Obtener los datos del usuario almacenados localmente en el servicio.
   * @returns {any} Datos del usuario almacenados en el servicio.
   */
  public getCurrentUserData(): any {
    //console.log("estos son los datos de geCurrentUserData: ",this.userData);
    return this.userData;
  }

  /**
   * Método para suscribirse a los datos del usuario almacenados en el Subject.
   * @returns {Observable<any>} Observable que contiene los datos del usuario.
   */
  public getUserDataSubject(): Observable<any> {
    console.log("observable",this.userDataSubject);
    
    return this.userDataSubject.asObservable();
  }
}
