<!DOCTYPE html>
<html>
<head>
  <meta name="viewport" content="width=device-width, initial-scale=0.8">
</head>
<body>

  <div class="bc-content bc-container-solid bc-bg-light">
    <ng-container *ngIf="isLoadingData">
      <load-aba-component></load-aba-component>
    </ng-container>
  </div>
  

  <div class="container" *ngIf="contractDetail != {} ">
        <app-detalles-contrato-negociador [contractDetail]="contractDetail" [contractChild]="contractChild" [contractMaster] = "contractMaster" [userData] = "userData"></app-detalles-contrato-negociador>
  </div>
</body>
</html>

