import { Component } from '@angular/core';
import { formatearFecha } from 'src/app/components/utils/formato-fecha.componente';
import { UserService } from 'src/app/services/api-services/user-services/user.service';

@Component({
  selector: 'app-solicitud-nuevo-rechazar',
  templateUrl: './solicitud-nuevo-rechazar.component.html',
  styleUrls: ['./solicitud-nuevo-rechazar.component.css']
})
export class SolicitudNuevoRechazarComponent {
  
   //Variables
   userData: any = {};
 
   // Cambiar por el nombre del usuario loggeado.
 
   tittle = "Hola" + this.userData?.user?.nombre;
 
   constructor(private userService: UserService) { }
 
   fecha:any;
 ngOnInit(){
   this.mostrarHora();
   
   //Obtener Nombre
   // this.authService.getUserData().subscribe(data => {
   //   this.userData = data;
   //   this.tittle = "Hola " + this.userData?.user?.nombre;
   // });
 
   this.userData = this.userService.getCurrentUserData();
 }
 
   /**
 * Actualiza la hora actual en la variable 'fecha' cada segundo.
 */
   mostrarHora(){
     this.fecha = formatearFecha(new Date());
     setInterval(()=>{
       this.fecha = formatearFecha(new Date());
     },1000);
   }

}
