import { Injectable } from '@angular/core';
import { UserService } from '../api-services/user-services/user.service';

/**
 * Servicio encargado de verificar los permisos del usuario para mostrar módulos en la aplicación.
 * Utiliza el UserService para obtener la información del usuario y sus permisos.
 */
@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor(private userService: UserService) { }

  /**
   * Verifica si el usuario tiene permisos para mostrar un módulo específico.
   * @param {string} moduleName - El nombre del módulo que se desea verificar.
   * @returns {boolean} true si el usuario tiene permisos para mostrar el módulo, false en caso contrario.
   */
  canShowModule(moduleName: string): boolean {
    // Obtener los datos del usuario desde el UserService
    const userData = this.userService.getCurrentUserData();
    // Verificar si el usuario tiene permisos para mostrar el módulo
    if (userData && userData.data && userData.data.modules && userData.data.rol) {
      // Utiliza el método some() para verificar si algún módulo coincide con el moduleName y tiene el permiso "show".
      
      return userData.data.modules.some((module: any) => module.module === moduleName && module.permissions.includes('show'));
    }
    // Si no se encuentra información suficiente sobre los permisos del usuario, se retorna false.
    return false;
  }
}
