<div class="bc-content bc-container-solid bc-bg-light">
	<div class="bc-row">
		<div class="bc-col-12">
			<div class="Encabezado">
				<img src="../../../assets/images/Header-Contratos.png">
			</div>
			<bc-page-header [date]="fecha" [title]="tittle" [bgWhite]="true">
			</bc-page-header>
		</div>
	</div>

	<div *ngIf="dataLoaded == false">
		<load-aba-component></load-aba-component>
	</div>
	
</div>

<div class="bc-text-center">
	<div>
		<p>
			Aquí encuentras los contratos que están bajo tu responsabilidad.
			<br>
			¿Quieres conocer más información? Ingresa al ID del Contrato.
		</p>
	</div>
</div>



<!-- Table-->

<div class="bc-content bc-container-solid bc-bg-light">
	<app-tabla-contratos *ngIf="dataLoaded" [data]="data">
	</app-tabla-contratos>
</div>


<div class="bc-text-center bc-margin-top-2">
	<p>¿Quieres ver más información de tus contratos? </p>
	<div class="bc-margin-top-1">
		<div class="boton-ariba-redireccion">
			<a href="https://s1.ariba.com/Sourcing/Main/aw?awh=r&awssk=ykGhN22y0HCLXD5G&realm=bancolombia&dard=1"
				target="_blank" class="bc-button">Ingresa a Ariba</a>
		</div>
	</div>
	<!-- <iframe id="miIframe" src="https://s1.ariba.com/Sourcing/Main/aw?awh=r&awssk=VShd1foNvYOfYLLw&realm=bancolombia&awrdt=1#b0" allow="geolocation; microphone; camera"></iframe> -->


</div>



<!-- <app-tabla-contratos *ngIf="dataLoaded" [data]="filteredData">
</app-tabla-contratos> -->


<!-- <router-outlet></router-outlet> -->