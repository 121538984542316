import { Component, Input, OnInit } from '@angular/core';
import { InforamtionServices } from 'src/app/services/commo-service/information-services';

@Component({
  selector: 'load-aba-component',
  templateUrl: './load-aba.component.html',
  styleUrls: ['./load-aba.component.css']  
})
export class LoadABAComponent implements OnInit {
  constructor(private inforamtionServices: InforamtionServices){} 
  ngOnInit() {}
 
}

