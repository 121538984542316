import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { formatearFecha } from 'src/app/components/utils/formato-fecha.componente';
import { Contrato, ContratoLite, ContratoProveedor } from 'src/app/models/contratos.interface';
import { ContratosService } from 'src/app/services/api-services/contratos-services/contratos.service';
import { NovedadesService } from 'src/app/services/api-services/novedades-services/novedades.service';
import { UserService } from 'src/app/services/api-services/user-services/user.service';
import { InforamtionServices } from 'src/app/services/commo-service/information-services';
import { BcDialogService } from '@bancolombia/design-system-web/bc-services';
import { BcAlertComponent } from '@bancolombia/design-system-web/bc-alert';
import { FavoriteModel } from 'src/app/models/contract-favorite.model';
import { Chart } from 'chart.js';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CONTRACTITEMS, CONTRACTLOAD } from 'src/app/state/contract_state/contract.action';
import { selectorContractItem, selectorContractList } from 'src/app/state/contract_state/contract.selector';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { UserGraphServices } from 'src/app/services/api-services/graph-services/user-graph.services';
import { HttpClient } from '@angular/common/http';
import { RequirementService } from 'src/app/services/api-services/requirement-services/requierement.service';
import { SendDataInforamtionServices } from 'src/app/services/commo-service/sendData-services';


@Component({
  selector: 'app-dashboard-user',
  templateUrl: './dashboard-user.component.html',
  styleUrls: ['./dashboard-user.component.css']
})
export class DashboardUserComponent implements OnInit{
    
  //Variables
  userData: any = {};
  tittle = ". ¡Qué gusto verte otra vez!";
  public Familiachart : any;
  public contractBeaterCount: number = 0;
  public contractNextBeatenCount: number = 0;
  public contractInitialCount: number = 0;
  public total : number = 0;
  protected currentUser: any;
  loading: boolean = true;


 
  // Cambiar por el nombre del usuario loggeado.

  public contractFavorite: FavoriteModel[] = [];
  public contractFNexinal: any = [];
  public contractByProviderCount: any = [];
  public contractNextFinalDTOList : any = [];
  public countContractByuser: number = 0;

  constructor(private contratosServices: ContratosService,private dialogService: BcDialogService,
    private novedadesServices: NovedadesService, private router: Router, private userGraphServices: UserGraphServices,
    private inforamtionServices: InforamtionServices,
    private store: Store<any>,
    private userService: UserService,
    private requirementService: RequirementService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private sendDataInforamtionServices: SendDataInforamtionServices, private infortionServices: InforamtionServices){
  }


  /**
 * Obtiene una lista de contratos agrupados por Favs.
 * @returns {ContratoLite[]} Una lista de objetos ContratoProveedor.
 */
  public async showMoreFavorites() {
    const list: any[] = [];
    await this.contractFavorite.map((data: any) => {
      this.store.dispatch(CONTRACTLOAD({ idContract: data.idContract }));
      this.store.select(selectorContractItem).pipe().subscribe(
        (success: any) => {
          if (!list.includes(JSON.stringify(success.data))) {
            if (success.data != undefined) {
              list.push(success.data);
              this.store.dispatch(CONTRACTITEMS({ items: list }));
            }
          }
        }
      );
    });
  
    this.router.navigate(["/contratos"]);
  }
  

  /**
 * Obtiene una lista de contratos agrupados por proveedor.
 * @returns {ContratoProveedor[]} Una lista de objetos ContratoProveedor.
 */
  /*public async showMoreNexFinal(){ 
    const list: any[] = [];  
    await this.contractFNexinal.map( (data: any) => {
        this.store.dispatch(CONTRACTLOAD({idContract:data.idContract}));
        this.store.select(selectorContractItem).pipe().subscribe(
          (success: any)=>{
            if(!list.includes(JSON.stringify(success.data))){
              if(success.data != undefined){
                list.push(success.data)
                this.store.dispatch(CONTRACTITEMS({items:list}))
              }              
            }              
          }
        )
      }      
    )
    this.router.navigate(["/contratos"])

  }
  */

 

  alertAba(type: String, menssage: String, tittle: string): void {
    const dialogRef = this.dialogService.open(BcAlertComponent, {
      id: tittle,
      type: type,
      text: menssage,
      timeout: 7000,
    });

    dialogRef.onResult().subscribe(
      (closed) => {},
      (dismissed) => {},
      () => {}
    );
  }


  /**
 * Obtiene una lista de contratos marcados como favoritos.
 * @returns {Contrato[]} Una lista de objetos Contrato.
 */
  get contratosFavoritos(): Contrato[]{
    const contratosFavoritos = this.contratosServices.resultadosContratos.filter(
      (ctr) => {
        return ctr.esFavorito == true; 
      }
    );
    return contratosFavoritos
  }

  private loadSubscribeSerive(currenUser: any){
    this.contratosServices.getContratosFavorite(currenUser.data.id).pipe().subscribe(      
      (success) =>  {        
        this.inforamtionServices.showInformationLoad.emit(true)
        this.contractFavorite = (success.data);
        this.contratosServices.getContratos().subscribe(
          (data) => {
              // Procesa los datos recibidos
              this.loading = false; // Establece loading en falso cuando la carga de datos está completa
          },
          (error) => {
              console.error('Error al cargar datos:', error);
              this.loading = false; // Asegúrate de manejar los errores y establecer loading en falso en caso de error
          }
      );
        this.contratosServices.getLisProviderCountContract().pipe().subscribe(
          (success) => {
            this.contractNextFinalDTOList = success.data.contractNextFinalDTOList;
            this.contractByProviderCount = success.data.contractProvider;
            this.countContractByuser = success.data.contractByUser;
            this.total = success.data.total;
            this.contractBeaterCount = success.data.contractBeater; //Contador para Vencidos
            this.contractNextBeatenCount = success.data.contractNextBeaten; // Almacena el valor de contractNextBeaten
            this.contractInitialCount = success.data.contractInitial; //Almacena valor De los Iniciales
            console.log(success)
            this.countContractByuser = success.data.contractByUser
            this.contratosServices.getListContractNextFinal(currenUser.data.id).pipe().subscribe(
              (success) => {
                this.contractFNexinal = success.data
                this.inforamtionServices.showInformationLoad.emit(false)
            },
             // (error) => this.alertAba("error","Error consultando los contratos proximos a vencer","Error")
            )
        },
         // (error) => this.alertAba("error","Error consultandor la cantidad de contratos","Error")
        )
      },
      //(error) => this.alertAba("error","Error consultando los contratos favoritos","Error")
    )
  }
  


  public removeFavorite(favoriteModel: FavoriteModel){
    this.contratosServices.deleteFavoriteContratos(favoriteModel.idFavotire).pipe().subscribe( 
      (success) => {
        this.contractFavorite = this.contractFavorite.filter(i => i.idContract.idContract != favoriteModel.idContract.idContract);
        this.alertAba("success","Se elimino de favorito el contrato.","Success");
      },
      (error) => this.alertAba("error", `No se pudo procesar la solicitud.\n ${error.error.mensage}` ,"Error")
    )
    return this.contractFavorite;
  }


  /**
 * Obtiene una lista de contratos próximos a vencer.
 * @returns {Contrato[]} Una lista de objetos Contrato.
 */
  get contratosAVencer(): Contrato[]{
    const contratosAVencer = this.contratosServices.resultadosContratos.filter(
      (ctr) => {
        return ctr.proximoVencer == true;
      }
    );

    return contratosAVencer;
  }

  

  datadfasf: Observable<any> = new Observable();

  fecha:any;
  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('userCurrentGraph') || '{}');
    this.datadfasf = this.store.select(selectorContractItem);
    this.mostrarHora();
    this.userData = this.userService.getCurrentUserData();
    console.log("EL USUARIO DE GRAPH ES " + this.userData.email);
    console.log("ESTE ES EL NUEVO ES : " + this.userData.data.email);
    this.loadData();
    this.loadSubscribeSerive(this.userData); 
  }

   /**
 * Actualiza la hora actual en la variable 'fecha' cada segundo.
 */
  mostrarHora(){
    this.fecha = formatearFecha(new Date());
    setInterval(()=>{
      this.fecha = formatearFecha(new Date());
    },1000);
  }




  //Novedades

  getStatusTooltip(state: string): string {
    switch (state) {
      case 'En Proceso':
        return 'Se espera Aprobación del nuevo Administrador.';
      case 'Pendiente':
        return 'Nuevo Admin Rechaza Solicitud / Admin dice que no es y no sabe quien es';
      case 'Completado':
        return 'El nuevo Admin Acepta';
      case 'Finalizado':
        return 'El Negociador hace el cambio en ariba';
      case 'Rechazado':
        return 'El negociador / category rechazan el cambio del nuevo admin';
      default:
        return 'Estado no reconocido.';
    }
  }


  //Eventos para cargar novedades

  public requirement: any[] = [];
  public novedad: any[] = [];
  dataLoaded: boolean | undefined;
  requirementnov: any[] = [];

  changeStateRequierement = new Object();

  changeStateRequirement = {
    idRequiremet: 'requirement.requirementId',
    status: 'requirement.contractId.status'
  }
  

  private loadData() {

    const idContract = this.route.snapshot.paramMap.get("idContrato")
    const idReceptor = this.route.snapshot.paramMap.get("idReceptor")

    const requirementParamnNov: any = {
      email: this.userData.data.email,
      type: "RECEPTOR"
    }

    const requirementParam: any = {
      email: this.userData.data.email,
      type: "REQUEST"
    }

    const dataLoaded = false;


    this.requirementService.findByIdContractAndReceptor(requirementParamnNov).subscribe(
      (response) => {
        // Limitar a los 5 más recientes
        this.novedad = response.data.slice(0, 5);
        this.dataLoaded = true;
      },
      (error) => {
        //this.alertAba("error","No se pudo cargar las novedades","Error")
      }
    )

    this.requirementService.findByIdContractAndReceptor(requirementParam).subscribe(
      (response) => {
        // Limitar a los 5 más recientes
        this.requirement = response.data.slice(0, 5);
      },
      (error) => {
        //this.alertAba("error","No se pudo cargar las novedades","Error")
      }
    )
  }

  getStatusDescription(statusName: string): string {
    switch (statusName.toLowerCase()) {
      case 'publicado':
        return 'Cuando el contrato ya esta listo y entro en vigencia';
      case 'vencido':
        return 'Cuando la fecha de expiracion del contrato ya paso y aun no se ha realizado una gestion a ese contrato (se cierra o se reactiva)';
      case 'borrador':
        return 'Se creo el proyecto (CW) Y esta en el proceso de legalización contractual';
      case 'modificación del borrador':
        return 'Es cuando a un contrato publicado se le realiza algun cambio';
      case 'congelado':
        return 'Contrato que se encuentra en espera';
      case 'pendiente':
        return 'El contrato ya está listo y esta a la espera de la fecha de inicio del mismo';
      default:
        return 'Contrato que ya finalizo y esta fuera de servicio';
    }
  }






  


  
}
