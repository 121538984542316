
<div class="color-header">
    <h4>Demanda</h4>
</div>

<div class="bc-flex bc-p-2 bc-w-100 bc-gap-lg-5 bc-direction-column bc-direction-lg-row">
    <bc-input-select componentId="bc-select2" [multiple]="true" [formatType]="'full'" [values]="proveedoresSelectValues"
        label="Añadir mas columnas" span=""  [filter]="true" class="bc-w-100" (valueOutput)="onSelectTableColumn($event)"> 
    </bc-input-select>
</div>


<div class="bc-row" >
    <bc-table-container  class="bc-col-12 bc-w-100" *ngIf="1 > 0" [dataTable]=1 [cellOptions]="cellOption">

        <bc-table-header [title]="''" [actionsSelections]="actions">
            <bc-paginator type="full" [totalRegisters]="totalRegisters" [perPage]="perpage"
                (onChangePage)="onChangePage($event)" [perMultiple]="'10'">
            </bc-paginator>
        </bc-table-header>

        <bc-table-content >
            <table 
                caption="tabla" bc-table [selection]='false' [scroll]="true" [scroll]="true" [sort]="true" [dropdownHtml]="true">
                <thead>
                    <tr>
                        <th  scope="row" bc-cell type="total">
                            <h5>{{markeplaceEnum.IDFORM}}</h5>
                        </th>
                        <th  scope="row" bc-cell type="total"><h5>{{markeplaceEnum.STATE}}</h5></th>
                        <th  scope="row" bc-cell type="total"><h5>{{markeplaceEnum.REQUESTRUN}}</h5></th>
                        <th  scope="row" bc-cell type="total"><h5>{{markeplaceEnum.NAME}}</h5></th>

                        <th *ngIf="selectTableColumn.REQUEST" scope="row" bc-cell type="total"><h5>{{markeplaceEnum.REQUEST}}</h5></th>
                        <th *ngIf="selectTableColumn.DESCRIPTION" scope="row" bc-cell type="total"><h5>{{markeplaceEnum.DESCRIPTION}}</h5></th>
                        <th *ngIf="selectTableColumn.BADGET" scope="row" bc-cell type="total"><h5>{{markeplaceEnum.BADGET}}</h5></th>
                        <th *ngIf="selectTableColumn.MONEY" scope="row" bc-cell type="total"><h5>{{markeplaceEnum.MONEY}}</h5></th>
                        <th *ngIf="selectTableColumn.PROVIDER" scope="row" bc-cell type="total"><h5>{{markeplaceEnum.PROVIDER}}</h5></th>
                        <th *ngIf="selectTableColumn.TIPOLOGY" scope="row" bc-cell type="total"><h5>Tipología</h5></th>
                        <th *ngIf="selectTableColumn.REQUIREMENTLEGALY" scope="row" bc-cell type="total"><h5>Es requerimiento legal</h5></th>
                        <th *ngIf="selectTableColumn.PRIORITIZE" scope="row" bc-cell type="total"><h5>¿Priorizado por el CAE? </h5></th>
                        <th *ngIf="selectTableColumn.ROL" scope="row" bc-cell type="total"><h5>Rol Asesor </h5></th>
                        <th *ngIf="selectTableColumn.TYPEPROCES" scope="row" bc-cell type="total"><h5>Tipo de proceso </h5></th>
                        <th *ngIf="selectTableColumn.DAYDURATION" scope="row" bc-cell type="total"><h5>Duración en dias </h5></th>
    
                    </tr>
                </thead>
                <tbody>
                    <tr  *ngFor="let contract of currentPage" (click)="selectItemCheckbox(contract)">                         
                        <td >
                            <bc-checkbox 
                            class="bc-my-4 bc-mx-4" id={{contract}}  
                            name="dfasdfas" [value]="'1'"></bc-checkbox>
                            <h6>{{contract["ID_lista"]}}</h6>
                        </td> 
                        <td bc-cell >
                            <div class="estatus_content">
                                <p>Pendiente</p>
                            </div>
                            <!--h6>{{ contract["Estado"] }}</h6-->
                        </td>
                        <td  bc-cell>
                            <h6 style="color: red;"> {{ "En desarrollo" }}</h6>
                        </td>  

                        <td bc-cell>
                            <h6> {{ contract["Nombre_area_y_ceco"] }}</h6>
                        </td>
                        <td *ngIf="selectTableColumn.REQUEST" bc-cell>
                            <h6> {{ contract["Nombre_solicitante"] }}</h6>
                        </td> 
                                              
                        <td *ngIf="selectTableColumn.DESCRIPTION" bc-cell>
                            <h6> {{ contract["Detalle_producto_o_servicio"] }}</h6>
                        </td>
                        <td *ngIf="selectTableColumn.BADGET" bc-cell>
                            <h6> {{ contract["Tiene_presupuesto"] }}</h6>
                        </td>
                        <td *ngIf="selectTableColumn.MONEY" bc-cell>
                            <h6> {{contract["Valor_presupuesto"]}} </h6>
                        </td>
                        <td *ngIf="selectTableColumn.PROVIDER" bc-cell>
                            <h6> {{ contract["Proveedores_conocidos"] }}</h6>
                        </td>
                        
                        <td *ngIf="selectTableColumn.TIPOLOGY" bc-cell>
                            <h6> {{ contract["Tipología a la que corresponde (Alta \/ Media \/ Baja)"] }}</h6>
                        </td>  
                        <td *ngIf="selectTableColumn.REQUIREMENTLEGALY" bc-cell>
                            <h6> {{ contract["Requerimiento_legal"] }}</h6>
                        </td> 
                        <td *ngIf="selectTableColumn.PRIORITIZE" bc-cell>
                            <h6 style="color: red;"> {{ "No lo encuento" }}</h6>
                        </td> 
                        <td *ngIf="selectTableColumn.ROL" bc-cell>
                            <h6> {{ contract["Rol_asesor"] }}</h6>
                        </td> 
                        <td *ngIf="selectTableColumn.TYPEPROCES" bc-cell>
                            <h6 style="color: red;"> {{ "No lo encuento" }}</h6>
                        </td>
                        <td *ngIf="selectTableColumn.DAYDURATION" bc-cell>
                            <h6> {{ contract["Duración (Días)"] }}</h6>
                        </td> 

                    </tr>
                </tbody>
            </table>
            
        </bc-table-content>
             
    </bc-table-container>
    
    <div class="color-header">
        <h4>Detalle del requerimiento ID:</h4>
    </div>

    <div class="bc-col-4 ">
        <div bc-card >
            <div class="bc-card-body">
                <div class="card-content__header">
                    Gestión de capacidad
                </div>     
                <h6 class="bc-card-title bc-align-left center-text__container">Negociador asignado: Monica Maria Martinez Medina</h6>
                <div class="row">
                    <p class="bc-card-subtitle bc-align-left bc-col-4">Horas Estimadas</p>
                    <bc-form-field class="bc-col-7">
                        <input bc-input aria-label="custom-aria-label" placeholder="" type="text" value=""  />
                    </bc-form-field>
        
                    <p class="bc-card-subtitle-number bc-align-left bc-col-4">Semanas requeridas</p>
                    <bc-form-field class="bc-col-7">
                        <input bc-input aria-label="custom-aria-label" placeholder="" type="text" value=""  />
                    </bc-form-field>
                    
                    <p class="bc-card-subtitle-number bc-align-left bc-col-4">Observaciones</p>
                    <bc-form-field class="bc-col-7">
                        <textarea bc-input aria-label="custom-aria-label" placeholder="" type="textarea" value="" rows="3">
                        </textarea>
                    </bc-form-field>
                </div>
                <button bc-button>
                    Registrar
                </button>
            </div>
        </div>
    </div>
    
    <div class="bc-col-8 ">
        <div bc-card  style="margin-left: 10px;">
            <div class="bc-card-body">
                <div class="card-content-requirement__header">
                    Información asociada al requerimiento
                </div>     
                <div class="row">
                    <div class="bc-col-12">
                        <div class="bc-col-5">
                            <div class="bc-col-6">
                                ¿Solicitud en curso?
                            </div>
                            <div class="bc-col-6">
                                <bc-input-select 
                                    [values]="requesProgresSelectValues"
                                    [formatType]="'full'"
                                    [ngModel] = "dataInput['¿Solicitud o en curso?']"
                                    >
                                </bc-input-select>
                            </div>
    
                            <div class="bc-col-6">
                                Renovación/ Requerimiento nuevo
                            </div>
                            <div class="bc-col-6">
                                <bc-form-field>
                                    <input 
                                    bc-input aria-label="custom-aria-label" 
                                    placeholder="" 
                                    [ngModel] = "dataInput['Renovación / Requerimiento Nuevo']"
                                    type="text" 
                                    value=""  />
                                </bc-form-field>
                            </div>
    
                            <div class="bc-col-6">
                                Categoría
                            </div>
                            <div class="bc-col-6">
                                <bc-form-field>
                                    <input bc-input aria-label="custom-aria-label"
                                    [ngModel] = "dataInput['Categoría']"
                                    placeholder="" type="text" value=""  />
                                </bc-form-field>
                            </div>
    
                            <div class="bc-col-6">
                                Familia asociada al servicio
                            </div>
                            <div class="bc-col-6">
                                <bc-form-field>
                                    <input bc-input aria-label="custom-aria-label" 
                                    [ngModel] = "dataInput['Familia asociada al servicio']"
                                    placeholder="" type="text" value=""  />
                                </bc-form-field>
                            </div>
                        </div>

                        <div class="bc-col-1"></div>
                        
                        <div class="bc-col-5">
                            <div class="bc-col-6">
                                Fecha de inicio
                            </div>
                            <div class="bc-col-6">
                                <bc-form-field>
                                    <input bc-input aria-label="custom-aria-label" 
                                    [ngModel] = "dataInput['']"
                                    placeholder="" type="text" value=""  />
                                </bc-form-field>
                            </div>
    
                            <div class="bc-col-6">
                                ¿Está vencido?
                            </div>
                            <div class="bc-col-6">
                                <bc-form-field>
                                    <input bc-input aria-label="custom-aria-label"
                                    [ngModel] = "dataInput['¿Está vencido?']"
                                    placeholder="" type="text" value=""  />
                                </bc-form-field>
                            </div>
    
                            <div class="bc-col-6">
                                SR
                            </div>
                            <div class="bc-col-6">
                                <bc-form-field>
                                    <input bc-input aria-label="custom-aria-label"
                                    [ngModel] = "dataInput['SR']"
                                    placeholder="" type="text" value=""  />
                                </bc-form-field>
                            </div>
    
                            <div class="bc-col-6">
                                Estado Ariba
                            </div>
                            <div class="bc-col-6">
                                <bc-form-field>
                                    <input bc-input aria-label="custom-aria-label" 
                                    [ngModel] = "dataInput['']"
                                    placeholder="" type="text" value=""  />
                                </bc-form-field>
                            </div>

                            <div class="bc-col-6">
                                Priorización
                            </div>
                            <div class="bc-col-6">
                                <bc-form-field>
                                    <input bc-input aria-label="custom-aria-label" 
                                    [ngModel] = "dataInput['Priorización']"
                                    placeholder="" type="text" value=""  />
                                </bc-form-field>
                            </div>
                        </div>
                    </div>
                </div>            
            </div>
        </div>
    </div>
</div>