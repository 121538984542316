import { Component } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { formatearFecha } from 'src/app/components/utils/formato-fecha.componente';
import { ContratosService } from 'src/app/services/api-services/contratos-services/contratos.service';
import { UserService } from 'src/app/services/api-services/user-services/user.service';
import { InforamtionServices } from 'src/app/services/commo-service/information-services';

@Component({
  selector: 'app-mis-contratos',
  templateUrl: './mis-contratos.component.html',
  styleUrls: ['./mis-contratos.component.css']
})
export class MisContratosComponent {

  data: any = {};
  dataLoaded: boolean = false;
  filteredData: any = {}; // Inicializar con un valor vacío aquí
  userData: any;
  userDataAvailable = false;
  loading: boolean = true;

  onFilterChanged(filteredData: any) {
    this.filteredData = { data: filteredData, count: filteredData.length };
  }

  constructor(private contratosService : ContratosService, private userService: UserService, private router: Router,
    private infortionServices: InforamtionServices) {
  }


  //Variable titulo del encabezado
  tittle = "";

  //Paginator
  pageNumber = 0;
  perPage = 200;

  //Fecha
  fecha: any;

  ngOnInit() {
    this.mostrarHora();
    this.contratosService.getContratos(this.pageNumber, this.perPage).subscribe({
      next: (response) => {
        this.data = response.data;
        this.dataLoaded = true;
      },
      error: (error) => {
        console.error(error);
      },
    });

    this.userService.getUserDataSubject().subscribe((userData) => {
      this.userData = userData.data;
      this.userDataAvailable = true;
    });
  }

  /**
 * Muestra la hora actual en un intervalo de un segundo.
 */
  mostrarHora() {
    this.fecha = formatearFecha(new Date());
    setInterval(() => {
      this.fecha = formatearFecha(new Date());
    }, 1000);
  }

  /**
  Se definen los breadcrumbs
  @type {Routes}
  */

  

  
 
}




