import { Component } from '@angular/core';
import { BcAlertComponent } from '@bancolombia/design-system-web/bc-alert';
import { BcDialogService } from '@bancolombia/design-system-web/bc-services';
import { UserService } from 'src/app/services/api-services/user-services/user.service';

@Component({
  selector: 'app-inicio-admin-negociador',
  templateUrl: './inicio-admin-negociador.component.html',
  styleUrls: ['./inicio-admin-negociador.component.css']
})
export class InicioAdminNegociadorComponent {

  userData: any;
  userDataAvailable = false;

  constructor(private dialogService: BcDialogService, private userService: UserService) {}

  alertAba(type: String, menssage: String, tittle: string): void {
    const dialogRef = this.dialogService.open(BcAlertComponent, {
      id: tittle,
      type: type,
      text: menssage,
      timeout: 7000,
    });

    dialogRef.onResult().subscribe(
      (closed) => {},
      (dismissed) => {},
      () => {}
    );
  }

  ngOnInit(): void {
    this.userService.getUserDataSubject().subscribe((userData) => {
      this.userData = userData.data;
      this.userDataAvailable = true;
    });

    
  }


}
