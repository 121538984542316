<bc-off-canvas #offCanvasComponent [tipoOffcanvas]="'vertical'" [backdrop]="''">
    <div offCanvasContent>
        <h3 class="bc-text-center">Reporte Novedad de administrador</h3>
        <br>
        <p>
            En esta opción podrás reportar la novedad de cambio de administrador del contrato o asignación de
            administrador
            temporal en caso de que vayas a presentar una ausencia. Al seleccionar enviar novedad estarás enviando la
            solicitud para que cadena de abastecimiento realice la gestión correspondiente.
        </p>
        <br>
        <!--Inputs-->

        <div class="bc-radio-group">
            <label class="bc-radio-label">
                Actualizar administrador
                <input type="radio" id="No Soy El Administrador" [(ngModel)]="ReporteNo" name="default" value="0"
                    role="radio" tabindex="0" aria-checked="false" checked="checked" (change)="resetRadioValues()" />
                <span class="bc-radio"></span>
            </label>
            <br>
            <!-- <label class="bc-radio-label">
                Asignar un adminsitrador temporal por motivo de ausencia
                <input type="radio" [(ngModel)]="ReporteNo" id="Asignar Un Temporal" name="default" value="1"
                    role="radio" tabindex="0" aria-checked="false" checked="checked" (change)="resetRadioValues()" />
                <span class="bc-radio"></span>
            </label> -->
        </div>
        <br>
        <!--Asignar un Temporal -->

        <!-- <div class="bc-container" *ngIf="ReporteNo == 1">
            <br>
            <div>
                <h5>Digite La Información requerida</h5>
                <br>
                <div>
                    <bc-form-field>
                      <input id="Se quien es el nuevo" bc-input aria-label="text-area" type="text" placeholder="" value=""
                        required>
                      <label for="02">Nombre del administrador temporal (Debes seleccionar un cargo de jefe)*</label>
                    </bc-form-field>
                    <br>
                    <bc-form-field>
                      <input id="02" bc-input aria-label="text-area" type="text" placeholder="" value="" required>
                      <label for="02">Correo del administrador temporal *</label>
                    </bc-form-field>
                    <br>
                    <bc-form-field>
                      <div>
                        <bc-form-field>
                          <em>calendar</em>
                          <input bc-input typeInput="input-default-left" id="idInput2" type="date" value="" required />
                          <em class="bc-input-select-toggle">arrow2-down</em>
                          <label for="idInput2">Fecha Inicio *</label>
                          <span>Selecciona una fecha del calendario</span>
                        </bc-form-field>
                      </div>
                    </bc-form-field>
                    <br>
                    <div>
                      <bc-form-field>
                        <em>calendar</em>
                        <input bc-input typeInput="input-default-left" id="idInput2" type="date" value="" required />
                        <em class="bc-input-select-toggle">arrow2-down</em>
                        <label for="idInput2">Fecha Fin *</label>
                        <span>Selecciona una fecha del calendario</span>
                      </bc-form-field>
                    </div>
                  </div>
                <br>

            </div>

        </div> -->
        <!--Caso no soy el administrador-->
        <div class="bc-container" *ngIf="ReporteNo == 0">
            <br>
            <div>
                <bc-form-field>
                    <input id="02" bc-input aria-label="text-area" type="text" placeholder="" value="" required>
                    <label for="02">Nombre del nuevo delegado*</label>
                </bc-form-field>
                <br>
                <bc-form-field>
                    <input id="02" bc-input aria-label="text-area" type="text" placeholder="" value="" required>
                    <label for="02">Correo del nuevo delegado*</label>
                </bc-form-field>
                <br>
                <div class="bc-radio-group">
                    <label class="bc-radio-label">
                        Enviar KIT de administrador y notificación de aceptación 
                        <input type="radio" id="No Soy El Administrador" [(ngModel)]="ReporteNo" name="default"
                            value="0" role="radio" tabindex="0" aria-checked="false"
                            (change)="resetRadioValues()" />
                        <span class="bc-radio"></span>
                    </label>
                </div>
                <br>
                <bc-form-field>
                    <input id="02" bc-input aria-label="text-area" type="text" placeholder="" value="" required>
                    <label for="02">Observaciones</label>
                  </bc-form-field>
            </div>
        </div>
        <!-- Caso no soy el administrador y se quien es-->

        <div class="bc-container" *ngIf="SaberSi == 2">
            <br>
            <div>
                <bc-form-field>
                    <input id="02" bc-input aria-label="text-area" type="text" placeholder="" value="" required>
                    <label for="02">Nombre del nuevo delegado*</label>
                </bc-form-field>
                <br>
                <bc-form-field>
                    <input id="02" bc-input aria-label="text-area" type="text" placeholder="" value="" required>
                    <label for="02">Correo del nuevo delegado*</label>
                </bc-form-field>
                <br>

            </div>
            <br>
        </div>

        <!-- Caso no soy el adminsitrador y no se quien es-->
        <div class="bc-container" *ngIf="SaberSi == 3">
            <br>
            <div>
                <p>
                    Haz indicado que no eres el administrador del contrato y tampoco sabes quién, por lo cual el equipo
                    de
                    abastecimiento se encargará de asignar el administrador correspondiente. Te estaremos notificando
                    mediante
                    correo electrónico la confirmación del cambio.
                </p>
            </div>

            <br>
        </div>
        <app-popout></app-popout>
        <br>
        <br>
        <button class="bc-button-secondary bc-button-default" (click)="closeOffCanvas()">
            Cancelar
        </button>
    </div>

</bc-off-canvas>
<button class="bc-button-primary bc-button-small" (click)="openOffCanvas()">
    {{textButton}}
</button>