import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BcCardModule } from '@bancolombia/design-system-web/bc-card';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BcCardModule
  ]
})
export class StaffingModule { }

