import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DemandComponent } from './staffing/demand/demand.component';
import { CapacityComponent } from './staffing/capacity/capacity.component';
import { MarkeplaceStaffing } from './staffing/staffing.component';
import { MarkeplacePage } from './markeplace.component';
import { ComponentsModule } from 'src/app/components/components.module';

import { BcTableModule } from '@bancolombia/design-system-web/bc-table'; 
import { BcCheckboxModule } from '@bancolombia/design-system-web/bc-checkbox'; 
import { BcInputModule } from '@bancolombia/design-system-web/bc-input';
import { BcPaginatorModule } from '@bancolombia/design-system-web/bc-paginator';
import { BcInputSelectModule } from '@bancolombia/design-system-web/bc-input-select';


import { BcSearchModule } from '@bancolombia/design-system-web/bc-search';
import { BcPageHeaderModule } from '@bancolombia/design-system-web/bc-page-header';
import { BcOffCanvasModule } from '@bancolombia/design-system-web/bc-off-canvas';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { BcInputDateModule } from '@bancolombia/design-system-web/bc-input-date';
import { FormsModule } from '@angular/forms'; // <-- Add this line
import { StaffingModule } from './staffing/staffing.module';
import { BcCardModule } from '@bancolombia/design-system-web/bc-card';
import { BcButtonModule } from '@bancolombia/design-system-web/bc-button';
import {BcProgressBarWebModule} from '@bancolombia/design-system-web/bc-progress-bar' 
 

@NgModule({
  declarations: [
    MarkeplacePage,
    DemandComponent,
    CapacityComponent,MarkeplaceStaffing,
  ],
  exports:[
    MarkeplacePage,
    MarkeplaceStaffing
  ],

  imports: [
    CommonModule,
    ComponentsModule,
    BcPaginatorModule,
    BcInputSelectModule,BcTableModule,BcCheckboxModule,BcInputModule,
    BcProgressBarWebModule,
    CommonModule,
    BcSearchModule,
    BcPageHeaderModule,
    BcPaginatorModule,
    BcOffCanvasModule,
    BcInputModule,
    AppRoutingModule,
    BcCheckboxModule,
    BcInputDateModule,
    ComponentsModule,
    BcInputSelectModule,
    BcCheckboxModule,
    FormsModule,
    StaffingModule,
    BcCardModule,
    BcButtonModule
  ]
})
export class MarkeplaceModule { }
