<bc-off-canvas #offCanvasComponent [tipoOffcanvas]="'vertical'" [backdrop]="''">
    <div offCanvasContent *ngIf="selectedContract">

      <bc-form-field>
        <div class="bc-container">
          <br>
          <div class="bc-align-items-center bc-text-center">
            <h1>Información Detallada Del Contrato</h1>
            <br>
          </div>
        </div>
      </bc-form-field>
        <!--Inputs-->
        <bc-form-field>
            <div class="bc-margin-top-2">
              <h3>Proveedor</h3>
              <p>{{ selectedContract.contractEntity.providerEntity.provider_name }}</p>
            </div>
          </bc-form-field>
        
          <bc-form-field>
            <h3>Id del Contrato</h3>
            <p>{{ selectedContract.contractEntity.idContract }}</p>
          </bc-form-field>
  
          <br>
        
          <!-- Parrafo -->
          <h3>Descripción del contrato</h3>
          <br>
          <p>
            {{ selectedContract.contractEntity.description }}
          </p>
          <br>
        <br>
        <br>
        <br>
       <button class="bc-button-primary bc-button-small" (click)="closeOffCanvas()">Cerrar</button>
        <button class="bc-button-primary bc-button-small bc-margin-top-5" (click)="onContractClick(selectedContract.contractEntity.idContract)">
          Detalles Contrato
      </button>
      <div class="imagen-detalles bc-margin-top-3">
        <img id="mi-imagen" src="../../../../assets/images/il-explore-items.svg">
      </div>
      
    </div>
    
    

</bc-off-canvas>
<a (click)="openOffCanvas()">
    Ver más
</a>