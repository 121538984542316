import { Component, Input, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { MenuItem } from 'src/app/models/menu-item.interface';
import { ModuleIcons } from 'src/app/models/module-icons.interface';
import { UserService } from 'src/app/services/api-services/user-services/user.service';
import { PermissionsService } from 'src/app/services/permissionsServices/permissions.service';


// La interfaz ModuleIcons se define en otro archivo (app/models/module-icons.interface.ts)

const moduleIcons: ModuleIcons = {
  dashboard: 'house',
  contratos: 'document',
  novedades: 'alert',
  solicitudes: 'alert',
  marketplace: 'table-settings',
  opensupply: 'alert',
  analista: 'alert',
  novedadesnegociador : 'alert',
  dashboardNegociador: 'house',
  contratosNegociador : 'document',
  icono : 'arrow2-down',
  

};

/**
 * Componente del menú que muestra las opciones de navegación disponibles para el usuario autenticado.
 * Utiliza el servicio PermissionsService para determinar qué módulos y opciones mostrar en el menú.
 */


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {


  currentPosition: number = 0;

  menuItems: MenuItem[] = [];
  isIframe = false;
  userDataAvailable = false;

  additionalIcon: string = 'arrow2-down';

  isDropdownOpen = false;

  userData: any;



  

  /**
   * Método para cerrar el menú desplegable.
   */
  closeDropdown(): void {
    this.isDropdownOpen = false;
  }


  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  
    // Si se abre el menú, suscríbete al evento 'click' global para cerrar el menú cuando se hace clic fuera de él
    if (this.isDropdownOpen) {
      document.addEventListener('click', this.closeDropdownOutsideClick);
    } else {
      // Si se cierra el menú, elimina la suscripción al evento 'click'
      document.removeEventListener('click', this.closeDropdownOutsideClick);
    }
  }
  
  // Método para cerrar el menú si se hace clic fuera de él
  closeDropdownOutsideClick = (event: Event) => {
    const menuElement = document.getElementById('dropdownBtn'); // Asegúrate de tener un ID único para el botón
    if (menuElement && !menuElement.contains(event.target as Node)) {
      this.closeDropdown();
    }
  };
  

  

  /**
   * Constructor del componente MenuComponent.
   * @param {PermissionsService} permissionsService - Servicio para determinar qué módulos y opciones mostrar en el menú.
   * @param {Router} router - Router de Angular para manejar la navegación y eventos del menú.
   * @param {ActivatedRoute} route - ActivatedRoute de Angular para obtener la información de la ruta actual.
   */
  constructor(
    private permissionsService: PermissionsService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
  ) { }

  /**
   * Método del ciclo de vida de Angular OnInit.
   * Se ejecuta una vez que el componente ha sido inicializado.
   * Se suscribe a los eventos de navegación del Router para construir el menú en función de las rutas configuradas en app-routing.module.ts.
   */
  ngOnInit(): void {
    
    // Suscribirse al observable userDataSubject para recibir las actualizaciones de datos del usuario
    this.userService.getUserDataSubject().subscribe((userData) => {
      // Construir el menú con los datos del usuario actualizados
      this.userDataAvailable = true;
      this.userData = userData.data;
      this.menuItems = this.buildMenu(userData);
    });
  }

  



  /**
   * Construye el menú en función de las rutas configuradas en app-routing.module.ts y los permisos del usuario.
   * @returns {MenuItem[]} Un array de objetos MenuItem que representan las opciones del menú.
   */
  buildMenu(userData: any): MenuItem[] {
    const menuItems: MenuItem[] = [];
    // Construimos el menú en función de las rutas configuradas en app-routing.module.ts
    this.router.config.forEach((route) => {
      
      // Obtenemos el nombre del módulo desde la propiedad 'data.module' o 'route.path'
      const moduleName = route.data?.['module'] || route.path!;

      const menuItem: MenuItem = {
        path: route.path!,
        text: route.data?.['breadcrumbs']?.text ? route.data['breadcrumbs'].text : '',
        iconName: moduleIcons[route.path!], // Usa el objeto moduleIcons para obtener el nombre del icono correspondiente al módulo
        permission: route.data?.['permission'] ? route.data['permission'] : '',
        allowedRoles: route.data?.['allowedRoles'] ? route.data['allowedRoles'] : []
      };

      // Agregamos el elemento al menú solo si tiene un texto definido y al menos un rol permitido
      if (menuItem.text && menuItem.allowedRoles.length > 0) {
        if (this.canShowModule(moduleName)) {
          menuItems.push(menuItem);
        }
      }
    });
  
    return menuItems;
  }
  /**
   * Verifica si se debe mostrar un módulo en el menú basado en los permisos del usuario.
   * @param {string} moduleName - Nombre del módulo que se verificará si debe mostrarse en el menú.
   * @returns {boolean} True si el módulo debe mostrarse en el menú, de lo contrario, false.
   */
  canShowModule(moduleName: string): boolean {
    return this.permissionsService.canShowModule(moduleName);
  }

  /**
   * Verifica si el usuario actual tiene al menos uno de los roles permitidos para acceder al módulo.
   * @param {string[]} allowedRoles - Roles permitidos para acceder al módulo.
   * @returns {boolean} True si el usuario tiene al menos un rol permitido, de lo contrario, false.
   */
  isUserInAllowedRoles(allowedRoles: string[]): boolean {
    // Obtener los roles del usuario actual, por ejemplo, desde el servicio de autenticación
    const userRoles: string[] = ['ADMINISTRADOR DE CONTRATOS', 'NEGOCIADOR/CATEGORY']; // Ejemplo: roles del usuario actual

    // Verificar si el usuario tiene al menos un rol permitido para acceder al módulo
    return allowedRoles.some((allowedRole) => userRoles.includes(allowedRole));
  }

  MylougoutFunction(): void {

  }

}
