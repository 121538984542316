import { Component, Input } from '@angular/core';
import { BcTableActionButton, BcTableOptionMenu } from '@bancolombia/design-system-behaviors';

@Component({
  selector: 'app-tabla-operador-estados-carga',
  templateUrl: './tabla-operador-estados-carga.component.html',
  styleUrls: ['./tabla-operador-estados-carga.component.css']
})
export class TablaOperadorEstadosCargaComponent {
  
  @Input('data') _pages!: any[];
  

  constructor(){    
  }
  //Table
  ngOnInit(): void {
    this.currentPage = this._pages[0];
    this.createPageForTableAccordion();
    this.tableAccCurrentPage = this.movements[0];    
  }
  movements: any[] = [[], [], []];
  tableAccCurrentPage = [];
  tableAccCurrentPageEmpty = [];
  dataActionClicked: any = null;
  title: string = "Contratos"

  dropdownOptions: BcTableOptionMenu[] = [
    { id: 'opt1', icon: 'amount', text: 'Ver detalle', action: this.rowSelected },
    { id: 'opt2', icon: 'lock', text: 'Bloquear', action: this.rowSelected },
    { id: 'opt3', icon: 'data', text: 'Ver extractos', action: this.rowSelected },
  ];

  actions: BcTableActionButton[] = [
    { icon: 'download-document', text: 'EXPORTAR', id: 'export' },
    { icon: 'remove', text: 'ELIMINAR', id: 'delete' },
    { icon: 'house', text: 'PRUEBA', id: 'prueba' },
  ];

  totalAmounts = [
    { title: 'Saldo 1', amount: '$ 5.000.000' },
    { title: 'Saldo 2', amount: '$ 7.000.000' },
    { title: 'Saldo 3', amount: '$ 12.000.000' },
    { title: 'Saldo 4', amount: '$ 24.000.000' },
  ];

  activeOptions: BcTableActionButton[] = [
    { id: 'lock', icon: 'lock', text: 'Bloquear' },
    { id: 'remove', icon: 'remove', text: 'Eliminar' },
    { id: 'detail', icon: 'detail', text: 'Detalle' },
  ];

  disabledOptions: BcTableActionButton[] = [
    { id: 'unlock', icon: 'unlock', text: 'Desbloquear' },
  ];

  pages: Array<any> = [];
  currentPage: any[] = [];

  optionSelected(data: any) {
    if (data.optionSeleted.indexOf('_') !== -1) {
      for (const row of this.tableAccCurrentPage) {
        if (row === data.rowData) {
          let rowF = 'EDITED';
          return rowF;
        }
        return this.rowSelected(0);
      }
    }
  }
  rowSelected(data: any) {
    return data;
  }

  export(rows: any) {
    return rows;
  }

  delete(rows: any) {
    return rows;
  }

  onChangePage(data: any) {
    this.tableAccCurrentPage = this.movements[data.CurrentPage - 1];
  }
  onChangePageEmpty(data: any) {
    this.tableAccCurrentPageEmpty = this.movements[data.CurrentPage - 1];
  }

  onChangePageTableScroll(data: any) {
    this.currentPage = this._pages[data.CurrentPage - 1];
  }

  getOptionsByStatus(status: string): any {
    let opt = this.activeOptions;
    if (status === 'disabled') {
      opt = this.disabledOptions;
    }
    return opt;
  }

  optionSelectedScroll(data: any) {
    if (data.optionSeleted === 'lock') {
      data.rowData.status = 'disabled';
    } else {
      data.rowData.status = 'active';
    }
  }

  onClickAction(data: any) {
    this.dataActionClicked = data;
  }

  createPageForTableAccordion(): void {
    for (let i = 0; i < this.movements.length; i++) {
      const mov = this.movements[i];
      for (let j = 0; j < 5; j++) {
        mov.push(
          {
            name: `${i}_${j} Bancolombia Ahorros 1`,
            lastMovement: `${i}_${j} 02/12/2004 2`,
            exchangeBalance: `${i}_${j} $ 9.000.000 3`,
            totalBalance: `${i}_${j} $ 21.000.000 4`,
            aviableBalance: `${i}_${j} $ 10.000.000 5`,
            name_2: `${i}_${j} Bancolombia Ahorros 6`,
            lastMovement_2: `${i}_${j} 02/12/2004 7`,
            exchangeBalance_2: `${i}_${j} $ 9.000.000 8`,
            totalBalance_2: `${i}_${j} $ 21.000.000 9`,
            aviableBalance_2: `${i}_${j} $ 10.000.000 10`,
          },
        )

      }
    }
  }

}
