<div class="bc-p-3 bc-bg-white">
    <div class="bc-container">

        <div class="bc-row">
            <div class="bc-col-6">
                <div class="bc-container">
                    <div class="bc-col-2">
                        <bc-icon  size="2xl"  name="calendar-day" [ariaHidden]="false" ariaLabel="name"></bc-icon>
                    </div>
                    <div class="bc-col-8">
                        <b>
                            <span>Fecha de inicio contrato</span>
                        </b>
                        
                    </div>
                    <div class="bc-col-4">
                        <div >
                            <p>{{ novedad.initialDate | date:'dd-MM-yyyy' }}</p>
                        </div>
                    </div>

                </div>
            </div>
            
            <div class="bc-col-6">
                <div class="bc-container">
                    <div class="bc-col-2">
                        <bc-icon  size="2xl"  name="calendar-exclamation" [ariaHidden]="false" ariaLabel="name"></bc-icon>
                    </div>
                    <div class="bc-col-8">
                        <b>
                            <span>Fecha de fin</span>
                        </b>
                    </div>
                    <div class="bc-col-4">
                        <div >
                            <p>{{ novedad.finalDate | date:'dd-MM-yyyy' }}</p>
                        </div>
                    </div>

                </div>
            </div>

        </div>

    </div>
    <br>

    <!-- Parte 2 -->

    <div class="bc-container">

        <div class="bc-row">
            <div class="bc-col-6">
                <div class="bc-container">
                    <div class="bc-col-2">
                        <bc-icon  size="2xl"  name="value" [ariaHidden]="false" ariaLabel="name"></bc-icon>
                    </div>
                    <div class="bc-col-8">
                        <b>
                            <span>
                                Estado del requerimiento
                            </span>
                        </b>
                    </div>
                    <div class="bc-col-4">
                        <div class="bc-flex bc-flex-direction-row bc-align-items-center">
                            <app-badge-estado
                                    [clase]="'novedad'"
                                    [estadoAdmin]="novedad.status"
                                    [tipo]="'only'">
                                    </app-badge-estado>
                        </div>
                    </div>

                </div>
            </div>
            
            <div class="bc-col-6">
                <div class="bc-container">
                    <div class="bc-col-2">
                        <bc-icon  size="2xl"  name="document" [ariaHidden]="false" ariaLabel="name"></bc-icon>
                    </div>
                    <div class="bc-col-8">
                        <b>
                            <span>
                                Tipo de novedad
                            </span>
                        </b>
                    </div>
                    <div class="bc-col-7">
                        <div >
                            <p>{{novedad.typeRequirement}}</p>
                        </div>
                    </div>

                </div>
            </div>

        </div>

    </div>

    <div class="bc-margin-top-3">
        <hr>
    </div>
    <!--Tabla-->
    <div>
        <bc-table-container class="bc-w-100" [dataTable]="dataTable">
            <bc-table-content>
                <div class="bc-align-left bc-p-3">
                </div>
                <table caption="tabla" bc-table class="bc-p-2">
                    <thead>
                        <tr class="bc-bg-brand-primary-01">
                            <!--th scope="row" bc-cell type="total">Fecha</th-->
                            <th scope="row" bc-cell type="total">Estado novedades</th>
                            <th scope="row" bc-cell type="total">Observaciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr >
                            <!--td>{{item.fecha | date: 'MMMM d, y, h:mm:ss a'}}</td-->
                            <td>
                                <!--app-badge-estado [clase]="'novedad'" [estadoNovedad]="item.estado.nombre"
                                    [tipo]="'simple'"></app-badge-estado-->
                                    <app-badge-estado
                                    [clase]="'novedad'"
                                    [estadoAdmin]="novedad.status"
                                    [tipo]="'only'">
                                    </app-badge-estado>
                            </td>
                            <td>
                                <!--app-badge-estado [clase]="'novedad'" [estadoNovedad]="item.estado.nombre"
                                    [tipo]="'simple'"></app-badge-estado-->
                                    <span>
                                        {{novedad.description}}
                                    </span>
                                    
                            </td>
                            <!--td>Se realiza el cambio de estado en la herramienta y se actualizan los
                                correspondientes aplicativos.</td-->
                        </tr>
                    </tbody>
                </table>
            </bc-table-content>
        </bc-table-container>
    </div>
</div>