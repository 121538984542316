export var multi = [
    {
      "name": "Apalancable",
      "series": [
        {
          "name": "En curso",
          "value": 45
        },
        {
            "name": "Vencidos",
          "value": 12
        },
        {
            "name": "Solicitud",
            "value": 23
        }
      ]
    },
  
    {
      "name": "Estratégico",
      "series": [
        {
            "name": "En curso",
          "value": 13
        },
        {
            "name": "Vencidos",
          "value": 3
        }
        ,
        {
            "name": "Solicitud",
            "value": 42
        }
      ]
    },
  
    {
      "name": "Critíco",
      "series": [
        {
            "name": "En curso",
          "value": 13
        },
        {
          "name": "Vencidos",
          "value": 31
        },
        {
            "name": "Solicitud",
            "value": 42
        }
      ]
    },

    {
        "name": "No Critíco",
        "series": [
          {
            "name": "En curso",
            "value": 32
          },
          {
            "name": "Vencidos",
            "value": 3
          },
          {
            "name": "Solicitud",
              "value": 24
          }
        ]
      }
  ];
  
  