import { LogLevel } from "@azure/msal-browser";

 

export const environment = {

    production: true,
    
    postLogoutUrl: '#{post-logout-redirect-uri}#',

    apiUrl: '#{api-url}#',

    apiMicrosoft: 'https://graph.microsoft.com/v1.0',

    /// Variables de Configuracion para autenticacion de azure active directory

 

    msalConfig: {

        auth: {
            clientId: '#{client-id}#',
            redirectUri: '#{api-redirect-uri}#',
            authority: '#{authority}#',
            postLogoutRedirectUri: '#{post-logout-redirect-uri}#',

        }

    },

    system: {

        loggerOptions: {

            loggerCallback(logLevel: LogLevel, message: string) {

                console.log(message);

            },

            logLevel: LogLevel.Verbose,

            piiLoggingEnabled: false

        }

    }

};