import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ContratosService } from 'src/app/services/api-services/contratos-services/contratos.service';
import { UserService } from 'src/app/services/api-services/user-services/user.service';

@Component({
  selector: 'app-fav-icon-button',
  templateUrl: './fav-icon-button.component.html',
  styles: [
  ]
})
export class FavIconButtonComponent implements OnInit, OnChanges {

  @Input() filled: boolean = true;
  @Input() contractData!: any;
  @Output() favoriteToggled = new EventEmitter<boolean>();
  userData : any = {};

  constructor(private contractService: ContratosService, private userService: UserService) {}

  // toggleFilled() {
  //   //this.filled = !this.filled;
  //   //this.changeFavStatus();

  //   // Call the ContractService method to update favorite status
  //   console.log(this.contractData.idContract);
    
  //   //this.contractService.setFavoriteContractById(this.contractData.contractEntity.idContract, this.contractData.contractEntity.idUserAdmin).subscribe(
  //     this.contractService.setFavoriteContractById(this.contractData.idContract, "1").subscribe(
  //     (response) => {
  //       console.log("Exitoso");
        
  //     },
  //     (error) => {
  //       // Handle error if needed
  //       console.log("Denegado");
        
  //     }
  //   );
  // }

  toggleFilled() {
    // Llama al servicio para agregar o eliminar de favoritos
    this.contractService.toggleFavoriteContract(this.contractData.idContract, this.userData.data.id).subscribe(
      (response) => {
        console.log("El id del usuario marcado como fav es " +this.userData.id );
        this.isFavorite = !this.isFavorite; // Invierte el estado de favorito
        this.filled = this.isFavorite; // Actualiza el estado del ícono lleno
        this.setFavBtnStatus(); // Actualiza el estado del botón
      },
      (error) => {
        console.log("Operación fallida");
        // Maneja el error si es necesario
      }
    );

    this.favoriteToggled.emit(this.isFavorite);
  }

  /**
   * Color del icon-button . 
   * @type {("white" | "yellow")}
   * @default "yellow"
  */
  favBtnColor: "white" | "yellow" = "yellow"

  /**
   * Texto del tooltip . 
   * @type {("Quitar de favoritos" | "Agregar a favoritos")}
   * @default 'Agregar a favoritos'
  */
  favBtnText: "Quitar de favoritos" | "Agregar a favoritos" = "Agregar a favoritos"


  /**
   * Parametro para denotar el estado de favorito. 
   * @type {boolean}
   * @default false
  */
  @Input() isFavorite: boolean = false;
  
  
  ngOnInit(): void {
    this.userData = this.userService.getCurrentUserData();
    console.log(("El id es el : " + this.userData.data.id))
    this.checkFavoriteStatus();
    console.log(this.contractData);
    
    //this.setFavBtnStatus()
  }

  checkFavoriteStatus() {
    // Llama al servicio para verificar el estado de favoritos
    this.contractService.checkIfContractIsFavorite(this.contractData.idContract, this.userData.data.id).subscribe(
      (dataFavorite) => {
        let idFavorite = dataFavorite.data.idFavotire;
        this.isFavorite = !!idFavorite;  //Convertir el valor a booleano
        this.filled = !!idFavorite; //Convertir el valor a booleano
        this.setFavBtnStatus(); // Actualiza el estado del botón después de obtener la respuesta
      },
      (error) => {
        // Maneja el error si es necesario
      }
    );
  }

  ngOnChanges(): void {
    this.setFavBtnStatus()
  }
  setFavBtnStatus() {
    if (this.isFavorite) {
      this.favBtnColor = "yellow";
      this.favBtnText = "Quitar de favoritos";
    } else {
      this.favBtnColor = "white";
      this.favBtnText = "Agregar a favoritos";
    }
    console.log(`State: ${this.isFavorite} | Color: ${this.favBtnColor} | Text: ${this.favBtnText}`);
    
  }

  changeFavStatus() {
    this.isFavorite = !this.isFavorite;
    this.setFavBtnStatus()
  }

}
