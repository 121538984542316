import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../components/components.module';
import {BcTableModule} from '@bancolombia/design-system-web/bc-table';
import { BcIconButtonModule } from '@bancolombia/design-system-web/bc-icon-button';
import { BcStatusModule } from '@bancolombia/design-system-web/bc-status';
import { BcIconModule } from '@bancolombia/design-system-web/bc-icon';
import { BcCardModule } from '@bancolombia/design-system-web/bc-card';
import { BcPageHeaderModule } from '@bancolombia/design-system-web/bc-page-header';
import {BcCarouselModule} from '@bancolombia/design-system-web/bc-carousel';
import {BcSearchModule} from '@bancolombia/design-system-web/bc-search';
import { BcPaginatorModule } from '@bancolombia/design-system-web/bc-paginator';
import {BcBreadcrumbModule} from '@bancolombia/design-system-web/bc-breadcrumb';
import { BcCardContainerModule } from "@bancolombia/design-system-web/bc-card-container";
import {  BcButtonModule } from '@bancolombia/design-system-web/bc-button';
import { DetallesContratoModule } from './detalles-contrato/detalles-contrato.module';
import { MisContratosModule } from './mis-contratos/mis-contratos.module';
import { AppRoutingModule } from '../app-routing.module';
import {BcRadioModule} from '@bancolombia/design-system-web/bc-radio'
import { BcInputDateModule } from '@bancolombia/design-system-web/bc-input-date';
import {BcAccordionModule} from '@bancolombia/design-system-web/bc-accordion'
import { MisNovedadesModule } from './mis-novedades/mis-novedades.module';
import { DashboardModule } from './dashboard/dashboard.module';
import {BcProgressBarWebModule} from '@bancolombia/design-system-web/bc-progress-bar'


import { OperadorContratosNovedadesModule } from './operador-contratos-novedades/operador-contratos-novedades.module';
import { OperadorSincronizacionDatosPlantaModule } from './operador-sincronizacion-datos-planta/operador-sincronizacion-datos-planta.module';
import { SolicitudesPendientesComponent } from './solicitudes-pendientes/solicitudes-pendientes.component';
import { SolicitudesPendientesModule } from './solicitudes-pendientes/solicitudes-pendientes.module';
import { ServicioMensajeComponent } from './servicio-mensaje/servicio-mensaje.component';
import { ServicioMensajeModule } from './servicio-mensaje/servicio-mensaje.module';
import { NoAutorizadoPageModule } from './no-autorizado-page/no-autorizado-page.module';
import { LogInModule } from './log-in/log-in.module';
import { MantenimientoComponent } from './mantenimiento/mantenimiento.component';
import { NoAutorizadoPageComponent } from './no-autorizado-page/no-autorizado-page.component';
import {BcStrokeModule} from '@bancolombia/design-system-web/bc-stroke';
import { AnalistaRiesgoViewComponent } from './analista-riesgo-view/analista-riesgo-view.component'

import {BcCheckboxModule} from '@bancolombia/design-system-web/bc-checkbox' 
import {BcInputModule} from '@bancolombia/design-system-web/bc-input' 
import {BcInputSelectModule} from '@bancolombia/design-system-web/bc-input-select' 
import { MarkeplaceModule } from './markeplace/markeplace.module';
import { MarketplaceComponent } from './marketplace/marketplace.component';
import { MarketplaceModule } from './marketplace/marketplace.module';
import { OppenSupplyComponent } from './oppen-supply/oppen-supply.component';
import { NovedadesNegociadorModule } from './novedades-negociador/novedades-negociador.module';
import { SincronizacionDatosComponent } from './sincronizacion-datos/sincronizacion-datos.component';
import { SincronizacionDatosModule } from './sincronizacion-datos/sincronizacion-datos.module';
import { MainPageComponent } from './detalles-contrato-negociador/main-page/main-page.component';
import { CardFechaComponent } from './detalles-contrato-negociador/card-fecha/card-fecha.component';
import { DetallesContratoNegociadorHeaderComponent } from './detalles-contrato-negociador/detalles-contrato-negociador-header/detalles-contrato-negociador-header.component';
import { ListItemContratoNegociadorComponent } from './detalles-contrato-negociador/list-item-contrato-negociador/list-item-contrato-negociador.component';
import { DetallesContratoNegociadorModule } from './detalles-contrato-negociador/detalles-contrato-negociador.module';
import { MisContratosNegociadorModule } from './mis-contratos-negociador/mis-contratos-negociador.module';
import { InicioAdminNegociadorComponent } from './inicio-admin-negociador/inicio-admin-negociador.component';
import { InicioAdminNegociadorModule } from './inicio-admin-negociador/inicio-admin-negociador.module';
import { LogoutComponent } from './pages/logout/logout.component';


@NgModule({
  declarations: [

    SolicitudesPendientesComponent,
    ServicioMensajeComponent,
    NoAutorizadoPageComponent,
    MantenimientoComponent,
    AnalistaRiesgoViewComponent,
    MarketplaceComponent,
    OppenSupplyComponent,
    SincronizacionDatosComponent,
    InicioAdminNegociadorComponent,
    LogoutComponent,
  ],
  imports: [
    
    CommonModule,
    // Modulos del sistema de diseño de bancolombia
    BcTableModule,
    BcIconButtonModule,
    BcStatusModule,
    BcIconModule.forRoot({
      path: "https://library-sdb.apps.bancolombia.com/bds/6.12.10"
    }),
    BcCardModule,
    BcPageHeaderModule,
    BcCarouselModule,
    BcSearchModule,
    BcPaginatorModule,
    BcBreadcrumbModule,
    BcCardContainerModule,
    BcButtonModule,
    BcIconModule,
    BcRadioModule,
    BcInputDateModule,
    BcAccordionModule,
    BcProgressBarWebModule,
    BcStrokeModule,
    // Modulos propios (pages)
    ComponentsModule,
    AppRoutingModule,
    MisContratosModule,
    MisContratosNegociadorModule,
    MarkeplaceModule,
    MarketplaceModule,
    DetallesContratoModule,
    MisNovedadesModule,
    NovedadesNegociadorModule,
    DashboardModule,
    NovedadesNegociadorModule,
    BcProgressBarWebModule,
    OperadorContratosNovedadesModule,
    OperadorSincronizacionDatosPlantaModule,
    SolicitudesPendientesModule,
    ServicioMensajeModule,
    NoAutorizadoPageModule,
    LogInModule,
    BcCheckboxModule,
    BcInputModule,
    BcInputSelectModule,
    DetallesContratoNegociadorModule,
    InicioAdminNegociadorModule
  ]
})
export class PagesModule { }
