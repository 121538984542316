import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BcPageHeaderModule } from '@bancolombia/design-system-web/bc-page-header';
import { OperadorContratosNovedadesComponent } from './operador-contratos-novedades.component';
import { OperadorNovedadesSearchbarComponent } from './operador-novedades-searchbar/operador-novedades-searchbar.component';
import { OperadorNovedadAccordionComponent } from './operador-novedad-accordion/operador-novedad-accordion.component';
import { BcInputModule } from '@bancolombia/design-system-web/bc-input';
import { BcInputSelectModule } from '@bancolombia/design-system-web/bc-input-select';
import { BcBadgeModule } from '@bancolombia/design-system-web/bc-badge';
import { BcTableModule } from '@bancolombia/design-system-web/bc-table';
import { BcIconModule } from '@bancolombia/design-system-web/bc-icon';
import { ComponentsModule } from "../../components/components.module";
import { BcAccordionModule } from '@bancolombia/design-system-web/bc-accordion';
import { BcButtonModule } from '@bancolombia/design-system-web/bc-button';
import { BcIconButtonModule } from '@bancolombia/design-system-web/bc-icon-button';
import { OffCanvasOperadorPortaNovedadComponent } from './off-canvas-operador-porta-novedad/off-canvas-operador-porta-novedad.component';
import { BcOffCanvasModule } from '@bancolombia/design-system-web/bc-off-canvas';
import { BcRadioModule } from '@bancolombia/design-system-web/bc-radio';



@NgModule({
    declarations: [
        OperadorContratosNovedadesComponent,
        OperadorNovedadesSearchbarComponent,
        OperadorNovedadAccordionComponent,
        OffCanvasOperadorPortaNovedadComponent,
    ],
    exports: [
        OperadorContratosNovedadesComponent
    ],
    imports: [
        CommonModule,
        BcPageHeaderModule,
        BcInputModule,
        BcInputSelectModule,
        BcBadgeModule,
        BcTableModule,
        BcIconModule,
        BcButtonModule,
        ComponentsModule,
        BcAccordionModule,
        BcButtonModule,
        BcIconButtonModule,
        BcOffCanvasModule,
        BcInputSelectModule,
        BcRadioModule,

        
    ]
})
export class OperadorContratosNovedadesModule { }
