import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AppState, ContractState, ItemState } from "./contract.state";

export const selectorContract = createFeatureSelector<ItemState>('contractState');

export const selectorContractItem =  createSelector(
    selectorContract,
    (state: ItemState) => state.item
)

export const selectorContractList =  createSelector(
    selectorContract,
    (state: ItemState) => {
        return state.items
    }
)