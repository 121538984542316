import { Pipe, PipeTransform } from '@angular/core';
import { PermissionsService } from '../../services/permissionsServices/permissions.service';
import { MenuItem } from '../../models/menu-item.interface';

@Pipe({
  name: 'menuFilter'
})
export class MenuFilterPipe implements PipeTransform {

  constructor(private permissionsService: PermissionsService) {}

  transform(menuConfig: MenuItem[]): MenuItem[] {
    return menuConfig.filter((item: MenuItem) => this.permissionsService.canShowModule(item.path));
  }

}
