import { Component, Input, OnInit } from '@angular/core';
import { formatearFecha } from 'src/app/components/utils/formato-fecha.componente';
import { AccordionColumn } from '@bancolombia/design-system-web/bc-accordion';
import { UserService } from 'src/app/services/api-services/user-services/user.service';
import { FindByIdContractAndReceptorModel, RequirementModel } from 'src/app/models/requirement.model';
import { RequirementService } from 'src/app/services/api-services/requirement-services/requierement.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { SendDataInforamtionServices } from 'src/app/services/commo-service/sendData-services';
import { msalConfig } from 'src/app/core/auth-config';
import { environment } from 'src/environments/environment';
import { ContratosService } from 'src/app/services/api-services/contratos-services/contratos.service';
@Component({
  selector: 'app-solicitud-antiguo-administrador',
  templateUrl: './solicitud-antiguo-administrador.component.html',
  styleUrls: ['./solicitud-antiguo-administrador.component.css']
})
export class SolicitudAntiguoAdministradorComponent implements OnInit {
  // Variables
  userData: any = {};
  @Input() requirementByUser: any;
  contractDetail: any = { selectedOption: null };
  
  @Input('contractDetail') _contract!: any;
  fecha: any;
  requirement: any[] = [];
  tittle = " ¡Qué gusto verte otra vez! ";
  changeStateRequierement = new Object();
  protected currentUser: any;
  userDataAvailable = false;
  dataLoaded: boolean = false;
  // Objeto
  changeStateRequirement = {
    idRequiremet: 'requirement.requirementId',
    status: 'CERRADO'
  };
  contratosService: any;
  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private requirementService: RequirementService,
    private http: HttpClient,
    private router: Router,
    private sendDataInforamtionServices: SendDataInforamtionServices,
    contratosService: ContratosService
  ) {}

  ngOnInit(): void {
    this.mostrarHora();
    this.currentUser = JSON.parse(sessionStorage.getItem('userCurrentGraph') || '{}');
    this.userData = this.userService.getCurrentUserData();
    this.route.paramMap.subscribe(params => {
      const contractId = params.get('id') ;
      if (contractId !== null) {
      this.contratosService.getContractByIdContract(contractId).subscribe((data: { data: any; }) => {
        this.contractDetail = data.data;       
      });
    }
    });

    this.loadData();

  }

  private loadData() {
    const idContract = this.route.snapshot.paramMap.get("idContrato");
    const idReceptor = this.route.snapshot.paramMap.get("idReceptor");

    const requirementParam: any = {
      email: this.userData.data.email,
      type: "RECEPTOR"
    };

    this.requirementService.findByIdContractAndReceptor(requirementParam).subscribe(
      (response) => {

        this.requirement = response.data.map((req: any) => ({
          ...req,
          hasActionTaken: false, // Nueva propiedad para indicar si la acción ha sido tomada
        }));
        
        this.requirement.sort((a, b) => new Date(b.initialDate).getTime() - new Date(a.initialDate).getTime());
        this.dataLoaded = true;
      },
      (error) => {
        console.log("Error::::: ", this.userData.data.email);
      }
    );
  }

  /**
   * Actualiza la hora actual en la variable 'fecha' cada segundo.
   */
  mostrarHora() {
    this.fecha = formatearFecha(new Date());
    setInterval(() => {
      this.fecha = formatearFecha(new Date());
    }, 1000);
  }

  /**
   * Metodo para las columnas del Acordeon
   */
  columns: Array<AccordionColumn & { field?: string }> = [
    { value: 'Solicitud nueva asignación de Contrato' },
  ];

  columnsForRequirement(requirement: any): Array<AccordionColumn> {
    return [
      { value: requirement.status },
    ];
  }

  uniqueContractIds: Set<string> = new Set<string>();

  private filterUniqueContracts(data: any[]): any[] {
    const filteredData: any[] = [];
    data.forEach((item: any) => {
      const idContract = item?.contractId?.idContract;
      if (idContract && !this.uniqueContractIds.has(idContract)) {
        this.uniqueContractIds.add(idContract);
        filteredData.push(item);
      }
    });
    return filteredData;
  }
 // ... (otro código)
aceptarSolicitud(requirement: any) {
  if (!requirement.hasActionTaken && requirement.status === 'PENDIENTE') {
    if (!requirement.hasActionTaken) {
      requirement.hasActionTaken = true;
      requirement.actionMessage = 'Has aceptado este contrato';
      this.changeStateRequirement = {
        idRequiremet: requirement.requirementId,
        status: 'ACEPTADO'
      };
      this.http.put(environment.apiUrl + '/requirement', this.changeStateRequirement).subscribe(
        (response) => {
          let dataNavigate: NavigationExtras = {
            queryParams: {
              emailreceptor: requirement.contractDetail.negotiator.email ,
              contractId: requirement.contractId.idContract,
              description: requirement.contractId.description,
              provider: requirement.contractId.providerEntity.commercial_name
            }
          };
          this.router.navigate(["/SolicitudAprobadaAntiguo"], dataNavigate);
  
        },
        (error) => {
          console.error('Error al actualizar el estado:', error);
        }
      );
    }
  }
  
}

rechazarSolicitud(requirement: any) {
  if (!requirement.hasActionTaken && requirement.status === 'PENDIENTE') {
    if (!requirement.hasActionTaken) {
      requirement.hasActionTaken = true;
      requirement.actionMessage = 'Has rechazado este contrato';
      this.changeStateRequirement = {
        idRequiremet: requirement.requirementId,
        status: 'RECHAZADO'
      };
      this.http.put(environment.apiUrl + '/requirement', this.changeStateRequirement).subscribe(
        (response) => {
          let dataNavigate: NavigationExtras = {
            queryParams: {
              emailreceptor: requirement.contractDetail.negotiator.email ,
              contractId: requirement.contractId.idContract,
              description: requirement.contractId.description,
              provider: requirement.contractId.providerEntity.commercial_name
            }
          };
          this.router.navigate(["/SolicitudRechazadaAntiguo"], dataNavigate);
  
          // Almacenar el estado actual después de la redirección
          localStorage.setItem('requirementState', JSON.stringify("CERRADO"));
        },
        (error) => {
          console.error('Error al actualizar el estado:', error);
        }
      );
    }
  }
  
}



}
