import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BcTabsModule} from '@bancolombia/design-system-web/bc-tabs-group'
import { OperadorSincronizacionDatosPlantaComponent } from './operador-sincronizacion-datos-planta.component';
import { BcIconModule } from '@bancolombia/design-system-web/bc-icon';
import { TablaOperadorEstadosCargaComponent } from './tabla-operador-estados-carga/tabla-operador-estados-carga.component';
import { BcTableModule } from '@bancolombia/design-system-web/bc-table';
import { BcPaginatorModule } from '@bancolombia/design-system-web/bc-paginator';

@NgModule({
  declarations: [
    OperadorSincronizacionDatosPlantaComponent,
    TablaOperadorEstadosCargaComponent,


  ],
  imports: [
    CommonModule,
    BcTabsModule,
    BcIconModule,
    BcTableModule,
    BcPaginatorModule


    
    
  ]
})
export class OperadorSincronizacionDatosPlantaModule { }
