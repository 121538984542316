import { Component, Input, OnInit } from '@angular/core';

function formatearFechaCorta(fecha: Date | string | null): string {
  if (!fecha) {
    return 'Fecha no disponible';
  }

  return fecha.toString(); // Devuelve la fecha como string sin convertirla
}
@Component({
  selector: 'app-card-fecha',
  templateUrl: './card-fecha.component.html',
  styleUrls: ['./card-fecha.component.css']
})
export class CardFechaComponent {
//Inputs
@Input() titulo!: string;
@Input() fecha!: Date;
@Input() tipo: string = "contrato";
@Input() mostrarAlerta: boolean = true;
@Input() contract!: any;
@Input() class?: string;
@Input('contractDetail') _contract!: any;

//Variables Fecha
fechaFormateada: string = "";
tituloAlerta: string = "";
color: string = "";
icon: string = "calendar-check";
  
ngOnInit(): void {
  if (this.fecha) {
    this.fechaFormateada = formatearFechaCorta(this.fecha);
    const resultadoColorEstado = this.obtenerColorEstado(new Date(this.fechaFormateada));
    this.color = resultadoColorEstado.color;
    this.tituloAlerta = resultadoColorEstado.tituloAlerta;
    this.icon = resultadoColorEstado.icon;
  } else {
    this.fechaFormateada = ""; // Asigna un valor vacío cuando la fecha es nula
    this.color = ""; // También podrías asignar valores vacíos a otras variables relacionadas con la fecha
    this.tituloAlerta = "";
    this.icon = "";
  }
}



/**
* Retorna el color de estado según la fecha de vencimiento
* @param {Date} fecha - La fecha de vencimiento del contrato
* @returns {string[]} - Un arreglo con: El color de texto, El nombre del estado y El icono correspondiente
*/
obtenerColorEstado(fecha: Date): { color: string; tituloAlerta: string; icon: string } {
  
  
  const colores = {
    vencido: ["bc-text-brand-sequential-AL-500", "Vencido", "calendar-times"],
    vigente: ["bc-text-brand-sequential-A-500", "Vigente", "calendar-check"],
    proxVencer: ["bc-text-brand-sequential-M-500", "Próximo a vencer", "calendar-exclamation"]
  };

  let currDate: Date = new Date();
  let diff: number = fecha.getTime() - currDate.getTime();
  let diffDays: number = Math.ceil(diff / (1000 * 3600 * 24));



  if (diffDays <= 0) {
    return { color: colores['vencido'][0], tituloAlerta: colores['vencido'][1], icon: colores['vencido'][2] };
  }

  if (diffDays > 0 && diffDays < 90) {
    return { color: colores['proxVencer'][0], tituloAlerta: colores['proxVencer'][1], icon: colores['proxVencer'][2] };
  }

  return { color: colores['vigente'][0], tituloAlerta: colores['vigente'][1], icon: colores['vigente'][2] };
}
}
