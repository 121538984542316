import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BcAccordionModule } from '@bancolombia/design-system-web/bc-accordion';
import { ComponentsModule } from "../../components/components.module";
import { BcTableModule } from '@bancolombia/design-system-web/bc-table';
import { MisNovedadesComponent } from './mis-novedades.component';
import { BcSearchModule } from '@bancolombia/design-system-web/bc-search';
import { BcPageHeaderModule } from '@bancolombia/design-system-web/bc-page-header';
import { NovedadAccordionContentComponent } from './novedad-accordion-content/novedad-accordion-content.component';
import { BcIconModule } from '@bancolombia/design-system-web/bc-icon';
import { SearchBarNovedadesComponent } from './search-bar-novedades/search-bar-novedades.component';
import { BcBadgeModule } from "@bancolombia/design-system-web/bc-badge";
import { BcInputSelectModule } from '@bancolombia/design-system-web/bc-input-select';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        MisNovedadesComponent,
        NovedadAccordionContentComponent,
        SearchBarNovedadesComponent
    ],
    imports: [
        CommonModule,
        ComponentsModule,
        BcAccordionModule,
        BcTableModule,
        BcSearchModule,
        BcPageHeaderModule,
        BcIconModule,
        BcBadgeModule,
        BcInputSelectModule,
        FormsModule,
        ComponentsModule
    ],
    exports:[
        MisNovedadesComponent
    ]
})
export class MisNovedadesModule { }
