import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardFechaComponent } from './card-fecha/card-fecha.component';
import { BcIconModule } from '@bancolombia/design-system-web/bc-icon';
import { BcCardContainerModule } from '@bancolombia/design-system-web/bc-card-container';
import { BcIconButtonModule } from '@bancolombia/design-system-web/bc-icon-button';
import { BcBreadcrumbModule } from '@bancolombia/design-system-web/bc-breadcrumb';
import { DetallesContratoComponent } from './main-page/detalles-contrato.component';
import { BcButtonModule } from '@bancolombia/design-system-web/bc-button';
import { ComponentsModule } from "../../components/components.module";
import { ReportarNovedadesComponent } from './reportar-novedades/reportar-novedades.component';
import { BcModalModule } from '@bancolombia/design-system-web/bc-modal';
import { PopoutComponent } from './popout/popout.component';
import { BcOffCanvasModule } from '@bancolombia/design-system-web/bc-off-canvas';
import { BcInputModule } from '@bancolombia/design-system-web/bc-input';
import { NovedadAdministradorComponent } from './reportar-novedades/novedad-administrador/novedad-administrador.component';
import {BcIllustrationModule} from '@bancolombia/design-system-web/bc-illustration';
import { ListItemContratoComponent } from './list-item-contrato/list-item-contrato.component';
import { NovedadDelegadoComponent } from './reportar-novedades/novedad-delegado/novedad-delegado.component';
import { RouterModule } from '@angular/router'
import { FormsModule } from '@angular/forms';
import { DetallesContratoPageHeaderComponent } from './detalles-contrato-page-header/detalles-contrato-page-header.component';
import { BcTooltipModule } from '@bancolombia/design-system-web/bc-tooltip';
import { FavIconButtonComponent } from 'src/app/components/fav-icon-button/fav-icon-button.component';
import { ReportarNovedadPortalComponent } from './reportar-novedad-portal/reportar-novedad-portal.component';
import { DetallesContratoUserComponent } from './detalles-contrato-user/detalles-contrato-user.component';
import { DetallesContratoAdminPortalComponent } from './detalles-contrato-admin-portal/detalles-contrato-admin-portal.component';
import { BcTabsModule } from '@bancolombia/design-system-web/bc-tabs-group';
import { ReportarCambioEstadoComponent } from './reportar-cambio-estado/reportar-cambio-estado.component';
import {BcInputSelectModule} from '@bancolombia/design-system-web/bc-input-select'
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        CardFechaComponent,
        DetallesContratoComponent,
        ReportarNovedadesComponent ,
        PopoutComponent,
        NovedadAdministradorComponent,
        ListItemContratoComponent,
        NovedadDelegadoComponent,
        DetallesContratoPageHeaderComponent,
        ReportarNovedadPortalComponent,
        DetallesContratoUserComponent,
        DetallesContratoAdminPortalComponent,
        ReportarCambioEstadoComponent,
    ],
    exports: [
        DetallesContratoComponent,
        ReactiveFormsModule,
    ],
    imports: [
        CommonModule,
        BcIconModule.forRoot({
            path: "https://library-sdb.apps.bancolombia.com/bds/6.12.10"
        }),
        BcCardContainerModule,
        ReactiveFormsModule,
        BcIconButtonModule,
        BcBreadcrumbModule,
        BcButtonModule,
        ComponentsModule,
        BcModalModule,
        BcOffCanvasModule,
        BcInputModule,
        BcIllustrationModule,
        RouterModule,
        FormsModule,
        BcTooltipModule,
        BcTabsModule,
        BcInputSelectModule,
        ComponentsModule
    ]
})
export class DetallesContratoModule { }
