import { Component, OnInit } from '@angular/core';
import { TableNameItem } from 'src/app/components/utils/enums/MarkeplaceEnum';
import SelectTableColumn from '../models/SelecTableColumn.model';
import {mockMarkeplace} from '../models/mockMarketplace';
import { BcTableActionButton, BcTableOptionMenu } from '@bancolombia/design-system-behaviors';
import moment from 'moment';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BcDialogService } from '@bancolombia/design-system-web/bc-services';
import { BcAlertComponent } from '@bancolombia/design-system-web/bc-alert';
import { MarketPlaceStatusAsignationEnum } from 'src/app/enums/marketplace-estatus.enum';
import * as XLSX from 'xlsx';



@Component({
  selector: 'app-demand',
  templateUrl: './demand.component.html',
  styleUrls: ['./demand.component.css']
})
export class DemandComponent {

  constructor(private fb: FormBuilder, private dialogService: BcDialogService){}

  cellOption: BcTableOptionMenu[] = [
    {text:"joser",action: ()=> "jose",  }
  ]

  public dataAribaEXEL:any[] = []
  public requirementDeatail: string = "";
  public isLoadDataServiceOrProcess: {
    capacity?: boolean,
    fileUploadAriba?: boolean
  } = {capacity: false, fileUploadAriba: false};
  public get markeplaceEnum (): typeof TableNameItem { return TableNameItem; }
  public proveedoresSelectValues: { label: string, value: any }[] = [];
  public requesProgresSelectValues: { label: string, value: any }[] = [];
  public showTable = false;
  public currentPage: any;
  public dataInput: any = {};
  totalRegisters: any = 0;
  perpage: any = 10;
  permultiple: string = "10";
  itemsPerPage = 5; // Número de elementos por página
  data:any = []; // Tu conjunto de datos


  /**
   * @description: Formulario para manejar cambios de informacion en los inputs
   * @return: FormGroup
   * @param: FormControl
   */
  form = this.fb.group({
    horas_estimadas: ['',Validators.required],
    semanas_requeridas: ['',Validators.required],
    observaciones: [''],
  });

  actions: BcTableActionButton[] = [
    { icon: 'download-document', text: 'Cambiar Administrador', id: 'cambiar' },
  ];
  
  public onChangePage(data: any) {
    this.data = mockMarkeplace.data;
    const startIndex = (data["CurrentPage"] - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    const pageData = this.data.slice(startIndex, endIndex);
    this.currentPage = pageData;
  }

  ngOnInit(): void {    
    this.loadData();
    this.initLoadTable();
    const dataTable = {
      "TotalRegisters": this.totalRegisters,
      "CurrentPage": 1,
      "PerPage": 10
    }
    this.onChangePage(dataTable);
  }

  loadData () {
    this.totalRegisters = mockMarkeplace.data.length
  }

  testingTemp(){
    const currentdate = (new Date().getDate() +"/"+ (new Date().getMonth()+1) +"/"+ new Date().getFullYear())
  }

  

  onSelectTableColumn (selected: any, ) {
    const data = selected as { label: string, value: any }[]; 
    data.map((r:{ label: string, value: any })=> r.value =true);
    const items: any[] = Object.keys(this.selectTableColumn);
    
    items.map( i=> {
      const response = data.find((item) => i == item.label)
      if(response !== undefined){
        this.selectTableColumn[i] = true;
      }else{
        this.selectTableColumn[i] = false;
      }
    })
  }



  initLoadTable () {
    const items: any[] = Object.keys(this.selectTableColumn);
    items.map(i => {
      this.proveedoresSelectValues.push({label: i,value: false})
    })
    for(let data of ["En curso", "Solicitud"]){
      this.requesProgresSelectValues.push({label:data, value:data})
    }
  }

  public selectTableColumn : SelectTableColumn | any = {
    //IDFORM            : true,
    //STATE             : true,
    //NAME              : false,
    REQUEST           : false,
    //REQUESTRUN        : true,
    DESCRIPTION       : false,
    BADGET            : false,
    MONEY             : false,
    PROVIDER          : false,
    TIPOLOGY          : false,
    REQUIREMENTLEGALY : false,
    PRIORITIZE        : false,
    ROL               : false,
    TYPEPROCES        : false,
    //DAYDURATION       : false,
  }

  public selectItemCheckbox (data: any) {
    this.dataInput = data
    this.requirementDeatail = this.dataInput["ID_lista"]
    this.isLoadDataServiceOrProcess.capacity = true;
    setTimeout(() => {
      this.isLoadDataServiceOrProcess.capacity = false;
    }, 1000);
  }

  /**
   * @description: Permite generar para el input 'Estado Vencido' SI o NO esta vencido 
   * @return: String
   * @param: SharepointItem
   */
  public generateDefeated(sharepointItem?: any, run?: boolean){
    const currentdate = (new Date().getDate() +"/"+ (new Date().getMonth()+1) +"/"+ new Date().getFullYear())
    let response = "";
    if(run){
      response = "Fecha incorrecta"
      if(sharepointItem['semanas_requeridas'] == undefined || sharepointItem['semanas_requeridas'].length == 0){
        response = "Semanas requeridas sin asignar"
      }else{
        if(sharepointItem['Fecha Asignación/Delegación'] != undefined
          && moment(sharepointItem['Fecha Asignación/Delegación'],"DD/MM/YYYY").isValid()){
            mockMarkeplace.data.map( (i: any) => {
              if(i["ID_lista"] == sharepointItem["ID_lista"]){
                const data = sharepointItem['Fecha Asignación/Delegación'].toString();
                if(moment( currentdate,"DD/MM/YYYY" ).diff( moment( data,"DD/MM/YYYY" ),'days')/7 > sharepointItem['semanas_requeridas']){
                  response = "SI"
                }else{
                  response = "NO"
                }
              }
            }
          )
        }
      }      
    }
    return response
  }

  /**
   * @description: Permite generar para el input y columna 'Solicitud en curso' [En CURSO, SOLICITUD]
   * @return: String
   * @param: SharepointItem
   */
  public generateRequesRun(sharepointItem?: any): string{
    let response = "SOLICITUD";
    if(sharepointItem['Negociador'] != undefined && sharepointItem['Negociador'].length > 2 ){
      if(sharepointItem['Fecha Asignación/Delegación'] != undefined && moment(sharepointItem['Fecha Asignación/Delegación'],"DD/MM/YYYY").isValid()){
        response = "EN CURSO"
      }
    }
    return response
  }

  /**
   * @description: Permite generar para el input y columna 'Solicitud en curso' [EN CURSO:12B76A, SOLICITUD: FF7F41]
   * @return: { reques: string, color: string }
   * @param: SharepointItem
   */
  public generateRequesRunColor(sharepointItem?: any): {reques: string, color: string}{
    let response: {reques: string, color: string} = {reques: "SOLICITUD",color:"#FF7F41"};
    if(this.generateRequesRun(sharepointItem ) === "EN CURSO"){
      response = {reques: "EN CURSO",color:"#12B76A"}
    }
    return response
  }

  /**
   * @description: Valida campos campos para gestion de capacidad
   * @return: {  }
   */
  public onCapacityRegister(dataInput: any) {
    if(!this.form.controls["horas_estimadas"].valid){
      this.alertAba("error", "No se pudo realizar la solicitud, debido al campo 'horas estimadas'", "Error");
    }
    if(!this.form.controls["semanas_requeridas"].valid){
      this.alertAba("error", "No se pudo realizar la solicitud, debido al campo 'semanas requeridas'", "Error");
    }
    if(this.form.controls["semanas_requeridas"].valid && this.form.controls["horas_estimadas"].valid){
      if(this.requirementDeatail.toString.length > 0){
        this.alertAba("success","Gestion de capacidad guardada para el registro: "+this.requirementDeatail,"Correcto")
        this.saveAndGetdataCapacity(dataInput)
      }else{
        this.alertAba("error", "No se pudo realizar la solicitud, Selecciona almenos un registro", "Error");
      }      
    }
  }

  /**
   * @description:  Guarda y genera nuevas columnas en DB para la gestion de capacidad
   * @return: {  }
   * @param: {}
   */
  private saveAndGetdataCapacity(sharepointItem?: any){
    mockMarkeplace.data.forEach( (i: any) => {
      if(i["ID_lista"] === sharepointItem["ID_lista"]){
        i['semanas_requeridas'] = this.form.controls["semanas_requeridas"].value
        i['horas_estimadas'] = this.form.controls["horas_estimadas"].value
        i['observaciones'] = this.form.controls["observaciones"].value
        this.form.reset()
      }
      
    })
  }

  /**
   * @description: Permite generar para la columna el estado de asignacion [PENDIENTE:  FFD2BC,         12B76A, SOLICITUD: FF7F41]
   * @enum: marketplace-status.enum.ts : StatusAsignationEnum
   * @return: { reques: string, color: string }
   * @param: SharepointItem
   */
  public generateStateAsignation(sharepointItem?: any) {
    //MarketPlaceStatusAsignationEnum.PENDIENTE
    let response = MarketPlaceStatusAsignationEnum.PENDIENTE
    //MarketPlaceStatusAsignationEnum.REVICION
    if(sharepointItem['Negociador'].length > 1 
              && sharepointItem['Categoría'].length > 1 
              && sharepointItem['horas_estimadas'] != undefined
              && sharepointItem['Fecha Asignación/Delegación'].length == 0) 
    {
      response = MarketPlaceStatusAsignationEnum.REVICION
    }
    if(sharepointItem['Negociador'].length > 1
              && sharepointItem['horas_estimadas'] != undefined
              && sharepointItem['Fecha Asignación/Delegación'].length > 1
              && this.generateRequesRunColor(sharepointItem).reques == 'EN CURSO')
    {
      response = MarketPlaceStatusAsignationEnum.EN_NEGOCIACION
    }
    
    return response;
  }

  private alertAba(type: String, menssage: String, tittle: string): void {
    const dialogRef = this.dialogService.open(BcAlertComponent, {
      id: tittle,
      type: type,
      text: menssage,
      timeout: 8000,
    });

    dialogRef.onResult().subscribe(
      (closed) => {},
      (dismissed) => {},
      () => {}
    );
  }

  /**
   * @description:  Permite subir un archivo para afinar datos faltantes de ariba
   * @return: {ARIBAXML}
   * @param: event
   */
  onUploadFileAriba(event: any,dataInput: any) {  
    this.isLoadDataServiceOrProcess.fileUploadAriba = true;  
    let workBook: any = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = event.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial:any, name: any) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      const dataJson = jsonData;
      
      setTimeout(() => {
        this.isLoadDataServiceOrProcess.fileUploadAriba = false;
        this.dataAribaEXEL = dataJson['Sheet1']
        this.generateStatudAribaItem(this.dataAribaEXEL, dataInput)
        console.log(this.dataAribaEXEL)
      }, 1000);
    }
    reader.readAsBinaryString(file);
  }

  /**
   * @description:  Genera la logica para identificar el estado de ariba del item
   * @return: {ARIBAXML}
   * @param: event
   */
  private generateStatudAribaItem(aribaList: any[], dataInput: any) {
    aribaList.map( (data) => {
      if(data['ID de proyecto'] == dataInput['SR_ARIBA']){
        
        this.dataInput['Estado_SR'] = data['Estado SR']
        console.log("Tenemos data:: ", this.dataInput)
      }
    })
  }


  //

  isComponentVisible: boolean = false;

  isSwitchOn: boolean = false; // Cambia el nombre de la propiedad a isSwitchOn}
  toggleSwitch() {

    
    // Cambia el estado del interruptor y muestra u oculta el componente
    this.isComponentVisible = !this.isComponentVisible;
  }

  //Date

  selectedDate: string | undefined; // Variable para almacenar la fecha seleccionada

  // Función para manejar cambios en la fecha
  onDateChange(event: any) {
    this.selectedDate = event.target.value;
    console.log('Fecha seleccionada:', this.selectedDate);
  }
  

  
  
  
}
