<div class="bc-col-12">
    <div class="bc-search" id="searchId">
        <div class="bc-search-content">
            <div class="bc-search-input">
                <div class="bc-form-field">
                    <input class="bc-input" id="id-01" required=""
                        name="nombreProducto" type="text" value="" placeholder="Búsqueda por palabras clave" (change)="updateBusquedaPalabraClave($event)"/><label
                        for="id-01">Búsqueda por palabra clave</label></div>
            </div>
            <div class="bc-search-button"><em class="bc-icon">search</em></div>
            <div class="bc-search-button-expanded" (click)="toggleExpandSearchAdvance()"><em
                    class="bc-icon">{{arrowIcon}}</em></div>
            <div class="bc-content-advanced-search {{searchAdvToggleClass}}">
                <h5>Búsqueda avanzada</h5>
                <div class="content-form-search">
                    <div class="bc-flex bc-p-2 bc-w-100 bc-gap-lg-5 bc-direction-column bc-direction-lg-row">
                      <bc-input-select componentId="bc-select-proveedor" [multiple]="true" [formatType]="'full'" [values]="proveedoresSelectValues"
                      label="Proveedor" span="" (valueOutput)="updateSelectValue($event, 'proveedores')" [filter]="true" class="bc-w-100"> 
                      </bc-input-select>

                      <bc-input-select componentId="bc-select-id-contrato" [multiple]="true" [formatType]="'full'" [values]="contratosSelectValues"
                      label="ID del contrato" span="" (valueOutput)="updateSelectValue($event, 'contratos')" [filter]="true" class="bc-w-100"> 
                      </bc-input-select>

                    </div>
                    <div class="bc-flex bc-p-2 bc-w-100 bc-gap-lg-5 bc-direction-column bc-direction-lg-row">
                        <bc-input-select componentId="bc-select-admin" [multiple]="true" [formatType]="'full'" [values]="adminContratosSelectValues"
                        label="Administrador del contrato" span="" (valueOutput)="updateSelectValue($event,  'administradores')" [filter]="true" class="bc-w-100"> 
                        </bc-input-select>
                        <bc-input-select componentId="bc-select-prox-vencer" [multiple]="true" [formatType]="'full'" [values]="proxVencerSelectValues"
                        label="Próximo a vencer" span="" (valueOutput)="updateSelectValue($event, 'proxVencer')" [filter]="true" class="bc-w-100"> 
                        </bc-input-select>
                    </div>
                    <div class="bc-flex bc-p-2 bc-w-100 bc-gap-lg-5 bc-direction-column bc-direction-lg-row">
                        <bc-checkbox (changeState)="updateCheckFavValue($event)" [value]="'1'" [isChecked]="onlyFavCheckStatus" label="Mostrar solo contratos favoritos"></bc-checkbox>
                    </div>
                </div>
                <div class="bc-search-button"><button class="bc-button-primary bc-button-small" aria-label="Boton"
                        bc-button="" sizebutton="small" role="button" tabindex="0" (click)="searchContratos()">Buscar</button></div>
            </div>
        </div>
        <div class="bc-col-xl-12 bc-col-lg-12 bc-col-md-12 bc-col-sm-12 bc-col-xs-12">
            <div class="bc-search-result">
                <div class="bc-content-show-result"></div>
                <div class="bc-search-content-clear"><span class="bc-search-clear">Limpiar búsqueda</span></div>
            </div>
        </div>
        <div class="bc-col-xl-12 bc-col-lg-12 bc-col-md-12 bc-col-sm-12 bc-col-xs-12">
            <div class="bc-search-result-null">
                <div><img class="bc-logo--sm" alt="iconMenu" src="/assets/icons/pictogramas/search.svg" /></div>
                <div><label>No se han encontrado resultados para su búsqueda</label>
                    <ul>
                        <li>Pruebe diferentes palabras claves</li>
                        <li>Pruebe con un slo criterio de búsqueda</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    
</div>