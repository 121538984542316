<div class="bc-row">
    <div class="bc-col-12">    
        <!--app-summary></app-summary-->
        <div *ngIf="selectTab.name == 'Demanda'">
            <app-demand></app-demand>
        </div>
        <div *ngIf="selectTab.name == 'Capacidad'">
            <app-capacity></app-capacity>
        </div>
        <div *ngIf="selectTab.name == 'Resumen'">
           <app-sumary></app-sumary>
        </div>
    </div>
</div>