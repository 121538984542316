import { Component, Input } from '@angular/core';
import { BcAlertComponent } from '@bancolombia/design-system-web/bc-alert';
import { BcDialogService } from '@bancolombia/design-system-web/bc-services';
import { ContratosService } from 'src/app/services/api-services/contratos-services/contratos.service';
import { EstadoServicesService } from 'src/app/services/estado-services/estado-service';

import { NovedadEstado } from 'src/app/models/novedad.interface';
import { Contrato } from 'src/app/models/contratos.interface';
import { BcTableActionButton, BcTableOptionMenu } from '@bancolombia/design-system-behaviors/lib/behaviors/organisms/bc-table/bc-table-interface';
import { formatearFechaCorta } from 'src/app/components/utils/formato-fecha.componente';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { selectorContractList } from 'src/app/state/contract_state/contract.selector';
import { UserService } from 'src/app/services/api-services/user-services/user.service';
import { UserGrap } from 'src/app/mock/graph/usersGraph.mock';
import { SaveRequirementModel } from 'src/app/models/requirement.model';
import { FormControl } from '@angular/forms';
import { RequirementStatusEnum } from 'src/app/enums/reporte-estado.enum';
import { RequirementTypeEnum } from 'src/app/enums/reporte-novedad.enum';
import { RequirementService } from 'src/app/services/api-services/requirement-services/requierement.service';





@Component({
  selector: 'app-novedades-negociador-solicitud',
  templateUrl: './novedades-negociador-solicitud.component.html',
  styleUrls: ['./novedades-negociador-solicitud.component.css']
})
export class NovedadesNegociadorSolicitudComponent {

 

  
  @Input('requirement') novedad!: any; 
  dataTable: any[] = [];
  public contract: any = [];
  estadoSeleccionado: string | null = null;
  selectedOption: string | null = null;
  contractDetail: any = { selectedOption: null };
  @Input('contractDetail') _contract!: any;

  constructor(
    private dialogService: BcDialogService,
    private requirementService : RequirementService ,
    private contratosService: ContratosService,
    private estadoService: EstadoServicesService,
    private store: Store<any>,
    private location: Location,
    private router: Router,
    private userService: UserService,
    private route: ActivatedRoute
    ){
      this.estadoSeleccionado = this.estadoService.getEstadoSeleccionado();
    console.log('Estado seleccionado recibido:', this.estadoSeleccionado);
    
  }

  reportarNovedadEstado() {
    if (this.selectedOption) {
      this.estadoService.setEstadoSeleccionado(this.selectedOption);
    }
  }

  onOptionSelected(event: any) {

    const value = event.target.value; 
    if (value === "") {
    this.selectedOption = null; // Establece en null si no se selecciona ninguna opción
    this.alertAba("error", "No se seleccionó ninguna opción", "Error");
    return; // Detiene la función aquí si no se selecciona ninguna opción.
  }
    this.selectedOption = value;
    this.contractDetail.selectedOption = value; // Asignar la opción al objeto contractDetail
    this.estadoService.setEstadoSeleccionado(value);
  }

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      const contractId = params.get('id') ;
      if (contractId !== null) {
      this.contratosService.getContractByIdContract(contractId).subscribe(data => {
        this.contractDetail = data.data;       
      });
    }
    });

  
    this.currentUser = JSON.parse(sessionStorage.getItem('userCurrentGraph') || '{}');

    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    console.log(this.novedad)
    /*this.contratosService.getAllContractByIdContrac(this.novedad.contractId.idContract).subscribe(
      {
        next: (success) => this.contract = success.data,
      }
    )
    */

    this.originalData = this._pages.data;
    
    this.userService.getUserDataSubject().subscribe((userData) => {
      this.userData = userData.data;
      this.userDataAvailable = true;
      console.log(this.userData.rol);
    });

    let showMoreDashboard: any[] = [];
    this.store.select(selectorContractList).subscribe(
      (success)=>{
        
        showMoreDashboard = success;
      }, (error)=> console.log("Error;; ", error) 
    )

    console.log("Data del componente:", this._pages); // Verifica si `_pages` tiene la información esperada.
    console.log("Contrato seleccionado:", this.selectedContract); // Verifica si `selectedContract` tiene la información esperada.
    console.log("Estado del contrato:", this.selectedContract?.contractEstatusEntity); // Verifica si `contractEstatusEntity` tiene la información esperada.
    

    
  }

  private alertAba(type: String, menssage: String, tittle: string): void {
    const dialogRef = this.dialogService.open(BcAlertComponent, {
      id: tittle,
      type: type,
      text: menssage,
      timeout: 7000,
    });

    dialogRef.onResult().subscribe(
      (closed) => {},
      (dismissed) => {},
      () => {}
    );
  }

  

  //Variables
  currentPageIndex: number = 0;
  itemsPerPage: number = 10;
  userData: any;
  userDataAvailable = false;
  selectedContract: any;
  showDescription: boolean = false;
  @Input('data') _pages!: any;
  totalRegisters: any;
  perpage: any = 10;
  permultiple: string = "10";
  originalData: any[] = [];



  showDetails(contract: any) {
    console.log("Contract recibido:", contract); // Verifica si el contrato se recibe correctamente.
  
    if (contract && contract.contractEstatusEntity) {
      this.selectedContract = contract; // Asignar el contrato seleccionado a la variable
    } else {
      console.log("Error: Contract o contractEstatusEntity es nulo o indefinido.");
    }
  }

  //Rechazar

  //Variables
  public userGraphData: any;
  public userGrapFilter: any[] = [];
  public formMeetAdminInputs: any = {};
  public inputAdministratorObservation: string = "";
  public showModal = true;
  public valuesTemp: any = {};

  dateSelector = new FormControl();
  @Input() textButton!: string;
  @Input() contrato: any;
  

  public get requirementTypeEnum(): typeof RequirementTypeEnum {
    return RequirementTypeEnum;
  }

  public get requirementStatusEnum(): typeof RequirementStatusEnum {

    return RequirementStatusEnum;

  }


  public inputData: any;
  protected currentUser: any;
  SaberSi: number | null = 10;

  public onNoveltySend(requerimentType: any, requirementStatus: any) {


    const saveData: SaveRequirementModel = {
      emailReceptor:  this.novedad.receptorId.email,
      emailReques: this.currentUser.mail,
      idContract: this.novedad.contractId.idContract,
      observation: "SE CERRO EL CAMBIO DE ADMINISTRADOR EN ARIBA",
      initialDate: new Date(), 
      finalDate: new Date(),     
      requirementType: requerimentType,
      requirementStatus: requirementStatus,
   };
    console.log("traje esto: ", saveData);

    

    this.requirementService.saveRequirement(saveData).subscribe(
      {
        next: (response) => {
          this.alertAba("success", "La novedad de cambio de Administrador fue reportada", "Correcto")
        },
        error: (error) => {
          this.alertAba("error", "La novedad de cambio de Adminsitrador no se pudo procesar", "Error")
        },
      }
    )

    //Function for Adminsitrador Nuevo

  }

  
  

}
