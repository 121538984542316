import { Component } from '@angular/core';

import { formatearFecha } from 'src/app/components/utils/formato-fecha.componente';
import { Contrato } from "../../../models/contratos.interface"
import { ActivatedRoute } from '@angular/router';
import { ContratosService } from 'src/app/services/api-services/contratos-services/contratos.service';
import { UserService } from 'src/app/services/api-services/user-services/user.service';
import { BcDialogService } from '@bancolombia/design-system-web/bc-services';
import { NovedadesService } from 'src/app/services/api-services/novedades-services/novedades.service';
import { RequirementService } from 'src/app/services/api-services/requirement-services/requierement.service';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.css']
})
export class MainPageComponent {

  contractDetail: any = {};
  contractChild : any = [];
  contractMaster: any = {};
  isLoadingData: boolean = true;


  constructor(
    private route: ActivatedRoute,
    private contratosService: ContratosService,
    private userService: UserService,
    private novedadesServices: NovedadesService,
    private requirementService: RequirementService,
    private dialogService: BcDialogService,
  ) { }

  ngOnInit(): void {

    this.currentUser = JSON.parse(sessionStorage.getItem('userCurrentGraph') || '{}');


    this.userService.getUserDataSubject().subscribe(data => {
      this.userData = data;
      this.tittle = "Hola " + this.userData?.data.name;
    });
    this.userData = this.userService.getCurrentUserData();

    this.route.paramMap.subscribe(params => {
      const contractId = params.get('id');
      if (contractId !== null) {
        this.loadContractData(contractId);
      }
    });

    this.userService.getUserDataSubject().subscribe((userData) => {
      this.userData = userData.data;
      this.userDataAvailable = true;
    });
  }

  loadContractData(contractId: string) {
    this.resetData();
    this.isLoadingData = true;
    this.loadContractDetail(contractId);
    this.loadChildContracts(contractId);
  }

  loadContractDetail(contractId: string) {
    this.contratosService.getContractByIdContract(contractId).subscribe(data => {
      this.contractDetail = data.data;
      this.isLoadingData = false;
    });
  }

  loadChildContracts(contractId: string) {
    this.contratosService.getChildsContractsByIdContract(contractId).subscribe(data => {
        this.contractChild = data.data.childContracts;
        this.contractMaster = data.data.masterContract;
        // Ordenar contratos hijos por fecha inicial
        this.orderContractsByDate();
    });
}

orderContractsByDate(): void {
    this.contractChild.sort((a: any, b: any) => {
        const dateA = a.initialDate ? new Date(a.initialDate) : null;
        const dateB = b.initialDate ? new Date(b.initialDate) : null;

        if (dateA && dateB) {
            return dateA.getTime() - dateB.getTime();
        } else if (dateA) {
            return 1;
        } else if (dateB) {
            return -1;
        } else {
            return 0;
        }
    });
}


  resetData() {
    this.contractDetail = {};
    this.contractChild = [];
    this.contractMaster = {};
  }

  /**
 * Objeto que representa el contrat.
 * @typedef {Object} Contrato
 * @property {string} idContrato - Identificador del contrato.
 * @property {Object} administrador - Información del administrador del contrato.
 * @property {string} administrador.usuarioRed - Usuario de red del administrador.
 * @property {string} administrador.nombre - Nombre del administrador.
 * @property {string} administrador.correo - Correo electrónico del administrador.
 * @property {Object} negociador - Información del negociador del contrato.
 * @property {string} negociador.usuarioRed - Usuario de red del negociador.
 * @property {string} negociador.nombre - Nombre del negociador.
 * @property {string} negociador.correo - Correo electrónico del negociador.
 * @property {Object} proveedor - Información del proveedor del contrato.
 * @property {string} proveedor.id - Identificador del proveedor.
 * @property {string} proveedor.nombre - Nombre del proveedor.
 * @property {string} proveedor.nit - NIT del proveedor.
 * @property {string} proveedor.nombreComercial - Nombre comercial del proveedor.
 * @property {string} proveedor.correoComercial - Correo electrónico del contacto comercial del proveedor.
 * @property {string} proveedor.contactoComercial - Número de contacto del contacto comercial del proveedor.
 * @property {Object} estado - Información del estado del contrato.
 * @property {number} estado.idEstado - Identificador del estado.
 * @property {string} estado.estado - Descripción del estado.
 * @property {Array<Object>} contratosHijos - Lista de contratos hijos del contrato.
 * @property {string} contratosHijos.idContrato - Identificador del contrato hijo.
 * @property {Object} contratosHijos.proveedor - Información del proveedor del contrato hijo.
 * @property {string} contratosHijos.proveedor.id - Identificador del proveedor.
 * @property {string} contratosHijos.proveedor.nombre - Nombre del proveedor.
 * @property {string} contratosHijos.proveedor.nit - NIT del proveedor.
 * @property {Object} contratosHijos.estado - Información del estado del contrato hijo.
 * @property {number} contratosHijos.estado.idEstado - Identificador del estado.
 * @property {string} contratosHijos.estado.estado - Descripción del estado.
 * @property {string} contratosHijos.nombre - Nombre del contrato hijo.
 * @property {Object} contratoPadre - Información del contrato padre.
 * @property {string} contratoPadre.idContrato - Identificador del contrato padre.
 * @property {Object} contratoPadre.proveedor - Información del proveedor del contrato padre.
 * @property {string} contratoPadre.proveedor.id - Identificador del proveedor.
 * @property {string} contratoPadre.proveedor.nombre - Nombre del proveedor.
 * @property {string} contratoPadre.proveedor.nit - NIT del proveedor.
 * @property {Object} contratoPadre.estado - Información del estado del contrato padre.
 * @property {number} contratoPadre.estado.idEstado - Identificador del estado.
 * @property {string} contratoPadre.estado.estado - Descripción del estado.
 */

  contrato: Contrato = {
    idContrato: "CW102",
    administrador: {
      usuarioRed: "rafa",
      nombre: "Rafael Muñoz",
      correo: "rafa@bancolombia.com.co",
    },
    negociador: {
      usuarioRed: "pepe",
      nombre: "Jose Perez",
      correo: "pepe@bancolombia.com.co",
    },
    proveedor: {
      id: "ACM120",
      nombre: "IBM",
      nit: "9999999-9",
      nombreComercial: "Pedro Nel Osorio",
      correoComercial: "posorio@gmv.com.co",
      contactoComercial: "3003458757",
    },
    estado: {
      idEstado: 1,
      estado: "Borrador",
    },
    contratosHijos: [
      {
        idContrato: "CW567",
        proveedor: {
          id: "ACM120",
          nombre: "IBM",
          nit: "9999999-9",
        },
        estado: {
          idEstado: 1,
          estado: "Modificación del borrador",
        },
        nombre: "Licencias de software",
      },
      {
        idContrato: "CW567",
        proveedor: {
          id: "ACM120",
          nombre: "IBM",
          nit: "9999999-9",
        },
        estado: {
          idEstado: 1,
          estado: "Congelado",
        },
        nombre: "Licencias de software",
      },
      {
        idContrato: "CW567",
        proveedor: {
          id: "ACM120",
          nombre: "IBM",
          nit: "9999999-9",
        },
        estado: {
          idEstado: 1,
          estado: "Pendiente",
        },
        nombre: "Licencias de software",
      }
    ],
    contratoPadre: {
      idContrato: "CW100",
      proveedor: {
        id: "ACM120",
        nombre: "IBM",
        nit: "9999999-9",
      },
      estado: {
        idEstado: 1,
        estado: "Publicado",
      },
      nombre: "Licencias de software",
    },
    nombre: "Licencias IBM DataStage",
    descripcion: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae ab temporibus enim pariatur id iusto ullam officia dolores, ratione quaerat, veniam dolor deserunt ex? Sed animi consequatur debitis eaque distinctio.  Autem minus ad nam incidunt libero vitae eaque unde deleniti delectus assumenda dicta, omnis soluta ullam, corrupti laudantium quae officiis corporis harum. Amet quia illo, possimus id tempore quam temporibus?",
    fechaInicio: new Date("2020-01-15"),
    fechaFinal: new Date("2024-01-15"),
    vencimientoTarifa: new Date("2022-10-17"),
    tipoAcuerdo: "Contrato marco",
    areaResponsable: "Abastecimiento Bancolombia",
    familia: "Canales digitales",
    proximoVencer: false,
    esFavorito: true,
    tipoVigencia: "Indefinido",
  };


   // Variables Usuario
   userData: any;
   userDataAvailable = false;
 
   /**
    * Constructor del componente DashboardComponent.
    * @param {UserService} userService - Servicio para obtener los datos del usuario autenticado.
  
 
   /**
    * Método del ciclo de vida de Angular OnInit.
    * Se ejecuta una vez que el componente ha sido inicializado.
    * Aquí se obtienen los datos del usuario autenticado desde el UserService y se almacenan en la variable userData.
    */
 


   //
   tittle = "Novedades Negociador";
  fecha: any;
  protected currentUser: any;
  public requirement: any[] = [];
  public novedad: any[] = [];
  dataLoaded: boolean | undefined;
  requirementnov: any[] = [];

  changeStateRequierement = new Object();

  changeStateRequirement = {
    idRequiremet: 'requirement.requirementId',
    status: 'requirement.contractId.status'
  }


  private loadData() {

    const idContract = this.route.snapshot.paramMap.get("idContrato")
    const idReceptor = this.route.snapshot.paramMap.get("idReceptor")

    const requirementParamnNov: any = {
      email: this.currentUser.mail,
      type: "RECEPTOR"
    }

    const requirementParam: any = {
      email: this.currentUser.mail,
      type: "REQUEST"
    }

    const dataLoaded = false;


    this.requirementService.findByIdContractAndReceptor(requirementParamnNov).subscribe(
      (response) => {

        this.novedad = response.data;
        this.dataLoaded = true;
      },
      (error) => {
        //this.alertAba("error","No se pudo cargar las novedades","Error")
      }
    )

    this.requirementService.findByIdContractAndReceptor(requirementParam).subscribe(
      (response) => {
        this.requirement = response.data;
      },
      (error) => {
        //this.alertAba("error","No se pudo cargar las novedades","Error")
      }
    )
  }


  

}
