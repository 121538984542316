import { Injectable } from '@angular/core';
import { ContratoLite } from 'src/app/models/contratos.interface';
import { NovedadEstado } from "../../../models/novedad.interface";

@Injectable({
  providedIn: 'root'
})
export class NovedadesService {

  private _resultados: NovedadEstado[] = [];

  constructor() {}


  get resultados() {
    return [...this._resultados];
  }

  searchNovedadesByAdmin() {
    /*

      To-Do: 
        - Colocar argumento de entrada: usuario de red loggeado.
        - Implementar peticion http (depende de la API)´
        - ...

    */
    
    const results: NovedadEstado[] = [
      {
        "idNovedad": 1,
        "contrato": {
          "idContrato": "CW356",
          "estado":{
            "idEstado": 1,
            "estado": 'Publicado'
          },
          "nombre": "Licencias de software",
          "proveedor": {
            "id": "ACM_123",
            "nombre": "IBM",
            "nit": "123456"
          }
        },
        "valorPrevio": {
          "idEstado": 1,
          "estado": 'Publicado'
        },
        "valorNuevo": {
          "idEstado": 2,
          "estado": 'Pendiente'
        },
        "tipoNovedad": {
          "id": 2,
          "nombre": "Cambio de estado",
        },
        "observaciones": "Lorem ipsum",
        "fechaRadicacion": new Date("2023-02-15 08:00:00"),
        "historialEstados": [
          {
            "fecha": new Date("2023-02-15 09:12:45"),
            "estado": {
              "id": 2,
              "nombre": "En espera"
            }
          },

          {
            "fecha": new Date("2023-02-15 09:02:12"),
            "estado": {
              "id": 1,
              "nombre": "Resuelto"
            }
          }
        ]
      },
      {
        "idNovedad": 1,
        "contrato": {
          "idContrato": "CW356",
          "estado":{
            "idEstado": 1,
            "estado": 'Publicado'
          },
          "nombre": "Licencias de software",
          "proveedor": {
            "id": "ACM_123",
            "nombre": "IBM",
            "nit": "123456"
          }
        },
        "valorPrevio": {
          "idEstado": 1,
          "estado": 'Publicado'
        },
        "valorNuevo": {
          "idEstado": 2,
          "estado": 'Pendiente'
        },
        "tipoNovedad": {
          "id": 2,
          "nombre": "Cambio de estado",
        },
        "observaciones": "Lorem ipsum",
        "fechaRadicacion": new Date("2023-02-15 08:00:00"),
        "historialEstados": [
          {
            "fecha": new Date("2023-02-15 09:12:45"),
            "estado": {
              "id": 2,
              "nombre": "En espera"
            }
          },

          {
            "fecha": new Date("2023-02-15 09:02:12"),
            "estado": {
              "id": 1,
              "nombre": "Resuelto"
            }
          }
        ]
      }
    ]

    this._resultados = results;

    

    // get contratosFromResultados(): ContratoLite[]{
    //   const contratos = this.resultados.map(
    //     (contrato: NovedadEstado) => (contr)
    //   )
    // }
  }
}
