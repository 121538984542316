<!-- Menu Básico -->


<!-- <bc-menu class="menu-app-showcase" [hideProfileInSubmenu]="true">
  <bc-menu-drawer>
    <ng-container *ngIf="this.menuItems && menuItems.length > 0">
      <bc-menu-item *ngFor="let menuItem of this.menuItems" [text]="menuItem.text" [iconName]="menuItem.iconName" routerLink="/{{menuItem.path}}" >
      </bc-menu-item>
    </ng-container>
  </bc-menu-drawer>
  <bc-menu-main>
  </bc-menu-main>
  <div class="bc-content-body">
    <router-outlet ></router-outlet>
  </div>
</bc-menu> -->



<!-- Menu de prueba 1 -->


<bc-menu class="menu-app-showcase" [hideProfileInSubmenu]='false' [state]="'close'">
  <bc-menu-drawer>
    <div class="dropdown" (click)="toggleDropdown()">
      <ng-container *ngIf="userData.rol === 'ADMINISTRADOR DE CONTRATOS'">
        <bc-menu-item text='Gestión de contratos' iconName='house'  id="dropdownBtn"></bc-menu-item>
        </ng-container>
        <ng-container *ngIf="userData.rol === 'NEGOCIADOR/CATEGORY'">
          <bc-menu-item text='Gestión de contratos' iconName='house'  id="dropdownBtn"></bc-menu-item>
      </ng-container>
      <div *ngIf="isDropdownOpen" class="dropdown-content">
        <bc-menu-column>
          <bc-menu-item *ngFor="let menuItem of this.menuItems" [text]="menuItem.text" [iconName]="menuItem.iconName" routerLink="/{{menuItem.path}}" >
            </bc-menu-item>
        </bc-menu-column>
      </div>

    </div>
  </bc-menu-drawer>
  <bc-menu-main>
  </bc-menu-main>
  <div class="bc-content-body">
    <router-outlet></router-outlet>
  </div>
</bc-menu>


<!-- Menu de prueba 2 -->
<!-- 
 <bc-menu class="menu-app-showcase"  [hideProfileInSubmenu]='false' [state]="'close'">
  <bc-menu-drawer>
    <bc-menu-item identifierContent='id2' text='Modulos' iconName='house' ></bc-menu-item>
  </bc-menu-drawer>
  <bc-menu-main>
    <bc-menu-body identifier='id2'>
      <bc-menu-column>
        <bc-menu-option *ngFor="let menuItem of this.menuItems" [text]="menuItem.text"
          routerLink="/{{menuItem.path}}" ></bc-menu-option>
      </bc-menu-column>
    </bc-menu-body>   
  </bc-menu-main>
  <div class="bc-content-body">
    <router-outlet></router-outlet>
  </div>
</bc-menu> -->
