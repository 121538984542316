import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { BcAlertComponent } from '@bancolombia/design-system-web/bc-alert';
import { BcDialogService } from '@bancolombia/design-system-web/bc-services';
import { Store } from '@ngrx/store';
import { RequirementStatusEnum } from 'src/app/enums/reporte-estado.enum';
import { RequirementTypeEnum } from 'src/app/enums/reporte-novedad.enum';
import { UserGrap } from 'src/app/mock/graph/usersGraph.mock';
import { Contrato } from 'src/app/models/contratos.interface';
import { SaveRequirementModel } from 'src/app/models/requirement.model';
import { ContratosService } from 'src/app/services/api-services/contratos-services/contratos.service';
import { RequirementService } from 'src/app/services/api-services/requirement-services/requierement.service';
import { UserService } from 'src/app/services/api-services/user-services/user.service';
import { EstadoServicesService } from 'src/app/services/estado-services/estado-service';
import { selectorContractList } from 'src/app/state/contract_state/contract.selector';

@Component({
  selector: 'app-detalles-contrato-admin-portal',
  templateUrl: './detalles-contrato-admin-portal.component.html',
  styleUrls: ['./detalles-contrato-admin-portal.component.css']
})
export class DetallesContratoAdminPortalComponent {
  
  @Input('contractDetail') _contract!: any;
  @Input('contractChild') contractsChilds!: any;
  @Input('contractMaster') contractMaster!: any;
  @Input('novedad') novedad!: any; 

  dataTable: any[] = [];
  public contract: any = [];
  estadoSeleccionado: string | null = null;
  
    /**
 * Objeto que representa el contrat.
 * @typedef {Object} Contrato
 * @property {string} idContrato - Identificador del contrato.
 * @property {Object} administrador - Información del administrador del contrato.
 * @property {string} administrador.usuarioRed - Usuario de red del administrador.
 * @property {string} administrador.nombre - Nombre del administrador.
 * @property {string} administrador.correo - Correo electrónico del administrador.
 * @property {Object} negociador - Información del negociador del contrato.
 * @property {string} negociador.usuarioRed - Usuario de red del negociador.
 * @property {string} negociador.nombre - Nombre del negociador.
 * @property {string} negociador.correo - Correo electrónico del negociador.
 * @property {Object} proveedor - Información del proveedor del contrato.
 * @property {string} proveedor.id - Identificador del proveedor.
 * @property {string} proveedor.nombre - Nombre del proveedor.
 * @property {string} proveedor.nit - NIT del proveedor.
 * @property {string} proveedor.nombreComercial - Nombre comercial del proveedor.
 * @property {string} proveedor.correoComercial - Correo electrónico del contacto comercial del proveedor.
 * @property {string} proveedor.contactoComercial - Número de contacto del contacto comercial del proveedor.
 * @property {Object} estado - Información del estado del contrato.
 * @property {number} estado.idEstado - Identificador del estado.
 * @property {string} estado.estado - Descripción del estado.
 * @property {Array<Object>} contratosHijos - Lista de contratos hijos del contrato.
 * @property {string} contratosHijos.idContrato - Identificador del contrato hijo.
 * @property {Object} contratosHijos.proveedor - Información del proveedor del contrato hijo.
 * @property {string} contratosHijos.proveedor.id - Identificador del proveedor.
 * @property {string} contratosHijos.proveedor.nombre - Nombre del proveedor.
 * @property {string} contratosHijos.proveedor.nit - NIT del proveedor.
 * @property {Object} contratosHijos.estado - Información del estado del contrato hijo.
 * @property {number} contratosHijos.estado.idEstado - Identificador del estado.
 * @property {string} contratosHijos.estado.estado - Descripción del estado.
 * @property {string} contratosHijos.nombre - Nombre del contrato hijo.
 * @property {Object} contratoPadre - Información del contrato padre.
 * @property {string} contratoPadre.idContrato - Identificador del contrato padre.
 * @property {Object} contratoPadre.proveedor - Información del proveedor del contrato padre.
 * @property {string} contratoPadre.proveedor.id - Identificador del proveedor.
 * @property {string} contratoPadre.proveedor.nombre - Nombre del proveedor.
 * @property {string} contratoPadre.proveedor.nit - NIT del proveedor.
 * @property {Object} contratoPadre.estado - Información del estado del contrato padre.
 * @property {number} contratoPadre.estado.idEstado - Identificador del estado.
 * @property {string} contratoPadre.estado.estado - Descripción del estado.
 */

  contrato: Contrato = {
    idContrato: "CW102",
    administrador: {
      usuarioRed: "rafa",
      nombre: "Rafael Muñoz",
      correo: "rafa@bancolombia.com.co",
    },
    negociador: {
      usuarioRed: "pepe",
      nombre: "Jose Perez",
      correo: "pepe@bancolombia.com.co",
    },
    proveedor: {
      id: "ACM120",
      nombre: "IBM",
      nit: "9999999-9",
      nombreComercial: "Pedro Nel Osorio",
      correoComercial: "posorio@gmv.com.co",
      contactoComercial: "3003458757",
    },
    estado: {
      idEstado: 1,
      estado: "Borrador",
    },
    contratosHijos: [
      {
        idContrato: "CW567",
        proveedor: {
          id: "ACM120",
          nombre: "IBM",
          nit: "9999999-9",
        },
        estado: {
          idEstado: 1,
          estado: "Modificación del borrador",
        },
        nombre: "Licencias de software",
      },
      {
        idContrato: "CW567",
        proveedor: {
          id: "ACM120",
          nombre: "IBM",
          nit: "9999999-9",
        },
        estado: {
          idEstado: 1,
          estado: "Congelado",
        },
        nombre: "Licencias de software",
      },
      {
        idContrato: "CW567",
        proveedor: {
          id: "ACM120",
          nombre: "IBM",
          nit: "9999999-9",
        },
        estado: {
          idEstado: 1,
          estado: "Pendiente",
        },
        nombre: "Licencias de software",
      }
    ],
    contratoPadre: {
      idContrato: "CW100",
      proveedor: {
        id: "ACM120",
        nombre: "IBM",
        nit: "9999999-9",
      },
      estado: {
        idEstado: 1,
        estado: "Publicado",
      },
      nombre: "Licencias de software",
    },
    nombre: "Licencias IBM DataStage",
    descripcion: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae ab temporibus enim pariatur id iusto ullam officia dolores, ratione quaerat, veniam dolor deserunt ex? Sed animi consequatur debitis eaque distinctio.  Autem minus ad nam incidunt libero vitae eaque unde deleniti delectus assumenda dicta, omnis soluta ullam, corrupti laudantium quae officiis corporis harum. Amet quia illo, possimus id tempore quam temporibus?",
    fechaInicio: new Date("2020-01-15"),
    fechaFinal: new Date("2024-01-15"),
    vencimientoTarifa: new Date("2022-10-17"),
    tipoAcuerdo: "Contrato marco",
    areaResponsable: "Abastecimiento Bancolombia",
    familia: "Canales digitales",
    proximoVencer: false,
    esFavorito: true,
    tipoVigencia: "Indefinido",
  };

  //Copiar Administrador
  @ViewChild('correoAdministrador', { static: false }) correoElement?: ElementRef;


  copiarCorreoAdministrador() {
    const correo = this.correoElement?.nativeElement.textContent;
    navigator.clipboard.writeText(correo);
  }


  @ViewChild('correoDelegado', { static: false }) correoElementDelegado?: ElementRef;


  /**
 * Copia el correo electrónico del delegado al portapapeles.
 * 
 * @returns {void}
 */
  copiarCorreoDelegado() {
    const correo = this.correoElementDelegado?.nativeElement.textContent;
    navigator.clipboard.writeText(correo);
  }


  @ViewChild('correoNegociador', { static: false }) correoElementNegociador?: ElementRef;


  copiarCorreoNegociador() {
    const correo = this.correoElementNegociador?.nativeElement.textContent;
    navigator.clipboard.writeText(correo);
  }


  @ViewChild('correoProveedorComercial', { static: false }) correoElementProveedorComercial?: ElementRef;

  
  /**
 * Copia el correo electrónico del proveedor al portapapeles.
 * 
 * @returns {void}
 */
  copiarCorreoProveedorComercial() {
    const correo = this.correoElementProveedorComercial?.nativeElement.textContent;
    navigator.clipboard.writeText(correo);

  }


  constructor(
    private estadoService: EstadoServicesService,
    private dialogService: BcDialogService,
    //
    private requirementService : RequirementService ,
    private contratosService: ContratosService,
    private store: Store<any>,
    private userService: UserService
    ) {
    this.estadoSeleccionado = this.estadoService.getEstadoSeleccionado();
    console.log('Estado seleccionado recibido:', this.estadoSeleccionado);
  }
  
  // ...

  getFormattedDate(date: string | null): string {
    if (!date) {
      return 'Fecha no disponible';
    }
    
    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) {
      return 'Fecha inválida';
    }

    return parsedDate.toLocaleDateString();
  }

  alertAba(type: String, menssage: String, tittle: string): void {
    const dialogRef = this.dialogService.open(BcAlertComponent, {
      id: tittle,
      type: type,
      text: menssage,
      timeout: 7000,
    });

    dialogRef.onResult().subscribe(
      (closed) => {},
      (dismissed) => {},
      () => {}
    );
  }


  //Variables
  currentPageIndex: number = 0;
  itemsPerPage: number = 10;
  userData: any;
  userDataAvailable = false;
  selectedContract: any;
  showDescription: boolean = false;
  @Input('data') _pages!: any;
  totalRegisters: any;
  perpage: any = 10;
  permultiple: string = "10";
  originalData: any[] = [];
  
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    console.log(this.novedad)
    this.contratosService.getAllContractByIdContrac(this.novedad.contractId.idContract).subscribe(
      {
        next: (success) => this.contract = success.data,
        //error: (error) => this.alertAba("error","No se pudo cargar los estados de los contratos","Error")
      }
    )

    this.originalData = this._pages.data;
    
    this.userService.getUserDataSubject().subscribe((userData) => {
      this.userData = userData.data;
      this.userDataAvailable = true;
      console.log(this.userData.rol);
    });

    let showMoreDashboard: any[] = [];
    this.store.select(selectorContractList).subscribe(
      (success)=>{
        
        showMoreDashboard = success;
      }, (error)=> console.log("Error;; ", error) 
    )

    console.log("Data del componente:", this._pages); // Verifica si `_pages` tiene la información esperada.
    console.log("Contrato seleccionado:", this.selectedContract); // Verifica si `selectedContract` tiene la información esperada.
    console.log("Estado del contrato:", this.selectedContract?.contractEstatusEntity); // Verifica si `contractEstatusEntity` tiene la información esperada.
    
  }

  showDetails(contract: any) {
    console.log("Contract recibido:", contract); // Verifica si el contrato se recibe correctamente.
  
    if (contract && contract.contractEstatusEntity) {
      this.selectedContract = contract; // Asignar el contrato seleccionado a la variable
    } else {
      console.log("Error: Contract o contractEstatusEntity es nulo o indefinido.");
    }
  }


  //Variables
  public userGraphData: any;
  public userGrapFilter: any[] = [];
  public formMeetAdminInputs: any = {};
  public inputAdministratorObservation: string = "";
  public showModal = true;
  public valuesTemp: any = {};

  dateSelector = new FormControl();
  @Input() textButton!: string;
  

 





  

}
