// app-state.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  private localStorageKey = 'reloadDone';

  constructor() {}

  public shouldReload(): boolean {
    return !localStorage.getItem(this.localStorageKey);
  }

  public markReloadDone(): void {
    localStorage.setItem(this.localStorageKey, 'true');
  }
}
