import { Component } from '@angular/core';

@Component({
  selector: 'app-no-autorizado-page',
  templateUrl: './no-autorizado-page.component.html',
  styleUrls: ['./no-autorizado-page.component.css']
})
export class NoAutorizadoPageComponent {

}
