
export enum RequirementTypeEnum {
    REPORTE_DE_NOVEDAD_DE_ESTADO_DE_CONTRATO = "REPORTE DE NOVEDAD DE ESTADO DE CONTRATO",
    REPORTE_NOVEDAD_TEMPORAL_ADMINISTRADOR = "REPORTE NOVEDAD TEMPORAL ADMINISTRADOR",
    REPORTE_NOVEDAD_SIN_ADMINISTRADOR = "REPORTE NOVEDAD SIN ADMINISTRADOR",
    REPORTE_NOVEDAD_CON_ADMINISTRADOR = "REPORTE NOVEDAD CON ADMINISTRADOR",
    REPORTE_RECHAZADO_NEGOCIADOR = "SOLICITUD RECHAZADA POR EL NEGOCIADOR",
    REPORTE_ACEPTADO_NEGOCIADOR = "SOLICITUD ACEPTADA POR EL NEGOCIADOR"
  }

  