import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TablaContratosComponent } from './tabla-contratos/tabla-contratos.component';
import { BcSearchModule } from '@bancolombia/design-system-web/bc-search';
import { BcPageHeaderModule } from '@bancolombia/design-system-web/bc-page-header';
import { BcTableModule } from '@bancolombia/design-system-web/bc-table';
import { BcPaginatorModule } from '@bancolombia/design-system-web/bc-paginator';
import { BcOffCanvasModule } from '@bancolombia/design-system-web/bc-off-canvas';
import { BcInputModule } from '@bancolombia/design-system-web/bc-input';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { BcCheckboxModule } from '@bancolombia/design-system-web/bc-checkbox';
import { BcInputDateModule } from '@bancolombia/design-system-web/bc-input-date';
import { BcInputSelectModule } from '@bancolombia/design-system-web/bc-input-select';
import { ComponentsModule } from "../../components/components.module";
import { FormsModule } from '@angular/forms'; // <-- Add this line
import { BcButtonModule } from '@bancolombia/design-system-web/bc-button';
import { MisContratosNegociadorComponent } from './mis-contratos-negociador.component';



@NgModule({
  declarations: [
    MisContratosNegociadorComponent,
    TablaContratosComponent
  ],
  imports: [
    CommonModule,
        BcSearchModule,
        BcPageHeaderModule,
        BcTableModule,
        BcPaginatorModule,
        BcOffCanvasModule,
        BcInputModule,
        AppRoutingModule,
        BcCheckboxModule,
        BcInputDateModule,
        ComponentsModule,
        BcInputSelectModule,
        BcCheckboxModule,
        FormsModule,
        BcButtonModule,
        ComponentsModule
  ]
})
export class MisContratosNegociadorModule { }
