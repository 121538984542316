<body>
    <div bc-card>
        <div class="bc-card-body">
            <!-- Total Requerimientos -->
            <!-- PRIMERA PARTE -->
            <!-- Total Requerimiento -->
            <div class="total-requirement__container bc-col-3">
                <div class="total-requirement__left"></div>
                <div class="bc-col-6">
                    <div class="total-header__item">Total requerimientos</div>
                    <div class="total-header__item-number">130</div>
                </div>
                <!-- En Curso -->
                <div class="bc-col-6">
                    <div class="bc-col-12">
                        <div class="total-body__item">En curso</div>
                        <div class="total-body__item-number" style="padding-left: 10px;">33</div>
                    </div>
                    <!-- En Solicitud -->
                    <div class="bc-col-12" style="padding-top: 15px;">
                        <div class="total-body__item">En solicitud</div>
                        <div class="total-body__item-number">10</div>
                    </div>
                </div>
            </div>
            <div>
                <!-- Item Derecha -->
                <!-- Contratos Estrategicos Y APalancables -->
                <div bc-card class="bc-col-2 card-header__items">
                    <div class="bc-card-body ">
                        <div class="bc-margin-top-4">
                            <div class="total-body__item-number">12</div>
                        </div>
                        <div class="subir_item">
                            <div class="total-body__item_cards">Cerrados Estratégicos y apalancables</div>
                        </div>
                    </div>
                </div>
                <!-- Cerrados Críticos y No críticos -->
                <div bc-card class="bc-col-2 card-header__items">
                    <div class="bc-card-body ">
                        <div class="bc-margin-top-4">
                            <div class="total-body__item-number">0</div>
                        </div>
                        <div class="subir_item">
                            <div class="total-body__item_cards">Cerrados Críticos y No críticos</div>
                        </div>
                    </div>
                </div>

                <!--Congelados -->
                <div bc-card class="bc-col-2 card-header__items">
                    <div class="bc-card-body ">
                        <div class="bc-margin-top-4">
                            <div class="total-body__item-number">0</div>
                        </div>
                        <div class="subir_item">
                            <div class="total-body__item_cards">Congelados</div>
                        </div>
                    </div>
                </div>
                <!-- Vencidos -->
                <div bc-card class="bc-col-2 card-header__items">
                    <div class="bc-card-body ">
                        <div class="bc-margin-top-4">
                            <div class="total-body__item-number">1</div>
                        </div>
                        <div class="subir_item">
                            <div class="total-body__item_cards">Vencidos</div>
                        </div>

                    </div>
                </div>
            </div>

        </div>

        <!-- Fin Del Cardbody Inicial -->

        <!-- Segunda Parte -->

        <!-- Evolcuion Del MarketPlace -->
    </div>

    <div class="color-header">
        <h4>Evolución del Marketplace</h4>
    </div>

    <div class="bc-row">

        <!-- Primera Gráfica -->

        <div class="bc-col-5">
            
            <div bc-card>
                <div class="bc-card-body" style="height: 450px;">
                    <!-- Header -->
                <div class="bc-container">
                    <div class="bc-row">
                        <div class="bc-col-1">
                            <span class="icon-container">
                                
                            </span>
                        </div>
                        <div class="bc-col-11">
                            <p>Resultados del punto de inicio para el Marketplace</p>
                        </div>
                    </div>
                </div>
                    <div class="bc-container">
                        <div class="bc-row bc-margin-top-2">
                            <div class="bc-col-6">
                                <div class="grafica_uno">
                                    <div class="bc-margin-top-1">
                                        <p>Staffing inicio :</p>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="bc-col-6">
                                <pre><div class="container--calendar_item">14/34/2923</div></pre>
                            </div>
                        </div>
                    </div>
                    <br>
                    <ngx-charts-bar-vertical-2d [view]="viewInicio" [scheme]="colorScheme" [results]="multi"
                        [xAxis]="true" [showDataLabel]="true" [groupPadding]="15" [barPadding]="2"
                        [legendPosition]="legendPosition" [legend]="true" (select)="onSelect($event)"
                        (activate)="onActivate($event)" [legendTitle]="''" (deactivate)="onDeactivate($event)">
                    </ngx-charts-bar-vertical-2d>
                </div>
            </div>
        </div>

        <!-- Segunda Gráfica -->

        <div class="bc-col-7">
            <div bc-card>
                <div class="bc-card-body" style=" height: 450px; margin-left: 17px;">
                    <div class="bc-row">
                        <!-- Staffing Ceremonia Anterior -->
                        <div class="bc-col-5">
                            <div class="sub-title__componets">
                                <div>
                                    <b>
                                        <p>Staffing ceremonia anterior</p>
                                    </b>
                                </div>
                                <div class="bc-row bc-margin-top-2">
                                    <div class="bc-col-11">
                                        <pre><div class="container--calendar_item">14/34/2923</div> </pre>
                                    </div>
                                    <div class="bc-col-1 bc-margin-top-1">
                                        <bc-icon size="sm" name="calendar" aria-hidden="true" aria-label="calendar">
                                        </bc-icon>
                                    </div>
                                </div>
                            </div>
                            <div class="responsive-chart-container">
                                <ngx-charts-bar-vertical-2d [view]="viewAnterior" [scheme]="colorScheme"
                                    [results]="multi" [xAxis]="true" [showDataLabel]="true" [groupPadding]="2"
                                    [barPadding]="1" [legendPosition]="legendPosition" [legend]="true"
                                    (select)="onSelect($event)" (activate)="onActivate($event)" [legendTitle]="''"
                                    (deactivate)="onDeactivate($event)">
                                </ngx-charts-bar-vertical-2d>
                            </div>
                        </div>

                        <div class="bc-col-1"></div>

                        <!-- Staffing Actual -->
                        <div class="bc-col-5">
                            <div class="sub-title__componets">
                                <div>
                                    <b>
                                        <p>
                                            Staffing ceremonia actual
                                        </p>
                                    </b>
                                </div>
                                <div class="bc-row bc-margin-top-2">
                                    <div class="bc-col-11">
                                        <pre><div class="container--calendar_item">14/34/2923</div> </pre>
                                    </div>
                                    <div class="bc-col-1 bc-margin-top-1">
                                        <bc-icon size="sm" name="calendar" aria-hidden="true" aria-label="calendar">
                                        </bc-icon>
                                    </div>
                                </div>
                            </div>
                            <div class="responsive-chart-container">
                                <ngx-charts-bar-vertical-2d [view]="viewActual" [scheme]="colorScheme" [results]="multi"
                                    [xAxis]="true" [showDataLabel]="true" [groupPadding]="15" [barPadding]="2"
                                    [legendPosition]="legendPosition" [legend]="true" (select)="onSelect($event)"
                                    (activate)="onActivate($event)" [legendTitle]="''"
                                    (deactivate)="onDeactivate($event)">
                                </ngx-charts-bar-vertical-2d>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bc-row">
        <div class="bc-col-4">
            <div class="color-header">
                <h4>Requerimientos vencidos por Categoría y Negociador</h4>
            </div>
            <div bc-card>
                <div class="bc-card-body">
                    <bc-icon-button class="bc-col-1" [type]="'solid'" [color]="'yellow'" [size]="'large'"
                        [customIcon]="'calendar-edit'" [ariaLabel]="'Texto Función'"></bc-icon-button>
                </div>
            </div>
        </div>
        <div class="bc-col-8">
            <div class="color-header">
                <h4>Volumen de requerimientos vencidos</h4>
            </div>
            <div bc-card>
                <div class="bc-card-body">
                    <bc-icon-button class="bc-col-1" [type]="'solid'" [color]="'yellow'" [size]="'large'"
                        [customIcon]="'calendar-edit'" [ariaLabel]="'Texto Función'"></bc-icon-button>
                </div>
            </div>
        </div>
    </div>
</body>