import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NovedadesNegociadorComponent } from './novedades-negociador.component';
import { NovedadNegociadorAccordionComponent } from './novedad-negociador-accordion/novedad-negociador-accordion.component';
import { BcAccordionModule } from '@bancolombia/design-system-web/bc-accordion';
import { ComponentsModule } from "../../components/components.module";
import { BcTableModule } from '@bancolombia/design-system-web/bc-table';
import { BcSearchModule } from '@bancolombia/design-system-web/bc-search';
import { BcPageHeaderModule } from '@bancolombia/design-system-web/bc-page-header';
import { BcIconModule } from '@bancolombia/design-system-web/bc-icon';
import { BcBadgeModule } from "@bancolombia/design-system-web/bc-badge";
import { BcInputSelectModule } from '@bancolombia/design-system-web/bc-input-select';
import { BcButtonModule } from '@bancolombia/design-system-web/bc-button';
import { NovedadesNegociadorSolicitudComponent } from './novedades-negociador-solicitud/novedades-negociador-solicitud.component';
import { OffcanvasNovedadesNegociadorComponent } from './offcanvas-novedades-negociador/offcanvas-novedades-negociador.component';
import { BcOffCanvasModule } from '@bancolombia/design-system-web/bc-off-canvas';
import { OffcanvasNovedadesNegociadorSolicitudComponent } from './offcanvas-novedades-negociador-solicitud/offcanvas-novedades-negociador-solicitud.component';
import { BcInputModule } from '@bancolombia/design-system-web/bc-input';
import { FormsModule } from '@angular/forms';
import { NovedadNegociadorAccordionRechazarComponent } from './novedad-negociador-accordion-rechazar/novedad-negociador-accordion-rechazar.component';
import { NovedadNegociadorAccordionCerrarAribaComponent } from './novedad-negociador-accordion-cerrar-ariba/novedad-negociador-accordion-cerrar-ariba.component';



@NgModule({
  declarations: [
    NovedadesNegociadorComponent,
    NovedadNegociadorAccordionComponent,
    NovedadesNegociadorSolicitudComponent,
    OffcanvasNovedadesNegociadorComponent,
    OffcanvasNovedadesNegociadorSolicitudComponent,
    NovedadNegociadorAccordionRechazarComponent,
    NovedadNegociadorAccordionCerrarAribaComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    BcAccordionModule,
    BcTableModule,
    BcSearchModule,
    BcInputModule,
    FormsModule,
    BcPageHeaderModule,
    BcOffCanvasModule,
    BcIconModule,
    BcBadgeModule,
    BcInputSelectModule,
    BcButtonModule,
    ComponentsModule

  ],
})
export class NovedadesNegociadorModule { }
