<div class="bc-content">
    <!-- Header -->
    <app-detalles-contrato-page-header [contrato]="_contract.contractEntity"></app-detalles-contrato-page-header>
    <!-- Mosaico de detalles de contrato -->
    <div class="bc-row bc-padding-4 bc-wrap bc-flex bc-direction-column bc-direction-lg-row">
        <div class="bc-col-12 bc-col-lg-8">
            <!-- Descripcion -->
            <div class="bc-row bc-margin-bottom-1">
                <div class="bc-col-12 bc-bg-light">
                    <bc-card-container cardType="default">
                        <div class="bc-padding-2">
                            <small><b>Descripci&oacute;n del contrato</b></small>
                            <p>{{_contract.contractEntity.description}}</p>
                        </div>
                    </bc-card-container>
                </div>
            </div>
            <!-- Row 2: Acuerdo, familia, vigencia y estado -->
            <div class="bc-col-12 bc-margin-bottom-1">
                <div class="bc-row bc-justify-content-space-between bc-bg-white bc-wrap">
                    <bc-card-container cardType="default">
                        <div class="bc-padding-2">
                            <small><b>Familia</b></small>
                            <p>
                                <ng-container *ngIf="_contract.family && _contract.family.length > 0; else noFamily">
                                  {{ _contract.family[0].nombre }}
                                </ng-container>
                                <ng-template #noFamily>
                                  No registra familia
                                </ng-template>
                              </p>
                              
                        </div>
                    </bc-card-container>
                    <bc-card-container cardType="default">
                        <div class="bc-padding-2">
                            <small><b>Tipo de acuerdo</b></small>
                            <p>{{_contract.contractEntity.agreementType}}</p>
                        </div>
                    </bc-card-container>
                    <bc-card-container cardType="default">
                        <div class="bc-padding-2">
                            <small><b>Tipo de vigencia</b></small>
                            <p>{{_contract.contractEntity.contractFamily}}</p>
                        </div>
                    </bc-card-container>
                    <bc-card-container cardType="default">
                        <div class="bc-padding-2">
                            <small><b>Estado</b></small>
                            <!-- <p>{{contrato.estado.estado}}</p> -->
                            <div class="badge-container">
                                <app-badge-estado  title="Administrador solo puede cambiar de estado a Cerrado"  [clase]="'contrato'" [estadoContrato]="_contract.contractEstatusEntity.status_name.charAt(0).toUpperCase() + _contract.contractEstatusEntity.status_name.slice(1).toLowerCase()" [tipo]="'icon-right'">
                                  <!-- Contenido del badge -->
                                </app-badge-estado>
                                <ng-content></ng-content>
                              </div>
                        </div>
                    </bc-card-container>
                </div>
            </div>

            <!-- Row 3: Fechas -->
            <div class="bc-col-12 bc-margin-bottom-1">
                <div class="bc-row bc-justify-content-space-between bc-wrap bc-gap-2">
                    <app-card-fecha [mostrarAlerta]='false' [fecha]='_contract.contractEntity.initialDate'
                        [titulo]="'Fecha de entrada en vigencia'" class="bc-flex-fill bc-flex"></app-card-fecha>
                    <app-card-fecha [fecha]='_contract.contractEntity.finalDate' [titulo]="'Fecha de vencimiento'"
                        class="bc-flex-fill bc-flex"></app-card-fecha>
                    <app-card-fecha [fecha]='_contract.contractEntity.tariffExpiry' [titulo]="'Fecha de vencimiento de tarifa'"
                        class="bc-flex-fill bc-flex"></app-card-fecha>
                </div>
            </div>

            <!-- Row 4: Administrador y delegados -->
            <div class="bc-col-12 bc-margin-bottom-1">
                <div class="bc-row bc-justify-content-space-between bc-wrap bc-gap-2">
                    <!-- Cambiar por componente rehusable y parametrizable -->
                    <div class="bc-padding-2 bc-flex-fill bc-align-self-stretch bc-align-self-center bc-bg-white">
                        <small><b>Nombre del administrador</b></small>
                        <p>{{contrato.administrador.nombre}}</p>
                        <div
                            class="bc-flex bc-justify-content-space-between bc-align-self-center bc-text-brand-sequential-N-300 bc-padding-bottom-2">
                            <div class="bc-flex bc-align-self-center">
                                <!-- Correo -->
                                <bc-icon size="xs" name="mail" aria-hidden="true" aria-label="mail"></bc-icon>
                                <div>
                                    <span #correoAdministrador>{{contrato.administrador.correo}}</span>
                                </div>
                            </div>
                            <span id="copy-btn">
                                <bc-icon-button [type]="'solid'" [color]="'white'" [shadow]=false [size]="'small'"
                                    [customIcon]="'add-doc'" (click)="copiarCorreoAdministrador()">
                                </bc-icon-button>
                            </span>
                        </div>
                        
                    </div>
                    <!-- Delegado -->
                    <!-- <div class="bc-padding-2 bc-flex-fill bc-align-self-stretch bc-align-self-center bc-bg-white">
                        <small><b>Nombre del delegado</b></small>
                        <p>{{contrato.administrador.nombre}}</p>
                        <div
                            class="bc-flex bc-justify-content-space-between bc-align-self-center bc-text-brand-sequential-N-300 bc-padding-bottom-2">
                            <div class="bc-flex bc-align-self-center">
                                <bc-icon size="xs" name="mail" aria-hidden="true" aria-label="mail"></bc-icon>
                                <div>
                                    <span #correoDelegado>{{contrato.administrador.correo}}</span>
                                </div>                                
                            </div>
                        </div>
                            <span id="copy-btn">
                                <bc-icon-button [type]="'solid'" [color]="'white'" [shadow]=false [size]="'small'"
                                    [customIcon]="'add-doc'" (click)="copiarCorreoDelegado()">
                                </bc-icon-button>
                            </span>
                            <app-reportar-novedades [textButton]="'Actualizar delegado'"></app-reportar-novedades>
                        </div> -->
                </div>
            </div>
        </div>

        <div class="bc-col-12 bc-col-lg-4">
            <div class="bc-bg-light bc-margin-bottom-1">
                <bc-card-container cardType="default">
                    <div class="bc-padding-2">
                        <small><b>&Aacute;rea responsable</b></small>
                        <p>{{_contract.contractEntity.responsibleArea}}</p>
                    </div>
                </bc-card-container>
            </div>

            <div
                class="bc-padding-2 bc-flex-fill bc-align-self-stretch bc-align-self-center bc-bg-white bc-margin-bottom-1">
                <small><b>Nombre del comercial</b></small>
                <p>{{_contract.contractEntity.providerEntity.commercial_name}}</p>
                <div
                    class="bc-flex bc-justify-content-space-between bc-align-self-center bc-text-brand-sequential-N-300">
                    <div class="bc-flex bc-align-self-center">
                        <!-- Correo Comercial -->
                        <div class="bc-margin-left-1">
                            <span #correoProveedorComercial>{{_contract.contractEntity.providerEntity.commercial_mail}}</span>
                        </div>
                    </div>
                    <span id="copy-btn">
                        <bc-icon-button [type]="'solid'" [color]="'white'" [shadow]=false [size]="'small'"
                            [customIcon]="'add-doc'" (click)="copiarCorreoProveedorComercial()">
                        </bc-icon-button>
                    </span>
                </div>
                <div
                    class="bc-flex bc-justify-content-space-between bc-align-self-center bc-text-brand-sequential-N-300">
                    <div class="bc-flex bc-align-self-center">
                        <!-- <bc-icon size="xs" name="phone-contact" aria-hidden="true" aria-label="phone"></bc-icon> -->
                        <!-- NombreAdministrador -->
                        <div class="bc-margin-left-1">
                            <span #correoAdministrador>{{contrato.administrador.correo}}</span>
                        </div>
                    </div>
                    <span id="copy-btn-correo">
                        <bc-icon-button [type]="'solid'" [color]="'white'" [shadow]=false [size]="'small'"
                            [customIcon]="'add-doc'" (click)="copiarCorreoAdministrador()">
                        </bc-icon-button>
                    </span>
                </div>
            </div>
            <div class="bc-padding-2 bc-flex-fill bc-align-self-stretch bc-align-self-center bc-bg-white">
                <small><b>Nombre del negociador</b></small>
                <p>{{_contract.contractEntity.idUserNegotiator}}</p>
                <div
                    class="bc-flex bc-justify-content-space-between bc-align-self-center bc-text-brand-sequential-N-300">
                    <div class="bc-flex bc-align-self-center">
                        <!-- <bc-icon size="xs" name="mail" aria-hidden="true" aria-label="mail"></bc-icon> -->
                        <div class="bc-margin-left-1">
                            <span #correoNegociador>{{contrato.negociador.correo}}</span>
                        </div>
                    </div>
                    <span id="copy-btn-correo">
                        <bc-icon-button [type]="'solid'" [color]="'white'" [shadow]=false [size]="'small'"
                            [customIcon]="'add-doc'" (click)="copiarCorreoNegociador()">
                        </bc-icon-button>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <!-- Nuevo Historial -->
    <div class="bc-padding-4 bc-margin-bottom-6">

        <bc-tabs-group #myTab>
            <bc-tab [labelTemplate]='tab4'>
                <ng-template #tab4>
                    <p>Historial Novedades</p>
                </ng-template>
                <div class='row'>
                    <div class='col-12'>
                        <div class="bc-p-3 bc-bg-white">
                            <div class="bc-margin-top-0.5">
                                <h6>Cambio de administrador</h6>
                            </div>
                            <div class="bc-container">
                                <div class="bc-row ">
                                    <div class="bc-col-xs-3 bc-margin-left-5 bc-col-sm-4 bc-col-md-4 bc-col-lg-1 bc-col-xl-1 bc-col-xxl-1">    
                                       <p>
                                        {{_contract.receptorId.name}}
                                       </p> 
                                        <span style="color:gray; "> eorozco@bancolombia.com.co</span>
                                    </div>
                                    <div class="bc-col-xs-3 bc-margin-left-6 bc-col-sm-4 bc-col-md-4 bc-col-lg-1 bc-col-xl-1 bc-col-xxl-1"> → </div>
                                    <div class="bc-col-xs-5 bc-margin-left-6 bc-col-sm-4 bc-col-md-4 bc-col-lg-1 bc-col-xl-1 bc-col-xxl-1">
                                        <p>
                                            Cesar Agudelo
                                            <span style="color:gray;"> ceaagudelo@bancolombia.com.co</span>
                                        </p>
                                    </div>               
                                 </div>
                                 <div class="bc-margin-top-1">
                                    <span style="color:gray">Aprobado por Nombre del administrador (administrador01@bancolombia.com.co)</span>
                                 </div>

                            </div>
                        </div>
                        <br>
                        <div class="bc-p-3 bc-bg-white">
                            <div class="bc-margin-top-0.5">
                                <h6>Cambio de estado</h6>
                            </div>
                            <div class="bc-container bc-margin-top-1">
                                <div class="bc-row ">
                                    <div class="bc-col-xs-2 bc-col-sm-5 bc-col-md-4 bc-col-lg-1 bc-col-xl-1 bc-col-xxl-1">
                                        <app-badge-estado [clase]="'contrato'" [estadoContrato]="'Borrador'"
                                                [tipo]="'only'"></app-badge-estado>
                                    </div>
                                    <div class="bc-col-xs-3 bc-margin-left-5 bc-col-sm-4 bc-col-md-4 bc-col-lg-1 bc-col-xl-1 bc-col-xxl-1"> → </div>
                                    <div class="bc-col-xs-4 bc-col-sm-5 bc-col-md-4 bc-col-lg-1 bc-col-xl-9 bc-col-xxl-1">
                                        <app-badge-estado [clase]="'contrato'" [estadoContrato]="'Publicado'"
                                        [tipo]="'only'"></app-badge-estado>
                                    </div>               
                                 </div>
                                 <div class="bc-margin-top-1">
                                 </div>

                            </div>
                        </div>
                    </div>
                </div>
            </bc-tab>
        </bc-tabs-group>
    </div>
</div>