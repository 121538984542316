import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { formatearFecha } from 'src/app/components/utils/formato-fecha.componente';
import { BcAlertComponent } from '@bancolombia/design-system-web/bc-alert';
import { Contrato } from '../../../models/contratos.interface';
import { BcDialogService } from '@bancolombia/design-system-web/bc-services';

@Component({
  selector: 'app-detalles-contrato-negociador-header',
  templateUrl: './detalles-contrato-negociador-header.component.html',
  styleUrls: ['./detalles-contrato-negociador-header.component.css']
})
export class DetallesContratoNegociadorHeaderComponent {

  constructor(private dialogService: BcDialogService) { }

  /**
   * @type {string}
   *  @type {string}
   */
  favBtnColor: "yellow" | "white" = "white";
  favBtnText: string = "";

  @Input() contrato!: any;
  @Input() isFavorite: boolean = false;

  fecha: any;

  ngOnInit() {
    this.mostrarHora();
    this.generarColorAleatorioClass();

  }

  /**
 * Actualiza la hora actual en la variable 'fecha' cada segundo.
 */
  mostrarHora() {
    this.fecha = formatearFecha(new Date());
    setInterval(() => {
      this.fecha = formatearFecha(new Date());
    }, 1000);
  }

  randomBackgroundColorClass: string = '';

  generarColorAleatorioClass() {
    const colorClasses = [
      'bc-random-background-1',
      'bc-random-background-2',
      // Agrega más clases de colores según sea necesario
    ];
    this.randomBackgroundColorClass = colorClasses[Math.floor(Math.random() * colorClasses.length)];
  }

  private alertAba(type: String, menssage: String, tittle: string): void {
    const dialogRef = this.dialogService.open(BcAlertComponent, {
      id: tittle,
      type: type,
      text: menssage,
      timeout: 7000,
    });

    dialogRef.onResult().subscribe(
      (closed) => { },
      (dismissed) => { },
      () => { }
    );
  }

  onFavoriteToggled(isFavoriteEvent: boolean) {
    if (isFavoriteEvent) {
      this.alertAba("error", "El contrato ha sido removido de tus favoritos", "Operación Exitosa");
    } else {
      this.alertAba("success", "El contrato ha sido agregado a tus favoritos", "Operación Exitosa");
    
    }
    
  }
  

}
