/**
 * Archivo centralizador de rutas (endpoints) para consumir diferentes APIs en la aplicación.
 * Define constantes con las rutas completas de los endpoints para cada API que se va a consumir.
 */
import { environment } from "src/environments/environment";

/**
 * Objeto que contiene las rutas completas de los endpoints para consumir las APIs.
 * Cada constante se compone concatenando `environment.apiUrl` (path base común de la API) con la ruta específica del endpoint.
 * Antes de utilizar estas constantes, asegúrate de que `environment.apiUrl` esté configurado correctamente
 * en el archivo de configuración correspondiente al ambiente actual.
 */
export const apiPaths = {
  /**
   * Ruta para obtener la lista de contratos.
   * @example `${environment.apiUrl}/contract/findAll`
   * @description La ruta completa se compone uniendo `environment.apiUrl` con el endpoint específico "/contract/findAll".
   * @type {string}
   */
  getListContratos: `${environment.apiUrl}/contract/findAll`,
  getListContractNextFinal: `${environment.apiUrl}/contract/findAllContractNextFinal`,
  getLisProviderCountContract: `${environment.apiUrl}/contract/findAllProviderCountContract`,
  deleteFavoriteContract: `${environment.apiUrl}/contract/deleteFavoriteContract`,
  getContractByIdContract: `${environment.apiUrl}/contract/findByIdContract`,
  getAllContractByIdContrac: `${environment.apiUrl}/contract/findAllContractByIdContrac`,  
  setFavoriteContractByIdContract: `${environment.apiUrl}/contract/checkFavorite`,  
  checkFavoriteContract: `${environment.apiUrl}/contract/favorite/findByIdcontractAndIdUser`,
  getChildsContractsByIdContract: `${environment.apiUrl}/contract/findAllMasterAndChillContracts`,

  /**
   * Ruta para obtener la lista de contratos de favoritos.
   * @type {string}
   */
  getListContractFavorite: `${environment.apiUrl}/contract/findAllFavorite`,

  /**
   * Ruta para obtener información de un usuario según su rol.
   * @example `${environment.apiUrl}/user/userByRol`
   * @description La ruta completa se compone uniendo `environment.apiUrl` con el endpoint específico "/user/userByRol".
   * @type {string}
   */
  getUser: `${environment.apiUrl}/user/userByRol`,

  /**
   * Rutas para los requerimientos.
   * @description La ruta completa se compone uniendo `environment.apiUrl` con el endpoint específico "/api/requirement".
   **/
  requirementPath: {
    saveRequirement: `${environment.apiUrl}/requirement`,
    findByIdContractAndReceptorRequirement: `${environment.apiUrl}/requirement/findByIdContractAndReceptor`,
    managementChange: `${environment.apiUrl}/requirement/manageRequirement`,
  }
  

};