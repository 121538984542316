<div class="modalDialog">
  <div [ngClass]="{'modal-dialod__error': showAlert.type == 'error','modal-dialod__success': showAlert.type == 'success'}">
    <a href="#close" title="Close" class="close" (click)="clouseAlsert()">X</a>
    <div class="row">
      <div class="column">
        <h4>Error</h4>
        <p>Se presento un error en la consulta.</p>
      </div>
    </div>
  </div>
</div>