<div class="Encabezado">
  <img src="../../../../assets/images/Cabezote-analistaRiesgo.png">
</div>
<div class="dashboard">
    <div class="cards">
      <div class="card text-center">
        <h2>Total de Proveedores</h2>
        <br>
        <h3>{{ totalProveedores }}</h3>
      </div>
    </div>
    <div class="chart">
      <canvas id="barChart"></canvas>
    </div>
  </div>
  
  
  <div class="contract-summary">
    <h2>Resumen de Proveedores</h2>
    <table>
      <thead>
        <tr>
          <th>Proveedor</th>
          <th>Monto</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let proveedor of contractByProviderCount">
            <td>{{proveedor.providerName}}</td>
            <td>{{proveedor.cotractTotal}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  