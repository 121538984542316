import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { AppRoutingModule } from '../app-routing.module';
import { BcMenuModule } from '@bancolombia/design-system-web/bc-menu';
import { BcPageHeaderModule } from '@bancolombia/design-system-web/bc-page-header';
import { BcFloatMenuModule } from '@bancolombia/design-system-web/bc-float-menu';
import { BcHeaderModule } from '@bancolombia/design-system-web/bc-header';
import { BadgeEstadoComponent } from './badge-estado/badge-estado.component';
import { BcStatusModule } from '@bancolombia/design-system-web/bc-status';
import { FavIconButtonComponent } from './fav-icon-button/fav-icon-button.component';
import { BcTooltipModule } from '@bancolombia/design-system-web/bc-tooltip';
import { MenuFilterPipe } from '../pipes/MenuFilter/menu-filter.pipe';
import { AlertABAComponent } from './alert-aba/alert-aba.component';
import { LoadABAComponent } from './load-aba/load-aba.component';
import {BcLoaderModule} from '@bancolombia/design-system-web/bc-loader';
import { TabABAComponent } from './tab-aba/tab-aba.component';
import { BcIconModule } from '@bancolombia/design-system-web/bc-icon';

@NgModule({
  declarations: [
    HeaderComponent,AlertABAComponent,
    MenuComponent,LoadABAComponent,
    BadgeEstadoComponent,
    FavIconButtonComponent,
    MenuFilterPipe,TabABAComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    BcMenuModule,
    BcPageHeaderModule,
    BcFloatMenuModule,
    BcHeaderModule,
    BcStatusModule,
    BcTooltipModule,    
    BcLoaderModule,
    BcIconModule
  ],
  exports:[
    AlertABAComponent,LoadABAComponent,
    HeaderComponent,
    MenuComponent,
    BadgeEstadoComponent,
    FavIconButtonComponent,
    TabABAComponent
  ]
})
export class ComponentsModule { }
