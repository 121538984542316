import { Component } from '@angular/core';
import { NovedadesService } from 'src/app/services/api-services/novedades-services/novedades.service';
import { novedadFilterFormInterface } from './novedad-filter.interface';

@Component({
  selector: 'app-search-bar-novedades',
  templateUrl: './search-bar-novedades.component.html',
  styleUrls: ['./search-bar-novedades.component.css']
})
export class SearchBarNovedadesComponent {

  /**
   * Permite indicar el estado (expandido o no) del search bar.
   *
   * @type {boolean}
   * @default false
   * @memberof SearchBarContratosComponent
   */
  isExpanded: boolean = false;

  /**
   * Clase que se aplica para expandir el search bar.
   *
   * @type {('bc-content-advanced-search-active' | '')}
   * @default ''
   * @memberof SearchBarContratosComponent
   */
  searchAdvToggleClass: "bc-content-advanced-search-active" | "" = "";

/**
 * Lista de valores seleccionables en el componente `bc-input_select` de contratos.
 *
 * @type {{label:string, value: any}[]}
 * @default []
 * @memberof SearchBarContratosComponent
 */
contratosSelectValues: { label: string, value: any }[] = [];


  /**
   * Lista de valores seleccionables en el componente `bc-input_select` de Id's de novedad.
   *
   * @type {{label:string, value: any}[]}
   * @default []
   * @memberof SearchBarContratosComponent
   */
  novedadSelectValues: { label: string, value: any }[] = [];


  /**
   * Lista de valores seleccionables en el componente `bc-input_select` de tipos de novedad.
   *
   * @type {{label:string, value: any}[]}
   * @default []
   * @memberof SearchBarContratosComponent
   */
  tipoNovedadSelectValues: { label: string, value: any }[] = [];

  /**
   * Lista de valores seleccionables en el componente `bc-input_select` de estado de novedad.
   *
   * @type {{label:string, value: any}[]}
   * @default []
   * @memberof SearchBarContratosComponent
   */
  estadoNovedadSelectValues: { label: string, value: any }[] = [];



  /**

  @typedef {Object} NovedadFilterFormInterface
  @property {string[]} idNovedad - Array de identificadores de novedades.
  @property {string[]} contratos - Array de identificadores de contratos.
  @property {string[]} tipoNovedad - Array de tipos de novedades.
  @property {string[]} estadoNovedad - Array de estados de novedades.
  */
    /**

      Objeto con valores iniciales para el formulario de filtro de novedades.
      @type {NovedadFilterFormInterface}
    */
  filterForm: novedadFilterFormInterface = {
    idNovedad: [],
    contratos: [],
    tipoNovedad: [],
    estadoNovedad: []
  }

  constructor(private novedadesService: NovedadesService){
    if (this.novedadesService.resultados.length) {
    }else{
      this.novedadesService.searchNovedadesByAdmin();
    }
  }
  
  /**
 * Función que cambia el estado de isExpanded y searchAdvToggleClass.
 * @returns {void}
 */
  toggleExpandSearchAdvance(){
    this.isExpanded = !this.isExpanded;
    this.searchAdvToggleClass = this.isExpanded ? "bc-content-advanced-search-active" : "";
  }

  /**
 * Obtiene un array de objetos con la estructura { label: string, value: any }
 * a partir de un array de objetos del tipo T, usando las claves especificadas
 * para el id y la etiqueta.
 * 
 * @template T El tipo de objeto en el array
 * @param {T[]} data El array de objetos del tipo T
 * @param {keyof T} idName La clave para el identificador del objeto T
 * @param {keyof T} labelName La clave para la etiqueta del objeto T
 * @returns { { label: string, value: any }[] } Un array de objetos con etiquetas y valores únicos
 */
  getSelectValues<T extends { [key: string]: any }>(data: T[], idName: keyof T, labelName: keyof T): { label: string, value: any }[] {
    // Obtener valores unicos 
    const uniqueData = data.filter((item: T, index: number) => {
      return data.findIndex((otherItem: T) => otherItem[idName] === item[idName]) === index;
    });

    // Mapear al objeto necesario para cargar el input-select
    const values = uniqueData.map((item: T) => {
      return { label: item[labelName], value: { id: item[idName] } };
    });
    console.log(values);

    return values;
  }

  /**
 * Actualiza los valores seleccionados en el filtro del formulario.
 *
 * @param {string | string[]} selectedValues - Los valores seleccionados para actualizar el formulario.
 * @param {"idNovedad" | "contratos" | "tipoNovedad" | "estadoNovedad"} param - El parámetro del formulario a actualizar.
 * @returns {void}
 */
  updateSelectValue(selectedValues: string | string[], param: "idNovedad" | "contratos" | "tipoNovedad" | "estadoNovedad") {
    if (Array.isArray(selectedValues) && selectedValues.length) {
      const values = selectedValues.map((item: any) => (item.value.id));
      this.filterForm[param] = values;
    }else{
      this.filterForm[param] = [];
    }
  }

}
