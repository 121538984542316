import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { formatearFecha } from 'src/app/components/utils/formato-fecha.componente';
import { FindByIdContractAndReceptorModel } from 'src/app/models/requirement.model';
import { RequirementService } from 'src/app/services/api-services/requirement-services/requierement.service';
import { UserService } from 'src/app/services/api-services/user-services/user.service';
import { SendDataInforamtionServices } from 'src/app/services/commo-service/sendData-services';

@Component({
  selector: 'app-solicitud-antiguo-rechazar',
  templateUrl: './solicitud-antiguo-rechazar.component.html',
  styleUrls: ['./solicitud-antiguo-rechazar.component.css']
})
export class SolicitudAntiguoRechazarComponent {
  //Variables
  userData: any = {};
  fecha: any;
  requirement: any;
  tittle = "Hola "  + JSON.parse(sessionStorage.getItem('userCurrentGraph') || '{}').displayName;


  

  constructor(
    private userService: UserService,
    private activityroute: ActivatedRoute,
    private requirementService: RequirementService,
    private sendDataInforamtionServices: SendDataInforamtionServices,
    private router: Router,
    private activatedRoute : ActivatedRoute,
  ) {}

  ngOnInit() {
    this.loadData();
    this.mostrarHora();

    this.userData = this.userService.getCurrentUserData();
  }
  
  private loadData() {
    this.activatedRoute.queryParams.subscribe((param: any)=>{
      this.requirement = param
    })
  }

  /**
* Actualiza la hora actual en la variable 'fecha' cada segundo.
*/
  mostrarHora(){
    this.fecha = formatearFecha(new Date());
    setInterval(()=>{
      this.fecha = formatearFecha(new Date());
    },1000);
  }

}
