//  Modulos (core)
import { LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
//Componentes
import { AppComponent } from './app.component';

//Modulos Bancolombia
import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from './components/components.module';
import { LayoutModule } from './layout/layout.module';
import { SecurityModule } from './security/security.module';
import { ServicesModule } from './services/services.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PagesModule } from './pages/pages.module';



// Imports de locals
import localeEsCo from "@angular/common/locales/es-CO";

// Modulos de terceros (third party)
import { MsalModule, MSAL_INSTANCE, MsalService, MsalRedirectComponent, MsalGuard, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalInterceptor } from "@azure/msal-angular";
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService } from './services/authServices/auth.service';
import { PipesModule } from './pipes/pipes.module';
import { InforamtionServices } from './services/commo-service/information-services';
import { EstadoServicesService } from './services/estado-services/estado-service';
import { StoreModule } from '@ngrx/store';
import { contractReducer } from './state/contract_state/contract.reducer';
import { EffectsModule } from '@ngrx/effects';
import { contractEffects } from './state/contract_state/contract.effect';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
 


// Registrar config locales
registerLocaleData(localeEsCo, 'es-CO');


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    // Pages and Components
    ComponentsModule,
    LayoutModule,
    ServicesModule,
    SecurityModule,
    FormsModule,
    ReactiveFormsModule,
    PagesModule,
    BrowserAnimationsModule,
    NgxChartsModule,
    BrowserAnimationsModule,
    // Third party modules
    MsalModule,
    PipesModule,
    StoreModule.forRoot({ contractState: contractReducer }, {}),
    EffectsModule.forRoot([contractEffects]),

  ],
  exports: [
    ReactiveFormsModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-CO' },
    {
      provide: MSAL_INSTANCE,
      useFactory: (authService: AuthService) => {
        return authService.getMsalInstance();
      },
      deps: [AuthService]
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: (authService: AuthService) => {
        return authService.getMSALGuardConfigFactory();
      },
      deps: [AuthService]
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: (authService: AuthService) => {
        return authService.getMSALInterceptorConfigFactory();
      },
      deps: [AuthService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService, InforamtionServices,EstadoServicesService 
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }

