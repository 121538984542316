import { Component, OnInit } from '@angular/core';
import { TableNameItem } from 'src/app/components/utils/enums/MarkeplaceEnum';
import SelectTableColumn from '../models/SelectTableColum.model';
import {mockMarkeplace} from '../models/mockMarkeplace';
import { animation, state, style, trigger } from '@angular/animations';



//export default interface GroupItems 


export default interface GroupCategory {
  category: string;
  items:  {
    negotiator: string;
    projectCount: number;
    capacity: number;
    analystType: number;
  }[]
}


@Component({
  selector: 'app-capacity',
  templateUrl: './capacity.component.html',
  styleUrls: ['./capacity.component.css'],
  animations: [
    trigger('animacionCuadro', [
      state('estado1', style({
        backgroundColor: 'red'
      })),
      state('estado2', style({
        backgroundColor: 'blue'
      }))
    ])
    
  ]
})
export class CapacityComponent implements OnInit {


  public estadoCuadro = 'estado1';

  animar(){
    this.estadoCuadro =this.estadoCuadro == 'estado1' ? 'estado2' : 'estado1'
  }


  sharePointData: any;
  perpage: any = 10;
  permultiple: string = "10";

  state = false;

  public onChangePage(data: any) {}
  public get markeplaceEnum (): typeof TableNameItem { return TableNameItem; }
  public proveedoresSelectValues: { label: string, value: any }[] = [];
  public showTable = false;
  public dataCapacity: any[] = [];

  

  ngOnInit(): void {    
    this.loadData();
    this.makeGenerateCapacity()
  }

  private loadData () {
    this.sharePointData = mockMarkeplace.data
  }

  private makeGenerateCapacity () {    
    const groupList: GroupCategory[] = this.capacityGenerateGroupCategoryAndName() 
    this.dataCapacity = this.capacityGrouop(this.capacityProjectCout(groupList))
    
  }


  
  private capacityGenerateGroupCategoryAndName(): GroupCategory[]{
    const list:  GroupCategory[] = [];
    for (let item of this.sharePointData){
      if(item['Negociador'].length > 1)
        if(list.find( (data) => data.category == item['Categoría'] )){
          list.map( ( i: GroupCategory ) => {
            if( i.category == item['Categoría'] ){
            i.items.push(
              {
                negotiator: item['Negociador'],
                projectCount: 0,
                capacity: 0,
                analystType: 0,
              }
            )  
            }
          }) 
        }else{
          list.push(
            {
              category: item['Categoría'],
              items: [
                {
                  negotiator: item['Negociador'],
                  projectCount: 34,
                  capacity: 0,
                  analystType: 345,
                }
              ]
            }
          )
        }
      }
    return list;
  } 

  private capacityProjectCout(list: GroupCategory[]): {coutn: number, negotiator: string, category: string}[] {
    const  group: any[]= [];
    list.map( ( item ) => {
      const negotiatorList: {coutn: number, negotiator: string, category: string}[]= []
      item.items.map(( i ) => {
        if(negotiatorList.find((( data ) => data.negotiator == i.negotiator )) ){
          negotiatorList.map( (item) => {
            if(item.negotiator == i.negotiator){
              item.coutn = item.coutn + 1;
            }
          })
        }else{
          negotiatorList.push(
            {
              category: item.category,
              negotiator: i.negotiator,
              coutn: 1
            }
          )
        }
      })
      group.push(negotiatorList)
    })
    return group
  }

  capacityGrouop(list: any[]): GroupCategory[] {
    const response: GroupCategory[]= [];
    list.map( (item: {coutn: number, negotiator: string, category: string}[]) => {

      item.map( (data) => {
        if( response.find(((i)=>i.category  == data.category)) ){
          response.map( (i) => {
            i.items.push(
              {
                negotiator: data.negotiator,
                projectCount: data.coutn,
                capacity: data.coutn * 100 / 28,
                analystType: 1 ,
              }
            )
          })
        }else{
          response.push(
            {
              category: data.category,
              items: [
                {
                  negotiator: data.negotiator,
                  projectCount: data.coutn,
                  capacity: 7 * 100 / 28,
                  analystType: 1,
                }
              ]
            }
          )
        }
      })
    })
    return response
  }

  public colorFuntion(data: number): String {
    let responses: String = "#EAECF0";
    if( data < 70 ) {
      responses = '#00C389'
    }
    if(data > 70 ) {
      responses = '#FDDA24'
    }
    if(data > 100 ){
      responses = '#FF7F41'
    }
    return responses
  }
  public converterNumber(data: any) {
    return parseInt(data);
  }
  

}
