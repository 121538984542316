import { HttpClient, HttpParams } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable, Subject, catchError, switchMap } from 'rxjs';
import { Contrato, ContratoLite, ContratoProveedor, Proveedor, Usuario } from 'src/app/models/contratos.interface';
import { apiPaths } from '../api-services-paths';

@Injectable({
  providedIn: 'root'
})
export class ContratosService {

  public contractsData: any;
  public contractsDataSubject = new Subject<any>();

  private _resultadosContratos: Contrato[] = [];
  private _resultadosContratosPorProveedor: ContratoProveedor[] = [];
  private _resultadosLite: ContratoLite[] = [];

  constructor(
    private http: HttpClient,
  ) { }

  getContratos(pageNumber: number = 0, perPage: number = 10): Observable<any> {
    let params = new HttpParams()
      .set('currentPage', pageNumber.toString())
      .set('numberData', perPage.toString())
    return this.http.get<any>(apiPaths.getListContratos, { params });
  }
  

  getContracts(pageNumber: number = 0, perPage: number = 10): Observable<any> {
    let params = new HttpParams()
      .set('currentPage', pageNumber.toString())
      .set('numberData', perPage.toString());
    return this.http.get<any>(apiPaths.getListContratos, { params });
  }

  getContractByIdContract(idContract: String): Observable<any> {
    let params = new HttpParams()
      .set('idContract', idContract.toString())
    return this.http.get<any>(apiPaths.getContractByIdContract, { params });
  }

  getChildsContractsByIdContract(idContract: String): Observable<any> {
    let params = new HttpParams()
      .set('idContractMaster', idContract.toString())
    return this.http.get<any>(apiPaths.getChildsContractsByIdContract, { params });
  }

  getContractFavoriteByIdContract(idContract: String, idUser: string): Observable<any> {
    let params = new HttpParams()
      .set('contract', idContract.toString())
      .set('idUser', idUser.toString())
    return this.http.get<any>(apiPaths.getContractByIdContract, { params });
  }

  deleteFavoriteContratos(idFavorite: String): Observable<any> {
    let params = new HttpParams()
      .set('idFavorite', idFavorite.toString())
    return this.http.delete<any>(apiPaths.deleteFavoriteContract, { params });
  }

  getContratosFavorite(idUser: number): Observable<any> {
    let params = new HttpParams()
      .set('idUser', idUser);
    return this.http.get<any>(apiPaths.getListContractFavorite, { params });
  }

  getListContractNextFinal(idUser: string): Observable<any> {
    let params = new HttpParams()
      .set('idUser', idUser);
    return this.http.get<any>(apiPaths.getListContractNextFinal, { params: params });
  }

  getLisProviderCountContract(): Observable<any> {
    return this.http.get<any>(apiPaths.getLisProviderCountContract);
  }

  getAllContractByIdContrac(idContract: string): Observable<any> {
    let params = new HttpParams()
      .set('idContract', idContract);
    return this.http.get<any>(apiPaths.getAllContractByIdContrac, { params });
  }

  setFavoriteContractById(idContract: String, idUser: string): Observable<any> {
    let params = new HttpParams()
      .set('idContract', idContract.toString())
      .set('idUser', idUser.toString())
    return this.http.put(apiPaths.setFavoriteContractByIdContract, {}, { params });
  }

  checkIfContractIsFavorite(idContract: string, idUser: string): Observable<any> {
    let params = new HttpParams()
      .set('contract', idContract.toString())
      .set('idUser', idUser.toString())
    return this.http.get(apiPaths.checkFavoriteContract, { params });
  }

  toggleFavoriteContract(idContract: string, idUser: string): Observable<any> {
    console.log(idContract, idUser);

    return this.checkIfContractIsFavorite(idContract, idUser).pipe(
      switchMap((dataFavorite) => {
        if (dataFavorite.data.idFavotire) {
          // Está en favoritos, elimínalo
          return this.deleteFavoriteContratos(dataFavorite.data.idFavotire);
        } else {
          // No está en favoritos, agrégalo
          return this.setFavoriteContractById(idContract, idUser);
        }
      })
    );
  }

  loadContractsData(pageNumber: number = 1, perPage: number = 10): void {
    this.getContracts(pageNumber, perPage).pipe(
      catchError((error) => {
        //console.error('Error al obtener los datos del usuario:', error);
        return [];
      })
    ).subscribe((contractsData) => {
      this.contractsData = contractsData;
      //console.log("Datos del usuario obtenidos del backend: ", this.userData);
      this.contractsDataSubject.next(contractsData);
    });
  }

  /**
 * Método para suscribirse a los datos del usuario almacenados en el Subject.
 * @returns {Observable<any>} Observable que contiene los datos del usuario.
 */
  public getUserDataSubject(): Observable<any> {
    return this.contractsDataSubject.asObservable();
  }
  get resultadosContratos() {
    return [...this._resultadosContratos];
  }

  get resultadosLite() {
    return [...this._resultadosLite];
  }

  get resultadosContratosPorProveedor() {
    /*
      ToDo:
        - Tener en cuenta para mostrar los contratos por proveedor segun
        su administrador o unidad organizativa.
    */
    return [...this._resultadosContratosPorProveedor];
  }

  /** 
  * Retorna los proveedores unicos existentes en los resultados de los contratos retornados.
  * @return {Proveedor[]}
  */
  get proveedoresFromResult(): Proveedor[] {
    // Obtener los proveedores bajo la estructura deseada
    return this.resultadosContratos.map(
      (contrato: Contrato) => (contrato.proveedor));
  }

  get administradoresFromResult(): Usuario[] {
    return [...this.resultadosContratos.map(
      (contrato: Contrato) => (contrato.administrador)
    )];
  }


  getTotalProviderCount(): number {
    const uniqueProviders: Set<string> = new Set<string>();
    this._resultadosContratos.forEach((contrato: Contrato) => {
      console.log('Proveedor en contrato:', contrato.proveedor.nombre); // Agrega este log
      uniqueProviders.add(contrato.proveedor.nombre);
    });
    return uniqueProviders.size;
  }


  calculateContractCount(): number {
    return this._resultadosContratos.length;
  }

  calculateProviderCount(): number {
    const uniqueProviders: Set<string> = new Set<string>();
    this._resultadosContratos.forEach((contrato: Contrato) => {
      uniqueProviders.add(contrato.proveedor.id); // Cambia a la propiedad adecuada
    });
    return uniqueProviders.size;
  }
}
