/**
 * Da formato a la fecha suministrada.
 * ```
 * >> formatearFecha(new Date())
 * "Lunes 6 de Marzo de 2023 2:36:00 p. m."
 * ```
 * 
 * @param fecha Fecha a formatear (Date)
 * @returns Fecha con el formato necesario para el _page header_.
 */
export function formatearFecha(fecha: Date): string {

        // Definir los nombres de los días de la semana y meses en un array  
        const dias: string[] = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
        const meses: string[] = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
        // Obtener el día de la semana, el día del mes, el mes, el año y la hora  
        const diaSemana: string = dias[fecha.getDay()];
        const diaMes: number = fecha.getDate();
        const mes: string = meses[fecha.getMonth()];
        const anio: number = fecha.getFullYear();
        let hora: number = fecha.getHours();
        const minutos: number = fecha.getMinutes();
        const seconds: number =fecha.getSeconds();
        // Convertir la hora a formato AM/PM  
        const amPm: string = hora >= 12 ? 'p. m.' : 'a. m.';
        hora = hora % 12;
        hora = hora ? hora : 12;
        // Construir la cadena de fecha formateada  
        const fechaFormateada: string = `${diaSemana} ${diaMes} de ${mes} de ${anio} ${hora}:${minutos.toString().padStart(2, '0')}:${seconds.toString().padStart(2,'0')} ${amPm}`;
        return fechaFormateada;
  }


  /**
 * Da formato corto a la fecha suministrada.
 * ```
 * >> formatearFechaCorta(new Date())
 * "06/03/2023"
 * ```
 * 
 * @param fecha Fecha a formatear (_Date_)
 * @returns Fecha con el formato corto (_string_).
 */
export function formatearFechaCorta(fecha: Date): string{
    const fechaFormateada: string = fecha.toLocaleDateString("es-ES");
    return fechaFormateada;
}