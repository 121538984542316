import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SolicitudAntiguoAdministradorComponent } from './solicitud-antiguo-administrador/solicitud-antiguo-administrador.component';
import { SolicitudNuevoAdministradorComponent } from './solicitud-nuevo-administrador/solicitud-nuevo-administrador.component';
import { BcPageHeaderModule } from '@bancolombia/design-system-web/bc-page-header';
import { SolicitudesPendientesComponent } from './solicitudes-pendientes.component';
import { BcCardModule } from '@bancolombia/design-system-web/bc-card';
import { BcButtonModule } from '@bancolombia/design-system-web/bc-button';
import { BcAccordionModule } from '@bancolombia/design-system-web/bc-accordion';
import { SolicitudAntiguoRechazarComponent } from '../servicio-mensaje/solicitud-antiguo-rechazar/solicitud-antiguo-rechazar.component';
import { SolicitudNuevoRechazarComponent } from '../servicio-mensaje/solicitud-nuevo-rechazar/solicitud-nuevo-rechazar.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { BcIconModule } from '@bancolombia/design-system-web/bc-icon';
import { ComponentsModule } from 'src/app/components/components.module';



@NgModule({
  declarations: [
    SolicitudAntiguoAdministradorComponent,
    SolicitudNuevoAdministradorComponent,
  ],
  exports: [
    SolicitudAntiguoAdministradorComponent,
    SolicitudNuevoAdministradorComponent,
  ],
  imports: [
    CommonModule,
    BcIconModule,
    BcPageHeaderModule,
    BcCardModule,
    BcButtonModule,
    BcAccordionModule,
    AppRoutingModule,
    ComponentsModule
    
  ]
})
export class SolicitudesPendientesModule { }
