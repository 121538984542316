<div class="bc-content">
    <bc-page-header [date]='fecha' [title]="tittle" [bgWhite]="false" [className]="'bc-bg-light'">
    </bc-page-header>
</div>

<div class="bc-container">
    <div bc-card>
        <div class="bc-card-body">
            <div>
                <!-- En caso de rechazar -->
                 <img src="../../../../assets/images/rechazar.PNG" alt="" style="width: 100%;"> 
            </div>
            <div class="bc-margin-top-4 bc-container">
                <div class="bc-row">
                    <!-- Izquierda -->
                    <div class="bc-col-6">
                        <div>
                            <b>Id Contrato</b>
                        </div>
                        <div class="bc-margin-top-1">
                            <b>Cw22234241</b>
                        </div>
                    </div>
                    <!-- Derecha -->
                    <div class="bc-col-6">
                        <div>
                            <b>Descripción</b>
                            <br>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                        </div>

                    </div>
                </div>
                <div class="bc-margin-top-3">
                    <b>
                        <p>
                            Proveedor
                        </p>
                    </b>

                    <p>Lorem Epsilum Umbrlla core</p>
                </div>

            </div>

            <div class="boton">
                <div class="bc-margin-top-2" style="width: 40%;" routerLink="/Solicitud">
                    <button bc-button>Continuar Con Otra Solicitud</button>
                </div>
            </div>
            

        </div>
    </div>
</div>