import { Component } from '@angular/core';
import { InforamtionServices } from 'src/app/services/commo-service/information-services';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent {
  constructor(private inforamtionServices: InforamtionServices){}
  
  public alert: any = false;
  public load: any = false;

  ngOnInit(){
    this.inforamtionServices.showInformationAler.subscribe(
      (data)=> this.alert = data
    )

    this.inforamtionServices.showInformationLoad.subscribe(
      (data)=> this.load = data
    )
  }

}
