import { Component } from '@angular/core';
import { novedadFilterFormInterface } from '../../mis-novedades/search-bar-novedades/novedad-filter.interface';
import { NovedadesService } from 'src/app/services/api-services/novedades-services/novedades.service';

@Component({
  selector: 'app-operador-novedades-searchbar',
  templateUrl: './operador-novedades-searchbar.component.html',
  styleUrls: ['./operador-novedades-searchbar.component.css']
})
export class OperadorNovedadesSearchbarComponent {

  
  /**
   * Permite indicar el estado (expandido o no) del search bar.
   *
   * @type {boolean}
   * @default false
   * @memberof SearchBarContratosComponent
   */
  isExpanded: boolean = false;

  /**
   * Clase que se aplica para expandir el search bar.
   *
   * @type {('bc-content-advanced-search-active' | '')}
   * @default ''
   * @memberof SearchBarContratosComponent
   */
  searchAdvToggleClass: "bc-content-advanced-search-active" | "" = "";

/**
 * Lista de valores seleccionables en el componente `bc-input_select` de contratos.
 *
 * @type {{label:string, value: any}[]}
 * @default []
 * @memberof SearchBarContratosComponent
 */
contratosSelectValues: { label: string, value: any }[] = [];


  /**
   * Lista de valores seleccionables en el componente `bc-input_select` de Id's de novedad.
   *
   * @type {{label:string, value: any}[]}
   * @default []
   * @memberof SearchBarContratosComponent
   */
  novedadSelectValues: { label: string, value: any }[] = [];


  /**
   * Lista de valores seleccionables en el componente `bc-input_select` de tipos de novedad.
   *
   * @type {{label:string, value: any}[]}
   * @default []
   * @memberof SearchBarContratosComponent
   */
  tipoNovedadSelectValues: { label: string, value: any }[] = [];

  /**
   * Lista de valores seleccionables en el componente `bc-input_select` de estado de novedad.
   *
   * @type {{label:string, value: any}[]}
   * @default []
   * @memberof SearchBarContratosComponent
   */
  estadoNovedadSelectValues: { label: string, value: any }[] = [];



  filterForm: novedadFilterFormInterface = {
    idNovedad: [],
    contratos: [],
    tipoNovedad: [],
    estadoNovedad: []
  }

  constructor(private novedadesService: NovedadesService){
    if (this.novedadesService.resultados.length) {
      // this.novedadSelectValues = this.getSelectValues(this.novedadesService.resultados, "idNovedad", "idNovedad");
      // this.contratosSelectValues = this.getSelectValues(this.novedadesService.resultados, "", "idNovedad");
      // this.novedadSelectValues = this.getSelectValues(this.novedadesService.resultados, "idNovedad", "idNovedad");

    }else{
      this.novedadesService.searchNovedadesByAdmin();
    }
  }
  
  toggleExpandSearchAdvance(){
    this.isExpanded = !this.isExpanded;
    this.searchAdvToggleClass = this.isExpanded ? "bc-content-advanced-search-active" : "";
  }


  getSelectValues<T extends { [key: string]: any }>(data: T[], idName: keyof T, labelName: keyof T): { label: string, value: any }[] {
    // Obtener valores unicos 
    const uniqueData = data.filter((item: T, index: number) => {
      return data.findIndex((otherItem: T) => otherItem[idName] === item[idName]) === index;
    });

    // Mapear al objeto necesario para cargar el input-select
    const values = uniqueData.map((item: T) => {
      return { label: item[labelName], value: { id: item[idName] } };
    });
    console.log(values);

    return values;
  }

  updateSelectValue(selectedValues: string | string[], param: "idNovedad" | "contratos" | "tipoNovedad" | "estadoNovedad") {
    if (Array.isArray(selectedValues) && selectedValues.length) {
      const values = selectedValues.map((item: any) => (item.value.id));
      this.filterForm[param] = values;
    }else{
      this.filterForm[param] = [];
    }
  }


}
