import { Component, Input, ViewChild } from '@angular/core';
import { BcOffCanvasComponent } from '@bancolombia/design-system-web/bc-off-canvas';
import { BcModalComponent } from '@bancolombia/design-system-web/bc-modal';
import { PopoutComponent } from '../popout/popout.component';
@Component({
  selector: 'app-reportar-novedades',
  templateUrl: './reportar-novedades.component.html',
  styleUrls: ['./reportar-novedades.component.css']
})
export class ReportarNovedadesComponent {

  titulo = "Reporte Novedad Del Delegado";
  descripcion = "En esta opción podrás reportar la novedad de cambio de delegado del contrato. Al seleccionar “enviar novedad” estarás enviando la solicitud para que cadena de abastecimiento realice la gestión correspondiente.";
 
  //Inputs
  @Input() textButton!: string;
  @ViewChild('offCanvasComponent',
    {
      static: true,
    })
  bcOffCanvasComponent: BcOffCanvasComponent | any;
  bcModalComponent : BcModalComponent | any;
  PopOutComponent : PopoutComponent | any ;
  tipoOffcanvas = '';
  backdrop = '';
  alturaHorizontal = '';
  backdrops = [
    {
      label: 'Activo',
      value: '',
    },
    {
      label: 'Apagado',
      value: 'off',
    },
  ];
  alturasHorizontal = [
    {
      label: '90vh',
      value: '90vh',
    },
    {
      label: '50vh',
      value: '50vh',
    },
    {
      label: '40vh',
      value: '40vh',
    },
  ];
  /**
Abre el componente off-canvas.
@returns {void}
*/
  openOffCanvas() {
    this.bcOffCanvasComponent.openOffCanvas();
  }

  /**
 * Cierra el OffCanvas.
 *
 * @function
 * @name closeOffCanvas
 * @returns {void}
 */
  closeOffCanvas() {
    this.bcOffCanvasComponent.closeOffCanvas(null);
  }

  /**
 * Opens a horizontal off-canvas menu.
 *
 * @function
 * @returns {void}
 */
  openOffCanvasHorizontal() {

    /**
   * Defines the type of off-canvas menu to open.
   *
   * @type {string}
   * @default 'horizontal'
   * @private
   */

    this.tipoOffcanvas = 'horizontal';
    this.openOffCanvas();
  }

  /**
 * Abre el offcanvas en modo vertical
 * @function openOffCanvasVertical
 * @memberof ClassName
 * @returns {void}
 */
  openOffCanvasVertical() {
    this.tipoOffcanvas = 'vertical';
    this.bcOffCanvasComponent.openOffCanvas();
  }

  /**

  Selecciona el valor para el Backrop
  @param {string} value - Nuevo valor para el Barckrop
  */
  selectBackcrop(value: string) {
    this.backdrop = value;
  }

  /**

  Selecciona la altura horizontal del offcanvas
  @param {string} value - El valor de la altura seleccionada
  @returns {void}
  */
  selectHeight(value: string) {
    this.alturaHorizontal = value;
  }
  /**
   * Muestra el Modal PopOut
   */
  MostrarPopOut()
  {
    this.bcModalComponent.openModal();
  }
  /**
   * Cierra el Off-Canvas
   */
  Cancelar()
  {
    this.bcOffCanvasComponent.closeOffCanvas();
  }


}
