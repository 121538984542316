import { Component, OnInit } from '@angular/core';

import { animation, state, style, trigger } from '@angular/animations';
import { BrowserModule } from '@angular/platform-browser';
import { LegendPosition, NgxChartsModule } from '@swimlane/ngx-charts';
import { multi } from './data';


export default interface GroupCategory {
  category: string;
  items:  {
    negotiator: string;
    projectCount: number;
    capacity: number;
    analystType: number;
  }[]
}

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css'],
  animations: [
    trigger('animacionCuadro', [
      state('estado1', style({
        backgroundColor: 'red'
      })),
      state('estado2', style({
        backgroundColor: 'blue'
      }))
    ])
    
  ]
})
export class SummaryComponent implements OnInit {

  ngOnInit(): void {
    //throw new Error('Method not implemented.');
  }

  public multi: any[] = multi;
  public view: [number, number] = [400, 350];
  public legendPosition = LegendPosition.Below 



  colorScheme: any = {
    domain: ['#F5B6CD', '#9063CD', '#00CCEA']
  };

  constructor() {
    Object.assign(this, { multi })
  }

 onSelect(data: any): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data: any): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data: any): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }


}
