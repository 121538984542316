import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { BcDialogService } from '@bancolombia/design-system-web/bc-services';
import { Store } from '@ngrx/store';
import { Chart } from 'chart.js';
import { Observable } from 'rxjs';
import { FavoriteModel } from 'src/app/models/contract-favorite.model';
import { ContratosService } from 'src/app/services/api-services/contratos-services/contratos.service';
import { NovedadesService } from 'src/app/services/api-services/novedades-services/novedades.service';
import { UserService } from 'src/app/services/api-services/user-services/user.service';
import { InforamtionServices } from 'src/app/services/commo-service/information-services';
import { CONTRACTITEMS, CONTRACTLOAD } from 'src/app/state/contract_state/contract.action';
import { selectorContractItem } from 'src/app/state/contract_state/contract.selector';

@Component({
  selector: 'app-analista-riesgo-view',
  templateUrl: './analista-riesgo-view.component.html',
  styleUrls: ['./analista-riesgo-view.component.css']
})
export class AnalistaRiesgoViewComponent implements OnInit, AfterViewInit {

  public contractByProviderCount: any = [];
  public contractFavorite: FavoriteModel[] = [];
  public contractFNexinal: any = [];
  totalProveedores: number = 0;
  userData: any = {};

  constructor(
    private contratosServices: ContratosService,
    private dialogService: BcDialogService,
    private novedadesServices: NovedadesService,
    private router: Router,
    private inforamtionServices: InforamtionServices,
    private store: Store<any>,
    private userService: UserService
  ) {}

  async ngOnInit() {
    // Obtener los datos del usuario actual
    this.userService.getUserDataSubject().subscribe(
      data => {
        this.userData = data;
        this.loadSubscribeService(this.userData);
      },
      error => {
        // Manejar el error
      }

    );
    console.log('Contract by provider count:', this.contractByProviderCount);
    this.userData = this.userService.getCurrentUserData();
  }

  

  async loadSubscribeService(currentUser: any) {
    console.log("Contracto", currentUser);
    if (currentUser && currentUser.data && currentUser.data.id) {
      this.contratosServices.getContratosFavorite(currentUser.data.id).pipe().subscribe(
        (success) => {
          this.contractFavorite = success.data;
          this.contratosServices.getLisProviderCountContract().pipe().subscribe(
            (success) => {
              this.contractByProviderCount = success.data.contractProvider;
              console.log('Contract by provider count:', this.contractByProviderCount);
              this.totalProveedores = this.contractByProviderCount.length;
              this.createBarChart(); // Llamar a la función de creación del gráfico de barras
              this.contratosServices.getListContractNextFinal(currentUser.data.id).subscribe(
                (success) => {
                  this.contractFNexinal = success.data;
                  this.inforamtionServices.showInformationLoad.emit(false);
                },
                error => {
                  // Manejar el error
                }
              );
            },
            error => {
              // Manejar el error
            }
          );
        },
        error => {
          // Manejar el error
        }
      );
    }
  }

  ngAfterViewInit() {
    console.log('Contract by provider count:', this.contractByProviderCount);
    this.createBarChart();
  }

  createBarChart() {
    const ctx = document.getElementById('barChart') as HTMLCanvasElement;
    console.log('Canvas element:', ctx);
    const existingChart = Chart.getChart(ctx);
    if (existingChart) {
      existingChart.destroy();
      console.log('Canvas element:', ctx);
    }
    const barChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: this.contractByProviderCount.map((provider: any) => provider.providerName), // Nombres de proveedores
        datasets: [
          {
            label: 'Cantidad de Contratos',
            data: this.contractByProviderCount.map((provider: any) => provider.cotractTotal), // Cantidad de contratos por proveedor
            backgroundColor: 'blue',
            borderWidth: 1
          }
        ]
      },
      options: {
        responsive: true,
        scales: {
          x: {
            beginAtZero: true
          },
          y: {
            beginAtZero: true
          }
        }
      }
    });
  }
}
