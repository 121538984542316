<div class="bc-row">
    <div class="bc-col-12">
        <bc-table-container class="bc-col-12 bc-w-100" *ngIf="currentPage.length > 0" [dataTable]="currentPage"
            [cellOptions]="dropdownOptions">
            <bc-table-header [title]="title" [actionsSelections]="actions" (actionClicked)="onClickAction($event)">
                <bc-paginator type="full" [totalRegisters]="'16'" [perPage]="'5'"
                    (onChangePage)="onChangePageTableScroll($event)" [perMultiple]="'5'">
                </bc-paginator>
            </bc-table-header>
            <bc-table-content>
                <table caption="tabla" bc-table [selection]='true' [scroll]="true" [sort]="true" [dropdownHtml]="true">
                    <thead>
                        <tr>
                            <th scope="row" bc-cell type="total">Fecha</th>
                            <th scope="row" bc-cell type="total">Estado</th>
                            <th scope="row" bc-cell type="total">Usuario</th>
                            <th scope="row" bc-cell type="total">Descripcion de carga</th>
                            <th scope="row" bc-cell type="total">total elementos cargados</th>
                            <th scope="row" bc-cell type="total">Registror con error</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let contrato of currentPage">
                            <td bc-cell>{{ contrato.fechaInicio }}</td>
                            <td bc-cell>{{ contrato.estado}}</td>
                            <td bc-cell>{{ contrato.usuario }}</td>
                            <td bc-cell>{{contrato.descripcionCarga}}</td>
                            <td bc-cell>{{contrato.totalElementos}}</td>
                            <td bc-cell>{{contrato.registrosError}}</td>

                        </tr>
                    </tbody>
                </table>
            </bc-table-content>
            <bc-table-footer> <bc-paginator type="full" [totalRegisters]="'16'" [perPage]="'5'"
                    [perMultiple]="'5'"></bc-paginator>
            </bc-table-footer> </bc-table-container>
    </div>
</div>