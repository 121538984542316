import { Component, Input, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { BcTableActionButton, BcTableOptionMenu } from '@bancolombia/design-system-behaviors';
import { TableNameItem } from 'src/app/components/utils/enums/MarkeplaceEnum';
import SelectTableColumn from './models/SelectTableColum.model';
import { FormControl, FormGroup } from '@angular/forms';


@Component({
  selector: 'markeplace_staffing',
  templateUrl: './staffing.component.html',
  styleUrls: ['./staffing.component.css']
})

export class MarkeplaceStaffing implements OnInit{
  
  @Input('data') _pages!: any;
  @Input() tabSelected: EventEmitter<{ name: string,id:number, status: string }> = new EventEmitter<{ name: string,id:number, status: string }>
  
  public selectTab: { name: string,id:number, status: string } = {id: 0,name:"Demanda",status:""}; 

  

  ngOnInit(): void {
    this.tabSelected.subscribe(
      (succes) => this.selectTab = this.selectTab = succes ,
      (error) => console.log("Error ::", error)
    )
  }




}
