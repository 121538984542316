import { UserGraphModel } from "src/app/models/graph-models/userGraph.model";
import { UserGraphServices } from "src/app/services/api-services/graph-services/user-graph.services";

export class AzureAdpPresenter {

    private userGraphServices: UserGraphServices

    constructor( userGraphServices: UserGraphServices){
        this.userGraphServices = userGraphServices
    }

    public async getUserByUserDisplayName(displayName: string): Promise<UserGraphModel> {
        let user: UserGraphModel = {
            businessPhones: [],displayName: "",
            givenName: "",jobTitle: "",mail: "",
            mobilePhone: "",officeLocation: "",preferredLanguage: "",
            surname: "",userPrincipalName: "",id: ""
        };
        await this.userGraphServices.getUserBydisplayNameGraph(displayName).subscribe(
            {
                next: (success) =>  user = success,
                error: (error) => console.log("ERROR CONSULTANDO DATOS:: ", error)
            }
        )
        return user
    }
}