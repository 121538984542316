import { Component, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-popout',
  templateUrl: './popout.component.html',
  styleUrls: ['./popout.component.css']
})
export class PopoutComponent {

  @ViewChild("modal")modal: any;
  @Input() show: boolean = false;
  action = false;
  typeIcon = '';
  customWidth = 'small';
  titleAlign = '';
  buttonAlignment = 'horizontal';
  selectedValue = '';
  subTitle = '';

  /**
   * Muestra el Mensaje Modal Seleccionado
   */
  MostrarPopOut()
  {
    if(this.show){
      this.modal.openModal();
    }
  }

}
