<div class="bc-content bc-container-solid bc-bg-light">
  <!-- Encabezado y Título -->
  <div class="Encabezado">
    <img src="../../../assets/images/Header-Novedades.png">
  </div>
  <bc-page-header [date]='fecha' [bgWhite]="false" [className]="'bc-bg-light'"></bc-page-header>

  <div *ngIf="dataLoaded == false">
		<load-aba-component></load-aba-component>
	</div>
  
  </div>


  <div class="bc-text-center">
    <div>
      <p>
        Aquí encuentras las novedades que te han reportado.
        <br>
        ¿Quieres conocer más información? Ingresa a la notificación.
      </p>
    </div>
  </div>

  <div>
  <div class="bc-content bc-container-solid bc-p-5 bc-bg-light">
    <div>
      <br>
      <!-- Agrupación de Pendientes -->
      <ng-container *ngIf="(novedad && novedad.length > 0) || (requirement && requirement.length > 0); else noNotificaciones">
        <h3>Notificaciones Pendientes</h3>
        <br>
        <bc-accordions-group [className]="'bc-margin-top-2'">
          <ng-container *ngFor="let item of (novedad.concat(requirement))">
            <ng-container *ngIf="item.status === 'PENDIENTE'">
              <bc-accordion type="column" class="bc-mb-4">
                <bc-accordion-header title="ID: {{item.contractId.idContract}}" [columns]="columnsForRequirement(item)"></bc-accordion-header>
                <bc-accordion-content *ngIf="novedad.includes(item)">
                  <app-novedad-negociador-accordion [novedad]="item"></app-novedad-negociador-accordion>
                </bc-accordion-content>
                <bc-accordion-content *ngIf="requirement.includes(item)">
                  <app-novedades-negociador-solicitud [requirement]="item"></app-novedades-negociador-solicitud>
                </bc-accordion-content>
              </bc-accordion>
            </ng-container>
          </ng-container>
        </bc-accordions-group>
      </ng-container>

      <!-- Agrupación de Completados -->
      <br>
      <ng-container *ngIf="(novedad && novedad.length > 0) || (requirement && requirement.length > 0)">
        <h3>Notificaciones Completadas</h3>
        <br>
        <bc-accordions-group [className]="'bc-margin-top-2'">
          <ng-container *ngFor="let item of (novedad.concat(requirement))">
            <ng-container *ngIf="item.status !== 'PENDIENTE'">
              <bc-accordion type="column" class="bc-mb-4">
                <bc-accordion-header title="ID: {{item.contractId.idContract}}" [columns]="columnsForRequirement(item)"></bc-accordion-header>
                <bc-accordion-content *ngIf="novedad.includes(item)">
                  <app-novedad-negociador-accordion [novedad]="item"></app-novedad-negociador-accordion>
                </bc-accordion-content>
                <bc-accordion-content *ngIf="requirement.includes(item)">
                  <app-novedades-negociador-solicitud [requirement]="item"></app-novedades-negociador-solicitud>
                </bc-accordion-content>
              </bc-accordion>
            </ng-container>
          </ng-container>
        </bc-accordions-group>
      </ng-container>

      <!-- Diferenciación dependiendo del tipo  -->
      <div>
        <!-- ... (otros elementos) ... -->
      </div>
    </div>
  </div>

  <!-- Mensaje si no hay Notificaciones Pendientes o Completadas -->
  <ng-template #noNotificaciones>
    <div class="no-novedades-negociador-message">
      <img src="../../../assets/images/il-sv-process.svg" alt="No Notificaciones">
      <p>No tienes novedades en este momento. ¡Todo está al día!</p>
    </div>
  </ng-template>
</div>

