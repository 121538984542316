<div class="bc-content">

    <div class="Encabezado">
        <img src="../../../../assets/images/Header-Inicio.png">
    </div>
    <bc-page-header [date]='fecha' [title]="'Hola ' +  this.userData?.data.name + tittle" [bgWhite]="false"
        [className]="'bc-bg-light'">
    </bc-page-header>

    <div *ngIf="loading">
        <load-aba-component></load-aba-component>
    </div>

    <div>
        <div class="bc-margin-top-3">
            <div class="titulo_cards_header bc-text-center" *ngIf="total > 0">
                <div>
                    <p>Este es el resumen de los contratos que negocias</p>
                </div>

                <br>
            </div>
            <div class="container-dashboard">
            <ng-container *ngIf="total > 0; else noDashboard">
                <div class="bc-row bc-padding-1 bc-wrap bc-flex bc-direction-column bc-direction-lg-row">
                    <div class="container_cards">
                        <div class="bc-col-3 bc-margin-1 bc-border-radius-0-top">
                            <bc-card-container cardType="default" class="custom-card">
                                <div class="bc-padding-3 bc-bg-brand-sequential-C-400" title="Total de contratos asignados a tu nombre">
                                    <small><b>
                                            <div class="bc-text-center ">
                                                <h4>
                                                    <b>Contratos asignados</b>
                                                </h4>
                                            </div>
                                        </b></small>
                                    <div class="bc-margin-top-2 bc-text-center">
                                        <b>
                                            <font size=10>{{total}}</font>
                                        </b>
                                    </div>
                                </div>
                            </bc-card-container>
                        </div>
    
                        <div class="bc-col-3 bc-margin-1 bc-border-radius-0-top">
                            <bc-card-container cardType="default" class="custom-card">
                                <div class="bc-padding-3 bc-bg-brand-status-wrong" title="Contratos sin vigencia actual">
                                    <small><b>
                                            <div class="bc-text-center">
                                                <h4><b>Contratos vencidos</b></h4>
                                            </div>
                                        </b></small>
                                    <div class="bc-margin-top-2 bc-text-center">
                                        <b>
                                            <font size=10> {{ contractBeaterCount }}</font>
                                        </b>
                                    </div>
                                </div>
                            </bc-card-container>
                        </div>
    
                        <div class="bc-col-3 bc-margin-1 bc-border-radius-0-top">
                            <bc-card-container cardType="default" class="custom-card">
                                <div class="bc-padding-3 bc-bg-brand-status-warning" title="Acuerdos que vencen en menos de 90 días">
                                    <small><b>
                                            <div class="bc-text-center">
                                                <h4><b>Contratos próximos a vencer</b></h4>
                                            </div>
                                        </b></small>
                                    <div class="bc-text-center bc-margin-top-2">
                                        <b>
                                            <font size=10>{{contractNextBeatenCount}}</font>
                                        </b>
                                    </div>
                                </div>
                            </bc-card-container>
                        </div>
    
                        <div class="bc-col-3 bc-margin-1 bc-border-radius-0-top">
                            <bc-card-container cardType="default" class="custom-card">
                                <div class="bc-padding-3 bc-bg-brand-status-success" title="Contratos con estado publicado y fecha de vencimiento mayor a 90 días">
                                    <small><b>
                                            <div class="bc-text-center">
                                                <h4><b>Contratos vigentes</b></h4>
                                            </div>
                                        </b></small>
                                    <div class="bc-margin-top-2 bc-text-center">
                                        <b>
                                            <font size=10>{{contractInitialCount}}</font>
                                        </b>
                                    </div>
                                </div>
                            </bc-card-container>
                        </div>
    
                    </div>
                </div>
            </ng-container>

            <ng-template #noDashboard>
                <div class="no-solicitudes-message">
                    <img src="../../../assets/images/NoContratos.png" alt="No Solicitudes">
                    <p>¡Oh no! A la fecha no tienes contratos bajo tu responsabilidad. Mantente atento para futuras actualizaciones.
                    </p>
                  </div>
            </ng-template>
                
            </div>
        </div>


        <!--Papá -->
        <div *ngIf="total > 0">

            <!-- Hijo 1-->
            <div class="bc-row bc-padding-4 bc-wrap bc-flex bc-direction-column bc-direction-lg-row">
                <div class="bc-col-12 bc-col-lg-8 ">
                    <!-- Sub Hijo 1.1 -->
                    <div class="TablaFavorito">
                        <bc-table-container class="bc-w-100 ">
                            <bc-table-content>
                                <div class="bc-flex bc-justify-content-space-between bc-p-3 bc-bg-brand-primary-00">
                                    <h5>Contratos favoritos</h5>
                                    
                                </div>
                                <table caption="tabla" bc-table class="bc-p-2">
                                    <thead>
                                        <tr>
                                            <th scope="row" bc-cell type="total"
                                                title="Identificador único del contrato">Id del contrato</th>
                                            <th scope="row" bc-cell type="total" title="Proveedor del contrato">
                                                Proveedor</th>
                                            <th scope="row" bc-cell type="total" title="Familia del contrato">Familia
                                            </th>
                                            <th scope="row" bc-cell type="total" title="Estado actual del contrato">
                                                Estado</th>
                                            <th scope="row" bc-cell type="total"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let contrato of contractFavorite">
                                            <!--Aqui necesito saber que colores combinan bien con el componente de arriba-->
                                            <td>
                                                <div *ngIf="contrato.idContract; then thenBlock else elseBlock"></div>
                                                <ng-template #thenBlock>
                                                    <a [routerLink]="['/contratosNegociador/detallesContrato/', contrato.idContract.idContract]">
                                                        {{contrato.idContract.idContract}}
                                                    </a>
                                                </ng-template>
                                                <ng-template #elseBlock>
                                                    <bc-icon size="xs" name="affinity" aria-hidden="true"
                                                        aria-label="affinity"></bc-icon>
                                                </ng-template>
                                            </td>
                                            <td>{{contrato.idContract.providerEntity.providerName}}</td>
                                            <td>
                                                <ng-container
                                                    *ngIf="contrato.families && contrato.families.length > 0; else noFamilies">
                                                    {{ contrato.families[0].nombre }}
                                                </ng-container>
                                                <ng-template #noFamilies>
                                                    No registra familia
                                                </ng-template>
                                            </td>

                                            <td>
                                                <app-badge-estado *if="contrato.statechange!=null" [clase]="'contrato'"
                                                    [tipo]="'only'"
                                                    [estadoContrato]="contrato.statechange.contractEstatusEntity.status_name.charAt(0).toUpperCase() + 
                                                    contrato.statechange.contractEstatusEntity.status_name.slice(1).toLowerCase()">
                                                </app-badge-estado>
                                            </td>
                                            <td>
                                                <app-fav-icon-button (click)="removeFavorite(contrato)"
                                                    [isFavorite]="contrato ? true : false"
                                                    class="small-star-icon"></app-fav-icon-button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </bc-table-content>
                        </bc-table-container>
                    </div>
                    <!--Sub Hijo 1.2-->
                    <div class="bc-margin-top-3 ProximosAVencer">
                        <bc-table-container class="bc-w-100">
                            <bc-table-content>
                                <div class="bc-flex bc-justify-content-space-between bc-p-3 bc-bg-brand-primary-00">
                                    <h5>Contratos próximos a vencer</h5>
                                </div>
                                <table caption="tabla" bc-table class="bc-p-2">
                                    <thead>
                                        <tr class="">
                                            <th scope="row" bc-cell type="total"
                                                title="Identificador único del contrato">Id del contrato</th>
                                            <th scope="row" bc-cell type="total" title="Proveedor del contrato">
                                                Proveedor</th>
                                            <th scope="row" bc-cell type="total" title="Familia del contrato">Familia
                                            </th>
                                            <th scope="row" bc-cell type="total"
                                                title="Fecha de Vencimiento del contrato">Fecha vencimiento</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let contrato of contractNextFinalDTOList">
                                            <td>
                                                <a [routerLink]="['/contratosNegociador/detallesContrato/', contrato.idContract]"> 
                                                    {{contrato.idContract}}
                                                </a>

                                            <td>
                                                {{contrato.providerName}}
                                            </td>
                                            <td>
                                                {{contrato.familyName}}
                                            </td>
                                            <td>
                                                {{contrato.finalDate | date : "dd/M/yyyy"}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </bc-table-content>
                        </bc-table-container>
                    </div>
                    <!-- Sub hijo 1.3 (Contratos por proveedor) -->
                    <div class="bc-margin-top-3 ContratosXProveedor">
                        <bc-table-container class="bc-w-100">
                            <bc-table-content>
                                <div class="bc-align-left bc-p-3 bc-bg-brand-primary-00">
                                    <h5>Contratos por proveedor</h5>
                                </div>
                                <table caption="tabla" bc-table class="bc-p-2 ">
                                    <thead>
                                        <tr class="">
                                            <th scope="row" bc-cell type="total" title="Nombre del Proveedor">Proveedor
                                            </th>
                                            <th scope="row" bc-cell type="total" title="Total Contratos">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let proveedor of contractByProviderCount">
                                            <td>{{proveedor.providerName}}</td>
                                            <td>{{proveedor.cotractTotal}}</td>
                                        </tr>
                                    </tbody>

                                </table>
                            </bc-table-content>
                        </bc-table-container>
                    </div>
                </div>

                <!--Sub Hijo 1.2-->
                <!-- <div class="bc-d-inline-flex bc-w-100 bc-gap-2 bc-margin-top-2"> -->

                <div class="bc-col-12 bc-col-lg-4">
                    <div>
                        <bc-table-container class="bc-w-100 ">
                            <bc-table-content>
                                <div class="bc-align-left bc-p-3 bc-bg-brand-primary-00">
                                    <h5>Estado de novedades</h5>
                                </div>
                                <table caption="tabla" bc-table class="bc-p-2">
                                    <thead>
                                        <tr class="">
                                            <th scope="row" bc-cell type="total" title="Descripción del reporte">Reporte
                                            </th>
                                            <th scope="row" bc-cell type="total" title="Estado actual del reporte">
                                                Estado</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let item of (requirement.concat(novedad))">
                                            <tr>
                                                <td>{{item.typeRequirement.toLowerCase()}} :
                                                    <a [routerLink]="['/novedadesnegociador']">
                                                        {{item.contractId.idContract}}
                                                    </a>
                                                </td>
                                                <td>
                                                    <ng-container
                                                        *ngIf="item.status === 'Publicado' || item.status === 'Vencido' || item.status === 'Pendiente'">
                                                        <app-badge-estado [clase]="'contrato'"
                                                            [estadoContrato]="item.status"
                                                            [tipo]="'only'"></app-badge-estado>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="item.status === 'PENDIENTE' || item.status === 'CERRADO' || item.status === 'TRAMITADO' || item.status === 'COMPLETADO' || item.status === 'RECHAZADO' || item.status === 'ACEPTADO' || item.status === 'ACTIVO'">
                                                        <app-badge-estado [clase]="'novedad'"
                                                            [estadoAdmin]="item.status"
                                                            [tipo]="'only'"></app-badge-estado>
                                                    </ng-container>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </bc-table-content>
                        </bc-table-container>
                    </div>

                </div>

            </div>

        </div>

    </div>
</div>