<div class="bc-content">
    <bc-page-header [date]='fecha' [title]="tittle" [bgWhite]="false" [className]="'bc-bg-light'">
    </bc-page-header>
</div>

<div  class="bc-container">
    <div bc-card >
        <div class="bc-card-body">
            <div class="bc-container">
                <div class="fondo-dashboard" style="border: 1px solid #2c2a29">
                    <div class="text-center bc-margin-top-6">
                        <h1>¡Revisaremos tu respuesta!</h1>
                    </div>
                    <div class="bc-margin-top-5 text-center" style="background-color: #2c2a29; color: #ffffff">
                        <br>
                        <h5>Has rechazado la solicitud para ser nuevo administrador.  El equipo de la Cadena de Abastecimiento revisará la novedad y, si es necesario, te contactarán para tener claridad sobre el tema.</h5>
                        <br>
                    </div>

                    <br>
                </div>
                <!-- En caso de rechazar -->
                <!-- <img src="../../../../assets/images/aceptar.PNG" alt="" style="width: 100%;"> -->
            </div>
            <div class="bc-margin-top-4 bc-container bc-p-4">
                <div class="bc-row">
                    <!-- Izquierda -->
                    <div class="bc-col-6">
                        <div>
                            <b>Id Contrato</b>
                        </div>
                        <div class="bc-margin-top-1">
                            <b>{{requirement.contractId}}</b>
                        </div>
                    </div>
                    <!-- Derecha -->
                    <div class="bc-col-6">
                        <div>
                            <b>Descripción</b>
                            <br>
                            <p>{{requirement.description}}</p>
                        </div>

                    </div>
                </div>
                <!-- <div class="bc-margin-top-3 bc-col-6">
                    <b>
                        <p>
                            Proveedor
                        </p>
                    </b>

                    <p>{{requirement.provider}}</p>
                </div> -->

                <div class="bc-col-4">
                    <div class="boton">
                        <div class="bc-margin-top-2" style="width: 100%;" routerLink="/solicitudes">
                            <button bc-button>Continuar Con Otra Solicitud</button>
                        </div>
                    </div>

                </div>
                

            </div>
            

        </div>
    </div>
</div>s