import { Injectable, EventEmitter } from '@angular/core';


@Injectable({providedIn: 'root'})
export class SendDataInforamtionServices {

  private _dataGLobal = new EventEmitter<any>();
  get dataGLobal(): EventEmitter<any> {
    return this._dataGLobal;
  };

 
}
