import { Component, OnInit } from '@angular/core';
import { InforamtionServices } from './services/commo-service/information-services';
import { UserGraphServices } from './services/api-services/graph-services/user-graph.services';
import { BcDialogService } from '@bancolombia/design-system-web/bc-services';
import { BcAlertComponent } from '@bancolombia/design-system-web/bc-alert';
import { UserGraphModel } from './models/graph-models/userGraph.model';
import { MsalService } from '@azure/msal-angular';

import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';


declare var gtag: (arg0: string, arg1: string, arg2: { page_path: string; }) => void;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  {

  constructor(private inforamtionServices: InforamtionServices, private userGraphServices: UserGraphServices,private dialogService: BcDialogService, private msalService: MsalService, private router: Router)
  {
    this.getCurrentUser()

    //Subscritor eventos 
    const navEndEvents = router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd)
    );
    
    navEndEvents.subscribe((event: NavigationEnd) => {
      gtag('config', 'G-N5LC0YEML4', {
        'page_path': event.urlAfterRedirects
      });
    });
  }
  
  title = 'Aba';

  private getCurrentUser() {
    if(sessionStorage.getItem('userCurrentGraph') == undefined){
      this.userGraphServices.getCurrenUserGraph().subscribe(
        {
          next: (success: UserGraphModel) => {
            sessionStorage.setItem('userCurrentGraph', JSON.stringify(success))
            
            this.alertAba("success",'Bienvenido: '+ success.displayName, "Correcto", )
          },
          error: (error) => {
            //this.alertAba("error", "No se pudo optener el usuario actual de la aplicacion", "Error")
          } 
        }
      )
    }    
  }

  private alertAba(type: String, menssage: String, tittle: string): void {
    const dialogRef = this.dialogService.open(BcAlertComponent, {
      id: tittle,
      type: type,
      text: menssage,
      timeout: 7000,
    });

    dialogRef.onResult().subscribe(
      (closed) => {},
      (dismissed) => {},
      () => {}
    );
  }

  isLoggedIn() : boolean
  {
    return this.msalService.instance.getActiveAccount() != null
  }
  logout () {
    this.msalService.logout();
  }


}
