import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaffingComponent } from './staffing/staffing.component';
import { CapacityComponent } from './staffing/capacity/capacity.component';
import { DemandComponent } from './staffing/demand/demand.component';
import { BcButtonModule } from '@bancolombia/design-system-web/bc-button';
import { BcCardModule } from '@bancolombia/design-system-web/bc-card';
import { BcCheckboxModule } from '@bancolombia/design-system-web/bc-checkbox';
import { BcInputModule } from '@bancolombia/design-system-web/bc-input';
import { BcInputDateModule } from '@bancolombia/design-system-web/bc-input-date';
import { BcInputSelectModule } from '@bancolombia/design-system-web/bc-input-select';
import { BcOffCanvasModule } from '@bancolombia/design-system-web/bc-off-canvas';
import { BcPageHeaderModule } from '@bancolombia/design-system-web/bc-page-header';
import { BcPaginatorModule } from '@bancolombia/design-system-web/bc-paginator';
import { BcProgressBarWebModule } from '@bancolombia/design-system-web/bc-progress-bar';
import { BcSearchModule } from '@bancolombia/design-system-web/bc-search';
import { BcTableModule } from '@bancolombia/design-system-web/bc-table';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { ComponentsModule } from 'src/app/components/components.module';
import { StaffingModule } from './staffing/staffing.module';
import {BcLoaderModule} from '@bancolombia/design-system-web/bc-loader' 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {BcBtnGroupModule} from '@bancolombia/design-system-web/bc-button-group' 
import {BcIconModule} from '@bancolombia/design-system-web/bc-icon' 
import {BcIconButtonModule} from '@bancolombia/design-system-web/bc-icon-button' 
import { SummaryComponent } from './staffing/summary/summary.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { SumaryComponent } from './staffing/sumary/sumary.component';


@NgModule({
  declarations: [
    StaffingComponent,
    DemandComponent,
    CapacityComponent,  
    SummaryComponent,
    SumaryComponent
  ],
  exports:[
    StaffingComponent,
  ],
  imports: [
    
    CommonModule,
    BcLoaderModule,
    ComponentsModule,
    BcPaginatorModule,
    BcInputSelectModule,BcTableModule,BcCheckboxModule,BcInputModule,
    BcProgressBarWebModule,
    CommonModule,
    BcSearchModule,
    BcPageHeaderModule,
    BcPaginatorModule,
    BcOffCanvasModule,
    BcInputModule,
    AppRoutingModule,
    BcCheckboxModule,
    BcInputDateModule,
    ComponentsModule,
    BcInputSelectModule,
    BcCheckboxModule,
    FormsModule,
    StaffingModule,
    BcCardModule,
    BcButtonModule,
    ReactiveFormsModule,
    BcBtnGroupModule,
    BcIconModule,
    BcIconButtonModule,
    BrowserAnimationsModule,
    NgxChartsModule
  ]
})
export class MarketplaceModule { }
