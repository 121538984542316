<bc-off-canvas #offCanvasComponent [tipoOffcanvas]="'vertical'" [backdrop]="''" >
    <div offCanvasContent >
      <h3 class="bc-text-center">Gestión de requerimientos</h3>
      <br>
      <p>
        Recuerda revisar que la solicitud cumpla con los requisitos establecidos para su gestión:
      </p>
      <br>
      <!--Inputs-->
      <br>
      <h5>Tipo de requerimiento</h5>
      <p>{{tipoRequerimiento}}</p>
      <br>
      <h5>ID Contrato</h5>
      <p>{{idContrato}}</p>
      <br>
      <h5>Recuerda</h5>
      <p>Antes de cerrar el requerimiento revisa que la información se encuentre actualizada en Ariba y en Conectados en
        caso de ser necesario,</p>
      <br>
      
      
      <div class="bc-row">
        <div class="bc-container">
          <div class="bc-row">
            <div class="bc-col-6">
              <label for="selectInput">
                <div class="Diseño-items">
                  <p>
                   Selecciona una opción:
                  </p> 
                </div>
                
              </label>
            </div>
            <div class="bc-col-6 select-container">
              <select id="selectInput" class="custom-select" (change)="onOptionSelected($event)">
                <option value=""></option>
                <option value="CERRADO">Cerrado</option>
              </select>
            </div>
          </div>
        </div>  
          
    </div>
  
      <br>
      <div class="bc-margin-top-2">
        <div>
          <h4>Observaciones Cambio Estado</h4>
        </div>
        <div class="bc-margin-top-2">

          <bc-form-field>
            <input bc-input aria-label="custom-aria-label" type="text" value="" [(ngModel)]="inputData" required id="00" />
          </bc-form-field>
        </div>
        <!-- Alerta para observaciones vacías -->
        <div *ngIf="!inputData" class="bc-margin-top-2">
          <p class="error-message">No se ingresaron observaciones</p>
        </div>
      </div>
  
      <br>
      <br>
      <!-- <button class="bc-button-primary bc-button-default" (click)="onNoveltySend(requirementTypeEnum.REPORTE_DE_NOVEDAD_DE_ESTADO_DE_CONTRATO, requirementStatusEnum.Pendiente)">
        Enviar
      </button> -->
      <br>
      <br>
      <button class="bc-button-secondary bc-button-default" (click)="closeOffCanvas()">
        Cancelar
      </button>
    </div>
  </bc-off-canvas>
  
  <button class="bc-button-primary bc-button-small" (click)="openOffCanvas()">
    {{textButton}}
  </button>