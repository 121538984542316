<bc-modal #modal [customWidth]="'large'" [titleAlign]="'center'"
  [isCancellable]='false' [subTitle]='subTitle' [buttonAlignment]="'vertical'" textBtn="Aceptar" textBtnLeft="Si"
  typeNotify='success'>
  <div modalContent>
    <div>
      <div>
          <img src="../../../../assets/icons/ilustrations/il-process-successful.svg " style="width: 9.5rem;margin-left :auto;margin-right:auto;display: block">
      </div>
    </div>
    <h3 class="bc-text-center">!Novedad Reportada!</h3>
    <p class="bc-text-start">
      La novedad de cambio de delegado fue reportada. El operador de abastecimiento se encargará de actualizar el delegado del contrato.
    </p>
      <p class="bc-text-start">
        Para ver el estado de la novedad, diríjase a “Mis novedades” en el inicio de “Mis contratos”
      </p>
  </div>
</bc-modal>
<br>
<button class="bc-button-primary bc-button-default" (click)="MostrarPopOut()">
  Reportar Novedad
</button>


