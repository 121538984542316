import { Component } from '@angular/core';

@Component({
  selector: 'app-operador-sincronizacion-datos-planta',
  templateUrl: './operador-sincronizacion-datos-planta.component.html',
  styleUrls: ['./operador-sincronizacion-datos-planta.component.css']
})
export class OperadorSincronizacionDatosPlantaComponent {

  
  data: any[] = [
    [
      {
        fechaInicio: '18/12/2022',
        estado :'Publicado',
        usuario:'juserna',
        descripcionCarga:'Lorem ipsum...',
        totalElementos:'12',
        registrosError:'0',

      },
      {
        fechaInicio: '18/12/2022',
        estado :'Publicado',
        usuario:'juserna',
        descripcionCarga:'Lorem ipsum...',
        totalElementos:'12',
        registrosError:'0',
      },
      {
        fechaInicio: '18/12/2022',
        estado :'Publicado',
        usuario:'juserna',
        descripcionCarga:'Lorem ipsum...',
        totalElementos:'12',
        registrosError:'0',
      },
      {
        fechaInicio: '18/12/2022',
        estado :'Publicado',
        usuario:'juserna',
        descripcionCarga:'Lorem ipsum...',
        totalElementos:'12',
        registrosError:'0',
      },
    ],
  ];

}
