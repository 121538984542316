import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Contrato, ContratoLite, Proveedor, Usuario } from 'src/app/models/contratos.interface';
import { ContratosService } from 'src/app/services/api-services/contratos-services/contratos.service';

interface inputSelect {
  label: string;
  value: any;
}

interface filterFormInterface {
  busquedaPalabraClave: string,
  proveedores: string[];
  contratos: string[];
  administradores: string[];
  proxVencer: string[];
  favoritos: boolean;
}
@Component({
  selector: 'app-search-bar-contratos',
  templateUrl: './search-bar-contratos.component.html',
  styleUrls: ['./search-bar-contratos.component.css']
})
export class SearchBarContratosComponent implements OnInit {
  /**
   * Permite indicar el estado (expandido o no) del search bar.
   *
   * @type {boolean}
   * @default false
   * @memberof SearchBarContratosComponent
   */
  isExpanded: boolean = false;

  /**
   * Icon del search bar.
   *
   * @type {('arrow2-down' | 'arrow2-up')}
   * @default 'arrow2-down'
   * @memberof SearchBarContratosComponent
   */
  arrowIcon: "arrow2-down" | "arrow2-up" = "arrow2-down";

  /**
   * Clase que se aplica para expandir el search bar.
   *
   * @type {('bc-content-advanced-search-active' | '')}
   * @default ''
   * @memberof SearchBarContratosComponent
   */
  searchAdvToggleClass: "bc-content-advanced-search-active" | "" = "";

  /**
   * Lista de valores seleccionables en el componente `bc-input_select` de proveedores.
   *
   * @type {{label:string, value: any}[]}
   * @default []
   * @memberof SearchBarContratosComponent
   */
  proveedoresSelectValues: { label: string, value: any }[] = [];

  /**
 * Lista de valores seleccionables en el componente `bc-input_select` de contratos.
 *
 * @type {{label:string, value: any}[]}
 * @default []
 * @memberof SearchBarContratosComponent
 */
  contratosSelectValues: { label: string, value: any }[] = [];


  /**
   * Lista de valores seleccionables en el componente `bc-input_select` de administrador de contratos.
   *
   * @type {{label:string, value: any}[]}
   * @default []
   * @memberof SearchBarContratosComponent
   */
  adminContratosSelectValues: { label: string, value: any }[] = [];

  /**
   * Lista de valores seleccionables en el componente `bc-input_select` para proximo a vencer.
   *
   * @type {{label:string, value: any}[]}
   * @default []
   * @memberof SearchBarContratosComponent
   */
  proxVencerSelectValues: { label: string, value: any }[] = [];

  /**
   * Estado del componente checkbox de contrato favorito.
   *
   * @type {boolean}
   * @default false
   * @memberof SearchBarContratosComponent
   */
  onlyFavCheckStatus: boolean = false;



  private filterForm: filterFormInterface = {
    busquedaPalabraClave: '',
    proveedores: [],
    contratos: [],
    administradores: [],
    proxVencer: [],
    favoritos: false,
  }


  constructor(private contratosService: ContratosService) {
  }

  /**
 * Toggles the state of the advanced search component and updates related class and icon properties.
 * @returns {void}
 */
  toggleExpandSearchAdvance(): void {
    this.isExpanded = !this.isExpanded;
    this.arrowIcon = this.isExpanded ? "arrow2-up" : 'arrow2-down';
    this.searchAdvToggleClass = this.isExpanded ? "bc-content-advanced-search-active" : "";
  }

  // Input select variables
  ngOnInit(): void {
    if (this.contratosService.resultadosContratos.length > 0) {
      this.proveedoresSelectValues = this.getSelectValues(this.contratosService.proveedoresFromResult, 'id', 'nombre');
      this.contratosSelectValues = this.getSelectValues(this.contratosService.resultadosContratos, 'idContrato', 'idContrato');
      this.adminContratosSelectValues = this.getSelectValues(this.contratosService.administradoresFromResult, 'usuarioRed', 'nombre');
      this.proxVencerSelectValues = [{ label: "Si", value: { id: true } }, { label: "No", value: { id: false } }]
    }
  }

  getSelectValues<T extends { [key: string]: any }>(data: T[], idName: keyof T, labelName: keyof T): { label: string, value: any }[] {
    // Obtener valores unicos 
    const uniqueData = data.filter((item: T, index: number) => {
      return data.findIndex((otherItem: T) => otherItem[idName] === item[idName]) === index;
    });

    // Mapear al objeto necesario para cargar el input-select
    const values = uniqueData.map((item: T) => {
      return { label: item[labelName], value: { id: item[idName] } };
    });
    console.log(values);

    return values;
  }

  /**
 * Actualiza los valores seleccionados en un filtro de búsqueda según el parámetro proporcionado.
 *
 * @param {string | string[]} selectedValues - Valores seleccionados en el filtro de búsqueda.
 * @param {'contratos' | 'proveedores' | 'administradores' | 'proxVencer'} param - Parámetro del filtro de búsqueda.
 */
  updateSelectValue(selectedValues: string | string[], param: "contratos" | "proveedores" | "administradores" | "proxVencer") {
    if (Array.isArray(selectedValues) && selectedValues.length) {
      const values = selectedValues.map((item: any) => (item.value.id));
      this.filterForm[param] = values;
    }else{
      this.filterForm[param] = [];
    }
  }

  /**
 * Actualiza "favoritos" del filtro "filterForm" objetos.
 * @param checkedValue - Un boolean que indica cuando "favoritos" esta activada.
 */
  updateCheckFavValue(checkedValue: boolean){
    this.filterForm.favoritos = checkedValue;    
  }

  /**
 * Actualiza el valor del campo de búsqueda por palabra clave.
 * @param {Event} event - El evento que activa la actualización.
 * @returns {void}
 */
  updateBusquedaPalabraClave(event: Event){
    this.filterForm.busquedaPalabraClave = (event.target as HTMLInputElement).value;
  }

  /**
 * Realiza una búsqueda de contratos utilizando los valores del formulario de filtro actual.
 * @returns {void}
 */
  searchContratos(){
    console.log(this.filterForm);
  }

  /**
 * Lista los contratos llamando al método `getContratos()` del servicio `contratosService`.
 * @returns {void}
 */
 
}
