<body>
    <div bc-card>
        <div class="bc-card-body">
            <div class="total-requirement__container bc-col-4">
                <div class="total-requirement__left"></div>
                <div class="bc-col-6 ">
                    <div class="total-header__item">Total requerimientos</div>
                    <div class="total-header__item-number">130</div>
                </div>
                <div class="bc-col-6">
                    <div class="bc-col-12">
                        <div class="total-body__item">En curso</div>
                        <div class="total-body__item-number">33</div>
                    </div>

                    <div class="bc-col-12" style="padding-top: 15px;">
                        <div class="total-body__item">En solicitud</div>
                        <div class="total-body__item-number">10</div>
                    </div>
                </div>
            </div>

            <div class="bc-col-2"></div>
            <div bc-card class="bc-col-2 card-header__items">
                <div class="bc-card-body ">
                    <div class="total-body__item-number">12</div>
                    <div class="total-body__item_cards">Cerrados Estratégicos y apalancables</div>
                </div>
            </div>
            <div bc-card class="bc-col-2 card-header__items">
                <div class="bc-card-body ">
                    <div class="total-body__item-number">0</div>
                    <div class="total-body__item_cards">Cerrados Críticos y No críticos</div>
                </div>
            </div>
            <div bc-card class="bc-col-2 card-header__items">
                <div class="bc-card-body ">
                    <div class="total-body__item-number">0</div>
                    <div class="total-body__item_cards">Congelados</div>
                </div>
            </div>
            <div bc-card class="bc-col-2 card-header__items">
                <div class="bc-card-body ">
                    <div class="total-body__item-number">1</div>
                    <div class="total-body__item_cards">Vencidos</div>
                </div>
            </div>

        </div>
    </div>
    <div class="color-header">
        <h4>Evolución del Marketplace</h4>
    </div>

    <div class="bc-row">
        <div class="bc-col-3">
            <div bc-card>
                
                <div class="bc-card-body" style="height: 450px;">
                    <p>Staffing inicio: 24/09/2021</p>
                    <ngx-charts-bar-vertical-2d 
                    [view]="view" 
                    [scheme]="colorScheme" 
                    [results]="multi" 
                    [xAxis]="true"
                    [showDataLabel]="true" 
                    [groupPadding]="15"
                    [barPadding]="2"
                    [legendPosition]="legendPosition"
                    [legend]="true"
                    (select)="onSelect($event)" 
                    (activate)="onActivate($event)"
                    [legendTitle]="''"
                    (deactivate)="onDeactivate($event)">
                </ngx-charts-bar-vertical-2d>
                </div>
            </div>
        </div>
        <div class="bc-col-9">
            <div bc-card>
                <div class="bc-card-body" style=" height: 450px; margin-left: 17px;">
                    <div class="bc-row">
                        <div class="bc-col-5">
                           
                            <div class="sub-title__componets">
                                Staffing ceremonia anterior 
                                <pre><div class="container--calendar_item">14/34/2923</div> </pre>
                                <bc-icon 
                                    size="sm" 
                                    name="calendar" 
                                    aria-hidden="true"
                                    aria-label="calendar">
                                </bc-icon>
                            </div> 
                            <ngx-charts-bar-vertical-2d 
                                [view]="view" 
                                [scheme]="colorScheme" 
                                [results]="multi" 
                                [xAxis]="true"
                                [showDataLabel]="true" 
                                [groupPadding]="15"
                                [barPadding]="2"
                                [legendPosition]="legendPosition"
                                [legend]="true"
                                (select)="onSelect($event)" 
                                (activate)="onActivate($event)"
                                [legendTitle]="''"
                                (deactivate)="onDeactivate($event)">
                            </ngx-charts-bar-vertical-2d>
                        </div>
                        <div class="bc-col-2">
                            
                        </div>
                        <div class="bc-col-5">     
                            <div class="sub-title__componets">
                                Staffing ceremonia actual
                                <pre><div class="container--calendar_item">14/34/2923</div> </pre>
                                <bc-icon 
                                    size="sm" 
                                    name="calendar" 
                                    aria-hidden="true"
                                    aria-label="calendar">
                                </bc-icon>
                            </div>                       
                            <ngx-charts-bar-vertical-2d 
                                [view]="view" 
                                [scheme]="colorScheme" 
                                [results]="multi" 
                                [xAxis]="true"
                                [showDataLabel]="true" 
                                [groupPadding]="15"
                                [barPadding]="2"
                                [legendPosition]="legendPosition"
                                [legend]="true"
                                (select)="onSelect($event)" 
                                (activate)="onActivate($event)"
                                [legendTitle]="''"
                                (deactivate)="onDeactivate($event)">
                            </ngx-charts-bar-vertical-2d>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bc-row">
        <div class="bc-col-4">
            <div class="color-header">
                <h4>Requerimientos vencidos por Categoría y Negociador</h4>
            </div>
            <div bc-card>
                <div class="bc-card-body">
                    <bc-icon-button 
                        class="bc-col-1"
                        [type]="'solid'"
                        [color]="'yellow'" 
                        [size]="'large'" 
                        [customIcon]="'calendar-edit'" 
                        [ariaLabel]="'Texto Función'" 
                    ></bc-icon-button>
                </div>
            </div>
        </div>
        <div class="bc-col-8">
            <div class="color-header">
                <h4>Volumen de requerimientos vencidos</h4>
            </div>
            <div bc-card>
                <div class="bc-card-body">
                    <bc-icon-button 
                        class="bc-col-1"
                        [type]="'solid'"
                        [color]="'yellow'" 
                        [size]="'large'" 
                        [customIcon]="'calendar-edit'" 
                        [ariaLabel]="'Texto Función'" 
                    ></bc-icon-button>
                </div>
            </div>
        </div>
    </div>
</body>