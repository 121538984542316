import { Component, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-staffing',
  templateUrl: './staffing.component.html',
  styleUrls: ['./staffing.component.css']
})
export class StaffingComponent {

  @Input('data') _pages!: any;
  @Input() tabSelected: EventEmitter<{ name: string,id:number, status: string }> = new EventEmitter<{ name: string,id:number, status: string }>
  
  public selectTab: { name: string,id:number, status: string } = {id: 0,name:"Demanda",status:""}; 

  

  ngOnInit(): void {
    this.tabSelected.subscribe(
      (succes) => this.selectTab = this.selectTab = succes ,
      (error) => console.log("Error ::", error)
    )
  }
  
}
