<div class="centered-container bc-p-3" *ngIf="originalData.length > 0">
    <div class="search" id="searchId">
      <div class="search-content">
        <div class="search-input">
          <div class="form-field">
            <i class="fa fa-search icon">
            </i> 
            <input class="input" id="id-01" required="" name="nombreProducto" type="text" value="" placeholder="Busca Aquí" type="text" [(ngModel)]="searchText" (keyup)="onSearch()" />
          </div>
        </div>
      </div>
    </div>
  </div>



<div class="bc-row bc-p-4 " *ngIf="currentPage.length > 0">
    <div class="bc-col-12">
        <bc-table-container class="bc-col-12 bc-w-100 " *ngIf="currentPage.length > 0" [dataTable]="currentPage">
            <div class="bc-flex bc-justify-content-space-between bc-p-3 bc-bg-brand-primary-00 ">
                <!--Fondo Negro Con Texto Blanco-->
                <h5>Contratos</h5>
                <div class="select">
                    <!-- <p style="text-decoration: none;" (click)="showMoreFavorites()"
                                            title="Ver Contratos Favoritos">ver más</p> -->
                </div>
            </div>
            <bc-table-content>
                <div>
                    <table caption="tabla" bc-table [selection]='false' [scroll]="true" [sort]="true">
                        <thead class="bc-text-center">
                            <tr class="bc-bg-brand-sequential-M-500">
                                <th scope="row" bc-cell type="total" title="Identificador único del contrato">Id del
                                    contrato</th>
                                <th scope="row" bc-cell type="total" title="Ordenar por proveedor del contrato">Proveedor</th>
                                <th scope="row" bc-cell type="total" title="Ordenar por descripción del contrato">Descripción</th>
                                <th scope="row" bc-cell type="total" title="Ordenar por estado actual del contrato">Estado</th>
                                <th scope="row" bc-cell type="total" title="Ordenar por familia">Familia</th>
                                <th scope="row" bc-cell type="total" title="Ordenar por administrador del contrato">Administrador
                                </th>
                                <th scope="row" bc-cell type="total" title="Ordenar por fecha de vencimiento del contrato">Fecha de
                                    fin del contrato</th>
                                <th scope="row" bc-cell type="total" title="Ordenar por vencimiento de tarifa">Fecha vencimiento de
                                    tarifa</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let contract of currentPage" (click)="showDetails(contract)">
                                <td bc-cell>
                                    <a [routerLink]="['/contratosNegociador/detallesContrato/', contract.idContract]">
                                        {{ contract.idContract }}
                                    </a>
                                </td>
                                <td bc-cell>{{ contract.providerEntity?.providerName || 'Proveedor no disponible' }}
                                </td>
                                <td bc-cell>
                                    <span class="description-cell" (mouseenter)="showDescription = true"
                                        (mouseleave)="showDescription = false">
                                        {{ contract.description ? (contract.description | slice:0:30) + '...' :
                                        'Descripción no disponible' }}
                                        <div class="tooltip" *ngIf="showDescription">
                                            {{ contract.description || 'Descripción no disponible' }}
                                        </div>
                                    </span>
                                </td>
                                <td bc-cell>
                                    <app-badge-estado *ngIf="contract" [clase]="'contrato'" [tipo]="'only'"
                                        [estadoContrato]="contract.status ? (contract.status.charAt(0).toUpperCase() + contract.status.slice(1).toLowerCase()) : 'Estado no disponible'"
                                        [title]="contract.status ? getStatusDescription(contract.status) : 'Descripción de estado no disponible'">
                                    </app-badge-estado>
                                </td>
                                <td bc-cell>
                                    <ng-container *ngIf="contract.familyList.length > 0; else noFamily">
                                        {{contract.familyList[0].nombre}}
                                    </ng-container>
                                    <ng-template #noFamily>
                                        No registra familia
                                    </ng-template>
                                </td>
                                <td bc-cell> {{contract.idUserAdmin || 'Administrador no disponible' }}</td>
                                <td bc-cell> {{contract.finalDate ? dateformat(contract.finalDate) : 'Fecha de
                                    vencimiento no disponible'}}</td>
                                <td bc-cell> {{contract.tariffExpiry ? dateformat(contract.tariffExpiry) : 'Fecha de
                                    vencimiento no disponible' }}</td>



                            </tr>

                        </tbody>
                    </table>
                </div>

            </bc-table-content>
            <bc-table-footer>
                <bc-paginator type="full" [totalRegisters]="totalRegisters" [perPage]="perpage"
                    (onChangePage)="onChangePage($event)" style="color: black;">
                </bc-paginator>
            </bc-table-footer>
        </bc-table-container>
    </div>
</div>

<div *ngIf="currentPage.length === 0">
    <div class="no-novedades-message-novedades">
        <div>
            <img src="../../../assets/images/il-sv-process.svg" alt="No Novedades">
        <p>¡Oh no! Parece que no tienes contratos por el momento. Sigue atento para futuras actualizaciones.</p>
        </div>
        
    </div>
</div>