import { Component, Input, OnInit } from '@angular/core';
import { BcDropdownBehavior, BcListBehavior } from '@bancolombia/design-system-behaviors';
import { formatearFechaCorta } from 'src/app/components/utils/formato-fecha.componente';;
import { ContratoLite } from '../../../models/contratos.interface';
import { BcDialogService } from '@bancolombia/design-system-web/bc-services';
import { BcAlertComponent } from '@bancolombia/design-system-web/bc-alert';
import { ContratosService } from 'src/app/services/api-services/contratos-services/contratos.service';
import { IdContract } from 'src/app/models/contract-favorite.model';
import { NavigationError, Router } from '@angular/router';

@Component({
  selector: 'app-list-item-contrato-negociador',
  templateUrl: './list-item-contrato-negociador.component.html',
  styleUrls: ['./list-item-contrato-negociador.component.css']
})
export class ListItemContratoNegociadorComponent {

  //Inputs
  @Input() contract!: any;
  @Input() class?: string;
  public idContract: IdContract[] = [];

  ngOnInit(): void {
    this.router.events.subscribe(event => {
        if (event instanceof NavigationError) {
            // Aquí puedes manejar el error de redirección
            console.error('Error de redirección:', event.error);
            this.alertAba('error', 'Error de redirección', 'Error');
        }
    });

    // Resto del código...
    BcListBehavior.setUpAll();
    BcDropdownBehavior.setUpAll({
        isPopUp: true
    });
}

  dateformat(date: any) {
    return date != null ? formatearFechaCorta(new Date(date)) : ""
  }

  constructor(private dialogService: BcDialogService, private contratosServices: ContratosService, private router: Router) {}


  alertAba(type: String, menssage: String, tittle: string): void {
    const dialogRef = this.dialogService.open(BcAlertComponent, {
      id: tittle,
      type: type,
      text: menssage,
      timeout: 7000,
    });

    dialogRef.onResult().subscribe(
      (closed) => {},
      (dismissed) => {},
      () => {}
    );
  }

 

  verificarYRedireccionar(idContract: string): void {
    // Realiza la petición al backend antes de redirigir
    this.contratosServices.getContractByIdContract(idContract).subscribe(
        (success) => {
            // Si la petición tiene éxito, realiza la redirección
            const url = `/contratosNegociador/detallesContrato/${idContract}`;
            this.router.navigateByUrl(url);
            
        },
        (error) => {
            // Si hay un error, muestra la alerta
            console.error('Error al obtener el contrato:', error);
            this.alertAba('error', `No se puede ingresar al detalle porque no eres el negociador del contrato`, 'Error');
        }
    );
}


  
}
