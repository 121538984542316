import { Component } from '@angular/core';
import { formatearFecha } from 'src/app/components/utils/formato-fecha.componente';
import { NovedadesService } from 'src/app/services/api-services/novedades-services/novedades.service';
import { novedadFilterFormInterface } from '../mis-novedades/search-bar-novedades/novedad-filter.interface';
import { AccordionColumn } from '@bancolombia/design-system-web/bc-accordion';
import { NovedadEstado } from 'src/app/models/novedad.interface';

@Component({
  selector: 'app-operador-contratos-novedades',
  templateUrl: './operador-contratos-novedades.component.html',
  styleUrls: ['./operador-contratos-novedades.component.css']
})
export class OperadorContratosNovedadesComponent {

  //Titulos Header
  tittle = "Novedades Portal";
  fecha: any;

  /**
   * Funcion para mostrar la Hora
   */
  mostrarHora() {
    this.fecha = formatearFecha(new Date());
    setInterval(() => {
      this.fecha = formatearFecha(new Date());
    }, 1000);
  }
 

  ngOnInit() {
    this.mostrarHora();
    // BcAccordionBehavior.setUpAll();
  }

  constructor(private novedadesServices: NovedadesService){
    this.novedadesServices.searchNovedadesByAdmin()
  }

  /**

  Retorna la lista de NovedadEstado.
  @returns {NovedadEstado[]} Lista de NovedadEstado.
  */
  getNovedades(): NovedadEstado[]{
    return this.novedadesServices.resultados
  }

  getAccordionColumns(novedadEstado: NovedadEstado) : Array<AccordionColumn>{
    const cols:Array<AccordionColumn> = [
      {value: `Contrato ${novedadEstado.contrato.idContrato}`, alignLeft: true},
      {value: `${novedadEstado.tipoNovedad.nombre}`},
      {value: `${novedadEstado.historialEstados[0].estado.nombre}`},
    ]

    return cols
  }

  // Search
  objectArrayItem = [
    {
      'nombreProducto': 'Bancolombia ahorros',
      'numeroProducto': '345268',
      'entidad': "Bancolombia S.A",
      'cuenta': "corriente"
    },
    {
      'nombreProducto': 'Cuenta pago',
      'numeroProducto': '023654',
      'entidad': "Bancolombia S.A",
      'cuenta': "ahorro"
    },
    {
      'nombreProducto': 'Bancolombia ahorros',
      'numeroProducto': '7563265',
      'entidad': "Bancolombia S.A",
      'cuenta': "corriente"
    }
  ];
  setDataSelect = (
    {
      selectInput: {
        nameSearch: 'cuenta',
        items: [
          {
            label: 'ahorro',
            value: 'ahorro',
          },
        ],
      },
    });

  arrResultNull: any[] = [];

  printData($event: any) {
    throw new Error('Method not implemented.');
  }
  
  // Accordion
  columns: Array<AccordionColumn> = [
    { value: 'Contrato CW345', alignLeft: true },
    { value: 'Cambio de estado' },
    { value: 'Completado' },
  ];

  dataTable: any[] = [];

}
