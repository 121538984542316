import { Component, Input, OnInit } from '@angular/core';
import { InforamtionServices } from 'src/app/services/commo-service/information-services';

@Component({
  selector: 'alert-aba-component',
  templateUrl: './alert-aba.component.html',
  styleUrls: ['./alert-aba.component.css']  
})
export class AlertABAComponent implements OnInit {
  

  public showAlert: {mensage: String, type: String} = {
    mensage:"" ,type: ''
  }
 
  constructor(private inforamtionServices: InforamtionServices){} 
  ngOnInit() {
    this.inforamtionServices.showInformationAler.subscribe(
      (data)=> this.showAlert = data
    )
  }

  clouseAlsert() {
    this.inforamtionServices.showInformationAler.emit(false);
  }
}

