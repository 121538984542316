import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-solicitudes-pendientes',
  templateUrl: './solicitudes-pendientes.component.html',
  styleUrls: ['./solicitudes-pendientes.component.css']
})
export class SolicitudesPendientesComponent implements  OnInit{
  
  constructor(){}

  ngOnInit(): void {

   
  }
}
