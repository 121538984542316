<div class="color-header">
    <h4>Capacidad equipo negociador</h4>
</div>
<div class="bc-row">
    <!-- Primera Parte, la tabla de la izquierda -->
    <div class="bc-col-8">
        <div class="bc-row">
            <div class="izquierda_borde">
                <div bc-card class="bc-col-12">
                    <div class="bc-card-body card-body__widgth">
                        <table>
                            <thead>
                                <tr>
                                    <th>Categoria</th>
                                    <th>Negociador</th>
                                    <th>Cantidad de proyectos</th>
                                    <th>Capacidad asignada</th>
                                    <th>Tipo de analista</th>
                                </tr>
                            </thead>
                            <div class="bc-row" *ngFor="let item of dataCapacity">
                                <h5 class="card-board__negotiator bc-col-2 center-text__container">{{item.category}}
                                </h5>
                                <div class="bc-col-10">
                                    <table class="table-content table-position">
                                        <div class="interno_borde">
                                            <tbody>
                                                <tr *ngFor="let element of item.items">
                                                    <td class="position-item__table">
                                                        <h5>{{element.negotiator}}</h5>
                                                    </td>
                                                    <td>
                                                        <h5 style="text-align: center">{{element.projectCount}}</h5>
                                                    </td>
                                                    <td>
                                                        <h5>
                                                            <div class="progres_bar">
                                                                <div class="progrest-bar__number">
                                                                    {{converterNumber(element.capacity)}} %
                                                                </div>
                                                                <div class="progres-bar__load" [ngStyle]="{
                                                                width: element.capacity +'%',
                                                                backgroundColor: colorFuntion(element.capacity),}">
                                                                </div>
                                                            </div>
                                                        </h5>
                                                    </td>
                                                    <td>
                                                        <h5 style="text-align: center">1</h5>
                                                    </td>
                                            </tbody>
                                        </div>
                                    </table>
                                </div>
                            </div>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Segunda Parte Drecha -->
    <div class="bc-col-4 ">
        <div bc-card class="card-plant-position__container">
            <div>
                <div class="card-plant-content__header">
                    <header class="card-plant-content">
                        <h5>Deficit de planta Bancolombia</h5>
                    </header>
                </div>
                <div class="bc-row ">
                    <div class="bc-col-4">
                        <div class="card-plant-content__left">
                            <p class="text_items">Total FTE:</p>
                            <p class="text_items">1</p>
                        </div>
                    </div>
                    <div class="bc-col-5">
                        <div class="progress-circular__content">
                            <bc-progress-bar [value]="0" [type]="'semi-circle'" [diameter]="200" [color]="'green'"
                                [stroke]="'20'">
                            </bc-progress-bar>
                            <div class="progress-circular__item">
                                <h3>0 %</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="bc-row">
                    <h5 class=" bc-align-left bc-col-6">Categoria</h5>
                    <h5 class=" bc-align-ringht center-text__container bc-col-6">Deficit en horas</h5>
                    <br>
                    <p class="bc-card-subtitle bc-align-left bc-col-6">Apoyo al negocio</p>
                    <p class="bc-card-subtitle bc-align-ringht center-text__container bc-col-6">0</p>
                    <br>
                    <p class="bc-card-subtitle bc-align-left bc-col-6">Canales y medidas de pago</p>
                    <p class="bc-card-subtitle bc-align-ringht center-text__container  bc-col-6">0</p>
                    <br>
                    <p class="bc-card-subtitle bc-align-left bc-col-6">Procesos del negocio</p>
                    <p class="bc-card-subtitle bc-align-ringht center-text__container  bc-col-6">0</p>
                    <br>
                    <p class="bc-card-subtitle bc-align-left bc-col-6">Seguridad</p>
                    <p class="bc-card-subtitle bc-align-ringht center-text__container bc-col-6">0</p>
                    <br>
                    <p class="bc-card-subtitle bc-align-left bc-col-6">Transfomacion TI</p>
                    <p class="bc-card-subtitle bc-align-ringht center-text__container bc-col-6">0</p>
                    <br>
                    <p class="bc-card-subtitle bc-align-left bc-col-6">Nequi</p>
                    <p class="bc-card-subtitle bc-align-ringht center-text__container bc-col-6">0</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="color-header">
    <h4>Capacidad equipo Nequi</h4>
</div>