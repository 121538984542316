import { Component } from '@angular/core';

@Component({
  selector: 'app-oppen-supply',
  templateUrl: './oppen-supply.component.html',
  styleUrls: ['./oppen-supply.component.css']
})
export class OppenSupplyComponent {

}
