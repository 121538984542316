import { Component, Input, ViewChild } from '@angular/core';
import { BcOffCanvasComponent } from '@bancolombia/design-system-web/bc-off-canvas';
import { Contrato, ContratoLite } from 'src/app/models/contratos.interface';
import { Estado, TipoNovedad } from 'src/app/models/novedad.interface';
import { AntiguoAdministrador, NuevoAdministrador} from 'src/app/models/cambio-administrador.interface';
import { FormBuilder, FormControl } from '@angular/forms';

import { BcAlertComponent } from '@bancolombia/design-system-web/bc-alert';
import { BcDialogService } from '@bancolombia/design-system-web/bc-services';
import { ContratosService } from 'src/app/services/api-services/contratos-services/contratos.service';
import { EstadoServicesService } from 'src/app/services/estado-services/estado-service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { NovedadesService } from 'src/app/services/api-services/novedades-services/novedades.service';
import { RequirementService } from 'src/app/services/api-services/requirement-services/requierement.service';
import { UserService } from 'src/app/services/api-services/user-services/user.service';
import { SendDataInforamtionServices } from 'src/app/services/commo-service/sendData-services';
import { BcModalComponent } from '@bancolombia/design-system-web/bc-modal';
import { RequirementStatusEnum } from 'src/app/enums/reporte-estado.enum';
import { RequirementTypeEnum } from 'src/app/enums/reporte-novedad.enum';
import { SaveRequirementModel } from 'src/app/models/requirement.model';
import { AzureAdpPresenter } from 'src/app/presenters/azure-adp/azureAdp.presenter';
import { EventServiceService } from 'src/app/services/EventService/event-service.service';
import { UserGraphServices } from 'src/app/services/api-services/graph-services/user-graph.services';

interface Option {
  value: string;
  label: string;
}


@Component({
  selector: 'app-offcanvas-novedades-negociador-solicitud',
  templateUrl: './offcanvas-novedades-negociador-solicitud.component.html',
  styleUrls: ['./offcanvas-novedades-negociador-solicitud.component.css']
})
export class OffcanvasNovedadesNegociadorSolicitudComponent {

  @Input() idContrato: string | undefined;
   @Input() tipoRequerimiento: string | undefined;

  selectedOption: string | null = null;
  @Input() contrato: any;  
  public userGraphData: any;
  public userGrapFilter: any[] = [];
  public formMeetAdminInputs: any= {};
  public inputAdministratorObservation: string = "";
  public showModal = true;
  public valuesTemp: any = {};
  private azureAdpPresenter = new AzureAdpPresenter(this.userGraphServices)
  protected currentUser: any;
  public inputData: any;


  @Input('requierement') requierement!: any;


  reportarNovedadEstado() {
    if (this.selectedOption) {
      this.estadoService.setEstadoSeleccionado(this.selectedOption);
    }
  }

  constructor(private estadoService: EstadoServicesService, private eventService: EventServiceService,
    private route: ActivatedRoute,private userGraphServices: UserGraphServices,
    private contratosService: ContratosService,private formBuilder: FormBuilder,private dialogService: BcDialogService,
    private requirementService: RequirementService) {}

  

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const contractId = params.get('id') ;
      if (contractId !== null) {
      this.contratosService.getContractByIdContract(contractId).subscribe(data => {
        this.requierement = data.data;       
      });
    }
    });
    this.currentUser = JSON.parse(sessionStorage.getItem('userCurrentGraph') || '{}');

  }
  onOptionSelected(event: any) {

    const value = event.target.value; 
    if (value === "") {
    this.selectedOption = null; // Establece en null si no se selecciona ninguna opción
    this.alertAba("error", "No se seleccionó ninguna opción", "Error");
    return; // Detiene la función aquí si no se selecciona ninguna opción.
  }
    this.selectedOption = value;
    this.requierement.selectedOption = value; // Asignar la opción al objeto contractDetail
    this.estadoService.setEstadoSeleccionado(value);
  }

  
  
  dateSelector = new FormControl();
   @Input() textButton!: string;

   
  @ViewChild('offCanvasComponent',
    {
      static: true,
    })
    /*
    *Muestra la configuracion del Canvas 
    */    
  bcOffCanvasComponent: BcOffCanvasComponent | any;
  bcModalComponent: BcModalComponent | any;
  tipoOffcanvas = '';
  backdrop = '';
  alturaHorizontal = '';
  backdrops = [
    {
      label: 'Activo',
      value: '',
    },
    {
      label: 'Apagado',
      value: 'off',
    },
  ];
  alturasHorizontal = [
    {
      label: '90vh',
      value: '90vh',
    },
    {
      label: '50vh',
      value: '50vh',
    },
    {
      label: '40vh',
      value: '40vh',
    },
  ];
  /*
  *Abre el offcanvas.
  */
  openOffCanvas() {
    this.bcOffCanvasComponent.openOffCanvas();
  }

  /*
  *Cierra el offcanvas.
  */
  closeOffCanvas() {
    this.bcOffCanvasComponent.closeOffCanvas(null);
  }
  options: Option[] = [
    { value: 'Publicado', label: 'Publicado' },
    { value: 'Vencido', label: 'Vencido' },
    { value: 'Borrador', label: 'Borrador' },
    { value: 'Modificación del borrador', label: 'Modificación del borrador' },
    { value: 'Congelado', label: 'Congelado' },
    { value: 'Pendiente', label: 'Pendiente' },
  ];


  public onNoveltySend(requerimentType : any,requirementStatus : any ){

    if (!this.selectedOption) {
      this.alertAba("error", "No se pudo enviar, debido a que no se ingresó ninguna opción", "Error");
      return; // Detiene la función aquí si no se selecciona ninguna opción.
    }

    if (!this.inputData) {
      this.alertAba("error", "No se pudo enviar, debido a que no se ingresaron observaciones", "Error");
      return; // Detiene la función aquí si no se ingresan observaciones.
    }

    const saveData: SaveRequirementModel = {
      emailReques: this.currentUser.mail,
      idContract: this.requierement.contractId.idContract,
      observation: this.inputData,
      requirementType:requerimentType,
      requirementStatus: requirementStatus,

      
    }
    this.requirementService.saveRequirement(saveData).subscribe(
      {
        next: (response) => {
          this.alertAba("success","La novedad de cambio de estado fue reportada","Correcto")
        },
        error: (error) => {
          this.alertAba("error","La novedad de cambio de estado no se pudo procesar","Error")
        },
        
      }

    )
  }

  public selectSearchedUser(data: any) {
    this.formMeetAdminInputs = {
      administratorName: null,
      administatorEmail: null,
      administratorId:null ,
      administratorObservation: "Cambio de estado a generico",
      adminsitratorInitialDate: null, // Asegúrate de usar el nombre correcto aquí
      adminsitratosFinalDate: null 
    }

    this.userGrapFilter = [];
  }
  

  
  

  public get requirementTypeEnum() :typeof RequirementTypeEnum{
    return RequirementTypeEnum;
  }

  public get requirementStatusEnum() : typeof RequirementStatusEnum{
    return RequirementStatusEnum;
  }

  private alertAba(type: String, menssage: String, tittle: string): void {
    const dialogRef = this.dialogService.open(BcAlertComponent, {
      id: tittle,
      type: type,
      text: menssage,
      timeout: 7000,
    });

    dialogRef.onResult().subscribe(
      (closed) => {},
      (dismissed) => {},
      () => {}
    );
  }
  

}



