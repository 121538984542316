<div
    class="bc-padding-2 bc-flex bc-flex-fill bc-align-self-stretch bc-justify-content-space-between bc-align-self-center bc-bg-white">
    <div >
        <p><b>{{titulo}}</b></p>

        <ng-container *ngIf="fecha && fecha !== null; else noFecha">
            <!-- Aplica el formato de fecha aquí -->
            <p>{{fecha | date:'dd/MM/yyyy'}}</p>
        </ng-container>

        <ng-template #noFecha>
            fecha no disponible
            <br>
        </ng-template>


        
        <!-- <div class="botones-detalles-container">
            <div *ngIf="mostrarAlerta && (contract.status == 'PUBLICADO' || contract.status == 'VENCIDO')" class="referenciacion-oppensupply">
                <button (click)="abrirEnNuevaPestana()" class="boton-detalles">Gestione el cierre aquí</button>

            </div>
            <div *ngIf="(color === 'bc-text-brand-sequential-AL-500' || color === 'bc-text-brand-sequential-M-500') && mostrarAlerta && (contract.status == 'PUBLICADO' || contract.status == 'VENCIDO')" class="referenciacion-oppensupply">
                <button class="boton-detalles" onclick="window.open('https://apps.powerapps.com/play/e/030e1206-2356-eb46-b90a-be6ab6655b0d/a/6bfa0aec-46a8-4327-acce-636e0e88d198?tenantId=b5e244bd-c492-495b-8b10-61bfd453e423&hint=cfce1c92-2a51-4251-a1cf-6904ce8b1696&sourcetime=1702648802109&source=portal')">Gestione la renovación aquí</button>
            </div>
        </div> -->
        
        
        <!-- Agregar el span condicional solo para ciertos estados -->
    </div>

    
    <div *ngIf="mostrarAlerta && (contract.status == 'PUBLICADO' || contract.status == 'VENCIDO') " class="bc-flex bc-direction-column bc-align-self-center bc-align-right {{color}}">
        <!-- ToDo: Cambiar a componente parametrizable -->
        <bc-icon size="md" name={{icon}} aria-hidden="true" aria-label="calendar"></bc-icon>
        <p><b>{{tituloAlerta}}</b></p>

    </div>
</div>