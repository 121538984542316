import { Component, EventEmitter } from '@angular/core';



export enum TabDictionariMarkePlace {
  STAFFING   = "Staffing",
  SUMMARIZES = "Summarizes",
}


@Component({
  selector: 'markeplace_page',
  templateUrl: './markeplace.component.html',
  styleUrls: ['./markeplace.component.css']
})
export class MarkeplacePage {

  public nameTab = TabDictionariMarkePlace.STAFFING;
  public tabListItems: { name: string; status: string; id: number; }[] = [
    {
      name: "Demanda",
      status: "active",
      id: 1
    },
    {
      name: "Capacidad",
      id: 2,
      status: "unactive"
    },
    {
      name: "Resumen",
      id: 3,
      status: "unactive"
    },
  ]

  public tabSelected: EventEmitter<{ name: string,id:number, status: string }> = new EventEmitter<{ name: string,id:number, status: string }>


  public onSelectItemTab(tabDictionariMarkePlace: TabDictionariMarkePlace){
    this.nameTab = tabDictionariMarkePlace;
  }

  public get TabDictionariMarkePlace(): typeof TabDictionariMarkePlace {
    return TabDictionariMarkePlace; 
  }
}
