

<div class="bc-row">
    <div class="bc-col-12">    
        <!--app-capacity></app-capacity-->  
        <div *ngIf="selectTab.name == 'Demanda'">
            <app-demand></app-demand>
        </div>
        <div *ngIf="selectTab.name == 'Capacidad'">
            <app-capacity></app-capacity>
        </div>
    </div>
</div>



