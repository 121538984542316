import { Component, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BcModalComponent } from '@bancolombia/design-system-web/bc-modal';
import { BcOffCanvasComponent } from '@bancolombia/design-system-web/bc-off-canvas';
import { PopoutComponent } from '../popout/popout.component';

@Component({
  selector: 'app-reportar-novedad-portal',
  templateUrl: './reportar-novedad-portal.component.html',
  styleUrls: ['./reportar-novedad-portal.component.css']
})
export class ReportarNovedadPortalComponent {
  SaberSi: number | null = 10;
  ReporteNo: number | null = 10;
   
  dateSelector = new FormControl();
  @Input() textButton!: string;

  @ViewChild('offCanvasComponent', { static: true })
  bcOffCanvasComponent!: BcOffCanvasComponent;
  
  bcModalComponent: BcModalComponent | any;
  PopOutComponent: PopoutComponent | any;
  
  tipoOffcanvas = '';
  backdrop = '';
  alturaHorizontal = '';
  
  backdrops = [
    {
      label: 'Activo',
      value: '',
    },
    {
      label: 'Apagado',
      value: 'off',
    },
  ];
  
  alturasHorizontal = [
    {
      label: '90vh',
      value: '90vh',
    },
    {
      label: '50vh',
      value: '50vh',
    },
    {
      label: '40vh',
      value: '40vh',
    },
  ];
  
  /* Abre el offcanvas */
  openOffCanvas() {
    this.bcOffCanvasComponent.openOffCanvas();
  }
  
  /* Cierra el offcanvas */
  closeOffCanvas() {
    this.bcOffCanvasComponent.closeOffCanvas(null);
  }

  resetRadioValues() {
    this.SaberSi = null;

  }
  resetRadioValuesNo() {
    this.ReporteNo = null;

  }
}
