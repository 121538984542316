<bc-tabs-group #myTab>
    <bc-tab [labelTemplate]='tab4'>
        <ng-template #tab4>
            <p>Estados de carga</p>
        </ng-template>
        <div class='row'>
            <div class='col-12'>
                <app-tabla-operador-estados-carga [data]="data"></app-tabla-operador-estados-carga>
            </div>
        </div>
    </bc-tab>
    <bc-tab [labelTemplate]='tab5'>
        <ng-template #tab5>
            <p>Sincronización de datos</p>
        </ng-template>
        <div class='row'>
            <div class='col-12'>
                <H5>2</H5>
            </div>
        </div>
    </bc-tab>
</bc-tabs-group>