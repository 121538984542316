<div class="bc-content">
    <bc-page-header [date]='fecha' [title]="tittle" [bgWhite]="false" [className]="'bc-bg-light'">
    </bc-page-header>
</div>

<div class="bc-container">
    <div bc-card>
        <div class="bc-card-body">
          <h6 class="bc-card-title bc-align-left ">¿Quién es administrador de contratos en Bancolombia?</h6>
          <p class="bc-card-subtitle bc-align-left bc-margin-top-2">El administrador es el representante de Bancolombia ante un proveedor, quien posee las capacidades y competencias humanas, técnicas y operativas necesarias para responder ante la organización por la consecución o logro de los objetivos propuestos; asegurando que los niveles de servicio y operación se ajusten a los valores definidos contractualmente.
            Es un rol con un nivel de responsabilidad asociada al control y la mitigación de los riesgos a los que se expongan el servicio, el proceso y el proveedor; y que contribuye de forma directa al cumplimiento del marco normativo del entorno de control.</p>
          <p class="bc-card-subtitle-number bc-align-left bc-margin-top-2">En esta oportunidad has sido elegido como el nuevo administrador del contrato:</p>
          <div class="bc-container-24 bc-margin-top-2">
            <div class="bc-row">
                <!-- Izquierda -->
              <div class="bc-col-6">
                <div>
                    <b>Id Contrato</b>
                </div>
                <div class="bc-margin-top-1">
                <b>Cw22234241</b>
                </div>
              </div>
              <!-- Derecha -->
              <div class="bc-col-6">
                <div>
                    <b>Proveedor</b>
                </div>
                <div class="bc-margin-top-1">2</div>
              </div>
            </div>
          </div>
          <div class="bc-margin-top-2">
            <h5>Descripción del proyecto</h5>
            <br>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
          </div>
          <div class="bc-margin-top-2">
            <h5>Esta solicitud fue asignada por:</h5>
            <p>Nombre Persona</p>
          </div>
          <div class="bc-margin-top-2">
            <b>
                <p>Al asumir este rol debes tener en cuenta la siguiente información:</p>
            </b>
          </div>
          <div align = "center "class="bc-margin-top-2">
            <img src="../../../../assets/images/Rol_Admin.jpg">
          </div>
          <div class="bc-margin-top-2">
            <b>
                <p>{{ userData?.user?.nombre }}</p>
            </b>
          </div>
          <div class="bc-margin-top-2">
            <p>Si necesitas mayor información sobre tu rol como administrador y responsabilidades del mismo puedes consultarlo a través de nuestro sitio “Administrador de contratos” aquí</p>
          </div>
          <div class="bc-container bc-margin-top-2">
            <div class="bc-row">
              <div class="bc-col-xs-1 bc-col-sm-1 bc-col-md-4 bc-col-lg-5 bc-col-xl-1 bc-col-xxl-7"></div>
              <div class="bc-col-xs-3 bc-col-sm-2 bc-col-md-2 bc-col-lg-3 bc-col-xl-2 bc-col-xxl-2">
                <button bc-button>Aceptar</button>
          
              </div>
              <div class="bc-col-xs-1 bc-col-sm-2 bc-col-md-2 bc-col-lg-1 bc-col-xl-2 bc-col-xxl-2">
                <button bc-button>Rechazar</button>
          
              </div>
            </div>
          </div>

          <div class="bc-card-link bc-align-center">
          </div>
        </div>
      </div> 
</div>