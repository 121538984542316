<bc-off-canvas #offCanvasComponent [tipoOffcanvas]="'vertical'" [backdrop]="''">
  <div offCanvasContent>
    <h3 class="bc-text-center">Reporte novedad de administrador</h3>
    <br>
    <div class="Diseño-items">
      <p>
        En esta opción podrás reportar la novedad de cambio de administrador del contrato o asignación de administrador
        temporal en caso de que vayas a presentar una ausencia. Al seleccionar enviar novedad estarás enviando la
        solicitud para que cadena de abastecimiento realice la gestión correspondiente.
      </p>
    </div>
    <br>
    <!--Inputs-->

    <div class="bc-radio-group">
      <label class="bc-radio-label">
        <div class="Diseño-items">
          <p>
            No soy el administrador
          </p>
        </div>
       
        <input type="radio" id="No Soy El Administrador" [(ngModel)]="ReporteNo" name="default" value="0" role="radio"
          tabindex="0" aria-checked="false" checked="checked" (change)="resetRadioValues(); formMeetAdminInputs = {}" />
        <span class="bc-radio"></span>
      </label>
      <br>
      <!-- <label class="bc-radio-label">
        Asignar un adminsitrador temporal por motivo de ausencia
        <input type="radio" [(ngModel)]="ReporteNo" id="Asignar Un Temporal" name="default" value="1" role="radio"
          tabindex="0" aria-checked="false" checked="checked" (change)="resetRadioValues(); formMeetAdminInputs = {}" />
        <span class="bc-radio"></span>
      </label> -->
    </div>
    <br>
    <!--Asignar un Temporal -->

    <!-- <div class="bc-container" *ngIf="ReporteNo == 1">
      <br>
      <div>
        <h5>Digite La Información requerida</h5>
        <br>
        <div>
          <bc-form-field>
            <input id="01" bc-input aria-label="text-area" type="text" name="administratorName" [ngModel]="formMeetAdminInputs.administratorName" 
              >
            <label for="02">Nombre del administrador temporal (Debes seleccionar un cargo de jefe)*</label>
          </bc-form-field>

  

          <br>
          <bc-form-field>
            <input id="02" bc-input aria-label="text-area" type="text" [(ngModel)]="formMeetAdminInputs.administatorEmail"
              type="text" name="administatorEmail" required>
            <label for="02">Correo del administrador temporal *</label>
          </bc-form-field>
          <br>



          <bc-form-field>
            <div>
              <bc-form-field>
                <em>calendar</em>
                <input bc-input typeInput="input-default-left" id="idInput2" type="date" name="adminsitratorInitialDate"
       [(ngModel)]="formMeetAdminInputs.adminsitratorInitialDate" required />
                <em class="bc-input-select-toggle">arrow2-down</em>
                <label for="idInput2">Fecha Inicio *</label>
                <span>Selecciona una fecha del calendario</span>
              </bc-form-field>
            </div>
          </bc-form-field>
          <br>
          <div>
            <bc-form-field>
              <em>calendar</em>
              <input bc-input typeInput="input-default-left" id="idInput3" type="date" name="adminsitratosFinalDate"
       [(ngModel)]="formMeetAdminInputs.adminsitratosFinalDate" required />
              <em class="bc-input-select-toggle">arrow2-down</em>
              <label for="idInput2">Fecha Fin *</label>
              <span>Selecciona una fecha del calendario</span>
            </bc-form-field>
          </div>
        </div>
        <br>

      </div>

    </div> -->
    <!--Caso no soy el administrador-->
    <div class="bc-container" *ngIf="ReporteNo == 0">
      <h5>¿Sabes quién es el administrador del contrato? </h5>
      <br>
      <div class="bc-radio-group">
        <label class="bc-radio-label">
          <div class="Diseño-items">
            Si
          </div>
          <input type="radio" id="Se quien es el administrador" [(ngModel)]="SaberSi"
            name="Se quien es el administrador" value="2" role="radio" tabindex="0" aria-checked="false"
            checked="checked" />
          <span class="bc-radio"></span>
        </label>
        <br>
        <label class="bc-radio-label">
          <div class="Diseño-items">
            No
          </div>
          <input type="radio" [(ngModel)]="SaberSi" id="No se quien es el administrador"
            name="No se quien es el administrador" value="3" role="radio" tabindex="0" aria-checked="false"
            checked="checked" />
          <span class="bc-radio"></span>
        </label>
        <br>
      </div>
    </div>
    <!-- Caso no soy el administrador y se quien es-->

    <form>
      <div class="bc-container bc-margin-top-2 " *ngIf="SaberSi == 2">
        <h5>Digite la información requerida</h5>
        <br>
        <div>
          <bc-form-field>
            <div class="bc-margin-top-2">
              <input id="01" bc-input aria-label="text-area" [(ngModel)]="formMeetAdminInputs.administratorName" type="text"
              name="administratorName" autocomplete="off">
            <label for="01">
              <div class="Diseño-items">
                <small>
                  Nombre del administrador*
                </small>
              </div>
            </label>
            </div>
          </bc-form-field>
          

          <!-- <div *ngIf="userGrapFilter.length > 0" class="auto-complete__boddy">
            <li *ngFor="let userFilter of userGrapFilter" (click)="selectSearchedUser(userFilter)">
              <a>{{userFilter.displayName}}</a>
            </li>
          </div> -->

          <br>
          <bc-form-field>
            <input id="02" bc-input aria-label="text-area" type="text" [(ngModel)]="formMeetAdminInputs.administatorEmail"
              type="text" name="administatorEmail" required>
            <label for="02">
              <div class="Diseño-items">
                <small>
                  Correo del administrador*
                </small>
              </div>
            </label>
          </bc-form-field>
          <br>
          <bc-form-field>
            <input id="03" bc-input aria-label="text-area" [ngModel]="formMeetAdminInputs.administratorObservation"
              type="text" name="administratorObservation" type="text" autocomplete="off">
            <label for="03">
              <div class="Diseño-items">
                <small>
                  Observaciones
                </small>
              </div>
            </label>
          </bc-form-field>
        </div>
        <br>
      </div>
    </form>


    <!-- Caso no soy el adminsitrador y no se quien es-->
    <div class="bc-container" *ngIf="SaberSi == 3">
      <br>
      <div>
        <div class="Diseño-items">
          <p>
            Haz indicado que no eres el administrador del contrato y tampoco sabes quién, por lo cual el equipo de
            abastecimiento se encargará de asignar el administrador correspondiente. Te estaremos notificando mediante
            correo electrónico la confirmación del cambio.
          </p>
        </div>
      </div>

      <br>
    </div>

    <button class="bc-button-primary bc-button-primary" *ngIf="ReporteNo == 1"
      (click)="onNoveltySend(requirementTypeEnum.REPORTE_NOVEDAD_TEMPORAL_ADMINISTRADOR, requirementStatusEnum.Pendiente)">
      Reportar novedad
    </button>

    <button class="bc-button-primary bc-button-primary" *ngIf="SaberSi == 2"
      (click)="onNoveltySend(requirementTypeEnum.REPORTE_NOVEDAD_CON_ADMINISTRADOR, requirementStatusEnum.Pendiente)">
      Reportar novedad
    </button>

    <button class="bc-button-primary bc-button-primary" *ngIf="SaberSi == 3"
      (click)="onNoveltySend(requirementTypeEnum.REPORTE_NOVEDAD_SIN_ADMINISTRADOR, requirementStatusEnum.Pendiente)">
      Reportar novedad
    </button>

    <!--app-popout (click)="onNoveltySend()" [show] = "showModal" ></app-popout-->
    <br>
    <br>
    <button class="bc-button-secondary bc-button-default" (click)="closeOffCanvas()">
      Cancelar
    </button>
  </div>

</bc-off-canvas>
<button class="bc-button-primary bc-button-small" (click)="openOffCanvas()">
  {{textButton}}
</button>