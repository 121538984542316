import { Injectable } from '@angular/core';
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { PublicClientApplication, AuthenticationResult, InteractionType } from '@azure/msal-browser';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { msalConfig } from 'src/app/core/auth-config';
import { HttpClient } from '@angular/common/http';

import { environment } from "src/environments/environment";
import { apiPaths } from '../api-services/api-services-paths';

/**
 * Servicio de autenticación que maneja las operaciones relacionadas con la autenticación y autorización
 * utilizando la librería MSAL (Microsoft Authentication Library) y Azure AD (Active Directory).
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private msalInstance: PublicClientApplication;
  private userDataSubject = new Subject<any>();

  constructor(
    private http: HttpClient,
  ) {
    // Crear una instancia de PublicClientApplication con la configuración proporcionada en msalConfig.
    this.msalInstance = new PublicClientApplication(msalConfig);
  }

  /**
   * Obtener la instancia de MSAL (PublicClientApplication) utilizada para manejar la autenticación.
   * @returns {PublicClientApplication} Instancia de MSAL (PublicClientApplication).
   */
  public getMsalInstance(): PublicClientApplication {
    return this.msalInstance;
  }

  /**
   * Obtener la configuración del interceptor MSAL para proteger los recursos.
   * @returns {MsalInterceptorConfiguration} Configuración del interceptor MSAL.
   */
  public getMSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set(environment.apiMicrosoft, ['user.read']);
    protectedResourceMap.set(environment.apiUrl, ['user.read']);

    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap
    };
  }

  /**
   * Obtener la configuración del guard MSAL para la autenticación.
   * @returns {MsalGuardConfiguration} Configuración del guard MSAL.
   */
  public getMSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: ['user.read']
      },
      loginFailedRoute: '/error'
    };
  }

}
