import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EstadoServicesService {

  private estadoSeleccionado: string | null = null;

  setEstadoSeleccionado(estado: string | null) {
    this.estadoSeleccionado = estado;
    console.log('Estado seleccionado actualizado:', this.estadoSeleccionado);
  }

  getEstadoSeleccionado(): string | null {
    console.log('Estado seleccionado obtenido:', this.estadoSeleccionado);
    return this.estadoSeleccionado;
  }

  obtenerEstadoSeleccionado(): string | null {
    console.log('Estado seleccionado obtenido:', this.estadoSeleccionado);
    return this.estadoSeleccionado;
  }

  private estadoRequerimientos: any[] = [];

  setEstadoRequerimientos(estado: any[]) {
    this.estadoRequerimientos = estado;
    console.log('Estado de requerimientos actualizado:', this.estadoRequerimientos);
  }

  getEstadoRequerimientos(): any[] {
    console.log('Estado de requerimientos obtenido:', this.estadoRequerimientos);
    return this.estadoRequerimientos;
  }

  
}
