import { Component, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BcOffCanvasComponent } from '@bancolombia/design-system-web/bc-off-canvas';
import { Contrato, ContratoLite } from 'src/app/models/contratos.interface';
import { Estado, TipoNovedad } from 'src/app/models/novedad.interface';
import { AntiguoAdministrador, NuevoAdministrador} from 'src/app/models/cambio-administrador.interface';
@Component({
  selector: 'app-off-canvas-operador-porta-novedad',
  templateUrl: './off-canvas-operador-porta-novedad.component.html',
  styleUrls: ['./off-canvas-operador-porta-novedad.component.css']
})
export class OffCanvasOperadorPortaNovedadComponent {

  //Variables cambio de vista
  SaberSi = 10;
  ReporteNo = 10;
  
  dateSelector = new FormControl();
  @Input() textButton!: string;


  @ViewChild('offCanvasComponent',
    {
      static: true,
    })
  bcOffCanvasComponent: BcOffCanvasComponent | any;
  tipoOffcanvas = '';
  backdrop = '';
  alturaHorizontal = '';
  backdrops = [
    {
      label: 'Activo',
      value: '',
    },
    {
      label: 'Apagado',
      value: 'off',
    },
  ];
  alturasHorizontal = [
    {
      label: '90vh',
      value: '90vh',
    },
    {
      label: '50vh',
      value: '50vh',
    },
    {
      label: '40vh',
      value: '40vh',
    },
  ];
  /*Abre el offcanvas.*/
  openOffCanvas() {
    this.bcOffCanvasComponent.openOffCanvas();
  }
  /*Cierra el offcanvas.*/
  closeOffCanvas() {
    this.bcOffCanvasComponent.closeOffCanvas(null);
  }

  //Novedad
  novedad: TipoNovedad = {
    id: 0,
    nombre: 'Cambio de administrador de contrato'
  }

  //CambioAdministrador
  antiguoadministrador : AntiguoAdministrador = {
    nombre: 'AGUDELO CASTILLO CESAR ANDRES',
    correo: 'CAAGUDE@BANCOLOMBIA.COM.CO',
    cargo: 'ANALISTA'
  }

  nuevoadministrador : NuevoAdministrador = {
    nombre: 'ELIZABETH OROZCO VARGAS',
    correo: 'ELIZA@BANCOLOMBIA.COM.CO',
    cargo: 'ANALISTA'
  }

  
  //Input SELECT

  showSelectedValue(value: string) {
  }
  //Variables 
  status: any;
  selector = new FormControl();
  formatType = 'simple';

  /**

  Representa el objeto estado con un ID y un Nombre
  @typedef {Object} Estado
  @property {number} id - Id del estado
  @property {string} nombre - Nombre del Estado.
  */
    /**
     * Instancia del objeto estado con un Id de 0 y con el valor de Resuelto
      @type {Estado}
    */
  estado: Estado = {
    id: 0,
    nombre: "Resuelto"
  };

  estado2:Estado =
  {
    id:2,
    nombre: "En espera"
  }


  //Contratos

  /**

  Representa un contrato.
  @typedef {Object} Contrato
  @property {string} idContrato - El ID del contrato.
  @property {Object} administrador - El administrador del contrato.
  @property {string} administrador.usuarioRed - El nombre de usuario en red del administrador.
  @property {string} administrador.nombre - El nombre completo del administrador.
  @property {string} administrador.correo - El correo electrónico del administrador.
  @property {Object} negociador - El negociador del contrato.
  @property {string} negociador.usuarioRed - El nombre de usuario en red del negociador.
  @property {string} negociador.nombre - El nombre completo del negociador.
  @property {string} negociador.correo - El correo electrónico del negociador.
  @property {Object} proveedor - El proveedor del contrato.
  @property {string} proveedor.id - El ID del proveedor.
  @property {string} proveedor.nombre - El nombre del proveedor.
  @property {string} proveedor.nit - El número de identificación tributaria del proveedor.
  @property {string} proveedor.nombreComercial - El nombre comercial del proveedor.
  @property {string} proveedor.correoComercial - El correo electrónico del contacto comercial del proveedor.
  @property {string} proveedor.contactoComercial - El número de teléfono del contacto comercial del proveedor.
  @property {Object} estado - El estado del contrato.
  @property {number} estado.idEstado - El ID del estado.
  @property {string} estado.estado - El nombre del estado.
  @property {Array<Object>} contratosHijos - Los contratos hijos del contrato.
  @property {string} contratosHijos.idContrato - El ID del contrato hijo.
  @property {Object} contratosHijos.proveedor - El proveedor del contrato hijo.
  @property {string} contratosHijos.proveedor.id - El ID del proveedor del contrato hijo.
  @property {string} contratosHijos.proveedor.nombre - El nombre del proveedor del contrato hijo.
  @property {Object} contratosHijos.estado - El estado del contrato hijo.
  @property {number} contratosHijos.estado.idEstado - El ID del estado del contrato hijo.
  @property {string} contratosHijos.estado.estado - El nombre del estado del contrato hijo.
  @property {string} contratosHijos.nombre - El nombre del contrato hijo.
  @property {Object} contratoPadre - El contrato padre del contrato.
  @property {string} contratoPadre.idContrato - El ID del contrato padre.
  @property {Object} contratoPadre.proveedor - El proveedor del contrato padre.
  @property {string} contratoPadre.proveedor.id - El ID del proveedor del contrato padre.
  @property {string} contratoPadre.proveedor.nombre - El nombre del proveedor del contrato padre.
  @property {Object} contratoPadre.estado - El estado del contrato padre.
  @property {number} contratoPadre.estado.idEstado - El ID del estado del contrato padre.
  @property {string} contratoPadre.estado.estado - El nombre del estado del contrato padre.
  @property {string} contratoPadre.nombre - El nombre del contrato padre.
  @property {string} nombre - El nombre del contrato.
  @property {string} descripcion - La descripción del contrato.
  @property {Date} fechaInicio - La fecha de inicio del contrato.
  */
  contrato: Contrato = {
    idContrato: "CW102",
    administrador: {
      usuarioRed: "rafa",
      nombre: "Rafael Muñoz",
      correo: "rafa@bancolombia.com.co",
    },
    negociador: {
      usuarioRed: "pepe",
      nombre: "Jose Perez",
      correo: "pepe@bancolombia.com.co",
    },
    proveedor: {
      id: "ACM120",
      nombre: "IBM",
      nit: "9999999-9",
      nombreComercial: "Pedro Nel Osorio",
      correoComercial: "posorio@gmv.com.co",
      contactoComercial: "3003458757",
    },
    estado: {
      idEstado: 1,
      estado: "Borrador",
    },
    contratosHijos: [
      {
        idContrato: "CW567",
        proveedor: {
          id: "ACM120",
          nombre: "IBM",
          nit: "9999999-9",
        },
        estado: {
          idEstado: 1,
          estado: "Modificación del borrador",
        },
        nombre: "Licencias de software",
      },
      {
        idContrato: "CW567",
        proveedor: {
          id: "ACM120",
          nombre: "IBM",
          nit: "9999999-9",
        },
        estado: {
          idEstado: 1,
          estado: "Congelado",
        },
        nombre: "Licencias de software",
      },
      {
        idContrato: "CW567",
        proveedor: {
          id: "ACM120",
          nombre: "IBM",
          nit: "9999999-9",
        },
        estado: {
          idEstado: 1,
          estado: "Pendiente",
        },
        nombre: "Licencias de software",
      }
    ],
    contratoPadre: {
      idContrato: "CW100",
      proveedor: {
        id: "ACM120",
        nombre: "IBM",
        nit: "9999999-9",
      },
      estado: {
        idEstado: 1,
        estado: "Publicado",
      },
      nombre: "Licencias de software",
    },
    nombre: "Licencias IBM DataStage",
    descripcion: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae ab temporibus enim pariatur id iusto ullam officia dolores, ratione quaerat, veniam dolor deserunt ex? Sed animi consequatur debitis eaque distinctio.  Autem minus ad nam incidunt libero vitae eaque unde deleniti delectus assumenda dicta, omnis soluta ullam, corrupti laudantium quae officiis corporis harum. Amet quia illo, possimus id tempore quam temporibus?",
    fechaInicio: new Date("2020-01-15"),
    fechaFinal: new Date("2024-01-15"),
    vencimientoTarifa: new Date("2022-10-17"),
    tipoAcuerdo: "Contrato marco",
    areaResponsable: "Abastecimiento Bancolombia",
    familia: "Canales digitales",
    proximoVencer: false,
    esFavorito: true,
    tipoVigencia: "Indefinido",
  };
}
