import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { UserService } from 'src/app/services/api-services/user-services/user.service';
import { AuthService } from 'src/app/services/authServices/auth.service';
import { environment } from 'src/environments/environment';

/**
 * Componente de encabezado que muestra la información del usuario autenticado.
 * Implementa el ciclo de vida OnInit y contiene métodos para obtener las iniciales de un nombre.
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  userData: any; // Aquí almacenaremos los datos del usuario autenticado

  // Variable Menu
  iniciales: string = ""; // Inicializar la variable en vacío

  menuVisible = false;
  ariaLabelogo = 'Bancolombia';
  ariaLabelChannelName = 'Nombre largo del canal de transacciones';
  userDataAvailable = false;
  tittle =JSON.parse(sessionStorage.getItem('userCurrentGraph') || '{}').displayName ;

  /**
   * Constructor del componente HeaderComponent.
   * @param {UserService} userService - Servicio para obtener los datos del usuario autenticado.
   */
  constructor(private userService: UserService, private authService : AuthService, private msalService: MsalService) { }

  /**
   * Método del ciclo de vida de Angular OnInit.
   * Se ejecuta una vez que el componente ha sido inicializado.
   * Aquí se obtienen los datos del usuario autenticado desde el UserService y se almacenan en la variable userData.
   */

  ngOnInit(): void {
    this.userService.getUserDataSubject().subscribe((userData) => {
      this.userData = userData.data;
      this.userDataAvailable = true;
    });

    
  }


  /**
   * Devuelve las iniciales de un nombre como una cadena en mayúsculas.
   * @param {string} name - El nombre del que se obtendrán las iniciales.
   * @returns {string} Las iniciales del nombre en mayúsculas.
   */
  getIniciales(name: string): string {
    const words = name.split(' ');
    let iniciales = '';
    words.forEach((word) => {
      if (word.length > 0) {
        iniciales += word[0];
      }
    });
    return iniciales.toUpperCase();
  }

  firstOptions1 = [
    {id: '01', mainText: ' Cerrar sesión', iconLeft: 'quit'},
  ]


  logout() {
    this.msalService.logoutRedirect({postLogoutRedirectUri : environment.postLogoutUrl});
  }





}
