import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BcTableActionButton, BcTableOptionMenu } from '@bancolombia/design-system-behaviors/lib/behaviors/organisms/bc-table/bc-table-interface';
import { ContratosService } from 'src/app/services/api-services/contratos-services/contratos.service';
import { formatearFechaCorta } from 'src/app/components/utils/formato-fecha.componente';
import { NavigationExtras, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { selectorContractList } from 'src/app/state/contract_state/contract.selector';
import { UserService } from 'src/app/services/api-services/user-services/user.service';

@Component({
  selector: 'app-tabla-contratos',
  templateUrl: './tabla-contratos.component.html',
  styleUrls: ['./tabla-contratos.component.css']
})
export class TablaContratosComponent {

  currentPageIndex: number = 0;
  itemsPerPage: number = 200;


  userData: any;
  userDataAvailable = false;

  selectedContract: any;
  showDescription: boolean = false;


  // ... (existing code)
  // Method to show the contract details in the off-canvas
  showDetails(contract: any) {
    console.log("Contract recibido:", contract); // Verifica si el contrato se recibe correctamente.
  
    if (contract && contract.contractEstatusEntity) {
      this.selectedContract = contract; // Asignar el contrato seleccionado a la variable
    } else {
      console.log("Error: Contract o contractEstatusEntity es nulo o indefinido.");
    }
  }
  

  closeOffCanvas() {
    this.selectedContract = null; // Limpiar el contrato seleccionado
  }

  @Input('data') _pages!: any;
  totalRegisters: any;
  perpage: any = 200;
  permultiple: string = "200";

  constructor(private contratosService: ContratosService,
    private store: Store<any>,
    private location: Location,
    private router: Router,
    private userService: UserService) {}

  //Table
  // ngOnInit(): void {
  //   this.currentPage = this._pages.contratos;
  //   this.totalRegisters = this._pages.total_pages;
  //   console.log(this.currentPage);

  //   this.tableAccCurrentPage = this.movements[0];    
  // }

  originalData: any[] = [];

  ngOnInit(): void {

    this.originalData = this._pages.data;
    
    this.userService.getUserDataSubject().subscribe((userData) => {
      this.userData = userData.data;
      this.userDataAvailable = true;
      console.log(this.userData.rol);
    });

    let showMoreDashboard: any[] = [];
    this.store.select(selectorContractList).subscribe(
      (success)=>{
        
        showMoreDashboard = success;
      }, (error)=> console.log("Error;; ", error) 
    )
    
    if(showMoreDashboard.length === 0 ){
      if (this._pages && this._pages.data) {
        this.currentPage = this._pages.data;
      } else {
        // Manejo de error o asignar un valor predeterminado
        this.currentPage = [];
      }
      this.totalRegisters = this._pages.count;
      this.tableAccCurrentPage = this.movements[0];
    }else{
      console.log("Data esta:: ", this.currentPage)
      this.currentPage=showMoreDashboard;
    }
    

    console.log("data:::: ", this.currentPage)
    console.log("Data del componente:", this._pages); // Verifica si `_pages` tiene la información esperada.
    console.log("Contrato seleccionado:", this.selectedContract); // Verifica si `selectedContract` tiene la información esperada.
    console.log("Estado del contrato:", this.selectedContract?.contractEstatusEntity); // Verifica si `contractEstatusEntity` tiene la información esperada.
    
  }

  movements: any[] = [[], [], []];
  tableAccCurrentPage = [];
  tableAccCurrentPageEmpty = [];
  dataActionClicked: any = null;
  title: string = "Contratos"
  pages: Array<any> = [];
  currentPage: any[] = [];

  //Funcion para habilitar boton "Cambio Administrador" al selecionar una o varias filas
  actions: BcTableActionButton[] = [
    { icon: 'download-document', text: 'Cambiar Administrador', id: 'cambiar' },
  ];

  //Funcion para traer data a la tabla segun las opciones de paginacion
  onChangePage(data: any) {

    
    const page = parseFloat(data.CurrentPage) -1; // Obtiene la página actual seleccionada
    this.perpage = data.PerPage // Obtiene el numero de registros por pagina y lo almacena

    // Llama al método getContratos del servicio para obtener la nueva data
    this.contratosService.getContratos(page, this.perpage).subscribe({
      next: (response) => {
        this.currentPage = response.data.data; // Asigna la nueva data a currentPage
        this.totalRegisters = response.data.count; // Actualiza el número total de registros
      },
      error: (error) => {
        console.error(error);
      },
    });
  }
  dateformat(date: any) {
    return date != null ? formatearFechaCorta(new Date(date)) : ""
  }





  searchText: string = '';

  onSearch() {
    const searchValue = this.searchText.trim().toLowerCase();
  
    if (!searchValue) {
      // Si el campo de búsqueda está en blanco, muestra todos los elementos originales
      this.currentPage = this.originalData.slice(this.currentPageIndex * this.itemsPerPage, (this.currentPageIndex + 1) * this.itemsPerPage);
    } else {
      this.currentPage = this.originalData.filter((contract: any) => {
        // Convertir todas las propiedades del contrato a cadena y buscar en ellas
        const contractString = JSON.stringify(contract).toLowerCase();
        return contractString.includes(searchValue);
      });
  
      this.totalRegisters = this.currentPage.length;
      this.currentPageIndex = 0;
    }
  }
  
  
  
  



  getStatusDescription(statusName: string): string {
    switch (statusName.toLowerCase()) {
      case 'publicado':
        return 'El contrato ya está listo y entró en vigencia';
      case 'vencido':
        return 'la fecha de expiración del contrato se hizo efectiva y no se ha gestionado su renovación o cierre';
      case 'borrador':
        return 'Se creó el proyecto (CW) y se encuentra en proceso de legalización contractual';
      case 'modificacion del borrador':
        return 'El contrato entró en vigencia pero tiene tareas pendientes para publicar';
      case 'congelado':
        return 'Contrato que se encuentra en espera';
      case 'pendiente':
        return 'El contrato está listo y está en espera para inicio de vigencia';
      default:
        return 'Contrato finalizado y en fuera de servicio';
    }
  }

  reloadPage(contractId: number) {
    const navigationExtras: NavigationExtras = {
      // Puedes agregar opciones de navegación aquí si es necesario
    };
  
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      return this.router.navigate(['contratos/detallesContrato', contractId], navigationExtras);
    });
  }

  redirectToDetails(contractId: number): void {
    this.router.navigate(['/contratos/detallesContrato', contractId]);
}

}
