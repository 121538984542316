import { outputAst } from '@angular/compiler';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { InforamtionServices } from 'src/app/services/commo-service/information-services';


export default interface TabInterface {
  name: string,id:number, status: string
}

@Component({
  selector: 'tab-aba-component',
  templateUrl: './tab-aba.component.html',
  styleUrls: ['./tab-aba.component.css']  
})
export class TabABAComponent implements OnInit {

  @Output() selectTab: EventEmitter<TabInterface> = new EventEmitter<TabInterface>();
  @Input() items: TabInterface[] = []
  
  ngOnInit() {}

  public onSelect (data: TabInterface) {
    this.items.map( (i)=> {
        if(i.id == data.id){
          this.selectTab.emit(data)
          i.status = "active";
        }else{
          i.status = "unactive";
        }
      }      
    )
  }


}

