import { createReducer, on } from '@ngrx/store';
import { CONTRACTITEM, CONTRACTITEMS, CONTRACTLOAD} from './contract.action';
import { ItemState } from './contract.state';

export const initialState: ItemState = {
    item: {},
    items: []
};

export const contractReducer = createReducer(
  initialState,
  on(CONTRACTITEM, (state,paylod) => {
    return {...state,item: paylod.item}
  }),
  on(CONTRACTITEMS, (state,paylod) => {   
    return {...state, items: paylod.items} 
}),
  on(CONTRACTLOAD, (state) => state),
);