<div class="bc-padding-2 bc-flex bc-flex-fill bc-align-self-stretch bc-justify-content-space-between bc-align-self-center bc-bg-white">
    <div>
        <p><b>{{titulo}}</b></p>
        <ng-container *ngIf="fecha && fecha !== null; else noFecha">
            <!-- Aplica el formato de fecha aquí -->
            <p>{{fecha | date:'dd/MM/yyyy'}}</p>
        </ng-container>
        
        <ng-template #noFecha>
            fecha no disponible
        </ng-template>
        
        
       
    </div>
    <div *ngIf="mostrarAlerta && (contract.status == 'PUBLICADO' || contract.status == 'VENCIDO')" 
        class="bc-flex bc-direction-column bc-align-self-center bc-align-right {{color}}">
        <!-- ToDo: Cambiar a componente parametrizable -->
        <bc-icon size="md" name={{icon}} aria-hidden="true" aria-label="calendar"></bc-icon>
        <p><b>{{tituloAlerta}}</b></p>

    </div>
</div>
