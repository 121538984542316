<div class="card-body">
  <div class="center-container">
    <div bc-card>
      <div class="Header_Card">
        <div class="bc-card">
          <div class="bc-card-body">
            <div class="Header_Login">
              <h1 class="bc-card-title">ABA</h1>
              <h4 class="bc-card-subtitle bc-margin-top-3">Iniciar Sesión</h4>
              <p class="bc-card-subtitle">Acceso Administrativo</p>
              <div class="row">
                <div class="bc-col-6 bc-margin-top-4">
                  <!-- Contenido izquierdo -->
                  <div class="login-container">
                    <form (ngSubmit)="submitLoginForm()" #loginForm="ngForm">
                      <div class="form-group">
                        <label for="username">Nombre de usuario:</label>
                        <input type="text" id="username" name="username" [(ngModel)]="username" required>
                      </div>
                      <div class="form-group">
                        <label for="password">Contraseña:</label>
                        <input type="password" id="password" name="password" [(ngModel)]="password" required>
                      </div>
                      <button type="submit" [disabled]="!loginForm.valid">Iniciar sesión</button>
                    </form>
                  </div>
                                  
    
                </div>
                <div class="col-6">
                  <!-- Contenido derecho -->

                  <div >
                    <h4>Ingresar con El Directorio Activo </h4>
                  </div>
                  <div class="bc-margin-top-4">
                    <button class="bc-button-primary bc-button-small" routerLink="./detallesContrato">
                      Ingresar con SSO
                  </button>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>            
        </div>
      </div>
    </div>
  </div>
</div>
