import { Component, OnInit } from '@angular/core';

import { animation, state, style, trigger } from '@angular/animations';
import { BrowserModule } from '@angular/platform-browser';
import { LegendPosition, NgxChartsModule } from '@swimlane/ngx-charts';
import { multi } from '../sumary/data';


export default interface GroupCategory {
  category: string;
  items:  {
    negotiator: string;
    projectCount: number;
    capacity: number;
    analystType: number;
  }[]
}

@Component({
  selector: 'app-sumary',
  templateUrl: './sumary.component.html',
  styleUrls: ['./sumary.component.css'],
  animations: [
    trigger('animacionCuadro', [
      state('estado1', style({
        backgroundColor: 'red'
      })),
      state('estado2', style({
        backgroundColor: 'blue'
      }))
    ])
    
  ]

})
export class SumaryComponent {

  ngOnInit(): void {
    //throw new Error('Method not implemented.');
  }

  public multi: any[] = multi;
  public viewInicio: [number, number] = [500, 300];
  public viewAnterior: [number, number] = [350, 300];
  public viewActual: [number, number] = [350, 300];
  public legendPosition = LegendPosition.Below



  colorScheme: any = {
    domain: ['#4AA1FF', '#F6863C', '#47D7AC']
  };

  constructor() {
    Object.assign(this, { multi })
  }

 onSelect(data: any): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data: any): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data: any): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

}
