import { Component, Input } from '@angular/core';
import { NovedadEstado } from 'src/app/models/novedad.interface';

@Component({
  selector: 'app-operador-novedad-accordion',
  templateUrl: './operador-novedad-accordion.component.html',
  styleUrls: ['./operador-novedad-accordion.component.css']
})
export class OperadorNovedadAccordionComponent {
  @Input('novedad') novedad!: NovedadEstado; 
  dataTable: any[] = [];

}
