import { Component } from '@angular/core';

@Component({
  selector: 'app-novedad-delegado',
  templateUrl: './novedad-delegado.component.html',
  styleUrls: ['./novedad-delegado.component.css']
})
export class NovedadDelegadoComponent {

}
