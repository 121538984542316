<div class="bc-content bc-container-solid bc-bg-light">
    <!-- <div class="Encabezado">
        <img src="../../../../assets/images/Cabezotes-novedades.png">
    </div> -->
    <bc-page-header [date]='fecha' [title]="tittle" [bgWhite]="false" [className]="'bc-bg-light'">
    </bc-page-header>

    <br>

    <div class="bc-padding-4 bc-margin-bottom-6">
        
        <div>
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a class="nav-link" (click)="selectTab(1)" [class.active]="selectedTab === 1">Estado de carga</a>
              </li>

              <li class="nav-item">
                <a class="nav-link" (click)="selectTab(2)" [class.active]="selectedTab === 2">Sincronización de datos</a>
              </li>

              <li class="nav-item">
                <a class="nav-link" (click)="selectTab(3)" [class.active]="selectedTab === 3">Carga de datos</a>
              </li>

            </ul>
          </div>
          <div class="tab-content">

            <div *ngIf="selectedTab === 1" class="tab-pane fade show active">
              
                <bc-table-container class="bc-col-12 bc-w-100" *ngIf="currentPage.length > 0" [dataTable]="currentPage">
                    <bc-table-header title="Carga de datos en ABA" (actionClicked)="onClickAction($event)">
                      <bc-paginator type="full" (onChangePage)="onChangePageTableScroll($event)"></bc-paginator>
                    </bc-table-header>
                    <bc-table-content>
                      <table caption="tabla" bc-table>
                        <thead>
                          <tr>
                            <th scope="row" bc-cell type="total">ID carga</th>
                            <th scope="row" bc-cell type="total">Fecha de carga</th>
                            <th scope="row" bc-cell type="total">Estado</th>
                            <th scope="row" bc-cell type="total">usuario</th>
                            <th scope="row" bc-cell type="total">Descripción de la carga</th>
                            <th scope="row" bc-cell type="total">Total elementos cargados</th>
                            <th scope="row" bc-cell type="total">Registros con error</th>
                            <th scope="row" bc-cell type="total">Descargar inconsistencias</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let account of currentPage">
                            <td bc-cell>{{ account.name }}</td>
                            <td bc-cell>{{ account.lastname }}</td>
                            <td bc-cell>{{ account.email }}</td>
                            <td bc-cell>{{ account.status }}</td>
                            <td bc-cell>{{ account.status }} 1</td>
                            <td bc-cell>{{ account.status }} 2</td>
                            <td bc-cell>{{ account.status }} 3</td>
                            <td bc-cell>Descargar</td>
                          </tr>
                        </tbody>
                      </table>
                    </bc-table-content>
                    <bc-table-footer>
                      <bc-paginator type="full"(onChangePage)="onChangePageTableScroll($event)"
                       ></bc-paginator>
                    </bc-table-footer>
                  </bc-table-container>

            </div>

            <div *ngIf="selectedTab === 2" class="tab-pane fade">
                <bc-table-container class="bc-col-12 bc-w-100" *ngIf="currentPage.length > 0" [dataTable]="currentPage">
                    <bc-table-header title="Sincronización de datos en ABA" (actionClicked)="onClickAction($event)">
                      <bc-paginator type="full" (onChangePage)="onChangePageTableScroll($event)"></bc-paginator>
                    </bc-table-header>
                    <bc-table-content>
                      <table caption="tabla" bc-table>
                        <thead>
                          <tr>
                            <th scope="row" bc-cell type="total">ID carga</th>
                            <th scope="row" bc-cell type="total">ID Contrato</th>
                            <th scope="row" bc-cell type="total">Estado</th>
                            <th scope="row" bc-cell type="total">Tipo de reporte</th>
                            <th scope="row" bc-cell type="total">Administrador anterior</th>
                            <th scope="row" bc-cell type="total">Adminitrador actual</th>
                            <th scope="row" bc-cell type="total">Fecha de validez</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let account of currentPage">
                            <td bc-cell>{{ account.name }}</td>
                            <td bc-cell>{{ account.lastname }}</td>
                            <td bc-cell>{{ account.email }}</td>
                            <td bc-cell>{{ account.status }}</td>
                            <td bc-cell>{{ account.status }} 1</td>
                            <td bc-cell>{{ account.status }} 2</td>
                            <td bc-cell>{{ account.status }} 3</td>
                          </tr>
                        </tbody>
                      </table>
                    </bc-table-content>
                    <bc-table-footer>
                      <bc-paginator type="full"(onChangePage)="onChangePageTableScroll($event)"
                       ></bc-paginator>
                    </bc-table-footer>
                  </bc-table-container>
            </div>


            <div *ngIf="selectedTab === 3" class="tab-pane fade">
             PENDIENTE POR REQUERIMIENTOS DEL CHJAT BOT
            </div>

          </div>
          
    </div>
</div>