<div class="bc-content bc-container-solid bc-bg-light bc-p-5">
	<div class="color-header">
		<h1>Staffing Markeplace</h1>
		<p class="text-header__markeplace">Dirección Cadena de Abastecimiento</p>
	</div>

	<!--div class="bc-flex bc-direction-xs-row bc-padding-2 bc-flex-fill">
		<div class="bc-col-xs-2 bc-col-md-2 bc-col-lg-1 bc-col-xl-4 bc-col-xxl-1 mouse">
			<h4 (click)="onSelectItemTab(TabDictionariMarkePlace.STAFFING)">Staffing</h4>
		</div>
		<div class="bc-col-sm-3 bc-col-md-4 mouse">
			<h4 (click)="onSelectItemTab(TabDictionariMarkePlace.SUMMARIZES)">Tablero Resumen</h4>
		</div>
	</div>
	
	<div class="tab_select">
		<div  [ngClass]="{'staffing-selec__tab': nameTab == 'Staffing','summarizes-selec__tab': nameTab == 'Summarizes'}"></div>
	</div-->

	<tab-aba-component [items]="tabListItems" (selectTab) = "tabSelected.emit($event)"></tab-aba-component>
			
	<markeplace_staffing [tabSelected] = "tabSelected"></markeplace_staffing>
</div>