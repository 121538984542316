import { Component, OnInit } from '@angular/core';
import { TableNameItem } from 'src/app/components/utils/enums/MarkeplaceEnum';
import SelectTableColumn from '../models/SelectTableColum.model';
import {mockMarkeplace} from '../models/mockMarkeplace';
import { BcTableActionButton, BcTableOptionMenu } from '@bancolombia/design-system-behaviors';

@Component({
  selector: 'app-demand',
  templateUrl: './demand.component.html',
  styleUrls: ['./demand.component.css']
})
export class DemandComponent implements OnInit {

  totalRegisters: any = 0;
  perpage: any = 10;
  permultiple: string = "10";


  itemsPerPage = 5; // Número de elementos por página
  data:any = []; // Tu conjunto de datos

  cellOption: BcTableOptionMenu[] = [
    {text:"joser",action: ()=> "jose",  }
  ]

  

  public get markeplaceEnum (): typeof TableNameItem { return TableNameItem; }
  public proveedoresSelectValues: { label: string, value: any }[] = [];
  public requesProgresSelectValues: { label: string, value: any }[] = [];
  public showTable = false;
  public currentPage: any;
  public dataInput: any = {};

  actions: BcTableActionButton[] = [
    { icon: 'download-document', text: 'Cambiar Administrador', id: 'cambiar' },
  ];
  
  public onChangePage(data: any) {
    this.data = mockMarkeplace.response;
    const startIndex = (data["CurrentPage"] - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    const pageData = this.data.slice(startIndex, endIndex);
    console.log("Response:::: ", this.data)
    this.currentPage = pageData;
  }

  ngOnInit(): void {    
    this.loadData();
    this.initLoadTable();
    const dataTable = {
      "TotalRegisters": this.totalRegisters,
      "CurrentPage": 1,
      "PerPage": 10
    }
    this.onChangePage(dataTable);
  }

  loadData () {
    this.totalRegisters = mockMarkeplace.data.length
  }


  

  onSelectTableColumn (selected: any, ) {
    const data = selected as { label: string, value: any }[]; 
    data.map((r:{ label: string, value: any })=> r.value =true);
    const items: any[] = Object.keys(this.selectTableColumn);
    
    items.map( i=> {
      const response = data.find((item) => i == item.label)
      if(response !== undefined){
        this.selectTableColumn[i] = true;
      }else{
        this.selectTableColumn[i] = false;
      }
    })
  }

  

  initLoadTable () {
    const items: any[] = Object.keys(this.selectTableColumn);
    items.map(i => {
      this.proveedoresSelectValues.push({label: i,value: false})
    })
    for(let data of ["En curso", "Solicitud"]){
      this.requesProgresSelectValues.push({label:data, value:data})
    }
  }

  public selectTableColumn : SelectTableColumn | any = {
    //IDFORM            : true,
    //STATE             : true,
    //NAME              : false,
    REQUEST           : false,
    //REQUESTRUN        : true,
    DESCRIPTION       : false,
    BADGET            : false,
    MONEY             : false,
    PROVIDER          : false,
    TIPOLOGY          : false,
    REQUIREMENTLEGALY : false,
    PRIORITIZE        : false,
    ROL               : false,
    TYPEPROCES        : false,
    DAYDURATION       : false,
  }

  public selectItemCheckbox (data: any) {
    console.log(data)
    this.dataInput = data
  }


}