<div class="bc-p-3 bc-bg-white">
    <div class="bc-container">
        <div class="bc-row">
            <div class="bc-col-6">


              <div class="bc-container">
                <div class="bc-col-2">
                  <bc-icon  size="3xl"  name="user-form" [ariaHidden]="false" ariaLabel="name"></bc-icon>
                </div>
                <div class="Diseño-items">
                  <div class="bc-col-8">
                    <b>
                      <span>
                        Id del contrato
                      </span>
                    </b>
                  </div>   
                  <div class="bc-col-4">
                      <p>{{novedad.contractId.idContract}}</p>
                  </div>
                </div>
              </div>

              <br>
              <br>
              <!-- Fecha Radicacion -->
              <div class="bc-container">
                <div class="bc-col-2">
                  <bc-icon  size="3xl"  name="calendar-check" [ariaHidden]="false" ariaLabel="name"></bc-icon>
                </div>
                <div class="Diseño-items">
                  <div class="bc-col-8">
                    <b>
                      <span>
                        Fecha de radicación
                      </span>
                    </b>
                  </div>   
                  <div class="bc-col-4">
                    <p> {{novedad.initialDate | date:'dd-MM-yyyy'}}</p>
                  </div>
                </div>
              </div>

              <br>
              <br>
              <!-- ADMINSITRADOR -->
              <div class="bc-container">
                <div class="bc-col-2">
                  <bc-icon  size="3xl"  name="user-check" [ariaHidden]="false" ariaLabel="name"></bc-icon>
                </div>
                <div class="Diseño-items">
                  <div class="bc-col-8">
                    <b>
                      <span>
                        Iniciador
                      </span>
                    </b>
                  </div>   
                  <div class="bc-col-4">
                    <p>{{novedad.contractId.idUserAdmin}}</p>
                  </div>
                </div>
              </div>
              <br>
              <br>
              <!-- Negociador -->
              <div class="bc-container">
                <div class="bc-col-2">
                  <bc-icon  size="3xl"  name="approving-user" [ariaHidden]="false" ariaLabel="name"></bc-icon>
                </div>
                <div class="Diseño-items">
                  <div class="bc-col-8">
                    <b>
                      <span>
                        Negociador asignado al contrato
                      </span>
                    </b>
                  </div>   
                  <div class="bc-col-4">
                    <p>{{novedad.contractId.idUserNegotiator}}</p>
                  </div>
                </div>
              </div>
              
            </div>

            <!-- TYPE REQUIEREMENT -->
            <div class="bc-col-6">
              <div class="bc-container">
                <div class="bc-col-2">
                  <bc-icon  size="3xl"  name="value" [ariaHidden]="false" ariaLabel="name"></bc-icon>
                </div>
                <div class="Diseño-items">
                  <div class="bc-col-8">
                    <b>
                      <span>
                        Tipo de requerimiento
                      </span>
                    </b>
                  </div>   
                  <div class="bc-col-9">
                    <p>{{novedad.typeRequirement}}</p>
                  </div>
                </div>
                
              </div>

              <br>
              <br>
              <br>
              
               
              <!-- Observaciones -->
              <div class="bc-container">
                <div class="bc-col-2">
                  <bc-icon  size="3xl"  name="chat" [ariaHidden]="false" ariaLabel="name"></bc-icon>
                </div>
                <div class="Diseño-items">
                  <div class="bc-col-3">
                    <b>
                      <span>
                        Observaciones
                      </span>
                    </b>
                  </div>   
                  <div class="bc-col-9">
                    <p> {{novedad.description}}</p>
                  </div>
                </div>
              </div>
             
            </div>
        </div>
        <!-- ACEPTAR EL FLUJO -->

        <div class="bc-container">
          <div *ngIf="novedad.status !== 'COMPLETADO' && novedad.status !== 'CERRADO' && novedad.status !== 'TRAMITADO' && novedad.status !== 'RECHAZADO' && novedad.status !== 'ACEPTADO'" >
          
            <div class="bc-row">
              <div class="bc-col-6">
                <div class="bc-margin-top-1">
                  <div>
                    <app-offcanvas-novedades-negociador
                      [novedad]="novedad"
                      [idContrato]="novedad.contractId.idContract"
                      [tipoRequerimiento]="novedad.typeRequirement"
                      [textButton]="'Gestionar flujo trabajo'"
                      [requirementId]="novedad.requirementId"
                    ></app-offcanvas-novedades-negociador>
                  </div>

                </div>
              </div>
              <div class="bc-col-6">
                <div class="bc-margin-top-1">
                  <div>
                    <app-novedad-negociador-accordion-rechazar
                  [novedad]="novedad"
                  [idContrato]="novedad.contractId.idContract"
                  [tipoRequerimiento]="novedad.typeRequirement"
                  [textButton]="'Rechazar flujo'"
                  [requirementId]="novedad.requirementId">
                  </app-novedad-negociador-accordion-rechazar>
                  </div>
                </div>
              </div>
            </div>

          
          </div>
      


        </div>
       
          <br>
    </div>


</div>