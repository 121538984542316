import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserGraphServices {

  constructor(private http: HttpClient) { }

  public getAllUserGraph () {
    return this.http.get<any>('https://graph.microsoft.com/v1.0/users');
  }

  public getCurrenUserGraph () {
    return this.http.get<any>('https://graph.microsoft.com/v1.0/me');
  }

  public getUserBydisplayNameGraph (displayName: string) {
    return this.http.get<any>('https://graph.microsoft.com/v1.0/me');
  }
}
