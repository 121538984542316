import { NgModule } from '@angular/core';
import { LogInComponent } from './log-in.component';
import { FormsModule } from '@angular/forms'
import { BcCardModule } from '@bancolombia/design-system-web/bc-card';


@NgModule({
  declarations: [
    LogInComponent
  ],
  imports: [
    FormsModule,
    BcCardModule
  ]
})
export class LogInModule { }
