<div class="bc-text-center bc-margin-top-4">
    <h2 >ERROR 501</h2>
    <h2 class="bc-margin-top-4">Pagina Actualmente en Mantenimiento</h2>

</div>
<div class="bc-margin-left-4" style="width: 200px;display:flex;justify-content:center" >
    <div style="width: 90px;height:90px">
        <img src="../../../assets/icons/ilustrations/il-process-mistake.svg ">
    </div>
    
</div>
