import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
//Pages
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { MisContratosComponent } from './pages/mis-contratos/mis-contratos.component';
import { MisNovedadesComponent } from './pages/mis-novedades/mis-novedades.component';
import { DetallesContratoComponent } from './pages/detalles-contrato/main-page/detalles-contrato.component';
import { OperadorContratosNovedadesComponent } from './pages/operador-contratos-novedades/operador-contratos-novedades.component';
import { OperadorSincronizacionDatosPlantaComponent } from './pages/operador-sincronizacion-datos-planta/operador-sincronizacion-datos-planta.component';
import { MsalGuard } from '@azure/msal-angular';
import { SolicitudesPendientesComponent } from './pages/solicitudes-pendientes/solicitudes-pendientes.component';
import { ServicioMensajeComponent } from './pages/servicio-mensaje/servicio-mensaje.component';
import { NoAutorizadoPageComponent } from './pages/no-autorizado-page/no-autorizado-page.component';
import { LogInComponent } from './pages/log-in/log-in.component';
import { SolicitudAntiguoComponent } from './pages/servicio-mensaje/solicitud-antiguo/solicitud-antiguo.component';
import { SolicitudAntiguoRechazarComponent } from './pages/servicio-mensaje/solicitud-antiguo-rechazar/solicitud-antiguo-rechazar.component';
import { SolicitudNuevoComponent } from './pages/servicio-mensaje/solicitud-nuevo/solicitud-nuevo.component';
import { SolicitudNuevoRechazarComponent } from './pages/servicio-mensaje/solicitud-nuevo-rechazar/solicitud-nuevo-rechazar.component';
import { MantenimientoComponent } from './pages/mantenimiento/mantenimiento.component';
import { AnalistaRiesgoViewComponent } from './pages/analista-riesgo-view/analista-riesgo-view.component';
import { MarketplaceComponent } from './pages/marketplace/marketplace.component';
import { OppenSupplyComponent } from './pages/oppen-supply/oppen-supply.component';
import { NovedadesNegociadorComponent } from './pages/novedades-negociador/novedades-negociador.component';
import { SincronizacionDatosComponent } from './pages/sincronizacion-datos/sincronizacion-datos.component';
import { MarkeplacePage } from './pages/markeplace/markeplace.component';
import { DashboardUserComponent } from './pages/dashboard/dashboard-user/dashboard-user.component';
import { DashboardAdminComponent } from './pages/dashboard/dashboard-admin/dashboard-admin.component';
import { MainPageComponent } from './pages/detalles-contrato-negociador/main-page/main-page.component';
import { MisContratosNegociadorComponent } from './pages/mis-contratos-negociador/mis-contratos-negociador.component';
import { InicioAdminNegociadorComponent } from './pages/inicio-admin-negociador/inicio-admin-negociador.component';
import { LogoutComponent } from './pages/pages/logout/logout.component';

const routes: Routes = [

  {
    path: '',
    component: InicioAdminNegociadorComponent,
    canActivate: [MsalGuard]
  },

  {
    path: 'dashboard',
    component:DashboardUserComponent,
    data: {
      breadcrumbs: {
        path: "dashboard",
        pathMatch: 'full',
        text: "Inicio"
      },
      permission: 'showDashboard', // Nombre del permiso asociado a esta opción del menú
      allowedRoles: ['ADMINISTRADOR DE CONTRATOS'] // Roles permitidos para ver esta opción del menú
    },
    canActivate: [MsalGuard]
  },
  {
    path: 'dashboardNegociador',
    component:DashboardAdminComponent,
    data: {
      breadcrumbs: {
        path: "dashboard",
        pathMatch: 'full',
        text: "Inicio"
      },
      permission: 'showDashboard', // Nombre del permiso asociado a esta opción del menú
      allowedRoles: ['ADMINISTRADOR DE CONTRATOS'] // Roles permitidos para ver esta opción del menú
    },
    canActivate: [MsalGuard]
  },
  //Contratos Adminsitrador
  {
    path: 'contratos',
    data: {
      breadcrumbs: {
        text: "Contratos"
      },
      permission: 'showContracts', // Nombre del permiso asociado a esta opción del menú
      allowedRoles: ['ADMINISTRADOR DE CONTRATOS'] // Roles permitidos para ver esta opción del menú
    },
    children: [
      {
        path: '',
        component: MisContratosComponent,
      },
      {
        path: 'detallesContrato/:id',
        component: DetallesContratoComponent,
        data: {
          breadcrumbs: {
            path: "contratos/detallesContrato/:id",
            text: "Detalles de contrato"
          }
        },
      },
    ],
    canActivate: [MsalGuard]
  },

  //Contratos Negociador

  {
    path: 'contratosNegociador',
    data: {
      breadcrumbs: {
        text: "Contratos"
      },
      permission: 'showContracts', // Nombre del permiso asociado a esta opción del menú
      allowedRoles: ['ADMINISTRADOR DE CONTRATOS'] // Roles permitidos para ver esta opción del menú
    },
    children: [
      {
        path: '',
        component: MisContratosNegociadorComponent,
      },
      {
        path: 'detallesContrato/:id',
        component: MainPageComponent,
        data: {
          breadcrumbs: {
            path: "contratosNegociador/detallesContrato/:id",
            text: "Detalles de contrato"
          }
        },
      },
    ],
    canActivate: [MsalGuard]
  },


  {
    path: 'novedades',
    component: MisNovedadesComponent,
    data: {
      breadcrumbs: {
        text: "Novedades",
      },
      permission: 'showNovedades', // Nombre del permiso asociado a esta opción del menú
      allowedRoles: ['ADMINISTRADOR DE CONTRATOS'] // Roles permitidos para ver esta opción del menú
    },
    canActivate: [MsalGuard]
  },

  {
    path:'novedadesnegociador',
    component: NovedadesNegociadorComponent,
    data: {
      breadcrumbs: {
        text: "Novedades ",
      },
      permission: 'showNovedades', // Nombre del permiso asociado a esta opción del menú
      allowedRoles: ['NEGOCIADOR/CATEGORY'] // Roles permitidos para ver esta opción del menú
    },
    canActivate: [MsalGuard]
  },


  {
    path: 'detallesContrato',
    component: DetallesContratoComponent,
    canActivate: [MsalGuard]
  },
  {
    path:'solicitudes/:idContrato/:idReceptor',
    component:SolicitudesPendientesComponent,
    data: {
      breadcrumbs:{
        text: "Solicitudes Pendientes",
      },
      permission: 'showNovedades', // Nombre del permiso asociado a esta opción del menú
      allowedRoles: ['ADMINISTRADOR DE CONTRATOS'], // Roles permitidos para ver esta opción del menú

    },
    canActivate: [MsalGuard]
  },
  {
    path:'solicitudes',
    component:SolicitudesPendientesComponent,
    data: {
      breadcrumbs:{
        text: "Solicitudes Pendientes",
      },
      permission: 'showNovedades', // Nombre del permiso asociado a esta opción del menú
      allowedRoles: ['ADMINISTRADOR DE CONTRATOS'], // Roles permitidos para ver esta opción del menú

    },
    canActivate: [MsalGuard]
  },
  {
    path:'solicitudMensaje',
    component:ServicioMensajeComponent,
    canActivate: [MsalGuard]
  },
  {
    path:'SolicitudAprobadaAntiguo',
    component:SolicitudAntiguoComponent,
    canActivate:[MsalGuard]
  },
  {
    path:'SolicitudRechazadaAntiguo',
    component:SolicitudAntiguoRechazarComponent,
    canActivate:[MsalGuard]
  },
  {
    path:'SolicitudAprobadaNuevo',
    component:SolicitudNuevoComponent,
    canActivate:[MsalGuard]
  },
  {
    path:'SolicitudRechazadaNuevo',
    component:SolicitudNuevoRechazarComponent,
    canActivate:[MsalGuard]
  },
 

  /*{
    path:'**',
    component:NoAutorizadoPageComponent,
  },*/
  
  //Operador Portal
  {
    path:'novedadesOperadorPortal',
    component: OperadorContratosNovedadesComponent,
    data: {
      allowedRoles: ['NEGOCIADOR/CATEGORY']
    }
  },
  {
    path:'sincronizacionOperadorPortal',
    component:OperadorSincronizacionDatosPlantaComponent,
    data: {
      allowedRoles: ['NEGOCIADOR/CATEGORY']
    }
  },
  {
    path:'noAutorizado',
    component:NoAutorizadoPageComponent,
    data: {
      allowedRoles: ['NEGOCIADOR/CATEGORY']
    }
  },
  
  {
    path:'sincronizaciondatos',
    component: SincronizacionDatosComponent,
    data: {
      allowedRoles: ['NEGOCIADOR/CATEGORY']
    }
  },


  //Analista Riesgo
  {
    path:'analista',
    component:AnalistaRiesgoViewComponent,
    data: {
      breadcrumbs:{
        text: "Analista De Riesgo ",
      },
      //permission: 'show', // Nombre del permiso asociado a esta opción del menú
      //allowedRoles: ['ADMINISTRADOR DE CONTRATOS'], // Roles permitidos para ver esta opción del menú

    },
    canActivate:[MsalGuard]
  },

  //OpenSupply
  {
    path:'opensupply',
    component:OppenSupplyComponent,
    data: {
      breadcrumbs:{
        text: "Open Supply",
      },
      //permission: 'show', // Nombre del permiso asociado a esta opción del menú
      //allowedRoles: ['ADMINISTRADOR DE CONTRATOS'], // Roles permitidos para ver esta opción del menú

    },
    canActivate: [MsalGuard]
  },

  //MarketPlace
  {
    path:'marketplace',
    component:MarketplaceComponent,
    data: {
      breadcrumbs:{
        text: "MarketPlace",
      },
      permission: 'show', // Nombre del permiso asociado a esta opción del menú
      allowedRoles: ['ADMINISTRADOR DE CONTRATOS'], // Roles permitidos para ver esta opción del menú

    },
    //canActivate: [MsalGuard]
  },

  {
    path:'logout',
    component:LogoutComponent,
    data: {
    }
  },




];


const isIframe = window !== window.parent && !window.opener;




@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Don't perform initial navigation in iframes or popups
    //initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
    initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled'
  })],

  exports: [RouterModule]

})

export class AppRoutingModule { }
