/**
 * Este archivo contiene los parámetros de autenticación. El contenido de este archivo
 * es similar en otras bibliotecas de MSAL.js. Estos parámetros se utilizan para inicializar
 * las configuraciones de Angular y MSAL Angular en el archivo app.module.ts.
 */
import { LogLevel, Configuration, BrowserCacheLocation, InteractionType } from '@azure/msal-browser';
import { environment } from '../../environments/environment';
const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
/**
 * Objeto de configuración que se pasa a la instancia de MSAL al crearla.
 * Para obtener una lista completa de los parámetros de configuración de MSAL.js, visita:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig: Configuration = {
    auth: environment.msalConfig.auth,
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // Configura la ubicación de la caché. "sessionStorage" es más seguro, pero "localStorage" te da SSO entre pestañas.
        storeAuthStateInCookie: isIE, // Establecer en "true" si tienes problemas en IE11 o Edge.
    },
    system: {
        loggerOptions: {
            loggerCallback(logLevel: LogLevel, message: string) {
                //console.log(message);
            },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false
        }
    }
}
/**
 * Un objeto silentRequest opcional puede ser utilizado para lograr SSO silencioso
 * entre aplicaciones proporcionando una propiedad "login_hint".
 */
export const silentRequest = {
    scopes: ["openid", "profile"],
    loginHint: "example@domain.net"
};