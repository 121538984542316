import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';
import { ContratosService } from 'src/app/services/api-services/contratos-services/contratos.service';
import { CONTRACTITEM, CONTRACTLOAD } from './contract.action';
 
@Injectable()
export class contractEffects {
 
  loadMovies$ = createEffect(() => this.actions$.pipe(
    ofType(CONTRACTLOAD),
    mergeMap((data) => this.contratosService.getContractByIdContract(data.idContract)
      .pipe(
        map(response => CONTRACTITEM({item: response})),
        catchError(() => EMPTY)
      ))
    )
  );
 
  constructor(
    private actions$: Actions,
    private contratosService: ContratosService
  ) {}
}