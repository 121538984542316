import { Component, Input } from '@angular/core';
import { formatearFecha } from 'src/app/components/utils/formato-fecha.componente';

@Component({
  selector: 'app-sincronizacion-datos',
  templateUrl: './sincronizacion-datos.component.html',
  styleUrls: ['./sincronizacion-datos.component.css']
})
export class SincronizacionDatosComponent {

  tittle = "Sincronización Datos";
  fecha: any;
  protected currentUser: any;
  public requirement: any[] = [];
orientation: any;

  ngOnInit() {
    this.mostrarHora();
    this.currentUser = JSON.parse(sessionStorage.getItem('userCurrentGraph') || '{}');
  }

  mostrarHora() {
    this.fecha = formatearFecha(new Date());
    setInterval(() => {
      this.fecha = formatearFecha(new Date());
    }, 1000);
  }

  selectedTab: number = 1;

  selectTab(tabNumber: number) {
    this.selectedTab = tabNumber;
  }

  //Tabla

  movements: any[] = [[], [], []];
  tableAccCurrentPage = [];
  tableAccCurrentPageEmpty = [];
  dataActionClicked: any = null;

  _pages: any[] = [
    [
      {
        name: 'Cristian',
        lastname: 'Vargas',
        email: 'test@gmail.com',
        status: 'active'
      },
      {
        name: 'Felipe',
        lastname: 'Vargas',
        email: 'test@gmail.com',
        status: 'active'
      },
      {
        name: 'Luis',
        lastname: 'Vargas',
        email: 'test@gmail.com',
        status: 'active'
      },
      {
        name: 'Tomas',
        lastname: 'Vargas',
        email: 'test@gmail.com',
        status: 'active'
      },
      {
        name: 'Caro',
        lastname: 'Vargas',
        email: 'test@gmail.com',
        status: 'active'
      },
    ],
    [
      {
        name: 'Marce',
        lastname: 'Vargas',
        email: 'test@gmail.com',
        status: 'active'
      },
      {
        name: 'Heli',
        lastname: 'Vargas',
        email: 'test@gmail.com',
        status: 'active'
      },
      {
        name: 'Cristian',
        lastname: 'Vargas',
        email: 'test@gmail.com',
        status: 'active'
      },
      {
        name: 'Cristian',
        lastname: 'Vargas',
        email: 'test@gmail.com',
        status: 'active'
      },
      {
        name: 'Felipe',
        lastname: 'Vargas',
        email: 'test@gmail.com',
        status: 'active'
      },
    ],
    [
      {
        name: 'Luis',
        lastname: 'Vargas',
        email: 'test@gmail.com',
        status: 'active'
      },
      {
        name: 'Tomas',
        lastname: 'Vargas',
        email: 'test@gmail.com',
        status: 'active'
      },
      {
        name: 'Caro',
        lastname: 'Vargas',
        email: 'test@gmail.com',
        status: 'active'
      },
      {
        name: 'Marce',
        lastname: 'Vargas',
        email: 'test@gmail.com',
        status: 'active'
      },
      {
        name: 'Heli',
        lastname: 'Vargas',
        email: 'test@gmail.com',
        status: 'active'
      },
    ],
    [
      {
        name: 'Luis',
        lastname: 'Vargas',
        email: 'test@gmail.com',
        status: 'active'
      }
    ],
  ];





  totalAmounts = [
    { title: 'Saldo 1', amount: '$ 5.000.000' },
    { title: 'Saldo 2', amount: '$ 7.000.000' },
    { title: 'Saldo 3', amount: '$ 12.000.000' },
    { title: 'Saldo 4', amount: '$ 24.000.000' },
  ];



  pages: Array<any> = [];
  currentPage: any[] = [];

  constructor() {
    this.currentPage = this._pages[0];
    this.createPageForTableAccordion();
    this.tableAccCurrentPage = this.movements[0];
  }

 
  rowSelected(data: any) {
    return data;
  }

  export(rows: any) {
    return rows;
  }

  delete(rows: any) {
    return rows;
  }

  onChangePage(data: any) {
    this.tableAccCurrentPage = this.movements[data.CurrentPage - 1];
  }
  onChangePageEmpty(data: any) {
    this.tableAccCurrentPageEmpty = this.movements[data.CurrentPage - 1];
  }

  onChangePageTableScroll(data: any) {
    this.currentPage = this._pages[data.CurrentPage - 1];
  }


  optionSelectedScroll(data: any) {
    if (data.optionSeleted === 'lock') {
      data.rowData.status = 'disabled';
    } else {
      data.rowData.status = 'active';
    }
  }

  onClickAction(data: any) {
    this.dataActionClicked = data;
  }

  createPageForTableAccordion(): void {
    for (let i = 0; i < this.movements.length; i++) {
      const mov = this.movements[i];
      for (let j = 0; j < 5; j++) {
        mov.push(
          {
            name: `${i}_${j} Bancolombia Ahorros 1`,
            lastMovement: `${i}_${j} 02/12/2004 2`,
            exchangeBalance: `${i}_${j} $ 9.000.000 3`,
            totalBalance: `${i}_${j} $ 21.000.000 4`,
            aviableBalance: `${i}_${j} $ 10.000.000 5`,
            name_2: `${i}_${j} Bancolombia Ahorros 6`,
            lastMovement_2: `${i}_${j} 02/12/2004 7`,
            exchangeBalance_2: `${i}_${j} $ 9.000.000 8`,
            totalBalance_2: `${i}_${j} $ 21.000.000 9`,
            aviableBalance_2: `${i}_${j} $ 10.000.000 10`,
          },
        )

      }
    }
  }
  
  
  

}
