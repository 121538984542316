<div class="color-header">
    <h4>Demanda</h4>
</div>

<div class="bc-flex bc-p-2 bc-w-100 bc-gap-lg-5 bc-direction-column bc-direction-lg-row">
    <bc-input-select componentId="bc-select2" [multiple]="true" [formatType]="'full'" [values]="proveedoresSelectValues"
        label="Añadir mas columnas" span="" [filter]="true" class="bc-w-100"
        (valueOutput)="onSelectTableColumn($event)">
    </bc-input-select>
</div>
<div class="bc-row">
    <bc-table-container class="bc-col-12 bc-w-100" *ngIf="1 > 0" [dataTable]=1 [cellOptions]="cellOption">

        <bc-table-header [title]="''" [actionsSelections]="actions">
            <bc-paginator type="full" [totalRegisters]="totalRegisters" [perPage]="perpage"
                (onChangePage)="onChangePage($event)" [perMultiple]="'10'">
            </bc-paginator>
        </bc-table-header>
        <bc-table-content>
            <div class="table-container">
                <table caption="tabla" bc-table [selection]='true' [scroll]="true" [scroll]="true" [sort]="true"
                    [dropdownHtml]="true">
                    <thead>
                        <tr>
                            <th scope="row" bc-cell type="total">
                                <h5>{{markeplaceEnum.IDFORM}}</h5>
                            </th>
                            <th scope="row" bc-cell type="total">
                                <h5>{{markeplaceEnum.STATE}}</h5>
                            </th>
                            <th scope="row" bc-cell type="total">
                                <h5>{{markeplaceEnum.REQUESTRUN}}</h5>
                            </th>
                            <th scope="row" bc-cell type="total">
                                <h5>{{markeplaceEnum.NAME}}</h5>
                            </th>
                            <th *ngIf="selectTableColumn.REQUEST" scope="row" bc-cell type="total">
                                <h5>{{markeplaceEnum.REQUEST}}</h5>
                            </th>
                            <th *ngIf="selectTableColumn.DESCRIPTION" scope="row" bc-cell type="total">
                                <h5>{{markeplaceEnum.DESCRIPTION}}</h5>
                            </th>
                            <th *ngIf="selectTableColumn.BADGET" scope="row" bc-cell type="total">
                                <h5>{{markeplaceEnum.BADGET}}</h5>
                            </th>
                            <th *ngIf="selectTableColumn.MONEY" scope="row" bc-cell type="total">
                                <h5>{{markeplaceEnum.MONEY}}</h5>
                            </th>
                            <th *ngIf="selectTableColumn.PROVIDER" scope="row" bc-cell type="total">
                                <h5>{{markeplaceEnum.PROVIDER}}</h5>
                            </th>
                            <th *ngIf="selectTableColumn.TIPOLOGY" scope="row" bc-cell type="total">
                                <h5>Tipología</h5>
                            </th>
                            <th *ngIf="selectTableColumn.REQUIREMENTLEGALY" scope="row" bc-cell type="total">
                                <h5>Es requerimiento legal</h5>
                            </th>
                            <th *ngIf="selectTableColumn.PRIORITIZE" scope="row" bc-cell type="total">
                                <h5>¿Priorizado por el CAE? </h5>
                            </th>
                            <th *ngIf="selectTableColumn.ROL" scope="row" bc-cell type="total">
                                <h5>Rol Asesor </h5>
                            </th>
                            <th *ngIf="selectTableColumn.TYPEPROCES" scope="row" bc-cell type="total">
                                <h5>Tipo de proceso </h5>
                            </th>
                            <!--th *ngIf="selectTableColumn.DAYDURATION" scope="row" bc-cell type="total"><h5>Duración en dias </h5></th-->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let contract of currentPage" (click)="selectItemCheckbox(contract)">
                            <td>
                                <bc-checkbox class="bc-my-4 bc-mx-4" id={{contract}} name="dfasdfas"
                                    [value]="'1'"></bc-checkbox>
                                <h6>{{contract["ID_lista"]}}</h6>
                            </td>
                            <td bc-cell>
                                <div class="estatus_content">
                                    <p>{{generateStateAsignation(contract)}}</p>
                                </div>
                                <!--h6>{{ contract["Estado"] }}</h6-->
                            </td>
                            <td bc-cell>
                                <h6> {{ generateRequesRun(contract) }}</h6>
                            </td>

                            <td bc-cell>
                                <h6> {{ contract["Nombre_area_y_ceco"] }}</h6>
                            </td>
                            <td *ngIf="selectTableColumn.REQUEST" bc-cell>
                                <h6> {{ contract["Nombre_solicitante"] }}</h6>
                            </td>

                            <td *ngIf="selectTableColumn.DESCRIPTION" bc-cell>
                                <h6> {{ contract["Detalle_producto_o_servicio"] }}</h6>
                            </td>
                            <td *ngIf="selectTableColumn.BADGET" bc-cell>
                                <h6> {{ contract["Tiene_presupuesto"] }}</h6>
                            </td>
                            <td *ngIf="selectTableColumn.MONEY" bc-cell>
                                <h6> {{contract["Valor_presupuesto"]}} </h6>
                            </td>
                            <td *ngIf="selectTableColumn.PROVIDER" bc-cell>
                                <h6> {{ contract["Proveedores_conocidos"] }}</h6>
                            </td>

                            <td *ngIf="selectTableColumn.TIPOLOGY" bc-cell>
                                <h6> {{ contract["Tipología a la que corresponde (Alta \/ Media \/ Baja)"] }}</h6>
                            </td>
                            <td *ngIf="selectTableColumn.REQUIREMENTLEGALY" bc-cell>
                                <h6> {{ contract["Requerimiento_legal"] }}</h6>
                            </td>
                            <td *ngIf="selectTableColumn.PRIORITIZE" bc-cell>
                                <h6 style="color: red;"> {{ "No lo encuento" }}</h6>
                            </td>
                            <td *ngIf="selectTableColumn.ROL" bc-cell>
                                <h6> {{ contract["Rol_asesor"] }}</h6>
                            </td>
                            <td *ngIf="selectTableColumn.TYPEPROCES" bc-cell>
                                <h6 style="color: red;"> {{ "No lo encuento" }}</h6>
                            </td>
                            <!--td *ngIf="selectTableColumn.DAYDURATION" bc-cell>
                            <h6> {{ contract["Duración (Días)"] }}</h6>
                        </td-->
                        </tr>
                    </tbody>
                </table>
            </div>
        </bc-table-content>
    </bc-table-container>
    <div class="color-header">
        <h4>Detalle del requerimiento ID: {{requirementDeatail}}</h4>
    </div>
    <!-- Pirmera Row  -->
    <div class="bc-col-4 ">
        <bc-loader *ngIf="isLoadDataServiceOrProcess.capacity == true" speedLoader='1.5' sizeLoader='xl'></bc-loader>
        <div bc-card *ngIf="isLoadDataServiceOrProcess.capacity == false">
            <div class="bc-card-body" [formGroup]="form">
                <div class="card-content__header">
                    Gestión de capacidad
                </div>
                
                <!-- Activar proximo MP -->
                <div class="bc-container-24">
                    <div class="bc-row">
                        <!-- Tercera Parte - El interruptor (switch) se mantiene en la esquina izquierda -->
                        <div class="bc-col-2">
                            <label class="switch">
                                <input type="checkbox" [(ngModel)]="isSwitchOn" (change)="toggleSwitch()">
                                <span class="slider"></span>
                            </label>
                        </div>
                        <div class="bc-col-10">
                            <div *ngIf="isComponentVisible">
                                <!-- Primera Parte -->
                                <div class="bc-col-6">
                                    <div>
                                        <h1 class="bc-card-title bc-align-left center-text__container">Activar para
                                            próximo MP</h1>
                                    </div>
                                </div>
                                <!-- Segunda Parte -->
                                <div class="bc-col-3">
                                    <div>
                                        <h6 class="bc-card-title bc-align-left center-text__container">
                                            <div class="date-container">
                                                <input type="date" id="selectedDate" [(ngModel)]="selectedDate"
                                                    (change)="onDateChange($event)">
                                            </div>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Negociador Asignado -->
                <div class="bc-container-24 bc-margin-top-2">
                    <div class="bc-row">
                        <div class="bc-col-6">
                            <div>
                                <h6 class="bc-card-title bc-align-left center-text__container">Negociador asignado :
                                </h6>
                            </div>
                        </div>
                        <div class="bc-col-6">
                            <div>
                                <h6 class="bc-card-title bc-align-left center-text__container">Monica Maria Martinez
                                    Medina</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Horas Estimadas -->
                <div class="bc-container-24 bc-margin-top-2">
                    <div class="bc-row">
                        <div class="bc-col-6">
                            <div>
                                <h6 class="bc-card-title bc-align-left center-text__container">Horas Estimadas : </h6>
                            </div>
                        </div>
                        <div class="bc-col-6">
                            <div class="input_horas">
                                <bc-form-field class="bc-col-7">
                                    <input bc-input aria-label="custom-aria-label" placeholder="" type="text"
                                        formControlName="horas_estimadas" />
                                    <div *ngIf="form.controls['horas_estimadas'].invalid && (form.controls['horas_estimadas'].dirty || form.controls['horas_estimadas'].touched)"
                                        class="alert">
                                        <span class="closebtn"
                                            onclick="this.parentElement.style.display='none';">&times;</span>
                                        Este campo es requerido
                                    </div>
                                </bc-form-field>
                            </div>
                            <!-- Aquí va el icono SVG -->
                            <div class="bc-margin-top-3">
                                <span class="icon-container">
                                
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Semanas Requeridas -->
                <div class="bc-container-24 bc-margin-top-2">
                    <div class="bc-row">
                        <div class="bc-col-6">
                            <div>
                                <h6 class="bc-card-title bc-align-left center-text__container">Semanas Requeridas: </h6>
                            </div>
                        </div>
                        <div class="bc-col-6">
                            <div class="input_horas">
                                <bc-form-field class="bc-col-7">
                                    <input bc-input aria-label="custom-aria-label" placeholder="" type="text"
                                        formControlName="semanas_requeridas" />
                                    <div *ngIf=" form.controls['semanas_requeridas'].invalid && (form.controls['semanas_requeridas'].dirty || form.controls['semanas_requeridas'].touched)"
                                        class="alert">
                                        <span class="closebtn"
                                            onclick="this.parentElement.style.display='none';">&times;</span>
                                        Este campo es requerido
                                    </div>
                                </bc-form-field>
                            </div>
                            <!-- Aquí va el icono SVG -->
                            <div class="bc-margin-top-3">
                                <span class="icon-container">
                                   
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Observaciones -->

                <div class="bc-container-24 bc-margin-top-2">
                    <div class="bc-row">
                        <div class="bc-col-6">
                            <div>
                                <h6 class="bc-card-title bc-align-left center-text__container">Observaciones </h6>
                            </div>
                        </div>
                    </div>
                    <div class="bc-margin-top-4">
                        <div class="input_horas">
                            <bc-form-field>
                                <input bc-input aria-label="custom-aria-label" placeholder="" type="textarea"
                                    formControlName="observaciones" rows="3">
                            </bc-form-field>
                        </div>
                    </div>
                </div>

                <button bc-button (click)="onCapacityRegister(dataInput)">
                    Registrar
                </button>
            </div>
        </div>
    </div>
    <div class="bc-col-8">
        <bc-loader *ngIf="isLoadDataServiceOrProcess.fileUploadAriba == true" speedLoader='1.5'
            sizeLoader='xl'></bc-loader>
        <div bc-card style="margin-left: 10px;" *ngIf="isLoadDataServiceOrProcess.fileUploadAriba == false">
            <div class="bc-card-body">
                <div class="card-content-requirement__header">
                    Información asociada al requerimiento
                </div>

                <div class="bc-container-16">
                    <div class="bc-row control--button__sharepoint">
                        <div class="bc-col-lg-2"></div>
                        <div class="bc-col-lg-10 ">
                            <div class="bc-col-7"></div>
                            <div class="bc-item-row">
                                <bc-icon-button [type]="'ghost'" [size]="'large'" [customIcon]="'add-doc'"
                                    [ariaLabel]="'Texto Función'"></bc-icon-button>
                                <bc-icon-button [type]="'ghost'" [size]="'large'" [customIcon]="'pencil-edit'"
                                    [ariaLabel]="'Texto Función'"></bc-icon-button>
                                <bc-icon-button [type]="'ghost'" [size]="'large'" [customIcon]="'components'"
                                    [ariaLabel]="'Texto Función'"></bc-icon-button>
                                <bc-icon-button [type]="'ghost'" [size]="'large'" [customIcon]="'remove'"
                                    [ariaLabel]="'Texto Función'"></bc-icon-button>
                                <input id='fileid' #uploader (change)="onUploadFileAriba($event,dataInput)" type='file'
                                    hidden />
                                <bc-icon-button [type]="'ghost'" [size]="'large'" [customIcon]="'upload'"
                                    [ariaLabel]="'Texto Función'" (click)="uploader.click()"></bc-icon-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bc-container-16 bc-margin-top-5">
                    <div class="bc-row">

                        <!-- Primera Parte Del Row -->


                        <div class="bc-col-lg-6 bc-col-md-12">
                            <!-- Solicitud En Curso -->
                            <div class="bc-container-16">
                                <div class="bc-row">
                                    <div class="bc-col-6">
                                        <div>¿Solicitud en curso?</div>
                                    </div>
                                    <div class="bc-col-6">
                                        <div class="input_button">
                                            <div class="requestrun_button">
                                                <div class="requestrun-button__item" [ngStyle]="{
                                                backgroundColor: generateRequesRunColor(dataInput).color,
                                            }"></div>
                                                <h5>{{generateRequesRunColor(dataInput).reques}}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--div style="color: red;" class="bc-col-6">
                                Renovación/ Requerimiento nuevo
                            </div>
                            <div class="bc-col-6">
                                <bc-form-field>
                                    <input 
                                    bc-input aria-label="custom-aria-label" 
                                    placeholder="" 
                                    [ngModel] = "dataInput['Renovación / Requerimiento Nuevo']"
                                    type="text" 
                                    value=""  />
                                </bc-form-field>                                
                            </div-->
                            <!-- Renovacion Requerimiento nuevo -->
                            <div class="bc-container-16 bc-margin-top-3">
                                <div class="bc-row">
                                    <div class="bc-col-6">
                                        <div>
                                            Renovación
                                        </div>
                                    </div>
                                    <div class="bc-col-6">
                                        <div class="bc-item">
                                            <div class="input_categoria">
                                                <bc-form-field>
                                                    <input bc-input aria-label="custom-aria-label" placeholder=""
                                                        type="text" value="" />
                                                </bc-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Categoria -->
                            <div class="bc-container-16 bc-margin-top-1">
                                <div class="bc-row">
                                    <div class="bc-col-6">
                                        <div>
                                            Categoría
                                        </div>
                                    </div>
                                    <div class="bc-col-6">
                                        <div class="bc-item">
                                            <div class="input_categoria">
                                                <bc-form-field>
                                                    <input bc-input aria-label="custom-aria-label"
                                                        [ngModel]="dataInput['Categoría']" placeholder="" type="text"
                                                        value="" />
                                                </bc-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="bc-container-16">
                                <div class="bc-row">
                                    <div class="bc-col-6">
                                        <div>
                                            Familia asociada al servicio
                                        </div>
                                    </div>
                                    <div class="bc-col-6">
                                        <div class="input_Familia">
                                            <bc-form-field>
                                                <input bc-input aria-label="custom-aria-label"
                                                    [ngModel]="dataInput['Familias']" placeholder="" type="text"
                                                    value="" />
                                            </bc-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Segunda Parte Del Row -->
                        <!-- Fecha De Inicio De La Negociación -->
                        <div class="bc-col-lg-6 bc-col-md-12">
                            <div class="bc-container-16">
                                <div class="bc-row">
                                    <div class="bc-col-6">
                                        <div>Fecha de inicio</div>
                                    </div>
                                    <div class="bc-col-6">
                                        <div class="input_Fecha">
                                            <div class="bc-col-9">
                                                <bc-form-field>
                                                    <input bc-input aria-label="custom-aria-label"
                                                        [ngModel]="dataInput['Fecha Asignación/Delegación']"
                                                        placeholder="" type="text" value="" />
                                                </bc-form-field>
                                            </div>
                                        </div>
                                        <!-- Aquí va el icono SVG -->
                                        <div class="bc-margin-top-3">
                                            <span class="icon-container">
                                                
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Esta Vencido? -->

                            <div class="bc-container-16">
                                <div class="bc-row">
                                    <div class="bc-col-6">
                                        <div>¿Está vencido?</div>
                                    </div>
                                    <div class="bc-col-6">
                                        <div class="input_Vencido">
                                            <div class="bc-col-6">
                                                <!-- {{generateDefeated(dataInput, true)}} -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Activar para próximo MP -->
                            <div class="bc-container-16 bc-margin-top-3" *ngIf="isComponentVisible">
                                <div class="bc-row">
                                    <div class="bc-col-6">
                                        <div>Activar para próximo MP</div>
                                    </div>
                                    <div class="bc-col-6">
                                        <div class="input_mp">
                                            <div>
                                                <span>{{ selectedDate }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- SR -->
                            <div class="bc-container-16 bc-margin-top-3">
                                <div class="bc-row">
                                    <div class="bc-col-6">
                                        <div>SR</div>
                                    </div>
                                    <div class="bc-col-6">
                                        <div class="input_sr">
                                            <div class="bc-col-6">
                                                <bc-form-field>
                                                    <input bc-input aria-label="custom-aria-label"
                                                        [ngModel]="dataInput['SR_ARIBA']" placeholder="" type="text"
                                                        value="" />
                                                </bc-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Estado ARIBA -->

                            <div class="bc-container-16">
                                <div class="bc-row">
                                    <div class="bc-col-6">
                                        <div>Estado Ariba
                                            <bc-icon *ngIf="dataAribaEXEL.length == 0" style="color: red;" size="xs"
                                                name="alert" aria-hidden="true" aria-label="affinity">
                                            </bc-icon>
                                        </div>
                                    </div>
                                    <div class="bc-col-6">
                                        <div>
                                            <div class="bc-col-6">
                                                <div class="input_ariba">
                                                    <bc-form-field>
                                                        <input bc-input aria-label="custom-aria-label"
                                                            [ngModel]="dataInput['Estado_SR']" placeholder=""
                                                            type="text" value="" />
                                                    </bc-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Priorizacion -->
                            <div class="bc-container-16">
                                <div class="bc-row">
                                    <div class="bc-col-6">
                                        <div>Priorización</div>
                                    </div>
                                    <div class="bc-col-6">
                                        <div>
                                            <div class="bc-col-6">
                                                <div class="input_priorizacion">
                                                    <bc-form-field>
                                                        <input bc-input aria-label="custom-aria-label"
                                                            [ngModel]="dataInput == {} ? '' :   dataInput['Priorización']='A3'"
                                                            placeholder="" type="text" value="" />
                                                    </bc-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>