<bc-header [menuInteraction]="true" headerType="bc-header-wrapper-black" [fixed]="true" *ngIf="userDataAvailable" >
  <bc-header-left logoName="grupo-bancolombia-horizontal" channelName="ABA"></bc-header-left>
  <bc-header-list>
    <li class="bc-header-nav-item bc-reverse-d-lg-none" role="button" aria-label="'Empresa X 1, NIT: 0123 456 789 - 1'">
      <div>
        <bc-float-menu>
          <div class="bc-float-menu-toggle bc-icon bc-flex bc-align-items-center" aria-controls="myDropdown3">
            <a class="bc-link">
              <em class="bc-icon bc-sm">shield-user</em>
              <div class="bc-header-nav-item-text">
                <p>Rol Actual</p>
                  <small>{{this.userData.rol}}</small>
              </div>
            </a>
          </div>
        </bc-float-menu>
      </div>
    </li>

    <li class="bc-header-nav-item">
      <div>
        <bc-float-menu>
          <div class="bc-float-menu-toggle bc-flex bc-align-items-center" aria-controls="myDropdown3">
            <div>
              <span>
                {{ userData.name }}
                
              </span>
            </div>
          </div>
        </bc-float-menu>
      </div>
    </li>

    <bc-header-item class="icono-regresar" icon="quit" text="Cerrar sesión" (click)="logout()"></bc-header-item>

  

  </bc-header-list>


</bc-header>