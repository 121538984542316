import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventServiceService {
  private estadoSeleccionadoSubject = new Subject<string | null>();
  estadoSeleccionado$ = this.estadoSeleccionadoSubject.asObservable();

  private estadoSeleccionado: string | null = null;

  setEstadoSeleccionado(estado: string | null) {
    this.estadoSeleccionado = estado;
    this.estadoSeleccionadoSubject.next(this.estadoSeleccionado);
  }

  getEstadoSeleccionado(): string | null {
    return this.estadoSeleccionado;
  }
}
