import { Component, Input } from '@angular/core';
import { BcAlertComponent } from '@bancolombia/design-system-web/bc-alert';
import { BcDialogService } from '@bancolombia/design-system-web/bc-services';
import { NovedadEstado } from 'src/app/models/novedad.interface';
import { ContratosService } from 'src/app/services/api-services/contratos-services/contratos.service';
import { RequirementService } from 'src/app/services/api-services/requirement-services/requierement.service';

@Component({
  selector: 'app-novedad-accordion-content',
  templateUrl: './novedad-accordion-content.component.html',
  styleUrls: ['./novedad-accordion-content.component.css']
})
export class NovedadAccordionContentComponent {

  @Input('novedad') novedad!: any; 
  dataTable: any[] = [];
  @Input('contract') contract!: any; 
  @Input('requirement') requirement!: any; 
  

  constructor(
    private dialogService: BcDialogService,
    private contratosService: ContratosService,
    private requirementService: RequirementService){
    
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    console.log('Valor de novedad:', this.novedad);
    console.log('valor del cont' , this.contract);
    console.log('valor del req' , this.requirement);

    /*this.contratosService.getAllContractByIdContrac(this.novedad.contractId.idContract).subscribe(
      {
        next: (success) => this.contract = success.data,
        
       
      }
      
      
    )
    */

    
    


   
  }

  private alertAba(type: String, menssage: String, tittle: string): void {
    const dialogRef = this.dialogService.open(BcAlertComponent, {
      id: tittle,
      type: type,
      text: menssage,
      timeout: 7000,
    });

    dialogRef.onResult().subscribe(
      (closed) => {},
      (dismissed) => {},
      () => {}
    );
  }

  
}
