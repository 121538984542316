<bc-off-canvas #offCanvasComponent [tipoOffcanvas]="'vertical'" [backdrop]="''">
  <div offCanvasContent>
    <div class="bc-text-center">
      <h2>Reportar cambio estado</h2>
    </div>
    <div class="bc-margin-top-2">
      <div class="Diseño-items">
        <p>En esta opción podrás reportar la novedad de “Cambio de Estado” del contrato. Al seleccionar “Reportar
          novedad de estado”, estarás enviando la solicitud para que el negociador del contrato pueda actualizar
          la información.</p>
      </div>
    </div>
    <div class="bc-margin-top-4">
      <div class="bc-row">
        <div class="bc-container">
          <div class="bc-col-6">
            <div class="Diseño-items">
              <p>Id del contrato</p>
            </div>
          </div>
          <div class="bc-col-6">
            <div class="Diseño-items">
              <p>{{contractDetail.idContract}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="bc-margin-top-4">
      <div class="bc-container">
        <div class="bc-row">
          <div class="bc-col-6">
            <label for="selectInput">
              <div class="Diseño-items">
                <p>
                  Selecciona una opción:
                </p>
              </div></label>
          </div>
          <div class="bc-col-6 select-container">
            <select id="selectInput" class="custom-select" (change)="onOptionSelected($event)">
              <option value=""></option>
              <option value="CERRADO">Cerrado</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="bc-margin-top-2">
      <div>
        <h5>Observaciones cambio estado</h5>
      </div>
      <div class="bc-margin-top-1">
        <bc-form-field>
          <input bc-input aria-label="custom-aria-label" type="text" value="" [(ngModel)]="inputData" required
            id="00" />
        </bc-form-field>
      </div>
      <!-- Alerta para observaciones vacías -->
      <div *ngIf="!inputData" class="bc-margin-top-1">
        <p class="error-message">No se ingresaron observaciones</p>
      </div>
    </div>

    <div class="bc-margin-top-2">
      <button class="bc-button-primary bc-button-default"
        (click)="onNoveltySend(requirementTypeEnum.REPORTE_DE_NOVEDAD_DE_ESTADO_DE_CONTRATO, requirementStatusEnum.Pendiente)">
        Reportar novedad del estado
      </button>
    </div>
    <div class="bc-margin-top-2">
      <button class="bc-button-primary bc-button-default" (click)="closeOffCanvas()">
        Cancelar
      </button>
    </div>

  </div>

</bc-off-canvas>
<button class="bc-button-primary bc-button-small" (click)="openOffCanvas()">
  {{textButton}}
</button>