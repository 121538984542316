<div class="bc-col-12">
    <div class="bc-search" id="searchId">
        <div class="bc-search-content">
            <h5 class="bc-w-100 bc-margin-top-3">Busqueda de novedades</h5>
            <div class="bc-search-button-expanded" (click)="toggleExpandSearchAdvance()">
                <bc-badge [id]="'badgeFilter'" [sizeBadge]="'small'" [icon]="'filters'" [position]="'top-left'"
                    [color]="'red'" [border]="false">
                </bc-badge>
            </div>
            <div class="bc-content-advanced-search {{searchAdvToggleClass}}">
                <div class="content-form-search">
                    <div class="bc-flex bc-p-2 bc-w-100 bc-gap-lg-5 bc-direction-column bc-direction-lg-row">
                      <bc-input-select componentId="bc-select-id-novedad" [multiple]="true" [formatType]="'full'" [values]="novedadSelectValues"
                      label="ID de la novedad" span="" (valueOutput)="updateSelectValue($event, 'idNovedad')" [filter]="true" class="bc-w-100"> 
                      </bc-input-select>

                      <bc-input-select componentId="bc-select-id-contrato" [multiple]="true" [formatType]="'full'" [values]="contratosSelectValues"
                      label="ID del contrato" span="" (valueOutput)="updateSelectValue($event, 'contratos')" [filter]="true" class="bc-w-100"> 
                      </bc-input-select>

                    </div>
                    <div class="bc-flex bc-p-2 bc-w-100 bc-gap-lg-5 bc-direction-column bc-direction-lg-row">
                        <bc-input-select componentId="bc-select-tipo-novedad" [multiple]="true" [formatType]="'full'" [values]="tipoNovedadSelectValues"
                        label="Tipo de novedad" span="" (valueOutput)="updateSelectValue($event,  'tipoNovedad')" [filter]="true" class="bc-w-100"> 
                        </bc-input-select>
                        <bc-input-select componentId="bc-select-estado-novedad" [multiple]="true" [formatType]="'full'" [values]="estadoNovedadSelectValues"
                        label="Estado de novedad" span="" (valueOutput)="updateSelectValue($event, 'estadoNovedad')" [filter]="true" class="bc-w-100"> 
                        </bc-input-select>
                    </div>
                </div>
                <div class="bc-search-button"><button class="bc-button-primary bc-button-small" aria-label="Boton"
                        bc-button="" sizebutton="small" role="button" tabindex="0">Buscar</button></div>
            </div>
        </div>
        <div class="bc-col-xl-12 bc-col-lg-12 bc-col-md-12 bc-col-sm-12 bc-col-xs-12">
            <div class="bc-search-result">
                <div class="bc-content-show-result"></div>
                <div class="bc-search-content-clear"><span class="bc-search-clear">Limpiar búsqueda</span></div>
            </div>
        </div>
        <div class="bc-col-xl-12 bc-col-lg-12 bc-col-md-12 bc-col-sm-12 bc-col-xs-12">
            <div class="bc-search-result-null">
                <div><img class="bc-logo--sm" alt="iconMenu" src="/assets/icons/pictogramas/search.svg" /></div>
                <div><label>No se han encontrado resultados para su búsqueda</label>
                    <ul>
                        <li>Pruebe diferentes palabras claves</li>
                        <li>Pruebe con un slo criterio de búsqueda</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>