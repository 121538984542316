import { Component } from '@angular/core';
import { formatearFecha } from 'src/app/components/utils/formato-fecha.componente';
import { BcAlertComponent } from '@bancolombia/design-system-web/bc-alert';
import { BcDialogService } from '@bancolombia/design-system-web/bc-services';
import { NovedadEstado } from 'src/app/models/novedad.interface';

import { NovedadesService } from 'src/app/services/api-services/novedades-services/novedades.service';
import { RequirementService } from 'src/app/services/api-services/requirement-services/requierement.service';
import { AccordionColumn } from '@bancolombia/design-system-web/bc-accordion';

import { UserService } from 'src/app/services/api-services/user-services/user.service';
import { FindByIdContractAndReceptorModel, RequirementModel, SaveRequirementModel } from 'src/app/models/requirement.model';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { SendDataInforamtionServices } from 'src/app/services/commo-service/sendData-services';



@Component({
  selector: 'app-novedades-negociador',
  templateUrl: './novedades-negociador.component.html',
  styleUrls: ['./novedades-negociador.component.css']
})
export class NovedadesNegociadorComponent {

  
  tittle = "En esta página encuentras las novedades que te han reportado";
  fecha: any;
  protected currentUser: any;
  public requirement: any[] = [];
  public novedad: any[] = [];
  requirementnov: any[] = [];

  uniqueContractIds: Set<string> = new Set<string>();
  userData: any = {};
  changeStateRequierement = new Object();
  dataLoaded: boolean = false;


  changeStateRequirement = {
    idRequiremet: 'requirement.requirementId',
    status: 'requirement.contractId.status'
  }


  ngOnInit() {
    this.mostrarHora();
    this.currentUser = JSON.parse(sessionStorage.getItem('userCurrentGraph') || '{}');
    this.userData = this.userService.getCurrentUserData();
    this.loadData();

  }

  constructor(
    private novedadesServices: NovedadesService,
    private requirementService: RequirementService,
    private dialogService: BcDialogService,
    private userService: UserService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private sendDataInforamtionServices: SendDataInforamtionServices
  ) {
    this.novedadesServices.searchNovedadesByAdmin()
  }

  private loadData() {
    const idContract = this.route.snapshot.paramMap.get("idContrato");
    const idReceptor = this.route.snapshot.paramMap.get("idReceptor");
  
    const requirementParamnNov: any = {
      email: this.userData.data.email,
      type: "RECEPTOR"
    };
  
    const requirementParam: any = {
      email: this.userData.data.email,
      type: "REQUEST"
    };
  
    // Utiliza un solo servicio para obtener datos
    this.requirementService.findByIdContractAndReceptor(requirementParamnNov).subscribe(
      (response) => {
        // Aquí puedes manejar la respuesta como sea necesario
        this.novedad = response.data;
        this.dataLoaded = true;
      },
      (error) => {
        // Manejar errores si es necesario
      }
    );

    this.requirementService.findByIdContractAndReceptor(requirementParam).subscribe(
      (response) => {
        this.requirement = response.data;

      },
      (error) => {
        //this.alertAba("error","No se pudo cargar las novedades","Error")
      }
    )
  }
  

  private filterUniqueContracts(data: any[]): any[] {
    const filteredData: any[] = [];
    data.forEach((item: any) => {
      const idContract = item?.contractId?.idContract;
      if (idContract && !this.uniqueContractIds.has(idContract)) {
        this.uniqueContractIds.add(idContract);
        filteredData.push(item);
      }
    });
    return filteredData;
  }

  mostrarHora() {
    this.fecha = formatearFecha(new Date());
    setInterval(() => {
      this.fecha = formatearFecha(new Date());
    }, 1000);
  }

  getNovedades(): NovedadEstado[] {
    return this.novedadesServices.resultados
  }

  columns: Array<AccordionColumn & { field?: string }> = [
    { value: 'Solicitud nueva asignación de Contrato' },
  ];

  columnsForRequirement(requirement: any): Array<AccordionColumn> {
    return [
      { value: requirement.status },
    ];
  }

  /**
  Obtiene las columnas para un panel de acordeón para una novedad.
  @param {NovedadEstado} novedadEstado - La novedad y su estado actual.
  @returns {Array<AccordionColumn>} - Las columnas del panel de acordeón.
  */
  getAccordionColumns(novedadEstado: NovedadEstado): Array<AccordionColumn> {
    const cols: Array<AccordionColumn> = [
      //{value: `Contrato ${novedadEstado.contrato.idContrato}`, alignLeft: true},
      //{value: `${novedadEstado.tipoNovedad.nombre}`},
      //{value: `${novedadEstado.historialEstados[0].estado.nombre}`},
    ]

    return cols
  }

  getAccordionColumnsSol(saveRequirementModel: SaveRequirementModel): Array<AccordionColumn> {
    const cols: Array<AccordionColumn> = [
      //{value: `Contrato ${novedadEstado.contrato.idContrato}`, alignLeft: true},
      //{value: `${novedadEstado.tipoNovedad.nombre}`},
      //{value: `${novedadEstado.historialEstados[0].estado.nombre}`},
    ]

    return cols
  }



  private alertAba(type: String, menssage: String, tittle: string): void {
    const dialogRef = this.dialogService.open(BcAlertComponent, {
      id: tittle,
      type: type,
      text: menssage,
      timeout: 7000,
    });

    dialogRef.onResult().subscribe(
      (closed) => { },
      (dismissed) => { },
      () => { }
    );
  }


  

  



}
