import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main/main.component';
import { ComponentsModule } from '../components/components.module';
import { BcPageHeaderModule } from '@bancolombia/design-system-web/bc-page-header';
import { AppRoutingModule } from '../app-routing.module';

@NgModule({
  declarations: [
    MainComponent
    
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    BcPageHeaderModule,
    AppRoutingModule
    
  ],
  exports: [
    MainComponent
  ]
})
export class LayoutModule { }
