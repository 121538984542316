<bc-off-canvas #offCanvasComponent [tipoOffcanvas]="'vertical'" [backdrop]="''">
  <div offCanvasContent>
    <h3 class="bc-text-center">Gestión de requerimientos</h3>
    <br>
    <p>
      Recuerda revisar que la solicitud cumpla con los requisitos establecidos para su gestión:
    </p>
    <br>
    <!--Inputs-->
    <br>
    <h5>Tipo de requerimiento</h5>
    <p>{{novedad.nombre}}</p>
    <br>
    <h5>ID Contrato</h5>
    <p>{{contrato.idContrato}}</p>
    <br>
    <h5>Recuerda</h5>
    <p>Antes de cerrar el requerimiento revisa que la información se encuentre actualizada en Ariba y en Conectados en caso de ser necesario.</p>
    <br>
    <bc-form-field>
      <input id="02" bc-input aria-label="text-area" type="text" placeholder="" value="" required>
      <label for="02">Actualizar estado de la solicitud:</label>
    </bc-form-field>
    <br>
    <br>
    <div>
      <bc-form-field>
        <input id="02" bc-input aria-label="text-area" type="text" placeholder="" value="" required>
        <label for="02">Comentarios</label>
      </bc-form-field>
    </div>

    <br>
    <br>
    <button class="bc-button-primary bc-button-default" (click)="openOffCanvas()">
      Enviar
    </button>
    <br>
    <br>
    <button class="bc-button-secondary bc-button-default" (click)="closeOffCanvas()">
      Cancelar
    </button>
  </div>
</bc-off-canvas>

<button class="bc-button-primary bc-button-small" (click)="openOffCanvas()">
  {{textButton}}
</button>