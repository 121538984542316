<div class="Encabezado">
    <img src="../../../../assets/images/Cabezote-MarketPlace.png">
</div>
<bc-page-header [date]='fecha' [title]="tittle" [bgWhite]="false" [className]="'bc-bg-light'">
</bc-page-header>

<tab-aba-component [items]="tabListItems" (selectTab) = "tabSelected.emit($event)"></tab-aba-component>
<div class="fondo-dashboard">
    
    <app-staffing [tabSelected]="tabSelected"></app-staffing>

</div>