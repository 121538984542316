import { Component } from '@angular/core';

@Component({
  selector: 'app-servicio-mensaje',
  templateUrl: './servicio-mensaje.component.html',
  styleUrls: ['./servicio-mensaje.component.css']
})
export class ServicioMensajeComponent {

}
